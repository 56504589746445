import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { MenuPerfil } from "../perfil/MenuPerfil";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { ListMenu } from "../../theme/Component/ListMenu";
import { UserRol } from "../perfil/UserRol";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { DropDown } from "../../theme/Component/DropDown";
import { toast } from "react-toastify";

export const RoleUsuario = () => {

  const single = Singleton.getInstance()
  const adminService = new AdminService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [fun, setFun] = useState(0);
  const [bean, setBean] = useState<any>({});
  const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
  const [modal1, setModal1] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
  const [modal2, setModal2] = useState<any>({ name: 'Menu', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
  const [modalUsr, setModalUsr] = useState<any>({ name: 'Usuarios', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

  useEffect(() => {
    getRoleCatalog();
  }, [])

  const getRoleCatalog = () => {
    setLoading(true);
    adminService.getRoleCatalog(null).subscribe((resp: any) => {
      setLoading(false);

      if (resp.DataBeanProperties.ObjectValue) {
        setLista(resp.DataBeanProperties.ObjectValue);
      }
    });
  };

  const updateRole = () => {

    setLoading(true);
    adminService.updateRole(bean).subscribe((resp: any) => {
      setLoading(false);

      if (resp.DataBeanProperties.ObjectValue) {
        setModal1({ ...modal1, ['view']: false });
        getRoleCatalog();
      }
    });

  }
  const deleteRole = () => {

    setLoading(true);
    adminService.deleteRole(bean).subscribe((resp: any) => {
      setLoading(false);

      if (resp) {
        setModalConfirm({ ...modalConfirm, ['view']: false });
        getRoleCatalog();
      }
    });

  }
  const agregarItem = () => {
    let b = {
      Name: '',
      Description: '',
      State: 1,
      Since: single.getCurrenDate(),
    }
    setBean(b);
    setModal1({ ...modal1, ['view']: true });
  }

  const idBean = 'IDRole';
  const head = [
    { title: 'ID', property: 'IDRole' },
    { title: 'Nombre', property: 'Name' },
    { title: 'Descripción', property: 'Description' },
    { title: 'Por defecto', property: 'IsOpenRole' },
    { title: 'Estado', property: 'StateName' },
  ]
  const listaBotones = () => {
    const botones = [

      { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModal1({ ...modal1, "view": true }) } },
      { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
      { titulo: 'Configurar Funciones', icono: 'ri-file-list-line', visible: true, evento: () => { setModal2({ ...modal2, "view": true, "name": bean.Name }); setFun(1) } },
      { titulo: 'Usuario en el Rol', icono: 'ri-file-user-line', visible: true, evento: () => { setModalUsr({ ...modalUsr, "view": true, "name": "Rol: " + bean.Name }) } },

    ]
    return (
      <ListMenu listaBotones={botones} />
    )
  }

  const eventChange=(e:any)=>{

      if(e.id === 1)
      {
        adminService.clearUserMenuCache(0).subscribe(
          (resp:any)=>{
              toast.success("Cahce actualizada.");
          }
        )
      }

  }

  const iconButtonsItem = [

    { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

  ]
  const actionsDropDown = [
    { id: 1, name: 'Borrar Cache', permiso: null, activo: true },    
  ];
  const buttons = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
        </div>
      </div>
    )
  }
  return (
    <div className="p-2 px-5">
      <BasicPage titulo="Roles de Sistema">
        <div className="row">
          <div className="col-md-12">
            <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setBean}></BasicTable>
          </div>
        </div>
        <Modal modal={modal1} updateModal={setModal1} eventModal={updateRole}>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">Nombre</label>
              <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
            </div>

            <div className="col-md-12">
              <label className="form-label">Descripción</label>
              <textarea name="Description" className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
            </div>
            <div className="col-md-6">
              <BasicSwitch estado={bean.IsOpenRole ?? false} eventChange={(e) => { setBean({ ...bean, 'IsOpenRole': e }) }} label="Rol por defecto"></BasicSwitch>
            </div>
            <div className="col-md-6">
              <label>Estado</label>
              <select className="form-select" value={bean.State ?? undefined} onChange={(e) => { setBean({ ...bean, 'State': parseInt(e.target.value) }) }}>
                <option value={0}>ACTIVO</option>
                <option value={1}>INACTIVO</option>
              </select>
            </div>

          </div>
        </Modal>

        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteRole}>
          <p>Eliminar Rol {bean.Name}</p>
        </ModalConfirm>

        <Modal modal={modal2} updateModal={setModal2}>
          <div className="row">
            <div className="col">
              {fun === 1 &&
                <MenuPerfil role={bean}></MenuPerfil>
              }
            </div>
          </div>
        </Modal>

        <Modal modal={modalUsr} updateModal={setModalUsr}>
          <UserRol IDRol={bean.IDRole} />
        </Modal>
      </BasicPage>
    </div>


  );
};
