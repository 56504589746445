import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Singleton } from '../../../core/services/Singleton';


interface Props {
    key?: number,
    DataBeanProperties: any
}

export const PadreMenu = ({ DataBeanProperties, key }: Props) => {

    const single = Singleton.getInstance()
    const [link, setLink] = useState('');

    return (

        <li style={{ listStyleType: 'none' }} className="nav-item" key={DataBeanProperties.IDApplicationType}>
            <a className="nav-link menu-link title-link" key={Singleton.getInstance().getRandomInt()} href={'#sidebar' + DataBeanProperties.IDApplicationType} data-bs-toggle="collapse" role="button" aria-expanded="false"
                aria-controls={'sidebar' + DataBeanProperties.IDApplicationType}>
                <div className="row">
                    <div className="col">
                        <div style={{ display: 'flex' }}>
                            <div> <i className={`${DataBeanProperties.ClassForName} align-middle me-2 colori`} key={'icone1'}></i></div>
                            <div>  <span className='mt-2' data-key={'t-' + DataBeanProperties.IDApplicationType}>{DataBeanProperties.Name}</span></div>
                        </div>
                    </div>
                </div>
            </a>
            <div className="collapse menu-dropdown" id={'sidebar' + DataBeanProperties.IDApplicationType} >
                <ul className="nav nav-sm flex-column">
                    {DataBeanProperties.Hijos.map((element: any, _i: number) => (
                        <li className={link === element.URL ? 'nav-item activeLink' : 'nav-item '} key={_i} style={{ margin: '0', padding: '0' }}
                            onClick={() => {
                                setLink(element.URL);
                            }}>
                            <NavLink key={single.getRandomInt()} to={`/app${element.URL}`} style={{ margin: '0', padding: '0' }} >
                                <div style={{ display: 'flex', marginLeft: '15px' }}>
                                    <div><i className='ri-arrow-drop-right-line' style={{ color: 'white' }}></i></div>
                                    <div> <span className='nav-link title-link-son' data-key={element.IDLn}>  {element.Name} </span></div>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </li>

    );
}