import { ContractService } from "../../../../core/services/ContractService";
import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { BpmServiceall } from "../../../../core/services/BpmServiceall";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Application } from "../../../admin/menu/Application";
import { Riskitem } from "./RiskItem";
import { GetRisk } from "./GetRisk";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";




export const Matrizriesgos = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalR, setModalR] = useState<any>({ name: 'Riesgos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});
    const [existingValues, setExistingValues] = useState<string[]>([]);
    const [tipoVista, settipoVista] = useState<any>(1);


    const idBean = 'IDRiskCategory';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getRiskCategoryCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            RiskType: 1,
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteRiskCategory(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateRiskCategory(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Riesgo', icono: 'ri-profile-line', visible: true, evento: () => { setModalR({ ...modalR, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
        // {nombre:'Agregar',icono:'ri-file-add-line',evento:agregarItem}

    ]



    return (
        <>
            <BasicPage titulo='Matriz de Riesgos'>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" value={bean.CodeID} onChange={(e) => { setBean({ ...bean, ['CodeID']: e.target.value }) }} />

                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            <Modal modal={modalR} updateModal={setModalR}>
                <div className="row">
                    <div className="col-md-12">
                        <Riskitem idRiskCategory={bean[idBean]} />
                    </div>
                </div>
            </Modal>
            {/* 
           <div className="row">
                <div className="col-xxl-12">
                    <div className="card ">
                        <div className="card-header">
                            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#app" role="tab" aria-selected="true" onClick={() => { settipoVista(1) }}>
                                        <i className="fas fa-home"></i>Categoria de Riesgos
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" href="#tipoaplicacion" role="tab" aria-selected="false" onClick={() => { settipoVista(2) }}>
                                        <i className="fas fa-home"></i> Riesgos
                                    </a>
                                </li>
                                

                            </ul>
                        </div>
                        <div className="card-body p-4">
                            <div className="tab-content">
                                <div className="tab-pane active show" id="app" role="tabpanel">
                                    
                                {tipoVista === 1 && <GetRisk/>}
                                </div>
                                <div className="tab-pane active show" id="tipoaplicacion" role="tabpanel">
                                {tipoVista === 2 && <Riskitem/>}
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}