

interface Props {
    formulario: any;
}

export const Forma7Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>CONSULTA LISTA VINCULANTES Y VERIFICACIÓN - USO EXLCUSIVO CAJA HONOR</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-6 color-celda">
                    <label className='titul-form'>Nombre Completo del funcionario:</label>
                    <p>{formulario.nombrefuncio}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className='titul-form'>No. Cedula de Ciudadania del funcionario:</label>
                    <p>{formulario.ciudadfuncionario}</p>
                </div>

            </div>

            <div className="row">
                <div className="col-12 color-celda">
                    <label className='titul-form'>Fecha de consulta - dd/mm/año</label>
                    <p>{formulario.fechacreacion}</p>
                </div>
            </div>

            {/* <PrintJson  json={formulario}/> */}
        </>
    )
} 