
import { Forma1Pdf } from "./FormasPdf/Forma1Pdf";
import { Forma2Pdf } from "./FormasPdf/Forma2Pdf";
import { Forma3Pdf } from "./FormasPdf/Forma3Pdf";
import { Forma4Pdf } from "./FormasPdf/Forma4Pdf";
import { Forma5Pdf } from "./FormasPdf/Forma5Pdf";
import { Forma6Pdf } from "./FormasPdf/Forma6Pdf";
import { Forma7Pdf } from "./FormasPdf/Forma7Pdf";
import { Forma8Pdf } from "./FormasPdf/Forma8Pdf";
import { Forma9Pdf } from "./FormasPdf/Forma9Pdf";


interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const ResumenConsentimiento = ({ supplier, formSupplier, updateInfo }: Props) => {

    return (
        <>

            <div className="row">
                <div className="col">
                    {formSupplier.forma1.isNatural &&
                        <Forma1Pdf formulario={formSupplier.forma1} />
                    }

                    {formSupplier.forma2.isJuridic &&
                        <Forma2Pdf formulario={formSupplier.forma2} />
                    }

                    {(formSupplier.forma1.isNatural == undefined && formSupplier.forma2.isJuridic == undefined) &&
                        <>
                            <Forma1Pdf formulario={formSupplier.forma1}></Forma1Pdf>
                            <Forma2Pdf formulario={formSupplier.forma2}></Forma2Pdf>
                        </>
                    }
                    <Forma3Pdf formulario={formSupplier.forma3} />
                    <Forma4Pdf formulario={formSupplier.forma4} />
                    <Forma5Pdf formulario={formSupplier.forma5} />
                    <Forma6Pdf formulario={formSupplier.forma6} />
                    <Forma7Pdf formulario={formSupplier.forma7} />
                    <Forma8Pdf formulario={formSupplier.forma8} />
                    <Forma9Pdf formulario={formSupplier.forma9} />
                </div>
            </div>
            {/* <PrintJson json={formSupplier}/> */}
        </>
    )
}