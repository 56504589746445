import { ContractService } from "../../../core/services/ContractService";
import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmService } from "../../../core/services/BpmService";
import { AdminService } from "../../../core/services/AdminService";
import { ListMenu } from "../../theme/Component/ListMenu";
import { ActivateAccount } from "./ActivateAccount";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";


export const AbstractAccount = () => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const serviceAdmin = new AdminService();
    const [lista, setLista] = useState([]);
    const [listaRol, setListaRol] = useState([]);
    const [nit, setNit] = useState(0);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [tipoinput, setTipoinput] = useState(0);
    const [surname, setSurname] = useState('');
    const [bean, setBean] = useState<any>({});
    const [pass, setPass] = useState<any>({ pas1: '', pas2: '' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal1, setModal1] = useState<any>({ name: 'Cuenta de Usuario', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDAccount';





    const setRowBean = (bean: any) => {
        console.log(bean);
        setBean(bean);
    }

    const getListaBN = () => {
        setLoading(true);
        service.getAccountByNit(nit).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }
    const getListaBN2 = (cc: any) => {
        setLoading(true);
        service.getAccountByNit(cc).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let temp = resp.DataBeanProperties.ObjectValue;
                    if (temp.length > 0) {
                        setBean(temp[0].DataBeanProperties);
                    }
                }
                setLoading(false);
            }
        );
    }

    const getListaS = () => {
        setLoading(true);
        service.getAccount(null, surname).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const getListaN = () => {
        setLoading(true);
        service.getAccount(name, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Nit: null,
            Name1: '',
            Name2: '',
            Surname1: '',
            Surname2: '',
            Tel: '',
            eMail: '',
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const updateItemUser = (b: any) => {


        setLoading(true);
        serviceAdmin.desactivateUserAccount(b.IDAccount).subscribe(
            (resp: any) => {
                serviceAdmin.activateUserAccount(b.IDAccount, b.eMail, b.Nit).subscribe(
                    (resp: any) => {
                        setMessage('Usuario Activado Exitosamente');
                        setLoading(false);
                        service.updateAbstractAccount(bean).subscribe(
                            (resp: any) => {
                                setLoading(false);
                                if (resp.DataBeanProperties.ObjectValue) {

                                }
                            });
                    }
                )
            }
        )


    }
    const updateItem = () => {
        setModal({ ...modal, "view": false });
        if (bean.IDAccount) {
            setLoading(true);
            service.updateAbstractAccount(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getListaS();
                        getListaN();
                        getListaBN();
                    }
                });
        } else {
            setLoading(true);
            service.createAccount(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getListaS();
                        getListaN();
                        getListaBN();
                    }
                });
        }
    }

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Cédula/Nit', property: 'Nit' },
        { title: 'Nombre', property: 'EntityName' },
        { title: 'Correo', property: 'eMail' },
        { title: 'Teléfono', property: 'Tel' },
    ]

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <>
                                    <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                    <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                                    <BasicButton value="Activar Cuenta" icon="ri-user-follow-line" eventClick={() => {
                                        setModal1({ ...modal1, ['view']: true });
                                    }} ></BasicButton>
                                </>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            {titulo:'Activar cuenta',icono:'ri-file-user-line',visible:true,evento:()=>{setModal1({...modal1,"view":true})}},

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', visible:single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    ]

    return (
        <>
            <BasicPage titulo="Gestión de Usuarios">
                <div className="row">
                    <div className="col-md-4">
                        <label>Filtrar por:</label>
                        <select className="form-control" value={tipoinput} onChange={(e: any) => { setTipoinput(e.target.value) }}>
                            <option value={0}>...</option>
                            <option value={1}>Identificación</option>
                            <option value={2}>Nombres</option>
                            <option value={3}>Apellidos</option>

                        </select>
                    </div>
                    <div className="col-md-4">
                        {tipoinput == 3 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setSurname(e.target.value)} className="form-control inputSurname " placeholder="Filtrar pór Apellido" />
                            </>

                        }
                        {tipoinput == 2 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setName(e.target.value)} className="form-control inputName " placeholder="Filtrar por Nombre" />
                            </>

                        }
                        {tipoinput == 1 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setNit(e.target.value)} className="form-control inputNit " placeholder="Filtrar por Cedula / Nit" />
                            </>

                        }
                    </div>

                    <div className="col-md-4 mt-4">

                        {tipoinput == 3 &&

                            <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaS} ></BasicButton>

                        }
                        {tipoinput == 2 &&
                            <>

                                <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaN} ></BasicButton>
                            </>}
                        {tipoinput == 1 &&
                            <>

                                <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaBN} ></BasicButton>
                            </>}
                    </div>



                </div>
                <div className="row">


                </div>
            </BasicPage>

            <BasicTable  listButtons={listaBotones()} iconButtons={iconButtonsItem}  head={head} body={lista} rowSet={setRowBean} />

            <Modal modal={modal1} updateModal={setModal1} eventModal={updateItemUser}>


                <ActivateAccount account={bean} idRole={0} idAccount={bean.IDAccount} updateRol={updateItemUser} />
                {/* <div className="row">
                    <div className="col-md-12">
                        <SelectRol idSelector={0} onChange={(e:number)=>{
                            setBean({...bean,"RoleID":e})
                        }}></SelectRol>
                    </div>
                    <div className="col-md-12">
                        <label>Usuario</label>
                        <input type="text" className="form-control" readOnly value={bean.eMail} />
                    </div>
                    <div className="col-md-6">
                        <label>Contraseña</label>
                        <input type="password" className="form-control"  value={pass.pas1} onChange={(e)=>{setPass({...pass,"pas1":e.target.value})}}/>
                    </div>
                    <div className="col-md-6">
                        <label>Repita Contraseña</label>
                        <input type="password" className="form-control"  value={pass.pas2} onChange={(e)=>{setPass({...pass,"pas2":e.target.value})}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Alert clase="warning">{message}</Alert>
                    </div>
                </div> */}

            </Modal>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">No. Identificación *</label>
                        <input type="text" className="form-control" value={bean.Nit} onBlur={(e) => {
                            console.log("Valor cambiado ", e.target.value);
                            getListaBN2(e.target.value);
                        }} onChange={(e) => {
                            setBean({ ...bean, ['Nit']: e.target.value })
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Nombre *</label>
                        <input type="text" className="form-control" value={bean.Name1} onChange={(e) => { setBean({ ...bean, ['Name1']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Segundo Nombre </label>
                        <input type="text" className="form-control" value={bean.Name2} onChange={(e) => { setBean({ ...bean, ['Name2']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Apellido *</label>
                        <input type="text" className="form-control" value={bean.Surname1} onChange={(e) => { setBean({ ...bean, ['Surname1']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Segundo Apellido *</label>
                        <input type="text" className="form-control" value={bean.Surname2} onChange={(e) => { setBean({ ...bean, ['Surname2']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Celular *</label>
                        <input type="text" className="form-control" value={bean.Tel} onChange={(e) => { setBean({ ...bean, ['Tel']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Correo Electronico *</label>
                        <input type="text" className="form-control" value={bean.eMail} onChange={(e) => { setBean({ ...bean, ['eMail']: e.target.value }) }} />
                    </div>
                    {/* <div className="col-md-12">                    
                        <label className="form-label">Lugar de expedición de documento *</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e)=>{setBean({...bean,['Name']:e.target.value})}}/>
                    </div>                                                                                       */}

                </div>
            </Modal>
        </>
    )
}