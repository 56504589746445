import { SupplierService } from "../../../core/services/SupplierService";
import { useEffect, useState } from 'react';
import { BasicTable } from "../../theme/Component/BasicTable";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    idSupplier: number;
}
export const HistorySupplier = ({ idSupplier }: Props) => {

    const service = new SupplierService();
    const { setLoading } = useDashboardStore();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});

    const head = [
        { title: 'Funcionario', property: 'EntityName', visible: true },
        { title: 'Evento', property: 'Event', visible: true },
        { title: 'Adjunto', property: 'URL', visible: true },
        { title: 'Fecha', property: 'Since', visible: true }

    ]

    useEffect(() => {
        getLista();
    }, [idSupplier])

    const getLista = () => {
        setLoading(true);
        service.getSupplierHistoryRender(idSupplier).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp: any = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.Attach) {
                            let attach = JSON.parse(element.DataBeanProperties.Attach);
                            element.DataBeanProperties.URL = "<a target='_blank' href='" + attach.URL + "'>" + attach.Name + "</a>";
                        } else {
                            element.DataBeanProperties.URL = "Sin Adjunto";
                        }
                    });
                    setLista(tmp);
                }


                setLoading(false);
            }
        )
    }

    return (
        <>
            {/* <PrintJson json={lista}/> */}
            <div className="row">
                <div className="col">
                    <BasicTable head={head} body={lista} rowSet={setBean}></BasicTable>
                </div>
            </div>
        </>
    )
}