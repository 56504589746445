import React, { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: number;
    onChange: any;
}

export const SelectorJsonPropertyDocType = ({ idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState<number | null>(null);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, []);

    useEffect(() => {
        onChange(id);
    }, [id]);

    const setValue = (e: any) => {
        setID(e);
        onChange(e);
    }

    const getLista = () => {
        service.getJsonPropertyDocumentType(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                } else {
                    console.error('La respuesta del servicio no contiene ObjectValue:', resp);
                }
            },
            (error: any) => {
                console.error('Error al obtener la lista:', error);
            }
        );
    }

    return (
        <>
            <label>Tipo de documento</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={null} key={Singleton.getInstance().getRandomInt()}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => (
                        <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                    ))
                }
            </select>
        </>
    )
}
