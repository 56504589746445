import { useState,useEffect } from 'react';
import { PrintJson } from '../theme/Component/PrintJson';
import { FileInput } from '../theme/Component/FileInput';

interface Props{
    ListDocuments:any;
    updateList:Function;
}
export const ListDocuments=({ListDocuments,updateList}:Props)=>{

    const [lista,setLista] = useState<any>([]);
    const [file,setFile] = useState<any>({});

    useEffect(()=>{

        console.log(ListDocuments);
        

        setLista(ListDocuments != null && ListDocuments != undefined ?  JSON.parse(ListDocuments) : []);
        
        
    },[ListDocuments])

    // useEffect(()=>{
    //     addFile(file);
        
    // },[file])

    const addFile=()=>{
        let l = lista;
        l.push(file);
        console.log(l);        
        setLista(l);
        updateList(l);
        setFile({});
    }

    const removeFile=(file:any)=>{
        
        console.log("elimiando ",file.Media);
        
        const nuevaLista = lista.filter((objeto:any) => objeto.Media !== file.Media);
        updateList(nuevaLista);
        setLista(nuevaLista);
    }

    return(
        <>
         <div className="row">
            <div className="col-md-8">
                <FileInput label='Documento Adjunto' onCreate={setFile}/> 
            </div>
            <div className="col-md-4">
            <button style={{marginTop:'38px'}} disabled={!file.FileMap} className='btn btn-primary' onClick={addFile}>Agregar</button>
            </div>
         <div className="col-md-12 mt-4">
                <ul className="list-group">
                   {Array.isArray(lista) && lista.map((acc:any,index:number)=>{
                    return(
                        <li key={index} className="list-group-item " aria-disabled="true">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <i className='ri-attachment-line'></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    {acc.Since} <br />  
                                    <a href={acc.URL} target='_blank'><strong className='ml-4'>{acc.Name}</strong></a>
                                </div>
                                <div className="flex-shrink-0">
                                    {/* <button onClick={()=>{removeFile(acc)}}> Eliminar</button> */}
                                    <span className="text-danger cursor" onClick={()=>{removeFile(acc)}}>Eliminar</span>
                                </div>
                            </div>
                        </li>
                    )
                   })}

                </ul>
            </div>
         </div>
        {/* <PrintJson json={lista}/> */}
        </>
    )

}