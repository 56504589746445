import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { BpmService } from '../../../core/services/BpmService';
import { SelectTramite } from './SelectTramite';
import { BasicButton } from '../../theme/Component/BasicButton';
import { Alert } from '../../theme/Component/Alert';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { SelectorTramites } from '../config/Componentes/SelectorTramites';
import { SelectorOffice } from '../config/Componentes/SelectorOffice';
import { SelectCharacterization } from '../config/Componentes/SelectCharacterization';

export const SolicitarImp = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const bpm = new BpmService();
    const [idBusinessClass, setIDBusinessClass] = useState<number>(4)
    const [variables, setVariables] = useState<any>({
        Tramite: 0,
        Oficina: 0,
        Account: single.getAccountID(),
        Description: '',
        Caracterizacion: 6
    });
    const [tramite, setTramite] = useState<any>({});
    const [vista, setVista] = useState(1);
    const [validado, setValidado] = useState<boolean>(true);

    const createBusinessProcess = () => {
        setLoading(true);
        bpm.createBusinessProcess(variables.Tramite, variables.Oficina, variables.Account, variables.Description, "", true, variables.Caracterizacion).subscribe(
            (resp: any) => {
                console.log(resp);
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setTramite(resp.DataBeanProperties.ObjectValue);
                    setVista(2);
                } else {
                    setVista(1);
                }

            }
        )
    }

    const validar = (e: number) => {
        if (e != 0 && e != null && !Number.isNaN(e)) {
            setValidado(false);
        }
        else {
            setValidado(true);
        }
    }

    return (
        <div className="container">
            {vista === 1 &&
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectorOffice idSelector={variables.Oficina} titulo="Seleccione una sucursal" onChange={(e: any) => { setVariables({ ...variables, "Oficina": e }) }}></SelectorOffice>
                        </div>
                        <div className="col-md-6">
                            <SelectCharacterization idSelector={variables.Caracterizacion} onChange={(e: any) => { setVariables({ ...variables, "Caracterizacion": e }) }} titulo='Caracterizaciones a agregar'></SelectCharacterization>
                        </div>
                        <div className="col-md-6">
                            <SelectorTramites titulo="Clase de tramites" idSelector={idBusinessClass} onChange={(e: any) => { setIDBusinessClass(e); validar(null); }} />
                        </div>
                        <div className="col-md-6">
                            <SelectTramite _idCharacterization={variables.Caracterizacion} _idBusinessClass={idBusinessClass} onChange={(e: number) => {
                                setVariables({ ...variables, "Tramite": e });
                                validar(e);
                            }} />
                        </div>
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea className='form-control' value={variables.Description} onChange={(e) => {
                                setVariables({ ...variables, "Description": e.target.value });
                            }}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <BasicButton disable={validado} icon='ri-send-plane-fill' eventClick={() => {
                                createBusinessProcess();
                            }}>Iniciar</BasicButton>
                        </div>
                    </div>
                </>
            }
            {vista === 2 &&
                <Alert clase='success'>
                    <strong>Se ha radicado un trámite</strong>
                    <p>
                        <b># </b>{tramite?.DataBeanProperties.AlphaCode} <br />
                        por favor de seguimiento en su bandeja de Gestión.
                    </p>
                </Alert>
            }
        </div>
    )
}