import { GenericApproval } from './custom-forms/GenericApproval';

import { SaveToken } from './custom-forms/SaveToken';
import { TokenAssign } from './custom-forms/TokenAssign';
import { ValidationSIIF } from './custom-forms/ValidationSIIF';
import { Singleton } from '../../../../core/services/Singleton';
import { ForpoService } from '../../../custom/forpo/components/service/ForpoService';
import { useEffect, useState } from 'react';
import { useInboxStore } from '../hooks/useInboxStore';
import { RequestToken } from './custom-forms/RequestToken';
import { AproveOrder } from './custom-forms/AprobeOrder';

interface Props {
    Procedure: any;
    credit: any;
    closeModal: any;
}

export const ProcedureAction = ({ Procedure, credit, closeModal }: Props) => {


    const single = Singleton.getInstance();
    const service = new ForpoService();
    const [afiliate, setAfiliate] = useState({});
    const { responseProcedureAction } = useInboxStore();

    useEffect(() => {
        getUser(credit.IDAffiliate)
    }, [credit])

    const update = (bean: any) => {
        single.spinner(true);
        service.updateCredit(bean).subscribe(
            (resp: any) => {
                if (resp) {
                    single.spinner(false);
                    single.update(bean);
                }
            }
        )
    }

    const getUser = (idUser: number) => {
        single.spinner(true);
        const properties = {
            IDAffiliate: idUser
        }
        service.getAffiliateRender(null, null, properties).subscribe(
            (resp: any) => {
                console.log(resp)
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    setAfiliate(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties)
                } else {
                    console.log("No se encontro el usuario");
                }
                single.spinner(false)
            }
        )
    }

    const responseAction = (map: any) => {
        console.log(Procedure.IDAction, map);
        responseProcedureAction(Procedure.IDAction, map);
        closeModal();
    }


    const msg = 'Lorem ipsum dolor sit amet consectetur adipiscing elit, pretium himenaeos nisl massa blandit senectus consequat diam, sed est ultricies sodales class non.'
    const renderSwicth = () => {
        switch (Procedure.FormCode) {
            case 'TKP01':
                return (
                    <TokenAssign procedure={{}} exportMap={(data: any) => {
                        console.log(data);
                        responseAction(data)
                    }} />
                )
            case 'APP01':
                return (
                    <AproveOrder affiliate={afiliate} credit={credit} title='Aprobación del Orden del gasto' message={""} exportMap={(data: any) => {
                        console.log(data);
                        responseAction(data)
                    }} />
                )
            case 'PT001':
                return (
                    <RequestToken
                        updateCredit={(bean: any) => { update(bean) }}
                        _credit={credit}
                        title='Registrar token portal tercero'
                        message={"Ingrese el token generado"}
                        exportMap={(data: any) => {
                            console.log(data);
                            responseAction(data)
                        }}
                        _user={afiliate}
                    />
                )
            case 'PT002':
                return (
                    <SaveToken closeModal={() => { closeModal() }} updateCredit={(bean: any) => { update(bean) }} affiliate={afiliate} credit={credit} title='Grabar token portal tercero' message={"Ingrese a portal terceros y grabe el token generado"} exportMap={(data: any) => {
                        console.log(data);
                        responseAction(data)
                    }} />
                )
            case "PT003":
                return (
                    <ValidationSIIF procedure={{}} title='Validar Cuenta de Usuario en SIIF' message={"Validar si la cuenta del usuario que esta solicitando el credito se encuentra en SIIF"} exportMap={(data: any) => {
                        console.log(data);
                        responseAction(data)
                    }} />
                )
            default:
                return (
                    <GenericApproval procedure={{}} title='Aprobación Genérica' message={msg} exportMap={(data: any) => {
                        console.log(data);
                        responseAction(data)
                    }} />
                )
        }
    }

    return (
        <div className='row w-100 mx-auto'>
            {renderSwicth()}
        </div>
    )
}
