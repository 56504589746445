import { useEffect, useState } from "react";
import { PrintJson } from "../../theme/Component/PrintJson";
import { ComparativeForm } from "./CustomForm/ComparativeForm/ComparativeForm";
import { RenderForm } from "../../theme/Component/RenderForm";
import { FileInput } from "../../theme/Component/FileInput";

interface Props {
    action: any;
    onReturn: Function;
}
export const ActionRender = ({ action, onReturn }: Props) => {

    const [forma, setForma] = useState<any>({});
    const [dataForma, setDataForma] = useState<any>({});

    useEffect(() => {
        if (action.jAction) {
            setForma(action.jAction);
        }


        if (action.JSONResponse) {
            try {
                setDataForma(JSON.parse(action.JSONResponse));
            } catch (error) {

            }
        }

    }, [action])

    const sendActionResponse = (e:any) => {
        console.log(e);
        
    }

    const renderForm = (code: number) => {
        console.log(code);
        
        switch (code) {
            case 555:
                return <ComparativeForm code={code} action={action} exportData={sendActionResponse} />
            default:
                return <RenderForm idFormType={code} data={dataForma} handleUpdate={sendActionResponse}/>

        }

    }

    return (
        <div className="row">

            {forma.TypeRequirement === 1 ?
                <>
                    {renderForm(forma.CodeForm)}
                </>
                :
                <>
                <FileInput onCreate={sendActionResponse}/>
                </>
            }

         
        </div>
    )
}