export const approvalUser=[
    {id:1,value:"Aprobado"},
    {id:2,value:"Rechazado"}
]
export const approvalPlan=[
    {id:1,value:"Abierto"},
    {id:2,value:"Cerrado"},
    {id:3,value:"Incompleto"}
]
export const approvalPlanType=[
    {id:10,value:"PLAN DE COMPRAS"},
    {id:11,value:"ITEM PLAN"},
    {id:12,value:"ARTICLE PLAN"}
]
export const roleApproval=[
    {id:1,value:"Profesional Area de Compra y Contratación"},
    {id:2,value:"Jefe Oficina Asesora de Planeación "},
    {id:3,value:"Líder Grupo de Presupuesto"},
    {id:4,value:"Jefe Área de Compras y Contratación"},
    {id:5,value:"Subgerente Administrativo"},
    {id:6,value:"Subgerente Financiero "},
    {id:7,value:"Gerente General"},
    
]

export const rolFuncionario = [
    {id:1,name:"Jefe de Area"},
    {id:2,name:"Preparador"},
    {id:3,name:"Supervisor"},
    {id:4,name:"Administrador Compras"},
  ]
  
export const rolHiring = [
    {id:20,name:"Proveedor"},
    {id:21,name:"Compras"},
    {id:22,name:"Abogado"},
    {id:23,name:"Supervisor"},
    {id:24,name:"Analista"},
  ]