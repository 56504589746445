import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { title } from 'process';



interface Props {
    idSelector: number;
    onChange: any;
    idProcess: number;
    titulo?: string;
}

export const SelectorClaseTramites = ({ idSelector, onChange, idProcess, titulo }: Props) => {




    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [idProcess])


    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {
        service.getBusinessProcessCatalog(null, null, null, idProcess, null, null).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>{titulo ?? "Tramites"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDBusinessProcess} value={item.DataBeanProperties.IDBusinessProcess} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}