import "./App.css";
// import { HashRouter, Navigate, Route, Routes, Switch } from 'react-router-dom';

import { Home } from "./modules/pages/Home";
import { Suspense, useState } from "react";
import { useEffect } from "react";
import { AdminService } from './core/services/AdminService';
import { Route, Routes, HashRouter } from "react-router-dom";
import { Login2 } from "./core/auth/Login2";
import { FrameBuster } from "./modules/theme/Component/FrameBuster";
import { Singleton } from './core/services/Singleton';
import { Spinner } from "./core/components/Spinner";




function App() {
  const single = Singleton.getInstance();
  const _AdminService = new AdminService();
  
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState<any>("");
  const [yek, setYek] = useState<string>("");


  useEffect(() => {
    
    // getKey();
    FrameBuster();
    
    let key = single.getCookie("yek");
    let keyDate = single.getCookie("yekDate");

    if(keyDate != single.getCurrenDate())
      {
        console.log("cambio de fecha");
        single.purgeCookie();
        getKey();
        
      }
      else{
        console.log("Es el mismo dia");
        
      }
    
    console.log("APP => ",key);
    
    if (key === null) {
      localStorage.clear();
      getKey();

    }
    else{
      setYek(key);
    }

 
    
  }, []);

  useEffect(()=>{
    getKey();
  },[isLogged])

  useEffect(()=>{

    let userCookie = single.getCookie("token");
    
    if (userCookie != null) {
      setIsLogged(true);
    }
    if (userCookie) {
      let u = JSON.parse(userCookie);
      setUser(u);
    }

  },[yek])

  useEffect(() => {
    
    if (isLogged) {
      console.table(Singleton.getInstance().getRolesContratos());

    }
  }, [isLogged]);


  const getKey = () => {

    _AdminService.securitymanager().subscribe((resp: any) => {


      if (resp.DataBeanProperties.ObjectValue) {
        
        single.setCookie("yek",resp.DataBeanProperties.ObjectValue.DataBeanProperties.Key,1);
        single.setCookie("yekDate",single.getCurrenDate(),1);
        setYek(resp.DataBeanProperties.ObjectValue.DataBeanProperties.Key);

      }
    });
  };

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <HashRouter basename="/">
          <Routes>
            <Route path="/login" element={<Login2 setIsLogged={setIsLogged} />} />
            <Route path="/app" element={<Home setIsLogged={setIsLogged} notFound={false} />}>
              <Route path=":module/" element={<Home setIsLogged={setIsLogged} notFound={false} />}>
                <Route path=":view" element={<Home setIsLogged={setIsLogged} notFound={false} />} />
              </Route>
            </Route>
            <Route path="/" element={<Login2 setIsLogged={setIsLogged} />} />
            <Route path="*" element={<Home setIsLogged={setIsLogged} notFound={true} />} />
          </Routes>
        </HashRouter>
      </Suspense>
    </>
  );
}

export default App;
