import { useState, useEffect } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton"
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ListMenu } from "../../theme/Component/ListMenu";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { RemoteAbstractAction } from "./RemoteAbstractAction";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";



export const ApplicationGroup = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();

    const [lista, setLista] = useState<any>([]);
    const [types, setTypes] = useState<any>([]);
    const [type, setType] = useState<any>(0);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalR, setModalR] = useState<any>({ name: 'Acciones Remotas', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getProcessTypeConstants();
        // getApplicationGroupCatalogByType();
    }, [])
    useEffect(() => {

        getApplicationGroupCatalogByType();
    }, [type])


    const getProcessTypeConstants = () => {
        setLoading(true);
        service.getProcessTypeConstants().subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setTypes(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setType(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }
    const getApplicationGroupCatalogByType = () => {
        setLoading(true);
        service.getApplicationGroupCatalogByType(type, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                } else {
                    setLista([]);
                }
                setModalC({ ...modalC, "view": false });
                setLoading(false);
            }
        )
    }
    const update = () => {
        setLoading(true);
        service.updateApplicationGroup(bean).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getApplicationGroupCatalogByType();
                    setModal({ ...modal, "view": false });
                }
                setLoading(false);
            }
        )
    }
    const agregarItem = () => {
        let b = {
            Name: '',
            Type: type
        }
        setBean(b);
        setModal({ ...modal, "view": true });
    }

    const handleChange = (e: any) => {
        if (e) {
            let name = e.target.name;
            if (name) {
                let value = e.target.value;
                // let value = single.renderValue(e.target.value);                
                setBean({ ...bean, [name]: value });
            }
        }
    }
    const editarItem = () => {
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        setLoading(true);
        service.deleteApplicationGroup(bean.IDApplicationGroup).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getApplicationGroupCatalogByType();

                }
            }
        )
    }



    const head = [
        { title: 'ID', property: 'IDApplicationGroup', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Tipo', property: 'Type', visible: true }
    ]
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [



            { titulo: 'Acciones Remotas', icono: 'ri-drag-move-2-line', visible: true, evento: () => { setModalR({ ...modalR, "view": true }) } },
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: true, evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <BasicPage titulo="Grupo aplicaciones QuickDataERP">
            <div className="row">
                <div className="col-md-6">
                    <label>Tipo Aplicación</label>
                    <select className="form-select" value={type} name="Type" onChange={(e) => { setType(e.target.value) }} >
                        {types.map((item: any) => {
                            return (
                                <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setBean}></BasicTable>
            <Modal modal={modal} updateModal={setModal} eventModal={update} >
                <div className="row">

                    <div className="col-md-12">
                        <label>Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={handleChange} name="Name" />
                    </div>
                </div>
            </Modal>
            <Modal modal={modalR} updateModal={setModalR} >
                <div className="row">

                    <div className="col-md-12">
                        <RemoteAbstractAction idApplicationGroup={bean.IDApplicationGroup} />
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                Eliminar item {bean.Name}
            </ModalConfirm>
        </BasicPage>
    )
}