import { useEffect, useState } from "react";
import { BasicButton } from "../../../theme/Component/BasicButton"
import { EstudioPrevioNatural } from "./EstudioPrevioNatural";
import { EstudioPrevioJuridico } from "./EstudioPrevioJuridico";
import { RiskMatrixData } from "../../interfaces";
import { Alert } from "../../../theme/Component/Alert";
import { ContractService } from "../../../../core/services/ContractService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { BpmService } from "../../../../core/services/BpmService";

export const defaultData: RiskMatrixData = {
    risks: [
        {
            IDRiskCategory: 1,
            Description: 'Seguridad de la informacion',
            Childs: [
                {
                    IDRiskItem: 1,
                    Risk: 'Sanciones, multas o afectación de la imagen de la Entidad, por exposicion de datos clasificados o reservados a causa de contratista.',
                    RiskDescription: 'Sanciones, multas o afectación de la imagen de la Entidad, por exposicion de datos clasificados o reservados a causa de contratista.',
                    Probability: 1,
                    Impact: 1,
                    Priority: 1,
                    Apply: false,
                    Responsible: '',
                    Valuation: ''
                },
                {
                    IDRiskItem: 2,
                    Risk: 'Afectación a la continuidad del negocio.',
                    RiskDescription: 'Afectación a la continuidad del negocio.',
                    Probability: 1,
                    Impact: 1,
                    Priority: 1,
                    Apply: false,
                    Responsible: '',
                    Valuation: ''
                }
            ]
        }
    ]
};

interface Props {
    adquisitioItem: any;
    vista: number;
    setVista: any;
    rol: number;
    _idArea?: number;
}
const service = new ContractService();
const _service = new BpmService();
export const EstudioPrevio = ({ adquisitioItem, vista, setVista, rol, _idArea }: Props) => {

    const { setLoading } = useDashboardStore();
    const [item, setItem] = useState<any>({});
    const [estudio, setEstudio] = useState<any>({});

    // InitStructure general

    const initEstructure = {
        Dependence: _idArea,
        Justification: adquisitioItem.Justification,
        ObjectContract: adquisitioItem.Description,
        requestType: 0,
        Coverage: '',
        ObligationList: '',
        Recruitment: {
            Name: '',
            Trainning: '',
            Exp: '',
        },
        ObligationText: '',
        Term: '',
        Factor: '',
        SST: '',
        ItemArticles: '',
        RiskMatrix: []
    }

    //Servicios
    useEffect(() => {
        getPreviousStudyCatalogPorPropiedad()
    }, [])


    const getRiskFactorTemplete = () => {
        setLoading(true);
        _service.getRiskMatrixTemplete().subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(JSON.parse(resp.DataBeanProperties.ObjectValue[0]));
                    setItem({ ...item, RiskMatrix: JSON.parse(resp.DataBeanProperties.ObjectValue[0]) })
                }
            }
        );
    }

    const getPreviousStudyCatalogPorPropiedad = () => {
        setLoading(true);
        service.getPreviousStudyCatalogPorPropiedad("IDHiringProcess", adquisitioItem.IDHiringProcess, null).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let items = resp.DataBeanProperties.ObjectValue;
                    if (items.length > 0) {
                        setEstudio(items[0].DataBeanProperties);
                        const prevBody = JSON.parse(items[0].DataBeanProperties.Body);
                        prevBody.Dependence = _idArea;
                        setItem(prevBody);
                        if (prevBody.RiskMatrix.length > 0) {
                            setItem({ ...prevBody, RiskMatrix: prevBody.RiskMatrix })
                        } else {
                            getRiskFactorTemplete()
                        }
                    } else {
                        updatePreviousStudy({
                            IDHiringProcess: adquisitioItem.IDHiringProcess,
                            Body: JSON.stringify(initEstructure)
                        });
                    }
                }
            }
        )
    }

    const updatePreviousStudy = (bean: any) => {
        setLoading(true);
        service.updatePreviousStudy(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let item = resp.DataBeanProperties.ObjectValue;
                    setEstudio(item.DataBeanProperties);
                    setItem(JSON.parse(item.Body));
                }
            }
        )
    }

    return (
        <>
            <hr />
            <div className="row mb-3" style={{ display: "flex", alignItems: "center" }}>
                <div className="col-md-2">
                    <BasicButton icon='ri-reply-line' eventClick={() => { setVista(1) }}>Regresar</BasicButton>
                </div>
                <div className="col-md-10">
                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                        <div className="col-md-2">
                            <label>Tipo de Contratación</label>
                        </div>
                        <div className="col-md-4">
                            <select className="form-control" value={estudio.TypePerson} onChange={(e) => { setEstudio({ ...estudio, "TypePerson": parseInt(e.target.value) }) }}>
                                <option value={null}>...</option>
                                <option value={11}>Persona Natural</option>
                                <option value={10}>Persona Juridica</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {estudio.TypePerson == 11 &&
                <EstudioPrevioNatural riskMatrixData={{ risks: item.RiskMatrix }} _Study={estudio} _item={item} adquisitioItem={adquisitioItem} rol={rol} ></EstudioPrevioNatural>
            }
            {estudio.TypePerson == 10 &&
                <EstudioPrevioJuridico riskMatrixData={{ risks: item.RiskMatrix }} _Study={estudio} _item={item} adquisitioItem={adquisitioItem} rol={rol}></EstudioPrevioJuridico>
            }
            {estudio.TypePerson != 10 && estudio.TypePerson != 11 &&
                <>
                    <div className="row">
                        <div className="col-md-12" style={{ display: "flex", justifyContent: "center" }}>
                            <Alert clase="warning">
                                <strong>Seleccione un tipo de contratación.</strong>
                            </Alert>
                        </div>
                    </div>
                </>
            }
        </>
    )
}