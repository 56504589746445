import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";

import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { ComparativeCard } from "./ComparativeCard";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";

interface Props {
    code: number;
    action: any;
    exportData: Function;
}
export const ComparativeForm = ({ code, action, exportData }: Props) => {
    const single = Singleton.getInstance();
    const contract = new ContractService();

    const [cuadro, setCuadro] = useState<any>({});
    const [offerSup, setOfferSup] = useState<any>({});
    const [isLote, setLote] = useState<any>(false);

    useEffect(() => {
        getOfferSupplierCatalogPorPropiedad();
    }, [action])
    useEffect(() => {
        getOfferSupplierComparative();
    }, [offerSup])

    const getOfferSupplierCatalogPorPropiedad = () => {

        contract.getOfferSupplierCatalogPorPropiedad("IDOfferSupplier", action.IDOfferSupplier).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    if (tmp.length > 0) {
                        let offer = tmp[0].DataBeanProperties;
                        setOfferSup(offer);

                    }

                }
            }
        );
    }

    const getOfferSupplierComparative = () => {
        single.spinner(true);
        contract.getOfferSupplierComparative(offerSup.IDPostOffer).subscribe(
            (resp: any) => {
                console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setCuadro(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                single.spinner(false);
            }
        )

    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div id="accordionlefticon" className="accordion lefticon-accordion custom-accordionwithicon accordion-border-box">
                    {cuadro.ListOffers && cuadro.ListOffers.map((item:any,index:number)=>{
                        return(
                            <div className="accordion-item ">
                            <h2 id={`accordionlefticon${index}`} className="accordion-header"><button className="accordion-button" type="button" 
                            data-bs-toggle="collapse" data-bs-target={`#accor_lef${index}`}> {item.DataBeanProperties.EntityName}</button></h2>
                            <div id={`accor_lef${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionlefticon">
                                <div className="accordion-body">
                                   <ComparativeCard offer={item.DataBeanProperties}/>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                   
                </div>

                

            </div>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                    <hr />
                        <strong>
                        Por favor indique su análisis de la comparación y la recomendación del proveedor que debe ser seleccionado
                        </strong>
                        
                        
                        <textarea name="obs" className="form-control"></textarea>
                    </div>
                    <div className="col-md-12">
                        <BasicSwitch estado={isLote} eventChange={setLote} label="Contrato por lotes"></BasicSwitch>
                    </div>
                    
                    <div className="col-md-12">
                        
                    </div>

                    <div className="col-md-12">
                        <BasicButton icon="ri-save-2-line" eventClick={()=>{}}>Guardar</BasicButton>
                    </div>
                </div>
            </div>
        </div>
    )
}