import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { Singleton } from "../../../core/services/Singleton"
import { GarantiasService } from "../../../core/services/GarantiasService"
import { BasicButton } from "../../theme/Component/BasicButton"
import { ListDocuments } from "../../contract/ListDocuments"
import { useDashboardStore } from "../../pages/hooks/useDashboardStore"

interface Props {
  IDRealesCredit?: number;
}

export const RealesCVSample = ({ IDRealesCredit }: Props) => {
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new GarantiasService();
  const [modal, setModal] = useState<any>({ name: 'Permisos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
  const [forma, setForma] = useState<any>({});
  const [bean, setBean] = useState<any>({});
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(single.getAccount());
  }, [])

  const updateForm = (e: any) => {
    setModal({ ...modal, ['view']: false });
    setLoading(true);
    service.updateRealesCV(bean).subscribe(
      (resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {

        }
        single.update(bean);
      });
  }


  const updateData = () => {

    let a = JSON.stringify(forma);
    let b = {
      IDRealesCredit: IDRealesCredit,
      IDEmployee: user.IDAccount,
      LastUpdate: single.getCurrenDate(),
      Attach: bean.Attach,
      BasicData: a,
    }
    updateForm(b);
    console.log(b);
  }

  return (
    <BasicPage titulo="Evidencias registros inmobiliarios">
      {/* <PrintObjectJson json={forma}/> */}

      <div className="row">
        <div className="col-md-6">
          <label className="form-label">Direccion</label>
          <input type="text" className="form-control" value={forma.direccion} onChange={(e) => { setForma({ ...forma, ['direccion']: e.target.value }) }}></input>
        </div>
        <div className="col-md-6">
          <label className="form-label">Teléfono Contacto</label>
          <input type="number" className="form-control" value={forma.Contacto} onChange={(e) => { setForma({ ...forma, ['Contacto']: e.target.value }) }}></input>
        </div>
        <div className="col-md-6">
          <label className="form-label">Barrio</label>
          <input type="text" className="form-control" value={forma.Barrio} onChange={(e) => { setForma({ ...forma, ['Barrio']: e.target.value }) }}></input>
        </div>
        <div className="col-md-6">
          <label className="form-label">Estrato</label>
          <input type="number" className="form-control" value={forma.Estrato} onChange={(e) => { setForma({ ...forma, ['Estrato']: e.target.value }) }}></input>
        </div>
        <div className="col-md-6">
          <label className="form-label">Observaciones</label>
          <input type="text" className="form-control" value={forma.Observaciones} onChange={(e) => { setForma({ ...forma, ['Observaciones']: e.target.value }) }}></input>
        </div>
      </div>
      <ListDocuments ListDocuments={bean.Attach} updateList={(e: any) => { setBean({ ...bean, "Attach": JSON.stringify(e) }) }} />
      <BasicButton icon="ri-save-line" eventClick={updateData}>Guardar</BasicButton>
    </BasicPage>
  )
}