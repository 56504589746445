import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ListMenu } from "../theme/Component/ListMenu";
import { DropDown } from "../theme/Component/DropDown";
import { AdquisitionArticle } from "./adquisition-setting/AdquisitionArticle";
import { Modal } from "../theme/Component/Modal";
import { RequestoQuote } from "./etapa-contrato/RequestoQuote";
import { InitHiringProcess } from "./FlowHiring/InitHiringProcess";
import { EstudioPrevio } from "./etapa-contrato/estudio-previo-items/EstudioPrevio";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";


interface Props {
    idAdquisitionPlanFunctionalID: number;
    phaseState: number,
    office: any;
    rol: number;
    _idArea?: number;
}
export const HiringProcessCatalog = ({ idAdquisitionPlanFunctionalID, phaseState, office, rol, _idArea }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [step, setStep] = useState(1);

    const [modalA, setModalA] = useState<any>({
        name: "Solicitar Cotización",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-xl",
    });
    const [modalB, setModalB] = useState<any>({
        name: "Iniciar Contratación",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: false,
        format: "modal-xl",
    });


    const idBean = 'IDHiringProcess';

    const head = [
        { title: 'ID', property: idBean, visible: false },
        { title: 'Valor Item', property: 'ValueNess' },
        { title: 'Valor Articulos', property: 'ArticuleValue' },
        { title: 'Etapa', property: 'StateName' },
        { title: 'Modalidad', property: 'HiringModalityName' },
        { title: 'Objeto Contractual', property: 'Description' },
        { title: 'Justificación', property: 'Justification' },

    ]

    useEffect(() => {
        setStep(1);
        getLista();

    }, [idAdquisitionPlanFunctionalID, rol])
    useEffect(() => {

        getLista();

    }, [step])



    const getLista = () => {

        setLoading(true);
        service.getHiringProcessByIDAdquisitionPlanFunctionalID(idAdquisitionPlanFunctionalID, 11).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const actionsDropDown = [
        { id: 1, name: 'Iniciar etapa Contractual', permiso: bean.State === 11, activo: true },
        { id: 2, name: 'Solicitar Cotización proveedor', permiso: null, activo: true },
        { id: 3, name: 'Ver Cotizaciones', permiso: null, activo: true },

    ];

    const eventDropDownn = (item: any) => {
        if (item.id === 1) {
            setModalB({ ...modalB, "view": true });
        }
        else if (item.id === 2) {
            setModalA({ ...modalA, "view": true });
        }
    }
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    {bean[idBean] &&
                        <DropDown lista={actionsDropDown} eventClick={eventDropDownn}></DropDown>
                    }
                </div>
            </div>
        )
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Configurar estudio previo', icono: 'ri-stack-line', visible: true, evento: () => { setStep(2) } },
            { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setStep(3) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <>
            <div className="row">
                <div className="col-md-12 p-2">
                    {step === 1 &&
                        <BasicTable headButtons={buttons()} listButtons={listaBotones()} head={head} body={lista} rowSet={setBean}></BasicTable>
                    }
                    {step === 2 &&
                        <EstudioPrevio _idArea={_idArea} adquisitioItem={bean} vista={step} setVista={setStep} rol={rol}></EstudioPrevio>
                    }
                    {step === 3 &&
                        <>
                            <div className="col-md-12">
                                <BasicButton icon='ri-reply-line' eventClick={() => { setStep(1) }}>Regresar</BasicButton>
                                <AdquisitionArticle viewHiringProcces={true} idAdquisitionItem={bean.IDAdquisitionItem} rol={2} />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Modal modal={modalA} updateModal={setModalA} eventModal={() => { }}>
                        <div className="row">
                            <div className="col">
                                <RequestoQuote idAdquisitionItem={bean.IDAdquisitionItem} idHiringProcess={bean.IDHiringProcess} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalB} updateModal={setModalB} eventModal={() => { }}>
                        <div className="row">
                            <div className="col">
                                <InitHiringProcess key={bean.IDHiringProcess} idAdquisitionItem={bean.IDAdquisitionItem} idHiringProcess={bean.IDHiringProcess} />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}