import { MenuUsuario } from "./menu/MenuUsuario";
import { useParams } from "react-router-dom";
import { Projects } from "../devops/Projects";
import { StepDevops } from "../devops/StepDevops";
import { RolDevops } from "../devops/RolDevops";
import { AccountProject } from "../devops/AccountProject";
import { History } from "../devops/History";
import { BudgetStructure } from "./configuracion/BudgetStructure";
import { FunctionalID } from "./configuracion/functionalID/FunctionalID";
import { Components } from "../devops/Components";
import { MatrizSeguimiento } from "../devops/MatrizSeguimiento";
import { ProjectID } from "./configuracion/ProjectID/ProjectID";
import { UnspscID } from "./configuracion/UnspscID/UnspscID";
import { BudgetID } from "./configuracion/BudgetID/BudgetID";
import { BasicPage } from "../theme/Component/BasicPage";
import { ApiModule } from "./api/ApiModule";
import { ApiRole } from "./api/ApiRole";
import { RoleUsuario } from "./Roles/RoleUsuario";
import { TrdID } from "./configuracion/TrdID/TrdID";
import { RequirementType } from "../contract/RequirementType";
import { HiringModalityDocument } from "../contract/HiringModalityDocument";
import { HiringModalityStage } from "../contract/HiringModalityStage";
import { BudgetIDType } from "./configuracion/BudgetIDType";
import { BudgetType } from "./configuracion/BudgetType";
import { SystemPropertie } from "./configuracion/SystemProperties";
import { AbstractAccount } from "./configuracion/AbstractAccount";
import { StructuringDocument } from "../contract/StructuringDocument";
import { MeasurementUnit } from "./configuracion/MeasurementUnit";
import { DeliveryType } from "./configuracion/DeliveryType";
import { AuditLog } from "./audit/AuditLog";
import { PgpEncrypt } from "../../core/secure/Pgp/PgpEncrypt";
import { SuppConstants } from "../bpm/config/Componentes/SuppConstants";
import { RequirementSST } from "../bpm/config/Componentes/RequirementSST";
import { Matrizriesgos } from "../bpm/config/Componentes/Matrizriesgos";
import { DocSupplierConfig } from "../bpm/config/Componentes/DocSupplierConfig";
import { MisApprovals } from "../approval/MisApprovals";
import { Typeformaservice } from "../bpm/config/Componentes/Typeformaservice";
import { FestiveDay } from "./utils/FestiveDay";
import { SiteID } from "./configuracion/SiteID/SiteID";
import { ApplicationGroup } from "./applicationGroup/ApplicationGroup";
import { Formatos } from "../bpm/config/Componentes/Formatos";
import { FactorMatrix } from "../bpm/config/Componentes/FactorMatrix";
import { Auditorianueva } from "../contract/auditoria/Auditorianueva";
import { ErrorsSql } from "../contract/auditoria/ErrorrsSql";
import { ThirdPartyUser } from "./ThirdPartyUser/ThirdPartyUser";
import { Afiliate } from "./Afiliados/Afiliate";
import { SalaryInquiry } from "../adminServices/SalaryInquiry";
import { DocumentTemplate } from "./plantillas/DocumentTemplate";
import { GenericCellEditor } from "./generic-editor/GenericCellEditor";
import { ExportMenu } from './menu/ExportMenu';
import { EducationalSupport } from "../Talentohumano/payRoll/EducationalSupport";
import { EnjoyedVacationERP } from "../Talentohumano/payRoll/EnjoyedVacationERP";
import { Dashed } from "../pages/Dashed/Dashed";
import { ProfileDashed } from "../pages/Dashed/components/ProfileDashed/ProfileDashed";
import { ProfileDashboard } from "../pages/ProfileDashboard";
import { GroupTemplate } from "../bpm/config/Componentes/GroupTemplate";
import { Historialdetramite } from "../bpm/config/Historialtramites";
import { VisualBandeja } from "./perfil/VisualBandeja";
import { ReaderUHF } from "./configuracion/UHFReader/ReaderUHF";
import { Company } from "../erp/cellEditor/Company/Company-component";
import { AccountantBook } from "../erp/cellEditor/AccountantBook/AccountantBook-component";
import { Office } from "../erp/cellEditor/Office/Office-component";
import { Currency } from "../erp/cellEditor/Currency/Currency-component";
import { InboxCash } from "../erp/cellEditor/CashDesk/InboxCash-component";
import { FormType } from "../custom/forpo/components/GenericForms&Constants/FormType.advantage";
import { BankTransfer } from "../erp/cellEditor/BankTransfer.tsx/BankTransfer-component";
import { Cashcajamenor } from "../erp/cellEditor/CashReimbursementcajamenor/CashReimbursement-component";
import { PerosnalInbox } from "../pages/Dashed/components/PersonaInbox/Personalnbox";


export const RouterAdmin = (props: any) => {
  const { module, view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "perfil":
        /* return (<Perfil />); */
        return <Dashed />;
      case "user-inbox":
        return <PerosnalInbox />;
      case "menu":
        return <MenuUsuario />;
      case "perfil-usuario":
        return <RoleUsuario />;
      case "software-project":
        return <Projects />;
      case "etapas-devops":
        return <StepDevops />;
      case "rol-devops":
        return <RolDevops />;
      case "usuario-proyecto":
        return <AccountProject />;
      case "historia-proyecto":
        return <History />;
      case "budget-structure":
        return <BudgetStructure />;
      case "budget-idtype":
        return <BudgetIDType />;
      case "budget-type":
        return <BudgetType />;
      case "measurement-unit":
        return <MeasurementUnit />;
      case "delivery-type":
        return <DeliveryType />;
      case "historialdetramite":
        return <Historialdetramite />;
      case "componentes":
        return <Components />;
      case "matriz-trazabilidad":
        return <MatrizSeguimiento />;
      case "third-party-user":
        return <ThirdPartyUser />;
      case "afiliate":
        return <Afiliate />;
      case "functionalid":
        return (
          <BasicPage titulo="Estructura Organizacional">
            <FunctionalID id={null} edit={true} />
          </BasicPage>
        );
      case "projectid":
        return (
          <BasicPage titulo="Árbol Proyectos">
            <ProjectID id={null} edit={true} />
          </BasicPage>
        );
      case "unspscid":
        return (
          <BasicPage titulo="Árbol Clasificador de Bienes y Servicios Unspsc">
            <UnspscID id={null} edit={true} />
          </BasicPage>
        );
      case "trdid":
        return (
          <BasicPage titulo="Tabla de Retenciones TRD">
            <TrdID id={null} edit={true} />
          </BasicPage>
        );

      case "budgetid":
        return (
          <BasicPage titulo="Árbol Rubros Presupuestales">
            <BudgetID tipoPresupuesto={null} id={null} edit={true} />
          </BasicPage>
        );

      case "apimodule":
        return <ApiModule />;
      case "api-role":
        return <ApiRole />;
      case "requirement-type":
        return <RequirementType />;
      case "hiring-modality-document":
        return <HiringModalityDocument />;
      case "hiring-modality-stage":
        return <HiringModalityStage />;
      case "systempropertie":
        return <SystemPropertie />;
      case "abstractaccounts":
        return <AbstractAccount />;
      case "structuring-document":
        return <StructuringDocument />;
      case "auditoria":
        return <Auditorianueva />;
      case "audit":
        return <AuditLog />;
      case "encrypt-file":
        return <PgpEncrypt />;
      case "salary-inquiry":
        return <SalaryInquiry />;
      case "suppConstants":
        return <SuppConstants />;
      case "requirement-sst":
        return <RequirementSST />;
      case "formatos":
        return <Formatos />;
      case "Matrizdefactores":
        return <FactorMatrix />;
      case "sqlerrors":
        return <ErrorsSql />;
      case "bandeja-mensajes":
        return <VisualBandeja />;

      case "matrizriesgos":
        return <Matrizriesgos />;
      case "doc-supplier-config":
        return <DocSupplierConfig />;
      case "mis-aprobaciones":
        return <MisApprovals />;
      case "formulariocompras":
        return <Typeformaservice />;
      case "festivos":
        return <FestiveDay />;
      case "generic-editor-erp":
        return <GenericCellEditor />;
      case "apoyo-educativo":
        return <EducationalSupport />;
      case "disfrute-vacaciones":
        return <EnjoyedVacationERP rol={1} />;
      case "monitor-almacen":
        return <ReaderUHF />;

      case "siteid":
        return (
          <BasicPage titulo="Ciudades">
            <SiteID id={null} edit={true} />
          </BasicPage>
        );
      case "app-quicdata-erp":
        return <ApplicationGroup />;
      case "grupos":
        return <BasicPage titulo="Ciudades"> </BasicPage>;
      case "plantilla-documento":
        return <DocumentTemplate />;
      case "export-menu":
        return <ExportMenu />;
      case "group-template":
        return <GroupTemplate />;
      case "catalogo-compania":
        return <Company />;
      case "transfer-bank":
        return <BankTransfer />;
      case "reembolso-caja-menor":
        return <Cashcajamenor />;
      case "catalogo-libros":
        return <AccountantBook />;
      case "catalogo-oficinas":
        return <Office />;
      case "catalogo-monedas":
        return <Currency />;
      case "inbox-cash":
        return <InboxCash />;
      case "formularios-quick":
        return <FormType />;

      default:
        // return <ProfileDashboard />;
        return <Dashed />;
    }
  };

  return <div className="px-5">{renderSwitch(view)}</div>;
};
