import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton"
import { BasicPage } from "../../theme/Component/BasicPage"
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { DocumentTemplateVersion } from "./DocumentTemplateVersion";
import { DictionaryWords } from "./DictionaryWords";
import { DictionaryPropertyes } from "./DictionaryPropertyes";
import { DocumentTemplateParsed } from "./DocumentTemplateParsed";


export const DocumentTemplate =()=> {

    const single = Singleton.getInstance();
    const service = new AdminService();

    const [options,setOptions] = useState<any>({
        Type:0,
        GroupCode:"",
        IsEnable:false
    });

    const [bean,setBean] = useState<any>({});
    const [view,setView] = useState<number>(0);
    const [modal, setModal] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalAdd, setModalAdd] = useState<any>({ name: 'Agregar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [lista,setLista] = useState<any>([]);
    const [listaType,setListaType] = useState<any>([]);
    const [listaGroup,setListaGroup] = useState<any>([]);
    const idBean = "IDDocumentTemplate";

    const [modalDiccionario, setModalDiccionario] = useState<any>({ name: 'Diccionario', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalProsp, setModalProps] = useState<any>({ name: 'Propiedades', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalView, setModalView] = useState<any>({ name: 'Ejecutar Plantilla', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(()=>{
        getProcessTypeConstants();
    },[])
    useEffect(()=>{
        getDocumentTemplateGroupCode();       
        setOptions({...options,"GroupCode":undefined});
    },[options.Type])

    const getProcessTypeConstants = ()=>{
        single.spinner(true);
        service.getProcessTypeConstants().subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setListaType(resp.DataBeanProperties.ObjectValue);
                    if(resp.DataBeanProperties.ObjectValue.length > 0)
                    {
                        let first = resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value;
                         setOptions({...options,"Type":first});
                    }
                        
                }
                else{
                    setListaType([]);
                }
                single.spinner(false);
            }
        )
    }
    const getDocumentTemplateGroupCode = ()=>{
        service.getDocumentTemplateGroupCode(options.Type).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setListaGroup(resp.DataBeanProperties.ObjectValue);     
                    if(resp.DataBeanProperties.ObjectValue.length > 0)
                    {
                        let first = resp.DataBeanProperties.ObjectValue[0];
                        setOptions({...options,"GroupCode":first});
                    }              
                }
                else{
                    setListaGroup([]);
                }
            }
        )
    }
    
    const getDocumentTemplateCatalog =()=>{

        single.spinner(true);
        service.getDocumentTemplateCatalog(options.Type,options.GroupCode ?? null,options.IsEnable).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                else{
                    setLista([]);
                }
                single.spinner(false);
            }   
        )


    }
    const updateDocumentTemplate =()=>{

        
        console.log(bean);
        
        single.spinner(true);
        service.updateDocumentTemplate(bean).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                 getDocumentTemplateCatalog();
                 setModalAdd({...modalAdd,"view":false})
                }
               
                single.spinner(false);
            }   
        )


    }

    const agregarItem =()=>{

        let b = {
            ProcessType:options.Type,
            GroupCode:options.GroupCode,
            ClassForName:'',
            Description:'',
            IsEnabled:true,
            Code:'',
            Name:'',
            IDEmployee:single.getAccountID()
        }
        setBean(b);

        setModalAdd({...modalAdd,"view":true});

    }

    const head = [
        {title:'ID',property:'IDDocumentTemplate',visible:true},
        {title:'Tipo de Proceso',property:'ProcessTypeName',visible:false},
        {title:'Código del Grupo de Plantillas',property:'GroupCode',visible:false}  ,      
        {title:'Código de la Plantilla',property:'Code',visible:true}  ,      
        {title:'Nombre',property:'Name',visible:true}  ,      
        {title:'Descripción',property:'Description',visible:true}  ,      
        {title:'Clase que implementa',property:'ClassForName',visible:true}  ,      
        {title:'Plantilla Habilitada',property:'IsEnabled',visible:true}  ,      
        {title:'Última Fecha de Actualización',property:'LastUpdate',visible:true}  ,      
        {title:'Funcionario que actualiza',property:'EmployeeName',visible:true}  ,      
    ]

     
    const iconButtonsItem = [
        { nombre: 'Agregar',visible:single.canCreate(), icono: 'ri-file-add-line', evento:agregarItem}
    ]

    
    const listaBotones =()=>{
        const botones = [            
            {titulo:'Editar',icono:'ri-edit-2-fill',visible:true,evento:()=>{setModalAdd({...modalAdd,"view":true})}},
            {titulo:'Versiones',icono:' ri-git-branch-line',visible:true,evento:()=>{setModal({...modal,"view":true})}},
            { titulo: 'Diccionario', icono: 'ri-booklet-line', visible: true, evento: () => { setModalDiccionario({ ...modalDiccionario, "view": true }) } },
            { titulo: 'Propiedades', icono: 'ri-list-ordered', visible: true, evento: () => { setModalProps({ ...modalProsp, "view": true }) } },
            { titulo: 'Ejecutar Plantilla', icono: 'ri-speed-line', visible: true, evento: () => { setModalView({ ...modalView, "view": true }) } },
        ]
        return(
            <ListMenu listaBotones={botones}/>
        )
    }

    const handleChange = (e:any)=>{

        if (e.target.name) {            
            setBean({ ...bean, [e.target.name]: e.target.value });
        }

    }

    return (
        <BasicPage titulo="Plantillas">
            <div className="row">
                <div className="col-md-2 mt-4">
                    <BasicSwitch  label="Plantillas activas" estado={options.IsEnable} eventChange={(e:boolean)=>{setOptions({...options,"IsEnable":e ? e : null})}}></BasicSwitch>
                </div>
                <div className="col-md-4">
                    <label>Tipo Proceso
                    <select name="tipo" className="form-select" value={options.Type} onChange={(e)=>{setOptions({...options,"Type":parseInt(e.target.value)})}}>
                        {listaType.map((item:any,index:number)=>{
                            return(
                                <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                            )
                        })

                        }
                    </select>
                    </label>
                    
                </div>
                <div className="col-md-4">
                    <label>Grupo Proceso</label>
                    <select name="tipo" className="form-select" value={options.GroupCode ?? undefined} onChange={(e)=>{setOptions({...options,"GroupCode":e.target.value})}}>
                        <option value={undefined}>...</option>
                        {listaGroup.map((item:any,index:number)=>{
                            return(
                                <option key={index} value={item}>{item}</option>
                            )
                        })

                        }
                    </select>
                </div>
                <div className="col-md-2 mt-3">
                    <BasicButton icon="" eventClick={getDocumentTemplateCatalog}>Buscar</BasicButton>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean}></BasicTable>
                </div>
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} eventModal={()=>{}}>
                        {view===0 && modal.view &&
                            <DocumentTemplateVersion idDocumentTemplate={bean[idBean]}/>
                        }
                    </Modal>
                    <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={updateDocumentTemplate}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={bean.Name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6">
                                <label>Código del Grupo de Plantillas</label>
                                <input type="text" name="GroupCode" className="form-control" value={bean.GroupCode} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6">
                                <label>Codigo</label>
                                <input type="text" name="Code" className="form-control" value={bean.Code} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mt-3">
                                <BasicSwitch label="Plantilla Habilitada" estado={bean.IsEnabled} eventChange={(e:boolean)=>{setBean({...bean,"IsEnabled":e})}}></BasicSwitch>
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" value={bean.Description} onChange={handleChange}></textarea>
                            </div>
                            <div className="col-md-12">
                                <label>ClassForName</label>
                                <input type="text" name="ClassForName" className="form-control" value={bean.ClassForName} onChange={handleChange}/>
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalDiccionario} updateModal={setModalDiccionario}>

                    <DictionaryWords idDocumentTemplate={bean.IDDocumentTemplate}></DictionaryWords>


                </Modal>
                <Modal modal={modalProsp} updateModal={setModalProps}>

                    <DictionaryPropertyes idDocumentTemplate={bean.IDDocumentTemplate}></DictionaryPropertyes>


                </Modal>
                <Modal modal={modalView} updateModal={setModalView}>
                    <DocumentTemplateParsed idDocumentTemplate={bean.IDDocumentTemplate}></DocumentTemplateParsed>
                </Modal>
                </div>
            </div>
        </BasicPage>
    )
}