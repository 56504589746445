import { useState } from 'react';
import { useEffect } from 'react';
import { BasicSwitch } from '../theme/Component/BasicSwitch';
import { BasicButton } from '../theme/Component/BasicButton';

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const AutorizacionConsulta = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});

    useEffect(() => {
        if (formSupplier.forma5 === undefined)
            formSupplier.forma5 = {};
        setFormulario(formSupplier.forma5);
    }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        temp.forma5 = formulario;
        updateInfo(temp);
    }

    return (
        <>
            <h5 className='titul-form' style={{ textAlign: 'center' }}>Autorización Consulta en las Centrales de Riesgos</h5>
            <hr />
            <div className="row">
                <div className="col-md-12 text-justify">
                    <p>Declaro que la información que he suministrado es verídica y doy mi consentimiento expreso y voluntario a la CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA para consultar la información comercial y financiera ante la Central de Información del Sector Financiero (CIFIN), o cualquier otra entidad que maneje o administre base de datos, con los mismos fines.  											</p>
                </div>
            </div>
            <div className="col-md-6">
                <label className="titul-form">Autoriza:</label>
                <BasicSwitch label="NO / SI" estado={formulario.consultaR} eventChange={(e: any) => {
                    setFormulario({ ...formulario, "consultaR": e });
                }} />
            </div>
            <div className="col-md-12 mt-4">
                <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
            </div>

            {/* <PrintJson json={formulario}/>
            <PrintJson json={supplier}/> */}

        </>
    )
}