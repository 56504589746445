import { useEffect, useState } from "react";
import { FinanceService } from "../../core/services/FinanceService";
import { BasicPage } from "../theme/Component/BasicPage";
import { Singleton } from "../../core/services/Singleton";
import { BasicTable } from "../theme/Component/BasicTable";
import { Modal } from "../theme/Component/Modal";
import { FunctionalIDPreparer } from "./FunctionalIDPreparer";
import { ListMenu } from "../theme/Component/ListMenu";
import { useForm } from 'react-hook-form';
import { AdquisitionItem } from "./adquisition-setting/AdquisitionItem";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { SelectAdquisitionPlanFunctionalID } from "./select/SelectAdquisitionPlanFunctionalID";
import { CardTaps } from "../theme/Component/CardTaps";


export const MisPlanesEstructured = () => {
  const serviceFinance = new FinanceService();
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState<any>([]);
  const [listaFun, setListaFun] = useState<any>([]);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [idPlan, setIDPlan] = useState(0);
  const [idArea, setIDArea] = useState(0);
  const [bean, setBean] = useState<any>({});
  const [modal, setModal] = useState<any>({
    name: "Planes",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-xl",
  });
  const [modalP, setModalP] = useState<any>({
    name: "Preparadores",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });
  const [modalA, setModalA] = useState<any>({
    name: "Mis Articulos",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-xl",
  });

  const rolFuncionario = [
    { id: 1, name: "Jefe de Area" },
    { id: 2, name: "Preparador" },
    { id: 3, name: "Supervisor" },
  ]


  useEffect(() => {
    getAdquisitionPlanOnStructuringState();
  }, []);

  useEffect(() => {
    if (idArea > 0) {
      console.log(idArea);

    }
  }, [idArea]);

  useEffect(() => {
    getAdquisitionPlanFunctionalIDCatalog();
  }, [idPlan]);

  const getAdquisitionPlanOnStructuringState = () => {
    setLoading(true);
    serviceFinance
      .getAdquisitionPlanOnStructuringState(single.getAccountID())
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };
  const getAdquisitionPlanFunctionalIDCatalog = () => {
    setLoading(true);
    serviceFinance
      // .getAdquisitionPlanFunctionalIDCatalog(idPlan)
      .getAdquisitionPlanFunctionalIDByIDAccount(idPlan, single.getAccountID())
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          let list = resp.DataBeanProperties.ObjectValue;
          list.forEach((ele: any) => {

            ele.DataBeanProperties.AccountName =
              (ele.DataBeanProperties.Name1 ?? '') + ' ' +
              (ele.DataBeanProperties.Name2 ?? '') + ' ' +
              (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
              (ele.DataBeanProperties.Surname2 ?? '');

          });
          setListaFun(list);

        }
        setLoading(false);
      });
  };

  const head = [
    { title: "ID", property: "IDAdquisitionPlanFunctionalID" },
    // { title: 'Tipo de Presupuesto', property: 'BudgetTypeName' },
    { title: "Oficina", property: "Name" },
    { title: "Funcionario Responsable", property: "AccountName" },
    { title: "Valor Estimado", property: "EstimatedValue" },
    { title: "Valor Estructurado", property: "TotalValueEstimate" },
    // {title: "Limitar Plan al Valor Estimado",property: "CloseToEstimatedValue"},
    { title: "Etapa del Plan", property: "PhaseStateName" },

    // { title: "Estado de Planeación del Plan", property: "PlanningStateName" },
    // { title: "Estado de Estructuración del Plan", property: "StructuringStateName" },
    // { title: "Estado de Contratación del Plan", property: "HiringStateName" },

  ];
  const setRow = (item: any) => {
    setBean(item);
    // console.log(bean);
    console.log(idPlan + single.getAccount());

  };


  const listaBotones = () => {
    const botones = [

      { titulo: 'Ver Ítems', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
      { titulo: 'Preparadores', icono: 'ri-file-user-line', visible: true, evento: () => { setModalP({ ...modalP, "view": true }) } },
    ]
    return (
      <ListMenu listaBotones={botones} />
    )
  }


  const taps = [
    { id: 0, name: 'Necesidades' },
    { id: 1, name: 'Preparadores' }
  ]
  const [indexTap, setIndexTap] = useState(0);

  return (
    <>
      <div className="row">


        <div className="col-md-12">
          <BasicPage titulo="Mis Planes de Compras por Estructurar">
          <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label>Mis Planes En Estructuración</label>
                    <select
                      className="form-select mt-2"
                      value={idPlan}
                      onChange={(e) => {
                        setIDPlan(parseInt(e.target.value));
                      }}
                    >
                      <option value="0">...</option>
                      {lista.map((item: any) => {
                        return (
                          <option value={item.DataBeanProperties.IDAdquisitionPlan}>
                            {item.DataBeanProperties.Description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <SelectAdquisitionPlanFunctionalID idAdquisitionPlan={idPlan} onChange={setIDArea} />
                  </div>

                </div>
              </div>
            </div>
            {idArea>0 ?
                <div className="row">
                  <div className="col">
                    <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                         {indexTap === 0 &&
                          <AdquisitionItem idAdquisitionPlanFunctionalID={idArea} rol={1} beanOficina={bean} />
                         }
                         {indexTap === 1 &&
                          <FunctionalIDPreparer IDArea={idArea}/>
                         }
                    </CardTaps>
                  </div>
                </div>
              :
              <div className="d-flex mt-4 justify-content-center">
                <div className="alert alert-warning" role="alert">
                  <p>
                    Debe seleccionar una <strong>Oficina/Area</strong> para poder continuar.
                  </p>
                </div>
              </div>
            }

          </BasicPage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">

          {modal.view &&
            <>
              <Modal modal={modal} updateModal={setModal} eventModal={(e: any) => {
                getAdquisitionPlanOnStructuringState();
                setModal({ ...modal, "view": false });
              }}>
                {/* <AdquisitionItemByFuncionalID preparador={false} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} beanOficina={bean}></AdquisitionItemByFuncionalID> */}
                <AdquisitionItem idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} rol={1} beanOficina={bean} />

              </Modal>
            </>
          }

          {modalP.view &&
            <>
              <Modal modal={modalP} updateModal={setModalP} eventModal={() => {
                getAdquisitionPlanOnStructuringState();
                setModalP({ ...modalP, "view": false });
              }}>
                <FunctionalIDPreparer IDArea={bean.IDAdquisitionPlanFunctionalID} />
              </Modal>
            </>
          }
          {/* <Modalcompras modal={modal} updateModal={setModal} onSubmit={handleSubmit(updateItem)}>
               
            </Modalcompras> */}
        </div>
      </div>
    </>
  );
};
