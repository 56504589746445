import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicTable } from "../../theme/Component/BasicTable"
import { useAfiliate } from "./useAfiliate";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const Afiliate = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const { bean, head, lista, setRowBean, update, modal, setModal, setBean, iconButtonsItem } = useAfiliate();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Afiliados">
                <>
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean}></BasicTable>
                </>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={update}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Identificacón</label>
                        <input type="number" className="form-control" value={bean.Nit} onChange={(e) => { setBean({ ...bean, "Nit": parseInt(e.target.value) }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Nombre</label>
                        <input type="text" className="form-control" value={bean.Name1} onChange={(e) => { setBean({ ...bean, "Name1": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Segundo Nombre</label>
                        <input type="text" className="form-control" value={bean.Name2} onChange={(e) => { setBean({ ...bean, "Name2": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Apellido</label>
                        <input type="text" className="form-control" value={bean.Surname1} onChange={(e) => { setBean({ ...bean, "Surname1": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Segundo Apellido</label>
                        <input type="text" className="form-control" value={bean.Surname2} onChange={(e) => { setBean({ ...bean, "Surname2": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Correo Electronico</label>
                        <input type="text" className="form-control" value={bean.eMail} onChange={(e) => {
                            setBean({ ...bean, "eMail": e.target.value })
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Telefono</label>
                        <input type="text" className="form-control" value={bean.Tel} onChange={(e) => { setBean({ ...bean, "Tel": e.target.value }) }} />
                    </div>
                </div>
            </Modal>

        </>
    )
}