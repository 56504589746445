import { env } from "../../env";
import api from "./api/api";


export class SupplierService {
  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;


  //Servicios SystemProperties

  public getSupplierCatalog(idSupplier: string | null, row: number | null, page: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierCatalog_Number_Number_Number",
      ArgumentList: [idSupplier, row, page],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSupplierCatalogByRisk(idSupplier: string | null, row: number | null, page: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierCatalogByRisk_Number_Number_Number",
      ArgumentList: [idSupplier, row, page],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSupplierCatalogByState(state: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierCatalogByState_Number",
      ArgumentList: [state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setApprovalState(supplier: any, state: number, observation: string, attach: string, idEmployee: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_setApprovalState_com.orange.finance.supplier.bean.Supplier_Number_String_String_Number",
      ArgumentList: [
        {
          DataBeanProperties: supplier,
          DataBeanName: "com.orange.finance.supplier.bean.Supplier"
        },
        state, observation, attach, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSupplierHistoryRender(idSupplier: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierHistoryRender_Number",
      ArgumentList: [
        idSupplier
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSupplierStatus(idSupplier: number, state: number, Obs: string, idEmployee: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_updateSupplierStatus_Number_Number_String_Number",
      ArgumentList: [idSupplier, state, Obs, idEmployee]
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSupplier(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_updateSupplier_com.orange.finance.supplier.bean.Supplier",
      ArgumentList: [{
        DataBeanProperties: bean,
        DataBeanName: 'com.orange.finance.supplier.bean.Supplier'
      }]
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateAbstractAccount(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_updateAbstractAccount_com.advantage.bean.account.AbstractAccount",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.account.AbstractAccount'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getListDocumentSupplier(idSupplier: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getListDocumentSupplier_Number",
      ArgumentList: [idSupplier],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateDocsBySypplier(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.DocsBySypplier_updateDocsBySypplier_com.orange.finance.supplier.bean.DocsBySypplier",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.finance.supplier.bean.DocsBySypplier"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFormTypeCatalogPorPropiedad(property: string | null, value: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [
        property, value, rows
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getFormParamCatalogPorPropiedades(properties: any, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormParamCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        properties, rows
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getFormAtributesByCode(codeForm: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getFormAtributesByCode_Number",
      ArgumentList: [
        codeForm
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


}
