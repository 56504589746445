import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idSelector: number;
    onChange: any;
}
export const SelectAdquisitionPlan = ({ idSelector, onChange }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);
    const [year, setYear] = useState(single.getCurrenYear());


    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [idSelector])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        console.log("Plan de adquisicion : ", year);

        let cache = Singleton.getInstance().getCacheItem("AdquisitionPlanCatalog");
        if (cache) {
            setLista(cache);
        }
        else {
            service.getAdquisitionPlanCatalog(year, year, null, null, null).subscribe(
                (resp: any) => {
                    setLista([]);

                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                        Singleton.getInstance().setCacheItem("AdquisitionPlanCatalog", resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                    }
                }
            );
        }


    }

    return (
        <>
            <label>Plan de Adquisición</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (

                            <option key={item.DataBeanProperties.IDAdquisitionPlan} value={item.DataBeanProperties.IDAdquisitionPlan} >{item.DataBeanProperties.Description}</option>
                        )
                    })
                }
            </select>
        </>
    )
}