import { BasicPage } from "../../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../../theme/Component/Modal";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { SuppConstantsValueForpo } from "./SuppConstantsValueForpo";
import { useSuppConstantsForpo } from './useSuppConstantsForpo';

export const SuppConstantsForpo = () => {

    const { bean, eliminarItem, head, iconButtonsItem, idBean, lista, modal, modalConfirm, setBean, setModal, setModalConfirm, setRowBean, updateItem, modalPC, setModalPC } = useSuppConstantsForpo()

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Propiedades', icono: 'ri-file-user-line', visible: true, evento: () => { setModalPC({ ...modalPC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Tablas Parametricas">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, ['Code']: e.target.value }) }} />
                        {/* <input type="text" className="form-control" value={bean.Code} onChange={handleCodeChange}/> */}

                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            <Modal modal={modalPC} updateModal={setModalPC}>
                <SuppConstantsValueForpo idSuppConstants={bean[idBean]}></SuppConstantsValueForpo>
            </Modal>
        </>
    )
}