import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { Singleton } from '../../../../core/services/Singleton';





interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
}

export const SelectrolesSST = ({ titulo, idSelector, onChange }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        let cache = Singleton.getInstance().getCacheItem("RoleSSTCatalogPorPropiedad");
        if (cache) {
            setLista(cache);
        }
        else {
            setLoading(true);
            service.getRoleSSTCatalogPorPropiedad('State', 1, null).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    setLoading(false);
                }
            );
        }


    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDRoleSST} value={item.DataBeanProperties.IDRoleSST} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}