import { useEffect, useState } from "react";
import { Selectortipodecliente } from "../../bpm/config/Componentes/Selectortipodecliente";
import { SelectoCustomerType } from "../../bpm/config/Componentes/SelectoCustomerType";
import { BasicTable } from "./BasicTable";
import { Singleton } from "../../../core/services/Singleton";
import { BpmService } from "../../../core/services/BpmService";
import { ModalConfirm } from "./ModalConfirm";
import { ListMenu } from "./ListMenu";
import { Modal } from "./Modal";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
  IDBusinessProcess: number;
}

// const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
export const Caracterizaciontramites = ({ IDBusinessProcess }: Props) => {
  const [selector2, setSelector2] = useState(0);
  const [selector, setSelector] = useState<any>();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [agregar, setAgregar] = useState<any>({ name: 'agregar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' })
  const [meliminar, setMeliminar] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: false, btnSubmit: true, format: "modal-sm", });

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new BpmService();

  const idBean = "IDBusinessCharacterization";

  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  useEffect(() => {
    getLista();
  }, [IDBusinessProcess]);

  const head = [
    { title: "ID ", property: idBean },
    { title: "Caracterización", property: "CharacterizationName" },
    { title: "Tipo de Cliente", property: "CustomerTypeName" },
    // { title: "IDBusinessProcess", property: "IDBusinessProcess" },
  ];

  const getLista = () => {
    setLoading(true);
    service
      .getBusinessCharacterizationCatalog(IDBusinessProcess)
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };

  const updateItem = () => {
    setLoading(true);
    service
      .addBusinessCharacterization(IDBusinessProcess, selector)
      .subscribe((resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {
          getLista();
        }
        setAgregar({ ...agregar, 'view': false })
      });
  };

  const eliminarItem = () => {
    setLoading(true);
    service
      .deleteBusinessCharacterization(bean[idBean])
      .subscribe((resp: any) => {
        setLoading(false);
        if (resp) {
          getLista();
        }
      });
    setMeliminar({ ...meliminar, ["view"]: false });
  };

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Eliminar",
        icono: "ri-file-user-line",
        visible: single.canDelete(),
        evento: () => {
          setMeliminar({ ...meliminar, view: true });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: true,
      icono: "ri-file-add-line",
      evento: () => { setAgregar({ ...agregar, ["view"]: true }); },
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
        </div>
      </div>

      <Modal modal={agregar} updateModal={setAgregar} eventModal={updateItem}>
        <>
          <div className="row">
            <div className="col-md-12">
              <SelectoCustomerType idSelector={selector2} onChange={(a: any) => { setSelector2(a); }}></SelectoCustomerType>
            </div>
            {selector2 &&
              <>
                <div className="col-md-12">
                  <Selectortipodecliente titulo="Caracterizaciones a agregar" idCharacterization={selector2} idSelector={selector} onChange={(e: any) => { setSelector(e); }} />
                </div>
              </>
            }
          </div>
        </>
      </Modal>

      <ModalConfirm
        modal={meliminar}
        updateModal={setMeliminar}
        eventModal={eliminarItem}
      >
        Esta seguro que desea eliminar el siguiente item{" "}
        <strong>{bean.CustomerTypeName}</strong>{" "}
      </ModalConfirm>
    </>
  );
};