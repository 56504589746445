import { useEffect, useState } from "react";
import { BasicPage } from "../../theme/Component/BasicPage"
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { TalentService } from "../../../core/services/TalentService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { InputDate } from "../../theme/Input/InputDate";
import { PrintJson } from "../../theme/Component/PrintJson";

interface Props {
    _vacation: {
        accountID: number,
        IDDocument: number,
        DocumentDate: string,
    };
    _user: number;
    _rol: number;
}

export const EnjoyedVacation = ({ _vacation, _user, _rol }: Props) => {
    const single = Singleton.getInstance();
    const employeeID = single.getAccountID();
    const { setLoading } = useDashboardStore();
    const service = new TalentService();
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalRelease, setModalRelease] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

    const idBean = 'IDEnjoyedVacation';

    const setRowBean = (bean: any) => {
        setBean(bean);
        console.log(bean)
    };

    useEffect(() => {
        getLista();
    }, [_vacation])

    const getLista = () => {
        setLoading(true);
        service.getActiveEnjoyedVacationListByDocument(_user, _vacation.IDDocument, _vacation.DocumentDate, false).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                console.log(resp);
                setLista(resp.DataBeanProperties.ObjectValue);
            }
        })
    }

    const eliminarItem = () => {
        setLoading(true);
        console.log(bean.IDEnjoyedVacation, employeeID)
        service.deleteEnjoyedVacation(bean[idBean], employeeID).subscribe((resp: any) => {
            setLoading(false);
            if (resp) {
                getLista();
            }
            single.delete(bean.idBean, bean);
        });
        setModalConfirm({ ...modalConfirm, "view": false });
    };

    const agregarEV = () => {
        let b = {
            NoveltyInitDate: '',
            NoveltyUptoDate: '',
            description: '',
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar vacaciones disfrutadas" });
    };

    const updateItem = () => {
        setModal({ ...modal, "view": false });
        console.log(_user, _vacation.IDDocument, _vacation.DocumentDate, bean.NoveltyInitDate, bean.NoveltyUptoDate, employeeID, bean.description)
        setLoading(true);
        service.addEnjoyedVacation(_user, _vacation.IDDocument, _vacation.DocumentDate, bean.NoveltyInitDate, bean.NoveltyUptoDate, employeeID, bean.description).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                console.log(resp)
                getLista();
            }
            single.update(bean);
        });
    };

    const releaseVacation = () => {
        setModalRelease({ ...modalRelease, "view": false });
        setLoading(true);
        service.releaseEnjoyedVacation(bean.IDEnjoyedVacation, single.getAccountID()).subscribe((resp: any) => {
            setLoading(false);
            console.log(resp);

            if (resp.DataBeanProperties) {
                getLista();
            }
        })

    }

    const head = [
        { title: "ID", property: idBean },
        { title: "Descripción", property: "Description" },
        { title: "Número de días", property: "Units" },
        { title: "Desde", property: "FromDate" },
        { title: "Hasta", property: "UptoDate" },
        { title: "¿Son vacaciones Anticipadas?", property: "IsAnticipated" },
        { title: "Agregadas por", property: "EmployeeName" },
    ];

    const iconButtonsItem = [
        { nombre: "Agregar Vacaciones disfrutadas", visible: single.canCreate(), icono: "ri-calendar-check-line", evento: agregarEV, },
        // { nombre: "Agregar Vacaciones Anticipadas", visible: true, icono: "ri-calendar-2-fill", evento: agregarAV, },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Anular registro", icono: "ri-file-list-line", visible: ((single.canDelete()) && (_rol === 1)), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            // { titulo: "Agregar Vacaciones anticipadas", icono: "ri-file-list-line", visible: true, evento: () => { setModalAnti({ ...modalAnti, "view": true }) } },
            // { titulo: "Relacionar vacaciones anticipadas a pagas", icono: "ri-file-list-line", visible: true, evento: () => { setModalRelation({ ...modalRelation, "view": true }) } },
            { titulo: "Liberar Vacaciones", icono: "ri-file-list-line", visible: ((single.canCreate() && _rol === 1 && bean.IsAnticipated)), evento: () => { setModalRelease({ ...modalRelease, "view": true, "name": "Liberar registro de vacaciones anticipadas" }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <div className="row">
                <>
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
                </>
            </div>
            {modal &&
                <Modal modal={modal} eventModal={updateItem} updateModal={setModal}>
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">IDDocument:</label>
                                <input type="Number" className="form-control" disabled value={_vacation.IDDocument} ></input>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Fecha del documento:</label>
                                <input className="form-control" disabled value={_vacation.DocumentDate} ></input>
                            </div>
                            <div className="col-md-6">
                                <InputDate name="desde" label="Fechas de inicio" value={bean.NoveltyInitDate} setDate={(e: any) => { setBean({ ...bean, "NoveltyInitDate": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate name="desde" label="Fecha de Finalización" value={bean.NoveltyUptoDate} setDate={(e: any) => { setBean({ ...bean, "NoveltyUptoDate": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Descripcion</label>
                                <textarea className="form-control" value={bean.description} onChange={(e) => { setBean({ ...bean, 'description': e.target.value }) }}></textarea>
                            </div>
                        </div>
                    </>
                </Modal>
            }

            {modalConfirm &&
                <ModalConfirm modal={modalConfirm} eventModal={eliminarItem} updateModal={setModalConfirm}>
                    <>
                        Anular registro: {bean.IDEnjoyedVacation}
                    </>
                </ModalConfirm>
            }

            {modalRelease &&
                <ModalConfirm eventModal={releaseVacation} modal={modalRelease} updateModal={setModalRelease}>
                    Liberar registro: {bean.Description}
                </ModalConfirm>
            }
        </>
    )
}