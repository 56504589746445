import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { SelectAdquisitionArticleConstants } from "./select/SelectAdquisitionArticleConstants";
import { SelectUnspscID } from "../admin/configuracion/UnspscID/SelectUnspscID";
import { SelectMonths } from "./select/SelectMonths";
import { FileInput } from "../theme/Component/FileInput";
import { ArticleDropDown } from "./ArticleDropDown";
import { ItemDropDown } from "./ItemDropDown";
import { FileService } from "../../core/services/FileService";
import { AdminService } from "../../core/services/AdminService";
import { InputMoney } from "../theme/Input/InputMoney";
import { SelectBudgetID } from "../admin/configuracion/BudgetID/SelectBudgetID";
import { SelectProjectID } from "../admin/configuracion/ProjectID/SelectProjectID";
import { SelectBudgetResource } from "./select/SelectBudgetResource";
import { AdquisitionItemBudgetID } from "./AdquisitionItemBudgetID";
import { SelectBudgetIDList } from "./select/SelectBudgetIDList";
import { SelectPreparadorCatalog } from './select/SelectPreparadorCatalog';
import { ListMenu } from '../theme/Component/ListMenu';
import { SelectorUmeasurement } from '../bpm/config/Componentes/SelectorUmeasurement';
import { Obligation } from './Obligation';
import { Alert } from '../theme/Component/Alert';
import { InputDinero } from '../theme/Component/InputDinero';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
interface Props {
    idAdquisitionPlanFunctionalID: number;
    beanOficina: any;
    modo?: boolean;
    selectRow?: any;
    preparador: boolean;
    idPrepared: number;
}
export const AdquisitionItemByFuncionalID = ({ idAdquisitionPlanFunctionalID, beanOficina, modo, selectRow, preparador, idPrepared }: Props) => {

    //en este crud se trae toda la iformacion respecto a al adquisitionItemArticle

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const adminService = new AdminService();
    const fileService = new FileService();
    const [lista, setLista] = useState([]);
    const [listaA, setListaA] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [plan, setPlan] = useState<any>({});
    const [editar, setEditar] = useState(true);
    const [budgetList, setbudgetlist] = useState<any>([]);
    const [step, setStep] = useState(0);
    const [bean, setBean] = useState<any>({});
    const [beanA, setBeanA] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    // const [modalObligacion, setModalObligacion] = useState<any>({ name: 'Obligaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalItem, setModalItem] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm1, setModalConfirm1] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    //Adquisition Item

    const idBean = 'IDAdquisitionItem';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Justificación', property: 'Justification', visible: true },

        { title: 'Valor total estimado', property: 'TotalValue' },
        { title: 'Valor total artículos', property: 'TotalArticle' },
        { title: 'Tipo de Presupuesto', property: 'BudgetTypeName', visible: false },
        // { title: 'Fecha Estimada Inicio Proceso', property: 'MonthName' },
        // { title: 'Duración estimada del contrato (Meses)', property: 'EstimatedMonths' },
        // { title: 'Modalidad de selección u Otro si de Contrato ', property: 'IDHiringModalityName', visible: false },
        // { title: 'Fuente de los recursos', property: 'IDBudgetResourceName', visible: false },
        // { title: 'Rubro Presupuestal', property: 'IDLnBudgetIDNameList', visible: true },
        { title: 'Iniciativa Estratégica/Proyecto', property: 'NameProject', visible: true },
        // { title: 'Etapa', property: 'PhaseStateName', visible: false },
        // { title: 'Estados', property: 'Estados', visible: true },
        // { title: 'Dependencia Solicitante', property: 'IDLnFunctionalIDName', visible: false },
        // { title: 'Estado preparador', property: 'PreparationStateName' },
        { title: 'Preparador', property: 'PreparertName' },
        { title: 'Estado', property: 'PreparationStateName' },

    ]

    useEffect(() => {
        getPlan();
        getLista();
        setStep(0);
        setBeanA({});
        if (modo != undefined) {
            setEditar(modo);
        }
        return () => {
            idAdquisitionPlanFunctionalID = 0;
        }
    }, [idAdquisitionPlanFunctionalID, beanOficina])

    useEffect(() => {
        if (step === 0) {
            getLista();
        }
        else if (step === 1) {
            getAdquisitionArticleCatalog();
        }
    }, [step])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    // useEffect(()=>{
    //     if(bean.IDAdquisitionItem)
    //     getAdquisitionArticleCatalog()
    // },[bean])

    //Adquisition Article
    const idBeanA = 'IDAdquisitionArticle';

    const headArticule = [

        // { title: 'Orden del Artículo', property: 'ItemOrder' },
        { title: 'ID', property: 'IDAdquisitionArticle', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Unidad de Medida', property: 'MeasurementUnit', visible: true },
        { title: 'Valor estimado unitario', property: 'EstimatedUnitaryValue', visible: true },
        { title: 'Valor Total', property: 'TotalValue', visible: true },
        { title: 'Presupuesto', property: 'BudgetTypeName', visible: false },
        { title: 'Especificaciones Técnicas', property: 'TechnicalSpecifications', visible: false },
        { title: 'Rubro', property: 'IDLnUnspscIDName', visible: true },
        // { title: 'Mes a ejecutar', property: 'MonthName' },
        // { title: 'Meses estimados ejecución del bien/servicio', property: 'EstimatedMonths' },
        { title: 'Estado', property: 'StructuringStateName', visible: true },
        { title: 'Adjunto', property: 'URL', visible: true },
    ]

    const getPlan = () => {
        adminService.getAdquisitionPlan(beanOficina.IDAdquisitionPlan).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setPlan(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                        // console.log("Plan", resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                    }
                }
            }
        );
    }

    const setRowBeanA = (bean: any) => {
        console.log("Row : ", bean);
        setBeanA(bean);

    }

    const renderEstados = (item: any) => {
        // let render =
        //     `<div>            
        //         <strong>Planeación</strong><br/><small>${item.DataBeanProperties.PlanningStateName ? item.DataBeanProperties.PlanningStateName : 'NA'}</small><br />
        //         <strong>Estructuración</strong><br/><small>${item.DataBeanProperties.StructuringStateName ? item.DataBeanProperties.StructuringStateName : 'NA'}</small><br />
        //         <strong>Contratación</strong><br/><small>${item.DataBeanProperties.HiringStateName ? item.DataBeanProperties.HiringStateName : 'NA'}</small><br />
        //     </div>`;

        let render = item.DataBeanProperties.PhaseState;
        if (item.DataBeanProperties.PhaseState === 0) {
            render = item.DataBeanProperties.PlanningStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 1) {
            render = item.DataBeanProperties.StructuringStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 2) {
            render = item.DataBeanProperties.HiringStateName;
        }
        return render;
    }


    const getLista = () => {
        setLoading(true);
        // si es cargado desde la vista de un preparador 
        if (beanOficina.IDFunctionalIDPreparer) {
            service.getAdquisitionItemByFuncionalIDAndPreparer(idAdquisitionPlanFunctionalID, single.getAccountID(), null, null).subscribe(
                // service.getAdquisitionItemByFuncionalIDAndPreparer2(beanOficina.IDFunctionalIDPreparer, null, null).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue)
                        if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                            let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                            // ll.forEach((element: any) => {
                            //     element.DataBeanProperties.Estados = renderEstados(element);
                            // });
                            setLista(ll);
                        }

                    setLoading(false);
                }
            );
        }
        // si es cargado desde la vista de un administrador
        else {
            //con este servicio se consume para traer la informacionb respectiva sobre los items funcionales.
            service.getAdquisitionItemByFuncionalID(idAdquisitionPlanFunctionalID, null, null, null).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                        ll.forEach((element: any) => {
                            element.DataBeanProperties.Estados = renderEstados(element);
                        });
                        setLista(ll);
                    }
                    setLoading(false);
                }
            );
        }
    }

    const getBudgetIDList = () => {
        if (bean.IDAdquisitionItem) {
            service.getBudgetIDList(bean.IDAdquisitionItem).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setbudgetlist(resp.DataBeanProperties.ObjectValue);
                    }
                }
            );
            setModalRubro({ ...modalRubro, "view": false });
        }
    }

    const getAdquisitionArticleCatalog = () => {

        setLoading(true);
        service.getAdquisitionArticleCatalog(bean.IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.MediaContext) {
                            element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`

                        } else {
                            element.DataBeanProperties.URL = "";
                        }

                    });

                    setListaA(tmp);
                } else {
                    setListaA([]);
                }

                setLoading(false);
            }
        );
    }

    const agregarItemArticule = () => {

        let b = {
            ItemOrder: 1,
            Description: '',
            TechnicalSpecifications: '',
            Quantity: 1,
            MeasurementUnit: '',
            Type: 0,
            UnspscViewCode: '',
            EstimatedUnitaryValue: 0,
            MonthName: '',
            EstimatedMonths: 1,
            Media: '',
            IDAccountStructurer: single.getAccountID(),
            IDAdquisitionItem: bean.IDAdquisitionItem,
            IDLnBudgetID: 0
        }

        setBeanA(b);
        setModal({ ...modal, "view": true });
    }

    const editarItem = () => {
        setModal({ ...modal, "view": true });
    }

    const eliminarItemArticulo = () => {
        setLoading(true);
        service.deleteAdquisitionArticle(beanA.IDAdquisitionArticle).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getAdquisitionArticleCatalog();
                }
                single.delete(beanA[idBeanA], beanA);
            }
        );
        setModalConfirm({ ...modalConfirm, "view": false });
    }


    const eliminarItem = () => {
        setLoading(true);
        service.deleteAdquisitionItem(bean.IDAdquisitionItem).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm1({ ...modalConfirm1, "view": false });
    }

    const updateItemArticulo = () => {
        console.log(beanA);
        setModal({ ...modal, "view": false });
        setLoading(true);
        service.updateAdquisitionArticle(beanA).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getAdquisitionArticleCatalog();
                }
                if (beanA[idBeanA]) {
                    single.update(beanA);
                }
                else {
                    single.create(beanA);
                }
                setLoading(false);
            });
    }

    const updateMItem = () => {
        console.log(bean);
        service.updateAdquisitionItem(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {
                    single.update(bean);
                }
                else {
                    single.create(bean);
                }
                setModalItem({ ...modalItem, "view": false });
            }
        )
    }

    const editarMItem = () => {
        setModalItem({ ...modalItem, "view": true, "name": 'Editar' });
    }

    const agregarItem = () => {
        let b = {
            IDAdquisitionPlanFunctionalID: idAdquisitionPlanFunctionalID,
            Description: '',
            EstimatedValue: 0,
            ItemOrder: 1,
            ExecutionMonth: 0,
            EstimatedMonths: 1,
            IDBudgetIDType: plan.IDBudgetIDType,
            IDLnBudgetID: 0,
            Justification: '',
            IDFunctionalIDPreparer: idPrepared,
        }
        setBean(b);
        setModalItem({ ...modalItem, "view": true, "name": 'Agregar Ítem' });

    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        {/* funcional item  */}
                        {editar &&
                            <div className="row">
                                {step === 0 && //items functional
                                    <>
                                        <div className="col-md">
                                            {bean[idBean] && step === 0 &&
                                                <ItemDropDown rol={2} bean={bean} permisos={permisos} updateEvent={() => { getLista(); setBean({}) }}></ItemDropDown>
                                            }
                                        </div>
                                    </>
                                }
                                {/* items Articulo */}
                                {step === 1 &&
                                    <div className="col-md">

                                        <BasicButton value="Regresar" icon="ri-arrow-left-fill" clase='primary' eventClick={() => { setStep(0); setBeanA({}); setBean({}) }}></BasicButton>

                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }



    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate(), evento: agregarItem }
    ]

    const iconButtonsArticule = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: agregarItemArticule }
    ]

    const listaBotonesItems = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: editarMItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: () => { setModalConfirm1({ ...modalConfirm1, ['view']: true }) } },
            { titulo: 'Rubros', icono: 'ri-git-branch-line', visible: true, evento: () => { setModalRubro({ ...modalRubro, "view": true }) } },
            { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setStep(1); getBudgetIDList() } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const listaBotonesArticulos = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row ">
                <div className="col-md-12 p-2">
                    {step === 0 &&
                        <>
                            <p>
                                <strong>Ítems del Plan Oficina</strong><br /><small>{beanOficina.IDLnFunctionalIDName}</small>
                            </p>
                            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotonesItems()} headButtons={renderButton()} head={head} body={lista} rowSet={setRowBean}></BasicTable>

                        </>
                    }
                    {step === 1 &&
                        <>
                            <p>
                                <strong>Bienes/Servicios del Ítem</strong><br /><small>{bean.Description}</small>
                            </p>
                            <BasicTable listButtons={listaBotonesArticulos()} headButtons={renderButton()} iconButtons={iconButtonsArticule} head={headArticule} body={listaA} rowSet={setRowBeanA}></BasicTable>
                        </>
                    }
                </div>
            </div>


            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItemArticulo}>
                {beanA.Description}
            </ModalConfirm>

            <ModalConfirm modal={modalConfirm1} updateModal={setModalConfirm1} eventModal={eliminarItem}>
                {bean.Description}
            </ModalConfirm>

            {/* adquisitionItem debe recibir el IDAdquisitionPlanFunctionalID*/}
            <Modal modal={modalItem} updateModal={setModalItem} eventModal={updateMItem}>

                <div className="row">
                    {!preparador &&
                        <div className="col-md-6">
                            <SelectPreparadorCatalog idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} idSelector={bean.IDFunctionalIDPreparer != null ? bean.IDFunctionalIDPreparer : 0}
                                onChange={(id: number) => { setBean({ ...bean, "IDFunctionalIDPreparer": id }) }}
                            />
                        </div>
                    }
                    <div className="col-md-6">
                        <label>Valor Estimado</label>
                        <InputMoney id={0} name='Valor Estimado' value={bean.TotalValue} onChange={(e: any) => { setBean({ ...bean, ['TotalValue']: parseInt(e) }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectMonths idSelector={bean.ExecutionMonth} onChange={(e: number) => { setBean({ ...bean, "ExecutionMonth": e }) }}></SelectMonths>
                    </div>
                    <div className="col-md-6">
                        <label>Meses Estimados</label>
                        <input type="number" className="form-control mt-2" value={bean.EstimatedMonths} onChange={(e) => { setBean({ ...bean, "EstimatedMonths": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectBudgetResource idSelector={bean.IDBudgetResource} onChange={(e: any) => {
                            setBean({ ...bean, "IDBudgetResource": e })
                        }}></SelectBudgetResource>
                    </div>

                    <div className="col-md-12">
                        <SelectProjectID renderValue={bean.IDLnProjectIDName} edit={false} onChange={(e: any) => {
                            console.log(e);
                            setBean({ ...bean, "IDLnProjectID": e.IDLn })
                        }}></SelectProjectID>
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Justificación</label>
                        <textarea className="form-control" placeholder='Incluya los aspectos legales, procedimentales, funcionales que sustentan la solicitud'
                            value={bean.Justification != null ? bean.Justification : ''} onChange={(e) => { setBean({ ...bean, "Justification": e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label"> Necesidad</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }}></textarea>
                    </div>

                </div>


            </Modal>



            {/* articule debe recibir el IDAdquisitionItem*/}
            <Modal modal={modal} updateModal={setModal} eventModal={updateItemArticulo}>


                {budgetList.length == 0 &&
                    <Alert clase='primary'>
                        Debe crear al menos un <b>Rubro</b>
                    </Alert>
                }
                {budgetList.length > 0 &&
                    <>

                        <div className="row">

                            <div className="col-md-4">
                                <label >Cantidad</label>
                                <input type="number" className="form-control mt-2" value={beanA.Quantity} onChange={(e) => { setBeanA({ ...beanA, "Quantity": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label >Valor estimado unitario</label>
                                {/* <input type="text" className="form-control" value={beanA.EstimatedUnitaryValue} onChange={(e) => { setBeanA({ ...beanA, "EstimatedUnitaryValue": e.target.value }) }} /> */}
                                <InputMoney id={0} name='Valor estimado unitario' value={beanA.EstimatedUnitaryValue} onChange={(e: any) => { setBeanA({ ...beanA, ['EstimatedUnitaryValue']: parseInt(e) }) }} />
                            </div>
                            <div className="col-md-4">
                                <SelectorUmeasurement titulo='Unidad de Medida' idSelector={beanA.MeasurementUnit} onChange={(e: any) => { setBeanA({ ...beanA, "MeasurementUnit": e }) }} />
                            </div>

                            <div className="col-md-12 mt-1">
                                <label >Especificaciones Técnicas</label>
                                <textarea className="form-control mt-2" value={beanA.TechnicalSpecifications} onChange={(e) => { setBeanA({ ...beanA, "TechnicalSpecifications": e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12 mt-2">
                                <label >Descripción</label>
                                <textarea className="form-control mt-2" value={beanA.Description} onChange={(e) => { setBeanA({ ...beanA, "Description": e.target.value }) }}></textarea>
                            </div>

                            <div className="col-md-3 mt-2">
                                <SelectAdquisitionArticleConstants idSelector={beanA.Type} onChange={(e: any) => { setBeanA({ ...beanA, "Type": e }) }}></SelectAdquisitionArticleConstants>
                            </div>
                            <div className="col-md-9 mt-2">
                                <SelectUnspscID renderValue={beanA.IDLnUnspscIDName} onChange={(e: any) => {
                                    setBeanA({ ...beanA, "IDLnUnspsc": e.IDLn })
                                }}></SelectUnspscID>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                {/* <SelectBudgetIDList idFiltro={beanA.IDAdquisitionItem} idSelector={beanA.IDLnBudgetID} onChange={(e: number) => {
                                    setBean({ ...beanA, "IDLnBudgetID": e })
                                }} /> */}
                                <label>Rubro</label>
                                <select className='form-select mt-2' value={beanA.IDLnBudgetID} onChange={(e) => {
                                    setBeanA({ ...beanA, "IDLnBudgetID": parseInt(e.target.value) })
                                }}>
                                    <option value="0">...</option>
                                    {budgetList.map((item: any) => {
                                        return (
                                            <option value={item.DataBeanProperties?.IDLn}>
                                                {item.DataBeanProperties.BudgetViewCode} - {item.DataBeanProperties.Name}
                                            </option>
                                        )
                                    })

                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <FileInput Media={beanA.Media} MediaContext={beanA.MediaContext} onCreate={(fileMap: any) => {
                                    setBeanA({ ...beanA, "Media": fileMap.Media, "MediaContext": fileMap.MediaContext })
                                }}></FileInput>
                            </div>
                        </div>
                    </>
                }

            </Modal>

            {/* rubros de la necesidad  */}
            <Modal modal={modalRubro} updateModal={setModalRubro} eventModal={() => { }}>
                {modalRubro.view &&
                    <div className="row">
                        <div className="col">
                            <AdquisitionItemBudgetID CanModify={bean.PreparationState} idAdquisitionItem={bean.IDAdquisitionItem} iDBudgetIDType={bean.IDBudgetIDType} />
                        </div>
                    </div>
                }

            </Modal>

            {/* modal obligaciones  */}

            {/* <Modal modal={modalObligacion} updateModal={setModalObligacion} eventModal={() => {}}>
                <Obligation CanModify={bean.PreparationState} IDAdquisitionItem={bean.IDAdquisitionItem} />
            </Modal> */}
        </>
    )
}