
import { QuickAction } from '../MenuDashed/MenuDashed'
import './bannerdashed.scss'

interface Props {
    quickAction: QuickAction
}
export const BannerDashed = ({ quickAction }: Props) => {
    return (
        <div className='banner-box'>
            <h2 className='text-white'>
                {quickAction?.label || ''}
            </h2>
            <div className="icon-banner">
                {quickAction?.icon || ''}
            </div>
        </div>
    )
}
