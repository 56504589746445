import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { FileInput } from "../../../../theme/Component/FileInput";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { MyEditor } from "../../../../theme/Component/MyEditor";
import { InternalMenuItem } from "./InternalMenuItem";
import { useInternalMenu } from "./useInternalMenu";
export const InternalMenu = () => {

    const {
        bean,
        setBean,
        lista,
        modal,
        modalConfirm,
        setModal,
        setModalConfirm,
        single,
        eliminarItem,
        iconButtonsItem,
        head,
        setRowBean,
        updateItem,
        modalMenu,
        setModalMenu
    } = useInternalMenu();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: `Editar ${bean.Menu}` }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
            { titulo: "Sub-Menus", icono: "ri-menu-add-line", visible: true, evento: () => { setModalMenu({ ...modalMenu, view: true, name: `Sub-menus de ${bean.Menu}` }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Menus">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Menu: </label>
                        <input type="text" className="form-control " value={bean.Menu} onChange={(e) => { setBean({ ...bean, 'Menu': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <FileInput
                            directory='Multimedia'
                            label='Imagen del menu'
                            docAcept='image/jpeg , image/png'
                            onCreate={({ Media, MediaContext, Name }) => {
                                let x = {
                                    Media: Media,
                                    MediaContext: MediaContext,
                                    Name: Name
                                }
                                setBean({ ...bean, "ImageCover": JSON.stringify(x) })
                            }}
                        />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción: </label>
                        <MyEditor onChange={(e: any) => { setBean({ ...bean, 'Description': e }) }} val={bean.Description}></MyEditor>
                    </div>
                </div>
            </Modal >
            <Modal modal={modalMenu} updateModal={setModalMenu} >
                <InternalMenuItem _idMenu={bean.IDInternalMenu} />
            </Modal>
        </>
    );
};
