// FORMATO DE PREENTACIÓN DE OFERTA/PROPUESTA
import { useState } from 'react'
import { formatDate, today } from '../../../utils/helpers'
import { Divider } from './Divider'

interface Props {
    data: any
}

const specifications = [
    'Precio Total + IVA',
    'Condiciones de pago',
    'Tiempos de entrega',
    'Experiencia en venta',
    'Servicio de postventa y garantías',
    'Dirección de oficinas',
]

const aditionalSpecifications = [
    'Tiempo de garantía máxima',
    'Descripción de la tecnológia ofrecida',
    'Descripción de los materiales del bien y/o servicio',
    'Tiempo máximo o duración del bien y/o servicio',
    'Tiempo máximo en la transferencia de tecnología o actualización tecnológica',
    'Cuenta con certificación del sistema de gestión',
]

export const OfferPurpose = ({ data }: Props) => {


    const [options, setOptions] = useState([
        'Item',
        'Descripción',
        'Cantidad',
        'Valor unitario con IVA',
        'Valor Total con IVA',

    ])
    const [options2, setOptions2] = useState([
        'Obligación',
        'Cumple',
        'No Cumple'

    ])
    const [items, setItems] = useState([])
    const [obligations, setObligations] = useState([])

    return (
        <div className='row'>
            <div className="w-100 my-2">
                <strong>Fecha</strong>&nbsp;{formatDate(today)}
            </div>
            <div className='d-flex flex-column mt-3'>
                <strong>Señores</strong>
                <strong className='mb-0'>Compras y Contratación</strong>
                <strong>Caja honor</strong>
            </div>
            <div className="col-12 mt-3">
                <p>De manera atenta, presento mi oferta con la siguiente información</p>
            </div>
            <div className="col-12">
                <Divider
                    title='Ficha técnica'
                    description='Ficha técnica o descriptiva del bien y/o servicio a contratar'
                />
                <div className="alert alert-warning" role="alert">
                    <strong>Objeto:</strong>
                </div>
                <div className='d-flex mt-3' style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                {options.map((_e, _i) =>
                                    <th key={_i} scope="col">
                                        {_e === '' ? `Proveedor ${_i + 1}` : _e}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((_e, _i) =>
                                <tr key={_i}>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>Otto</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12">
                <Divider
                    title='Obligaciones'
                    description='Obligaciones principales del bien y/o servicio. Señalados en el estudio previo'
                />
            </div>
            <div className='d-flex mt-3' style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            {options2.map((_e, _i) =>
                                <th key={_i} scope="col">
                                    {_e === '' ? `Proveedor ${_i + 1}` : _e}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((_e, _i) =>
                            <tr key={_i}>
                                <th scope="row">1</th>
                                <td colSpan={8}>Mark</td>
                                <td colSpan={2}>Otto</td>
                                <td colSpan={2}>@mdo</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <Divider
                    title='Información del bien y/o servicio.'
                    description='Las siguientes son las especificaciones realizadas por el proveedor'
                />
                <div className="accordion" id="accordionExample">
                    {specifications.map((_e, __i) =>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${__i}`} aria-expanded="false">
                                    {_e}
                                </button>
                            </h2>
                            <div id={`collapse${__i}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Lorem ipsum
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-12">
                <Divider
                    className='mt-5'
                    title='Información adicional del Proveedor.'
                    description='Podrá solicitarse, de requerirse, para la comparación de ofertas la siguiente información adicional'
                />
                <div className='mt-3'>
                    <strong>Condición del proveedor:</strong>
                    &nbsp;
                    <span>{`(Fabricante, Independiente, Distribuidor, Importador, Otro ¿Cuál?)`}</span>
                </div>
                <div className='mt-3'>
                    <p>
                        <small className='text-muted'>
                            En cumplimiento al Decreto 830 de 2021 y las Circulares Externas expedidas por la Superintendencia Financiera de Colombia para el
                            Sistema de Administración del Riesgo de Lavado de Activos y Financiación al Terrorismo - SARLAFT, marque con una X su condición:
                        </small>
                    </p>
                </div>
                <div className='mt-3'>
                    <strong>Personas Expuestas Políticamente – PEP:</strong>
                    &nbsp;
                    <span>{`(SI, NO)`}</span>
                    <div className='mt-2'>
                        <p>
                            <small className='text-muted'>
                                <strong>Descripción:</strong>
                                <br />
                                <strong>1. Persona Expuesta Políticamente (PEP):</strong> &nbsp; Se consideran PEP los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y territorial, cuando en los cargos que ocupen, tengan en las funciones del área a la que pertenecen o en las de la ficha del empleo que ocupan, bajo su responsabilidad directa o por delegación, la expedición de normas o regulaciones, la dirección general, la formulación de políticas institucionales y adopción de planes, programas y proyectos, el manejo directo de bienes, dineros o valores del Estado y la administración de justicia o facultades administrativo sancionatorias. Funciones pueden ser a través de ordenación de gasto, contratación pública, gerencia de proyectos de inversión, pagos, liquidaciones, administración de bienes muebles e inmuebles. PEP desde su nombramiento y hasta 2 años después de dejar su cargo.
                                &nbsp;
                                <br />
                                <br />
                                <strong>2. PEP de organizaciones internacionales:</strong> &nbsp; Son aquellas personas que ejercen funciones directivas en una organización internacional. Se entienden por PEP de organizaciones internacionales directores, subdirectores, miembros de juntas directivas o cualquier persona que ejerza una función equivalente. Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo.
                                <br />
                                <br />
                                &nbsp;
                                <strong>3. PEP extranjeros:</strong> &nbsp; Son aquellas personas que desempeñan funciones públicas destacadas en otro país. Se entienden por PEP extranjeros: (i) jefes de Estado, jefes de Gobierno, ministros, subsecretarios o secretarios de Estado; (ii) congresistas o parlamentarios; (iii) miembros de tribunales supremos, tribunales constitucionales u otras altas instancias judiciales cuyas decisiones no admitan normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de tribunales o de las juntas directivas de bancos centrales; (v) embajadores, encargados de negocios y altos funcionarios de las fuerzas armadas, y (vi) miembros de los órganos administrativos, de gestión o de supervisión de empresas de propiedad estatal. \f0Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo.   Definición de vinculado:  1. Segundo grado de consanguinidad (padres, abuelos, hermanos, hijos, nietos), 2. Segundo de afinidad (yernos, nueras, suegros, cuñados, abuelos del cónyuge o compañero/a permanente del PEP), y 3. Primero civil (hijos adoptivos o padres adoptantes).  4. Asociado cercano cuando el PEP sea socio o asociado de una persona jurídica y, además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica en los términos del artículo 261 del Código de Comercio.
                            </small>
                        </p>
                    </div>

                </div>
                <div className='my-3'>
                    <strong>De ser una persona PEP deberá acreditar tal condición de acuerdo con lo establecido anteriormente.</strong>
                </div>
            </div>
            <div className='col-12'>
                <div className="accordion" id="accordion">
                    {aditionalSpecifications.map((_e, __i) =>
                        <div key={__i} className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${_e}`} aria-expanded="false">
                                    {_e}
                                </button>
                            </h2>
                            <div id={`collapse${_e}`} className="accordion-collapse collapse show" data-bs-parent="#accordion">
                                <div className="accordion-body">
                                    <p>
                                        Lorem ipsum
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-12 m-5 mb-0">
                <div className='mt-2'>
                    <strong>Atentamente:</strong>
                </div>
                <div className='mt-5 d-flex flex-column'>
                    <strong>Firma:</strong>
                    <strong>Teléfono:</strong>
                    <strong>Celular:</strong>
                    <strong>Correo electrónico:</strong>
                </div>
            </div>
        </div>
    )
}
