
import { BsChevronCompactDown } from 'react-icons/bs'
import './nextevents.scss'

export type EventItem = {
    day?: string,
    date: string,
    month: string,
    description: string
    event?: (e: EventItem) => void
}

interface Props {
    /* events: Event[] | undefined */
    events: EventItem[]
}
export const NextEvents = ({ events }: Props) => {

    function getMonthName(monthNumber) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "diciembre"
        ];

        return months[monthNumber];
    }

    return (
        <div className="list_container">
            <div className='event_title'>
                Próximos Eventos
            </div>
            {events.length > 0 &&
                <div className="list_over">
                    {events.map((e, i) =>
                        <div key={i} className='event_item'
                            onClick={() => e.event ? e.event(e) : ''}
                        >
                            <div className='date_container'>
                                <span>{e.date}</span>
                                <span>{e.month}</span>
                            </div>
                            <div className="event_description">
                                {e.description}
                            </div>
                        </div>
                    )}
                </div>}
            <div className='d-flex h-100 justify-content-center'>
                <div className='event_item mt-4'>
                    <div className='date_container'>
                        <span>{new Date().getDate()}</span>
                        <span>{getMonthName(new Date().getMonth())}</span>
                    </div>
                    <div className="event_description">
                        {'No hay eventos hoy'}
                    </div>
                </div>
            </div>
            <div>
                <h5 className='text-white'>No hay eventos</h5>
            </div>
            <div className='scroll_icon' style={{ marginTop: '0.6em' }}>
                <BsChevronCompactDown />
            </div>
        </div>
    )
}
