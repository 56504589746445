import React, { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BpmService } from "../../../core/services/BpmService";
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface Props {
    IDProcedure: any;
}

export const ProcedureAction = ({ IDProcedure }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Historico de invocaión del servicio', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalR, setModalR] = useState<any>({ name: 'Respuesta del servicio', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-sm' });
    const [respJson, setRespJson] = useState<any[]>([]);

    useEffect(() => {
        getTramites();
    }, [IDProcedure]);

    const getTramites = () => {
        setLoading(true);
        service.getProcedureActionByProcedureImp(IDProcedure).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((ele: any) => {
                        if (ele.DataBeanProperties.FormCode == null) {
                            ele.DataBeanProperties.FormCode = "NA";
                        }
                        if (ele.DataBeanProperties.ResponseJsonValue == null) {
                            ele.DataBeanProperties.ResponseJsonValue = "NA";
                        }
                    });

                    setLista(list);
                }
                setLoading(false);
            }
        );
    };

    const listaBotones = () => {
        const botones = [
            { titulo: 'Historico de invocación del servicio', icono: 'ri-alarm-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Respuesta del servicio', icono: 'ri-chat-1-line', visible: true, evento: () => { setModalR({ ...modalR, "view": true }) } },
        ];
        return (
            <ListMenu listaBotones={botones}></ListMenu>
        );
    };

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

        if (bean.ResponseJsonValue && bean.ResponseJsonValue !== "NA") {
            try {
                const parsedJson = JSON.parse(bean.ResponseJsonValue);
                setRespJson(parsedJson);
                console.log(parsedJson);
            } catch (error) {
                console.error("Error al parsear JSON:", error);
            }
        } else {
            setRespJson([])
            console.log(respJson)
        }
    };

    const head = [
        { title: 'ID', property: 'IDDocument' },
        { title: 'Area encargada', property: 'FunctionalIDName' },
        { title: 'Etapa', property: 'ProcedureName' },
        { title: 'Responsable', property: 'AccountName' },
        { title: 'Tipo de actividad', property: 'ActivityTypeName' },
        { title: 'Nombre de documento', property: 'ProcedureActionName' },
        { title: 'Tipo de documento', property: 'DocumentTypeName' },
        { title: 'Estado', property: 'StateName' },
        { title: 'Codigo de Formulario', property: 'FormCode' },
        { title: 'Es opcional', property: 'IsOptional' },
        { title: 'Datos documentos', property: 'ResponseJsonValue' },
    ];

    return (
        <>
            <BasicPage titulo="Historial de gestión del proceso">
                <>
                    <BasicTable body={lista} head={head} rowSet={setRowBean} listButtons={listaBotones()}></BasicTable>
                </>
            </BasicPage>

            <Modal modal={modal} updateModal={setModal}>
                <>
                    {bean.InvokedValidated != null &&
                        <>
                            <div className="row">
                                <div className="col-md-12 overflow-auto">
                                    <table className="table table-nowrap mb-0">
                                        <tr>
                                            <td><p>Servicio Invocado</p></td>
                                            <td>{bean.InvokedValidated}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Servicio Enviado Construido sin Errores</p></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><p>Fecha de Envío</p></td>
                                            <td>{bean.InvokedDate}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Mensaje Enviado Al Servicio</p></td>
                                            <td>{bean.InvokedMsgJsonValue}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Mensaje recibido del Servicio</p></td>
                                            <td>{bean.ResponseJsonValue}</td>
                                        </tr>
                                        <tr>
                                            <td><p>Mensaje de Error</p></td>
                                            <td>{bean.InvokedErrorMsg == '' ? 'Sin errores' : bean.InvokedErrorMsg}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    {bean.InvokedValidated == null &&
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: 'center' }}>
                                    <h3>
                                        <strong>El servicio no fue utilizado</strong>
                                    </h3>
                                </div>
                            </div>
                        </>
                    }
                </>
            </Modal>

            <Modal modal={modalR} updateModal={setModalR}>
                <>
                    <div className="col">
                        {respJson && respJson.length > 0 && typeof respJson[0] === 'object' ? (
                            <div className="col-md-12 overflow-auto">
                                <table>
                                    <tbody>
                                        {Object.entries(respJson).map(([key, value], index) => (
                                            <tr key={index}>
                                                <td><strong>{key}:</strong></td>
                                                <td>{typeof value === 'boolean' ? value.toString() : value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2>El servicio No Aplica o no tiene respuesta</h2>
                        )}
                    </div>
                </>
            </Modal>
        </>
    );
};
