// FORMATO ESTUDIO PREVIO DE CONVENIENCIA Y OPORTUNIDAD PARA LA CONTRATACIÓN DE UNA PERSONA JURÍDICA
import React from 'react'
import { Divider } from './Divider'


export const OportunityContractLegalEntity = () => {
    return (
        <div className='row'>
            <div className="d-flex">
                <small className='text-muted'>
                    El siguiente es el formato de solicitud de contración de un bien y/o servicio
                    para persona jurídica, por favor diligenciar los campos de acuerdo con lo solicitado.
                </small>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
                <strong>Radicado No.</strong>
                <strong>Dependencia(s)</strong>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='JUSTIFICACIÓN DE LA CONTRATACIÓN'
                />
                <p className='mt-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc congue feugiat ornare. Phasellus felis lectus,
                    ornare ut orci at, pretium iaculis massa.
                    Maecenas quis ante sed velit aliquam posuere.
                    Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                    Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                    vitae placerat diam consectetur a.
                </p>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='OBJETO DEL CONTRATO'
                />
                <p>
                    Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                    Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                    vitae placerat diam consectetur a.
                </p>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='DESCRIPCIÓN DEL BIEN O SERVICIO'
                />
                <p>
                    Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                    Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                    vitae placerat diam consectetur a.
                </p>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='COBERTURA'
                />
                <div className='d-flex' style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nombre del lugar</th>
                                <th>Dirección</th>
                                <th>Ciudad</th>
                                <th>Dependencia <br />
                                    <small>Encargada de la recepción del bien/servicio en Caja Honor</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            { }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='OBLIGACIONES PRINCIPALES'
                    description='Corresponde a las acciones primordiales que el proveedor
                    debe realizar con la entrega del bien y/o servicio; 
                    deben ser claras, expresar, exigibles y acordes con el bien y/o servicio.'
                />
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Donec fermentum purus quis ligula rutrum egestas.</li>
                    <li>Maecenas ac felis feugiat, elementum augue sit amet, rutrum leo.</li>
                    <li>Nunc laoreet libero vitae justo faucibus ultrices.</li>
                    <li>Suspendisse maximus massa a est vestibulum, vitae accumsan mauris pulvinar.</li>
                    <li>Integer pharetra nulla id pharetra cursus.</li>
                    <li>Quisque non nisi vel metus vulputate lacinia quis vel risus.</li>
                    <li>Fusce posuere ante sed est posuere efficitur vel faucibus mauris.</li>
                    <li>Duis vulputate metus viverra magna semper pulvinar.</li>
                    <li>Maecenas maximus quam sed nisi gravida interdum ac nec dui.</li>
                    <li>Vivamus iaculis risus non ante pulvinar tristique.</li>
                    <li>Ut luctus augue sit amet nisi tincidunt egestas.</li>
                    <li>Praesent suscipit dui ac justo semper venenatis.</li>
                    <li>In et metus sagittis, consequat sem sit amet, fermentum odio.</li>
                    <li>Phasellus id quam vitae nibh dictum sollicitudin id in dui.</li>
                </ul>
            </div>
            <div className="col-12 mt-3">
                <strong>Garantía al bien y/o servicio:</strong>
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='Requisitos del SG-SST'
                    description='Que aplican para el bien y/o servicio solicitado.'
                />
            </div>
        </div>
    )
}
