import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../core/services/FinanceService';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
export const BudgetStructureItem = ({ BudgetStructure, regresar }: any) => {

    const { setLoading } = useDashboardStore();
    const service = new FinanceService();
    const [idPadre, setIDPadre] = useState(0);
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDBudgetStructureItem';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nivel', property: 'Level' },
        { title: 'Máximo de Dígitos del item', property: 'MaxDigits' },
        { title: 'Nombre Item Rubro', property: 'Name' },
        { title: 'Descripción', property: 'Description' },




    ]

    useEffect(() => {
        if (BudgetStructure.IDBudgetStructure) {
            setIDPadre(BudgetStructure.IDBudgetStructure);
            getLista(BudgetStructure.IDBudgetStructure);
        }

    }, [])
    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])



    const getLista = (idBudgetStructure: number) => {

        setLoading(true);
        service.getBudgetStructureItemCatalog(idBudgetStructure).subscribe(
            (resp: any) => {
                setLista([]);
                console.log("Lista", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            Level: '',
            MaxDigits: '',
            IDBudgetStructure: BudgetStructure.IDBudgetStructure
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteBudgetStructureItem(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista(idPadre);
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateBudgetStructureItem(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista(idPadre);
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', evento: agregarItem },
        { nombre: 'Regresar', icono: 'ri-edit-2-line', evento: regresar }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Level</label>
                        <input type="number" className="form-control" value={bean.Level} onChange={(e) => { setBean({ ...bean, ['Level']: e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">MaxDigits </label>
                        <input type="text" className="form-control" value={bean.MaxDigits} onChange={(e) => { setBean({ ...bean, ['MaxDigits']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Description</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>

        </>
    );
}