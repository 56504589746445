import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { MultiSelect } from '../../theme/Component/MultiSelect';
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    idApiModule: number;
}

export const ApiPermissions = ({ idApiModule }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [listaRoleGroup, setListaRoleGroup] = useState([]);
    const [listaStateGroup, setListaStateGroup] = useState([]);
    const [listaState, setListaState] = useState([]);
    const [roles, setRoles] = useState([]);
    const [states, setStates] = useState([]);
    const [StateGroups, setStateGroups] = useState('');
    const [miswitch, setMiswitch] = useState(false);

    const [bean, setBean] = useState<any>({});
    const [view, setView] = useState(0);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal1, setModal1] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });



    const idBean = 'IDApiPermissions';

    const head = [
        { title: 'ID', property: idBean },
        // { title: 'Bean', property: 'ClassForName' },
        { title: 'Método', property: 'Method' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Aplicar Filtro de Roles', property: 'ApplyRolesFilter' },
        { title: 'Roles asignados', property: 'RolePermissions' },
        { title: 'Aplicar Filtro de Estados', property: 'ApplyStatesFilter' },
        { title: 'Permisos por Estado', property: 'renderPermiso' },
    ]

    useEffect(() => {
        getLista();
    }, [idApiModule])
    useEffect(() => {
        getStateList();
    }, [StateGroups])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const renderObj = (obj: any) => {
        let str = '';

        if (obj != null) {
            let claves = Object.keys(obj);
            for (let index = 0; index < claves.length; index++) {
                const element = obj[claves[index]];
                str += `<strong>${claves[index]}</strong><br /><small>${element}</small><br/>`;
            }
        }

        return str;
    }
    const getLista = () => {

        setLoading(true);
        service.getApiPermissionsCatalog(idApiModule, null, null).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        element.DataBeanProperties.renderPermiso = renderObj(element.DataBeanProperties.StatePermissions);
                        if (element.DataBeanProperties.ApplyRolesFilter == false) {
                            element.DataBeanProperties.RolePermissions = "NA";
                        }
                        if (element.DataBeanProperties.ApplyStatesFilter == false) {
                            element.DataBeanProperties.renderPermiso = "NA";
                        }
                    });
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }

                setLoading(false);
            }
        );

        service.getRoleGroups(idApiModule).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    let par: any = [];
                    tmp.forEach((element: any) => {
                        let obj = { id: element.DataBeanProperties.Value, Name: element.DataBeanProperties.Property }
                        par.push(obj);
                    });
                    setListaRoleGroup(par);
                }
            }
        );

        service.getStateGroups(idApiModule).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    let par: any = [];
                    tmp.forEach((element: any) => {
                        let obj = { id: element, Name: element }
                        par.push(obj);
                    });
                    setListaStateGroup(par);

                    if (par.length > 0) {
                        setStateGroups(par[0].id);
                    }

                }
            }
        );
    }
    const getStateList = () => {
        setLoading(true);
        service.getStateList(idApiModule, StateGroups).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    let par: any = [];
                    tmp.forEach((element: any) => {
                        let obj = { id: element.DataBeanProperties.Value, Name: element.DataBeanProperties.Property }
                        par.push(obj);
                    });
                    setListaState(par);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 1,
            Rules: JSON.stringify([]),
            Since: single.getCurrenDate()
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        // setLoading(true);
        // service.deleteHiringModality(bean[idBean]).subscribe(
        //     (resp: any) => {
        //         setLoading(false);
        //         if (resp) {
        //             getLista();
        //         }
        //     }
        // );
        // setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        // setModal({ ...modal, ['view']: false });

        // setLoading(true);
        // service.updateHiringModality(bean).subscribe(
        //     (resp: any) => {
        //         setLoading(false);
        //         if (resp.DataBeanProperties.ObjectValue) {
        //             getLista();
        //         }
        //     });

    }
    const updateRol = () => {

        if (view === 1) {
            setLoading(true);
            service.setRolesForApiPermissions(bean.IDApiPermissions, roles, miswitch).subscribe(
                (resp: any) => {
                    console.log(resp);
                    setLoading(false);
                    getLista();
                    setModal1({ ...modal1, "view": false });
                }
            )
        }
        else if (view === 2) {
            setLoading(true);
            service.setApiStatesForApiPermissions(bean.IDApiPermissions, StateGroups, states, miswitch).subscribe(
                (resp: any) => {
                    console.log(resp);
                    setLoading(false);
                    getLista();
                    // setModal1({...modal1,"view":false});           
                }
            )
        }

    }

    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    {/* <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton> */}
                    {bean[idBean] &&
                        <>
                            <BasicButton value="Definir Roles" clase="warning" icon="ri-file-add-line" eventClick={() => {
                                setModal1({ ...modal1, "view": true, "name": 'Agregar Roles' });
                                setView(1);
                                // setMiswitch(false);
                            }} ></BasicButton>
                            <BasicButton value="Definir Estados" clase="warning" icon="ri-file-add-line" eventClick={() => {
                                setModal1({ ...modal1, "view": true, "name": 'Agregar Estados' });
                                setView(2);
                                // setMiswitch(false);
                            }} ></BasicButton>
                        </>
                    }

                </div>
                <div className="col-md-12 p-2">
                    <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>


            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal1} updateModal={setModal1} eventModal={updateRol}>
                {view === 1 &&

                    <div className="row">
                        <div className="col-md-12">
                            <MultiSelect lista={listaRoleGroup} getLista={(lista: any) => { setRoles(lista) }}></MultiSelect>
                        </div>
                        <div className="col-md-12">
                            <BasicSwitch label="Filtro de Roles Habilitado" estado={bean.ApplyRolesFilter} eventChange={(e: any) => {
                                setMiswitch(e);
                                setBean({ ...bean, "ApplyStatesFilter": e });
                            }} />
                        </div>
                    </div>
                }
                {view === 2 &&
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="StateGroups">StateGroups</label>
                            <select id="StateGroups" className="form-control" value={StateGroups} onChange={(e) => { setStateGroups(e.target.value) }}>
                                {listaStateGroup.map((ele: any) => {
                                    return (
                                        <option value={ele.Name}>{ele.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-md-12">
                            <MultiSelect lista={listaState} getLista={(lista: any) => { setStates(lista) }}></MultiSelect>
                        </div>
                        <div className="col-md-12">
                            <BasicSwitch label="Filtro de Roles Habilitado" estado={bean.ApplyStatesFilter} eventChange={(e: any) => {
                                setMiswitch(e);
                                setBean({ ...bean, "ApplyStatesFilter": e });
                            }} />
                        </div>
                    </div>
                }
            </Modal>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>
        </>
    )
}