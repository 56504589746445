import { useState } from "react";
import { SolicitarImp } from "./SolicitarImp";
import { GestionarImp } from "./GestionarImp";
import { DevueltosImp } from "./DevueltosImp";
import { HistoricoImp } from "./HistoricoImp";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicPage } from "../../theme/Component/BasicPage";

export const RequestProcedure = () => {
    const [vista, setVista] = useState(0);
    const [title, setTitle] = useState('');

    const cards: any = [
        { id: 1, Name: 'Solicitar', Description: 'Solicita Nuevos Trámites', Icon: 'ri-mail-add-line' },
        { id: 2, Name: 'Gestionar Activos', Description: 'Gestiona trámites Abiertos', Icon: 'ri-mail-open-line' },
        { id: 3, Name: 'Gestionar Devueltos', Description: 'Gestiona los rechazos en tus trámites', Icon: 'ri-mail-forbid-line' },
        { id: 4, Name: 'Ver Historico', Description: 'Mira el Historico de tus trámites', Icon: 'ri-mail-lock-line' }
    ]

    return (
        <BasicPage titulo="Mi Bandeja">
            {vista != 0 &&
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <BasicButton icon="ri-arrow-left-line" eventClick={() => { setVista(0) }}>Regresar</BasicButton>
                        </div>
                        <div className="col-md-8">
                            <h4 className="mt-2 text-lefht">{title}</h4>
                        </div>
                    </div>
                    <br />
                </>
            }
            {vista === 0 &&
                <div className="container">
                    <div className="row">
                        {cards.map((card: any) => {
                            return (
                                <div key={card.id} className="col-md-6 g-2">
                                    <div className="card card-body" style={{ border: 'solid 1px rgba(247, 111, 0, 0.08)' }}>
                                        <div className="avatar-sm mx-auto mb-3">
                                            <div className="avatar-title bg-soft-primary text-primary fs-17 rounded text-center">
                                                <i className={card.Icon}></i>
                                            </div>
                                        </div>
                                        <h4 className="card-title">{card.Name}</h4>
                                        <p className="card-text text-muted">{card.Description}</p>
                                        <span className="btn btn-primary" onClick={() => {
                                            setVista(card.id);
                                            setTitle(card.Description);
                                        }}>{'Entrar'}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {vista === 1 &&
                <SolicitarImp />
            }
            {vista === 2 &&
                <GestionarImp />
            }
            {vista === 3 &&
                <DevueltosImp />
            }
            {vista === 4 &&
                <HistoricoImp />
            }
        </BasicPage>
    )
}
