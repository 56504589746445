import { BasicPage } from "../theme/Component/BasicPage"
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { AdquisitionItemCatalog } from "./AdquisitionItemCatalog";
import { AdquisitionPeople } from "./AdquisitionPeople";
import { Alert } from "../theme/Component/Alert";
import { AdquisitionStates } from "./AdquisitionStates";
import { AdquisitionPlanFunctionalID } from "./AdquisitionPlanFunctionalID";
import { AdquisitionHistory } from "./AdquisitionHistory";
import { AdquisitionPhase } from "./AdquisitionPhase";
import { PlanDropDown } from "./PlanDropDown";
import { InputMoney } from "../theme/Input/InputMoney";
import { SelectBudgetType } from "../admin/configuracion/select/SelectBudgetType";
import { ListMenu } from "../theme/Component/ListMenu";
import { useForm } from 'react-hook-form';
import { Modalcompras } from "../theme/Component/Modalcompras";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { SelectAdquisitionPlan } from "./select/SelectAdquisitionPlan";
import { DataBeanProperties } from '../../core/services/model/server-response.interface';
import { toast } from "react-toastify";

export const AdquisitionPlanCatalog = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaType, setListaType] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [migrar, setMigrar] = useState<boolean>(false);
    const [idPlan, setIDPlan] = useState<number>(0);
    const [indexTap, setIndexTap] = useState(0);
    const [fromYear, setFromYear] = useState(0);
    const [uptoYear, setUptoYear] = useState(0);
    const [vista, setvista] = useState(0);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal1, setModal1] = useState<any>({ name: 'Configuración Plan', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDAdquisitionPlan';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Año', property: 'Year' },
        { title: 'Tipo de Plan', property: 'TypeName' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Tipo de Presupuesto', property: 'BudgetTypeName' },
        { title: 'Valor total', property: 'TotalValue' },
        { title: 'Fecha Creado', property: 'Since' },
        { title: 'Etapa', property: 'PhaseStateName' },
        // { title: 'Estado', property: 'Estados' },
    ]

    // Se realiza la impelmentacion de la historia de usuario HU04 componente 67

    useEffect(() => {
        // getLista();
        let date = new Date();
        setFromYear(single.getCurrenYear());
        setUptoYear(single.getCurrenYear()+1);
        // getApiPermissionsByRole();
        getAdquisitionPlanTypeConstants();
    }, [])
    useEffect(() => {

        if (indexTap === 0) {
            getLista();
        }

    }, [indexTap])

    //cambio para la capacitacion
    const setRowBean = (bean: any) => {
        setBean(bean);
        validarEdit();
    }

    const renderEstados = (item: any) => {

        let render = '';
        if (item.DataBeanProperties.PhaseState === 0) {
            render = item.DataBeanProperties.PlanningStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 1) {
            render = item.DataBeanProperties.StructuringStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 2) {
            render = item.DataBeanProperties.HiringStateName;
        }
        return render;
    }

    const getAdquisitionPlanTypeConstants = () => {
        service.getAdquisitionPlanTypeConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaType(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }


    const getLista = () => {

        setLoading(true);
        service.getAdquisitionPlanCatalog(fromYear, uptoYear, null, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    ll.forEach((element: any) => {
                        element.DataBeanProperties.Estados = renderEstados(element);
                    });
                    setLista(ll);
                }

                setLoading(false);
            }
        );
    }


    const agregarItem = () => {
        let date = new Date();
        let b = {
            Year: date.getFullYear(),
            Description: '',
            TotalValue: 0,
            IDBudgetType: 0
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteAdquisitionPlan(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        if(!migrar || bean.IDAdquisitionPlan > 0)
            {
                setLoading(true);
            service.updateAdquisitionPlan(bean).subscribe(
                (resp: any) => {
                    
                    if (resp.DataBeanProperties.ObjectValue) {
                        getLista();
                    }
                    if (bean[idBean]) {
    
                        single.update(bean);
    
                    }
                    else {
                        single.create(bean);
                    }
                    setLoading(false);
                });
               
            }
        else{
            setLoading(true);
            service.createPlanByOtherIDPlan(idPlan,single.getAccountID(),bean).subscribe(
                (resp: any) => {
                    
                    if (resp.DataBeanProperties.ErrorMessage) {
                        getLista();
                        toast.error(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        getLista();
                        toast.success("Se ha creado el plan exitosamente");
                    }
                    if (bean[idBean]) {
    
                        single.update(bean);
    
                    }
                    else {
                        single.create(bean);
                    }
                    setLoading(false);
                });
        }

       

    }
    const updateItemModal = () => {

        setModal1({ ...modal1, "view": false });
        getLista();

    }

    let validarEdit = () => {
        let a = false;
        if (single.canEdit() && bean.PhaseState == 2) {
            a = true;
        } else {
            a = false;
        }
        return a;
    }

    const buttons = () => {
        return (
            <>
                <div className="row">
                    <div className="col">

                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        {bean[idBean] &&
                            <PlanDropDown bean={bean} updateEvent={getLista} permisos={permisos}></PlanDropDown>
                        }
                    </div>
                </div>
            </>
        )
    }

    const iconButtons = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: (single.canEdit() && (bean.PhaseState == 0 || bean.PhaseState == 1)), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            {
                titulo: 'Ver Ítems', icono: 'ri-file-list-line', visible: true, evento: () => {
                    setModal1({ ...modal1, "view": true, "name": bean.Description, "btnSubmit": false });
                    setvista(6);
                }
            },
            {
                titulo: 'Ver Areas', icono: 'ri-git-merge-line', visible: true, evento: () => {
                    setModal1({ ...modal1, "view": true, "name": "Agregar Áreas/Dependencias", "btnSubmit": false });
                    setvista(3);
                }
            },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Plan de Compras">

                {indexTap === 0 &&
                    <>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Año inicial</label>
                                <input type="number" className="form-control" value={fromYear} onChange={(e) => { setFromYear(parseInt(e.target.value)) }} />
                            </div>
                            <div className="col-md-2">
                                <label>Año Final</label>
                                <input type="number" className="form-control" value={uptoYear} onChange={(e) => { setUptoYear(parseInt(e.target.value)) }} />
                            </div>
                            <div className="col-md-2 mt-4">
                                <BasicButton value="Buscar" icon="ri-search-line" eventClick={getLista} />
                            </div>
                            <div className="col-md-12 p-2">
                                <BasicTable headButtons={buttons()} iconButtons={iconButtons} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                            </div>
                        </div>
                    </>
                }

                {indexTap === 1 &&

                    <>
                        <AdquisitionItemCatalog _phaseState={bean.PhaseState} idAdquisitionPlan={bean[idBean]} />
                    </>
                }

            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            {/* Modal multiple para mostrar las configuraciones del plan  */}
            <Modal modal={modal1} updateModal={setModal1} eventModal={updateItemModal}>

                <>
                    {modal1.view &&
                        <>
                            {vista === 0 &&
                                <Alert clase="warning" key={'alert-generica'}>
                                    <strong>Sin elementos para mostrar</strong>
                                </Alert>
                            }
                            {vista === 1 &&
                                <AdquisitionPeople bean={bean} />
                            }
                            {/* si es boton modificar estados */}
                            {vista === 2 &&
                                <AdquisitionStates bean={bean} />
                            }
                            {vista === 3 &&
                                <AdquisitionPlanFunctionalID bean={bean} />
                            }
                            {vista === 4 &&
                                <AdquisitionHistory bean={bean} />
                            }
                            {vista === 5 &&
                                <AdquisitionPhase bean={bean} handleUpdate={() => { setModal1({ ...modal1, "view": false }); getLista() }} />
                            }
                            {vista === 6 &&
                                <>
                                    <AdquisitionItemCatalog _phaseState={bean.PhaseState} key={bean[idBean]} idAdquisitionPlan={bean[idBean]} />
                                </>
                            }
                        </>
                    }
                </>

            </Modal>
            <Modalcompras modal={modal} updateModal={setModal} onSubmit={handleSubmit(updateItem)}>
                <div className="row">

                    {modal.view &&
                        <>
                            <div className="col-md-6">
                                <label >Año Inicial</label>
                                <input type="number" readOnly={validarEdit()} className="form-control mt-2" value={bean.Year} onChange={(e) => { setBean({ ...bean, ['Year']: parseInt(e.target.value) }) }} />

                            </div>
                            <div className="col-md-6">
                                <label >Tipo Presupuesto</label>
                                <select disabled={validarEdit()} className="form-control mt-2" {...register('Selectprop', { required: true })} value={bean.Type} onChange={(e) => { setBean({ ...bean, "Type": e.target.value }) }}>
                                    <option value={0}>...</option>
                                    {listaType.map((item: any) => {
                                        return (
                                            <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                        )
                                    })}
                                </select>
                                {errors.Selectprop?.type === 'required' && <div className="alert alert-warning shadow" role="alert"><strong> Porfavor rellene el campo </strong></div>}
                            </div>
                            <div className="col-md-6">
                                <SelectBudgetType _disabled={validarEdit()} idSelector={bean.IDBudgetIDType} onChange={(e: any) => { setBean({ ...bean, ['IDBudgetIDType']: e }) }}></SelectBudgetType>
                            </div>

                            <div className="col-md-6">
                                <InputMoney _label="Valor Estimado" readonly={validarEdit()} id={0} name='Valor Estimado' value={bean.TotalValue} onChange={(e: any) => { setBean({ ...bean, ['TotalValue']: parseInt(e) }) }} />
                            </div>

                            <div className="col-md-12">
                                <label className="form-label">Descripción</label>
                                <textarea readOnly={validarEdit()} className="form-control mt-2" {...register('Description', { required: true })} value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                                {errors.Description?.type === 'required' && <div className="alert alert-warning shadow" role="alert"><strong> Por favor rellene el campo </strong></div>}
                            </div>
                            <div className="col-md-6">                                
                                <BasicSwitch label="Copiar de otro plan" estado={migrar} eventChange={(e)=>{setMigrar(e)}}/>
                            </div>
                            <div className="col-md-12">
                                {migrar &&
                                <div className="row">
                                   
                                    <div className="col-md-6">
                                    {/* <SelectAdquisitionPlan idSelector={year} onChange={(e)=>{console.log(e)}} /> */}
                                    <label>Plan de compras</label>
                                    <select name="plan" id="idplan" className="form-select" value={idPlan} onChange={(e)=>{setIDPlan(parseInt(e.target.value))}}>
                                        <option value={0}>...</option>
                                        {lista.map((item:any,index:number)=>{
                                            return(
                                                <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>{item.DataBeanProperties.Year} - {item.DataBeanProperties.Description}</option>
                                            )
                                        })}
                                    </select>
                                    </div>
                                </div>
                                }
                            </div>

                        </>

                    }


                </div>
            </Modalcompras>
        </>
    )
}