import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { PrintJson } from "../../theme/Component/PrintJson";
import { PrintObjectJson } from "../../theme/Component/PrintObjectJson";
import { PersonaNatural } from "../PersonaNatural";
import { PersonaJuridica } from "../PersonaJuridica";
import { Entrevista } from "../Entrevista";
import { NotFound } from "../../theme/NotFound";
import { DeclaracionVoluntaria } from "../DeclaracionVoluntaria";
import { AutorizacionConsulta } from "../AutorizacionConsulta";
import { AdminDatos } from "../AdminDatos";
import { Consultalistas } from "../Consultalistas";
import { VerificacionInfo } from "../VerificacionInfo";
import { DocsAnexos } from "../DocsAnexos";
import { ResumenConsentimiento } from "../ResumenConsentimiento";

interface Props {
    supplier: any;
}
export const ConsentimientoInfo = ({ supplier }: Props) => {
    const single = Singleton.getInstance();
    const service = new AdminService();

    const [IDSupplier, setIdSupplier] = useState<number>(0);
    const [tap, setTap] = useState(0);
    const [formulario, setFormulario] = useState<any>({});
    const [beanSupp, setBeanSpp] = useState<any>({});

    const taps = [
        { id: 1, name: "Persona natural" },
        { id: 2, name: "Persona jurídica" },
        { id: 3, name: "Entrevista - Uso exclusivo de Caja Honor solo para vinculación" },
        { id: 4, name: "Declaración voluntaria de origen y destino de fondos" },
        { id: 5, name: "Autorización consulta en las centrales de riesgos" },
        { id: 6, name: "Administración de datos - CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICIA" },
        { id: 7, name: "Consulta listas vinculantes y verificación información - USO EXCLUSIVO CAJA HONOR" },
        { id: 8, name: "Verificación de la información consignada en este formato" },
        { id: 9, name: "Documentos anexos - Documentos para vinculación y actualización" },
        { id: 10, name: "Resumen consentimiento" },
    ]

    useEffect(() => {
        console.log(supplier.Consent);
        setIdSupplier(supplier.IDSupplier);
        setForma(supplier);
    }, [supplier])

    // useEffect(()=>{
    //     setForma(beanSupp);
    // },[tap])

    const setForma = (spp: any) => {

        console.log(spp);
        if (spp.Consent === null || spp.Consent === undefined) {
            spp.Consent = {
                forma1: { state: false },
                forma2: { state: false },
                forma3: { state: false },
                forma4: { state: false },
                forma5: { state: false },
                forma6: { state: false },
                forma7: { state: false },
                forma8: { state: false },
                forma9: { state: false },
                forma10: { state: false },
            };

        }
        else {
            console.log(spp.Consent);
            try {
                spp.Consent = JSON.parse(spp.Consent);

            } catch (error) {
                console.log(error);

            }
            //   sp.Consent = JSON.parse(sp.Consent);
        }
        console.log(spp.Consent);

        setFormulario(spp.Consent);
        setBeanSpp(spp);

    }

    const updateSupplier = (bean: any) => {
        console.log(bean);
        let bt: any = {
            IDSupplier: beanSupp.IDSupplier,
            Consent: JSON.stringify(bean)
        };
        // bt.Consent = JSON.stringify(bean);
        single.spinner(true);
        service.updateSupplier(bt).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp.DataBeanProperties.ObjectValue);

                    // setBeanSpp(resp.DataBeanProperties.ObjectValue);
                    setForma(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        )

    }

    const render = (modulo: number) => {
        switch (modulo) {
            case 1:
                return <PersonaNatural supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 2:
                return <PersonaJuridica supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 3:
                return <Entrevista supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 4:
                return <DeclaracionVoluntaria supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 5:
                return <AutorizacionConsulta supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 6:
                return <AdminDatos supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 7:
                return <Consultalistas supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 8:
                return <VerificacionInfo supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 9:
                return <DocsAnexos supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />
            case 10:
                return <ResumenConsentimiento supplier={supplier} formSupplier={formulario} updateInfo={updateSupplier} />

            default:
                return <NotFound />
        }
    }


    return (
        <>
            {/* <h4> {supplier.DataBeanProperties.Account.EntityName}</h4> */}
            <div className="row">
                {/* <div className="col">
        <h6>CONOCIMIENTO DEL CLIENTE PERSONA JURIDICA</h6>
        <hr />
        </div> */}
            </div>
            <div className="row ">
                <div className="col-md-12">
                    <label className="titul-form">Información</label>
                    <select className="form-select" value={tap} onChange={(e) => { setTap(parseInt(e.target.value)) }}>
                        <option value={0}>...</option>
                        {taps.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-4 mb-4">
                <div className="col-md-12">
                    {
                        render(tap)
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* <ResumenConsentimiento formSupplier={} supplier={} updateInfo={}/> */}
                </div>
            </div>


        </>
    )
}