import { Modal } from '../../../theme/Component/Modal'
import { DirectContractLegalEntity } from '../../customForms/DirectContractLegalEntity'
import { OfferPurpose } from '../../customForms/OfferPurpose'
import { OffersComparative } from '../../customForms/OffersComparative'
import { OportunityContractLegalEntity } from '../../customForms/OportunityContractLegalEntity'
import { OportunityContractNaturalPerson } from '../../customForms/OportunityContractNaturalPerson'

interface Props {
    idData: number
    formCode: number,
    modal: any,
    setModal: (nModal: any) => void
}

export const FormVisualizer = ({ formCode, idData, modal, setModal }: Props) => {

    /* const  */

    const renderSwitch = () => {
        switch (formCode) {
            case 555: return (
                <OffersComparative data={{}} />
            )
            case 666: return (
                <OfferPurpose data={{}} />
            )
            case 777: return (
                <DirectContractLegalEntity data={{}} />
            )
            case 888: return (
                <OportunityContractLegalEntity />
            )
            case 999: return (
                <OportunityContractNaturalPerson />
            )
            default: return <h4 className='mt-3 mb-0'>No existe forma para este formulario</h4>
        }
    }

    return (
        <Modal modal={modal} updateModal={setModal} >
            {renderSwitch()}
        </Modal>
    )
}
