import { useState } from 'react';
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BpmService } from "../../../core/services/BpmService";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from '../../theme/Component/Modal';
import { ProcedureIMP } from './ProcedureIMP';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';


export const AuditAccount = () => {


    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [nit, setNit] = useState(0);
    const [name, setName] = useState('');
    const [tipoinput, setTipoinput] = useState(0);
    const [surname, setSurname] = useState('');
    const [bean, setBean] = useState<any>({});
    const [modalTramites, setModalTramites] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });

    const setRowBean = (bean: any) => {
        console.log(bean);
        setBean(bean);
    }

    const getListaBN = () => {
        setLoading(true);
        service.getAccountByNit(nit).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const getListaS = () => {
        setLoading(true);
        service.getAccount(null, surname).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const getListaN = () => {
        setLoading(true);
        service.getAccount(name, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const head = [
        { title: 'ID', property: 'IDAccount' },
        { title: 'Cédula/Nit', property: 'Nit' },
        { title: 'Nombre', property: 'EntityName' },
        { title: 'Correo', property: 'eMail' },
        { title: 'Teléfono', property: 'Tel' },
    ]

    const listaBotones = () => {
        const botones = [

            { titulo: 'Tramites', icono: 'ri-hard-drive-2-line', visible: true, evento: () => { setModalTramites({ ...modalTramites, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Auditoria de tramites">
                <div className="row">
                    <div className="col-md-4">
                        <label>Filtrar por:</label>
                        <select className="form-control" value={tipoinput} onChange={(e: any) => { setTipoinput(e.target.value) }}>
                            <option value={0}>...</option>
                            <option value={1}>Identificación</option>
                            <option value={2}>Nombres</option>
                            <option value={3}>Apellidos</option>

                        </select>
                    </div>
                    <div className="col-md-4">
                        {tipoinput == 3 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setSurname(e.target.value)} className="form-control inputSurname " placeholder="Filtrar pór Apellido" />
                            </>

                        }
                        {tipoinput == 2 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setName(e.target.value)} className="form-control inputName " placeholder="Filtrar por Nombre" />
                            </>

                        }
                        {tipoinput == 1 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setNit(e.target.value)} className="form-control inputNit " placeholder="Filtrar por Cedula / Nit" />
                            </>

                        }
                    </div>

                    <div className="col-md-4 mt-4">

                        {tipoinput == 3 &&
                            <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaS} ></BasicButton>
                        }

                        {tipoinput == 2 &&
                            <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaN} ></BasicButton>
                        }

                        {tipoinput == 1 &&
                            <BasicButton value="BUSCAR" icon="ri-file-add-line" eventClick={getListaBN} ></BasicButton>
                        }

                    </div>
                </div>
                <div className="row">


                </div>
            </BasicPage>

            <Modal modal={modalTramites} updateModal={setModalTramites}  >
                <>
                    <ProcedureIMP bean={bean}></ProcedureIMP>
                </>
            </Modal>
            <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} />
        </>
    )
}