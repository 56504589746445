import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { BasicPage } from "../theme/Component/BasicPage"
import { useState, useEffect } from 'react';
import { BasicTable } from "../theme/Component/BasicTable";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const DashboardPlan = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const contract = new ContractService();
    const [ano, setAno] = useState<number>(0);
    const [idPlan, setIDPlan] = useState<number>(0);

    const [variable, setVariable] = useState<number>(0);
    const [valor, setValor] = useState<string>('0');
    const [grafica, setGrafica] = useState<any>({});

    const [listaP, setLista] = useState<any>([]);
    const [listaM, setListaM] = useState<any>([]);
    const [bean, setBean] = useState<any>({});

    const properties = [
        { id: 1, name: 'Oficinas', property: 'ofi.Name', title: 'Name', type: 'count' },
        // { id: 2, name: 'Estados Preparación', property: 'item.PreparationState',title:'PreparationState',type:'count' },
        { id: 20, name: 'Consolidado por Oficinas', property: 'GRAFICA_PLAN_POR_AREA', type: 'value' },
        { id: 30, name: 'Consolidado Iniciativa/Proyecto', property: 'GRAFICA_PLAN_POR_PROYECTO', type: 'value' },
        { id: 44, name: 'Consolidad Rubro', property: 'GRAFICA_PLAN_POR_PRESUPUESTO', type: 'value' }
    ]


    useEffect(() => {
        setAno(single.getCurrenYear());
        setLista([]);
    }, [])
    useEffect(() => {
        console.log("Año actualizado");
        getPlanTableCatalogPorPropiedad();
    }, [idPlan])

    useEffect(() => {


        properties.forEach((ele: any) => {
            if (ele.id === variable) {
                setGrafica(ele);


            }
        });

    }, [variable])

    useEffect(() => {
        if (grafica.type == 'count') {
            getSeriesByAdquisitionPlan();
        } else {
            getSeriesByAdquisitionPlanResources();
        }
    }, [grafica])

    const getAdquisitionPlanByYear = () => {
        setLoading(true);
        contract.getAdquisitionPlanByYearSearch(ano).subscribe(
            (resp: any) => {
                console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        let item = resp.DataBeanProperties.ObjectValue[0];
                        setIDPlan(item.DataBeanProperties.IDAdquisitionPlan);
                    }
                }
                setLoading(false);
            }
        )

    }
    const getPlanTableCatalogPorPropiedad = () => {
        setLoading(true);
        contract.getPlanTableCatalogPorPropiedad("IDAdquisitionPlan", idPlan, null).subscribe(
            (resp: any) => {
                console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaM(resp.DataBeanProperties.ObjectValue);

                }
                setLoading(false);
            }
        )

    }
    const getSeriesByAdquisitionPlanResources = () => {
        setLoading(true);
        contract.getSeriesByAdquisitionPlanResources(idPlan, grafica.property).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp);
                    setListaM(resp.DataBeanProperties.ObjectValue);

                }
                setLoading(false);
            }
        )

    }
    const getSeriesByAdquisitionPlan = () => {
        setLoading(true);
        contract.getSeriesByAdquisitionPlan(idPlan, grafica.property).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp);
                    setListaM(resp.DataBeanProperties.ObjectValue);

                }
                setLoading(false);
            }
        )

    }


    const headCount = [

        { title: 'Nombre', property: 'Property', visible: true },
        { title: 'Cantidad Items', property: 'Value', visible: true }
    ];
    const headVariable = [

        { title: 'Variable', property: 'Property', visible: true },
        { title: 'Cantidad Items', property: 'Value', visible: true },
        { title: 'Total', property: 'SumTotal', visible: true }
    ];

    return (
        <BasicPage titulo="DashBoard">
            <div className="row">
                <div className="col-md-4">
                    <div className="input-group">
                        <input type="text" className="form-control" aria-label="Año del plan de compras" value={ano} onChange={(e) => { setAno(parseInt(e.target.value)) }} />
                        <button className="btn btn-primary shadow-none" type="button" id="button-addon2" onClick={getAdquisitionPlanByYear}>Buscar</button>
                    </div>
                </div>
                <div className="col-md">

                    <select className="form-select" value={idPlan} onChange={(e) => setIDPlan(parseInt(e.target.value))}>

                        {listaP.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>{item.DataBeanProperties.Description}</option>
                            )
                        })}
                    </select>

                </div>
            </div>
            <div className="row">

                {idPlan > 0 &&
                    <div className="col-md-6">
                        <label>Variable</label>

                        <select className="form-select" value={variable} onChange={(e) => { setVariable(parseInt(e.target.value)) }}>
                            <option value={''}>...</option>
                            {properties.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                }

            </div>
            <div className="row">
                <div className="col-md-12 mt-4">

                    {/* <PrintJson json={grafica}/>
                    <PrintJson json={listaM}/> */}
                    <BasicTable body={listaM} head={grafica.type == 'count' ? headCount : headVariable} rowSet={setBean}></BasicTable>
                </div>
            </div>
        </BasicPage>
    )
}