import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { SelectoCustomerType } from "./Componentes/SelectoCustomerType";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useCaracterization } from "./hooks/useCharacterization";

export const Characterization = () => {
  const {
    bean,
    errors,
    idBean,
    lista,
    modal,
    modalConfirm,
    register,
    selector,
    setBean,
    setModal,
    setModalConfirm,
    setSelector,
    single,
    editarItem,
    eliminarItem,
    agregarItem,
    iconButtonsItem,
    head,
    updateItem,
    setRowBean,
  } = useCaracterization();

  const renderButton = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <BasicButton
              value="Agregar"
              icon="ri-file-add-line"
              eventClick={agregarItem}
            ></BasicButton>
            {bean[idBean] && (
              <>
                <BasicButton
                  value="Editar"
                  icon="ri-edit-2-line"
                  eventClick={editarItem}
                ></BasicButton>
                <BasicButton
                  value="Eliminar"
                  icon="ri-eraser-line"
                  eventClick={() => {
                    setModalConfirm({ ...modalConfirm, ["view"]: true });
                  }}
                ></BasicButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Editar",
        icono: "ri-file-list-line",
        visible: single.canEdit(),
        evento: () => {
          setModal({ ...modal, view: true, name: "Editar" });
        },
      },
      {
        titulo: "Eliminar",
        icono: "ri-file-user-line",
        visible: single.canDelete(),
        evento: () => {
          setModalConfirm({ ...modalConfirm, view: true });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  return (
    <>
      <BasicPage titulo="Caraterizaciones">
        <div className="row">
          <div className="col-md-12">
            <BasicTable
              listButtons={listaBotones()}
              iconButtons={iconButtonsItem}
              head={head}
              body={lista}
              rowSet={setRowBean}
            />
          </div>
        </div>
      </BasicPage>

      <ModalConfirm
        modal={modalConfirm}
        updateModal={setModalConfirm}
        eventModal={eliminarItem}
      >
        {bean.Name}
      </ModalConfirm>

      <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Nombre</label>
            <input type="text" className="form-control" value={bean.Name}
              {...register("Name", { required: true })}
              onChange={(e) => {
                setBean({ ...bean, ["Name"]: e.target.value });
              }}
            />
            {errors.Name?.type === "required" && (
              <div className="alert alert-warning shadow" role="alert">
                <strong> Porfavor rellene el campo </strong>
              </div>
            )}
          </div>
          <div className="col-md-12">
            <SelectoCustomerType idSelector={bean.IDCustomerType} onChange={(e: any) => { setBean({ ...bean, "IDCustomerType": e }); }}></SelectoCustomerType>
          </div>
          <div className="col-md-12">
            <label className="form-label">Descripción</label>
            <textarea className="form-control" value={bean.Description}
              {...register("Description", { required: true })}
              onChange={(e) => {
                setBean({ ...bean, ["Description"]: e.target.value });
              }}
            ></textarea>
            {errors.Description?.type === "required" && (
              <div className="alert alert-warning shadow" role="alert">
                <strong> Porfavor rellene el campo </strong>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
