import { useEffect, useState } from "react";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { Singleton } from "../../../core/services/Singleton";
import { ListDocuments } from "../../contract/ListDocuments";
import { BasicButton } from "../../theme/Component/BasicButton";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";


interface Props {
  idRealesCredit: number;
  lista: any;
}

export const RealesCVDocs = ({ idRealesCredit, lista }: Props) => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new GarantiasService();
  const [modal, setModal] = useState<any>({ name: 'Permisos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
  const [bean, setBean] = useState<any>({});
  const [documento, setDocumento] = useState<any>();
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(single.getAccount());
    setBean(lista);
  }, [idRealesCredit, lista])

  const updateForm = (e: any) => {
    setModal({ ...modal, ['view']: false });
    setLoading(true);
    service.updateRealesCV(e).subscribe(
      (resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {

          let cv = resp.DataBeanProperties.ObjectValue;


          console.log(cv);

          setBean(cv);
        }
        single.update(bean);
      });
  }


  const updateData = (form: any) => {

    let b = {
      IDRealesCredit: idRealesCredit,
      IDEmployee: user.IDAccount,
      LastUpdate: single.getCurrenDate(),
      Attach: documento,
      BasicData: bean.DataBeanProperties?.BasicData,
    }

    updateForm(b);

  }

  return (
    <>

      <strong>Adjuntos</strong>
      <ListDocuments ListDocuments={documento} updateList={(e: any) => { setDocumento(JSON.stringify(e)) }} />
      <BasicButton eventClick={updateData} icon=""> Actualizar Archivos</BasicButton>
    </>
  )
}