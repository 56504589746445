import { format } from 'date-fns';
import { AuditService } from './AuditService';
import { ContractService } from './ContractService';
import { DataBeanProperties, ObjectValue } from './model/server-response.interface';
import { CookieManager } from './CookieManager';
import { BasicStore } from './BasicStore';
import { AdminService } from './AdminService';
export class Singleton {

    private static instance: Singleton;
    private cookie: CookieManager;
    private audit = new AuditService();
    private contracService = new ContractService();
    private adminService = new AdminService();

    private account: any = {};
    private menu: any = [];
    public spin = false;
    public eventos: any;
    private dollarUSLocale = Intl.NumberFormat('en-US');
    private permisos: any = [];
    public rolContratos: any = [];
    private linkPermisions: any = {};
    public currentURI: any = {};

    public mapBudgetID = [];
    private defaultCache = [];
    private lastView = '';
    public contractStore;


    private constructor() {

        this.eventos = {
            spin: false
        };
        this.cookie = new CookieManager("/");
        this.contractStore = new BasicStore('COMPRAS');
    }

    public setCookie(name: string, value: string, days: number) {
        this.cookie.setCookie(name, value, days);
    }
    public getCookie(name: string) {
        return this.cookie.getCookie(name);
    }
    public removeCookie(name: string) {
        this.cookie.setCookie(name, '', -1);
    }
    public purgeCookie() {
        this.cookie.deleteAllCookies();
    }



    public setCacheItem(key: string, value: any) {
        this.defaultCache[key] = value;
    }
    public getCacheItem(key: string) {
        return this.defaultCache[key];
    }


    //Auditoria 

    getRolesContratos() {
        if (this.rolContratos.length == 0) {
            this.contracService.getHiringConstants("HIRINGROLES").subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        let temp = resp.DataBeanProperties.ObjectValue;
                        temp.forEach((item: any) => {
                            this.rolContratos.push({
                                id: item.DataBeanProperties.Value,
                                name: item.DataBeanProperties.Property
                            })
                        });




                    }
                }
            )
        }
        return this.rolContratos;
    }

    public getAudit(): AuditService {
        return this.audit;
    }


    public view(obs: string, bean: any) {
        let b = {
            IDAction: this.audit.VIEW,
            JsonBefore: JSON.stringify(bean ? bean : '{}'),
            State: 1,
            Observations: obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        if (this.lastView != b.Observations) {
            this.lastView = b.Observations;
            this.audit.updateAuditFunction(b).subscribe(
                (resp: any) => {
                    // console.log("AUDIT ", resp);

                }

            )
        }

    }
    public update(bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.UPDATE,
            JsonBefore: JSON.stringify(bean ? bean : '{}'),
            State: 1,
            Observations: "El usuario ha modificado " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {                
        //     }
        // )
    }
    public download(obs: string) {


        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";

        let b = {
            IDAction: this.audit.DOWNLOAD,
            JsonBefore: '{}',
            State: 1,
            Observations: "descarga modulo " + uri + " " + obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }

        this.audit.updateAuditFunction(b).subscribe(
            (resp: any) => { }
        );

    }



    public makeAcction(obs: string, bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";

        let b = {
            IDAction: this.audit.ACTIVON,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "Acción " + uri + " " + obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => { }
        // );

    }
    public create(bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.CREATE,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "El usuario ha creado en " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {
        //          console.log("AUDIT ",resp);

        //     }

        // )
    }
    public delete(idBean: number, bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.DELETE,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "El usuario ha eliminado en " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDDataBean: idBean,
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {
        //          console.log("AUDIT ",resp);

        //     }

        // )
    }

    //Auditoria 

    public static getInstance(): Singleton {
        if (!Singleton.instance) {
            Singleton.instance = new Singleton();

        }

        return Singleton.instance;
    }


    public spinner(estado: boolean) {
        this.spin = estado;
        this.eventos.spin = estado;
        localStorage.setItem("eventos", JSON.stringify(this.eventos));
        window.dispatchEvent(new Event("storage"));
    }

    public startSesion(Account: any) {

        let date: Date = new Date();
        this.account = Account;
        this.account.session = this.getCurrenDate2();
        // console.log("Iniciando sesion ", date.getFullYear());
        this.setCookie("code", JSON.stringify(Account), 1);
        //localStorage.setItem('code', JSON.stringify(Account));

    }

    public setPermision(p: any) {
        this.permisos = p;
    }
    public canEdit() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.editar === true) {
                c = true;
            }
        }
        return c;
    }
    public canDelete() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.eliminar === true) {
                c = true;
            }
        }
        return c;
    }
    public canCreate() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.crear === true) {
                c = true;
            }
        }
        return c;
    }

    public canExport() {
        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.exportar === true) {
                c = true;
            }
        }
        return c;
    }

    public getLinkPermisions() {

        return this.linkPermisions;
    }
    public havePermision(link: string) {
        let permiso = false;
        this.permisos.forEach((m: any) => {


            if (m.URL == link) {
                permiso = true;
                this.linkPermisions = JSON.parse(m.IDLnPermissions ? m.IDLnPermissions : '{}');
                this.currentURI = m;

            }
        })


        if (link == '/admin/perfil')
            permiso = true;

        return permiso;
    }
    public getAccess() {
        return this.linkPermisions;
    }


    public getAccount() {
        let code = this.getCookie('code') || "{}";
        let acc = null;
        if(code)
            {
                acc = JSON.parse(code);
                this.account = acc;
            }

        return acc;
    }
    public getAccountID() {
        let id = null;
        this.account = this.getAccount();
        if (this.account != null) {
            id = this.account.IDAccount;
        }
        return id;
    }
    public closeSesion() {
        localStorage.clear();
        this.purgeCookie();
        return true;
    }
    public getRandomInt() {
        return Math.floor(Math.random() * 10000);
    }
    public setMenuUsuario(menuUsuario: any) {
        this.menu = menuUsuario;
    }

    public simplificarLista(lista = []) {
        let lista2: any = [];

        lista.map((element) => {

        })

        return lista2;
    }
    public parseDate(originalDate: any) {
        let ret = "";
        if (typeof (originalDate)) {

            const dateObject = new Date(originalDate);

            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const day = String(dateObject.getDate()).padStart(2, '0');
            const hours = String(dateObject.getHours()).padStart(2, '0');
            const minutes = String(dateObject.getMinutes()).padStart(2, '0');
            const seconds = String(dateObject.getSeconds()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            ret = formattedDate;
            console.log(formattedDate); // Salida: "2022-01-08 16:43:16"

        }
        return ret;
    }
    public formatearFecha(date: Date) {
        let fecha = "";
        import('date-fns/locale/es').then((locale) => {
            const fechaFormateada = format(date, 'dd MMMM yyyy', { locale: locale.default });
            fecha = fechaFormateada;
            console.log(fechaFormateada); // Esto imprimirá la fecha en español en la consola
        });
        return fecha;
    };
    public getCurrenDate() {
        return (new Date().toISOString().slice(0, 10)) + " 00:00:00";
    }
    public getCurrenDate2() {
        return (new Date().toISOString());
    }
    public getCurrenYear() {
        return parseInt((new Date().toISOString().slice(0, 4)));
    }
    public renderConstant(constant: any, id: number) {

        let value = '';
        value = constant.map((item: any) => {
            if (item.id === id)
                return item.name;
        });

        return value;

    }


    public renderValue(valor: any) {
        let value1 = valor;

        // Intenta convertir el valor a un número
        if (!isNaN(valor)) {
            return parseInt(valor);
        }

        // Intenta analizar el valor como una fecha
        const date = new Date(valor);
        if (!isNaN(date.getTime())) {
            return this.formatearFecha(date);
        }


        return value1;
    }

    public isNumber = (n: any) => (typeof (n) === 'number' || n instanceof Number);
    public formatField = (value: any) => {
        let val = value;

        if (this.isNumber(value)) {
            // val = this.dollarUSLocale.format(value);
            val = "<div class='text-end'>" + this.dollarUSLocale.format(value) + "</div>";
            // val = this.dollarUSLocale.format(value);
        }
        else if (typeof val === "boolean") {

            val = val ? "Si" : "No";

        }
        else {
            val = `<div>${value ? value : ''}</div>`
            // val = `<div class='text-capitalize'>${value ? value : ''}</div>`
        }

        return val;
    }
    public formatMoney = (value: any) => {
        let val: any = value;


        if (this.isNumber(value)) {

            if (val > 0) {
                val = this.dollarUSLocale.format(parseInt(value+""));
                // console.log(val,isNaN(val));

                if (!isNaN(val))
                    val = '';
            }

        }

        return val;
    }

    public basicName(nombre: string) {
        // Obtener la fecha y hora actual
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1; // Los meses comienzan en 0
        var año = fecha.getFullYear();
        var hora = fecha.getHours();
        var minutos = fecha.getMinutes();
        var segundos = fecha.getSeconds();

        // Crear un nombre aleatorio
        var nombreAleatorio = nombre + "_" + dia + mes + año + "_" + hora + minutos + segundos;


        return nombreAleatorio;
    }

    public handleFormChange(bean: any, e: any) {

        // como usar 
        // setRow(single.handleFormChange({...row},e));
        if (e.target.name) {

            if (e.target.type === 'number') {
                if (this.esDouble(e.target.value)) {
                    bean = { ...bean, [e.target.name]: parseFloat(e.target.value) };
                }
                else if (this.esNumerico(e.target.value)) {
                    bean = { ...bean, [e.target.name]: parseInt(e.target.value) };
                }
            }
            else {
                bean = { ...bean, [e.target.name]: e.target.value };
            }

        }

        return bean;
    }

    private esNumerico(valor) {
        return /^\d+$/.test(valor) || /^-\d+$/.test(valor) || /^(\d+\.\d+|\.\d+|\d+)$/.test(valor);
    }
    private esDouble(valor) {
        return /^-?\d*\.?\d+$/.test(valor);
    }

    public sendEmailMessage(idaccount:number,subject:string|null,message:string)
    {

        this.adminService.getAccountByID(idaccount).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                    {
                        let acc = resp.DataBeanProperties.ObjectValue;
                        if(acc.DataBeanProperties)
                            {
                                console.log(acc.DataBeanProperties);
                                let to = acc.DataBeanProperties.eMail;
                                if(to!==undefined || to!==null)
                                this.adminService.sendCustonEmailMessage([to],subject,message).subscribe(
                                    (resp:any)=>{
                                        console.log(resp);
                                        
                                    }
                                )
                            }
                    }
            }
        )
        

    }
    

}