import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmService } from "../../../core/services/BpmService";
import { Listatramitesoffice } from "./Componentes/Listatramitesoffice";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectSiteID } from "../../admin/configuracion/SiteID/SelectSiteID";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const Office = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalLista, setModalLista] = useState<any>({ name: 'Lista de tramites por sucursal', tabIndex: -1, view: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDOffice';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getOfficeCatalog(null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 0,
            Since: single.getCurrenDate(),
            Code: 0,
            IDSiteLn: 0,
            IDEmployee: user.IDAccount,
        }
        setBean(b);
        setModal({ ...modal, 'view': true, name: 'Agregar' });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteOffice(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });

    }
    const updateItem = () => {

        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.updateOffice(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }
    // const updateItem1 = () => {

    //     setModal({ ...tramites, ['view']: false });

    //     setLoading(true);
    //     service.updateOffice(bean).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 getListaT();
    //             }
    //         });

    // }
    const verlista = () => {

        setModalLista({ ...modalLista, 'view': true });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true, name: 'Edita' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Trámites disponibles', icono: 'ri-file-user-line', visible: true, evento: () => { setModalLista({ ...modalLista, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Sucursales">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <SelectSiteID edit={false} onChange={(e: any) => { setBean({ ...bean, "SiteIDLn": e.IDLn }) }} renderValue={bean.NameSite} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>
            <Modal modal={modalLista} updateModal={setModalLista} eventModal={verlista}>
                <Listatramitesoffice IDOffice={bean.IDOffice} />
            </Modal>
        </>
    )
}