import { useState, useEffect } from 'react';
import { AdminService } from '../../../core/services/AdminService';
interface Props{
    idSelector:number;
    onChange:any;
}
export const SelectProject =({idSelector,onChange}:Props)=>{

    const service = new AdminService();
    const[lista,setLista] = useState([]);
    const[id,setID] = useState(0);

    useEffect(()=>{

        setID(idSelector);
        getLista();

    },[])
    useEffect(()=>{

        onChange(id);

    },[id])

    const setValue=(e:any)=>{
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        
        service.getSoftwareProjectCatalog().subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }                
            }
        );
    }

    return(
        <>
        <label>Proyecto</label>
        <select className="form-control" value={id} onChange={(e)=>{setValue(e.target.value)}}> 
            <option value={0}>...</option>
            {lista && 
                lista.map((item:any)=>{
                    return(
                    <>
                    <option key={item.DataBeanProperties.IDSoftwareProject} value={item.DataBeanProperties.IDSoftwareProject} >{item.DataBeanProperties.Name}</option>
                    </>)
                })
            }
        </select>
        </>
    )
}