import { BasicPage } from '../../../theme/Component/BasicPage';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { useEffect, useState } from 'react';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { MyEditor } from '../../../theme/Component/MyEditor';
import { CompanyEditor } from '../Company/CompanyEditor';
import { OfficeEditor } from '../Office/OfficeEditor';
import { BankService } from './Bank-Service';
import { toast } from 'react-toastify';
import { AccountEditor } from '../../../editor/AccountEditor';


export const BankTransfer = () => {

    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);
    const [idProcess, setIDProcess] = useState(0);
    const [user, setUser] = useState<any>({});
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const service = new BankService();
    const idBean = 'IDCashDeskAccount';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'IDAccount' },
        { title: 'Valor de la Base', property: 'BaseValue' },
        { title: 'Caja Destino', property: 'DestinityCash' },
        { title: 'Cuenta Contable', property: 'AccountantID' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
        console.log(bean)
    }

    useEffect(() => {
        setBean({ ...bean, 'IDOffice': office })

        if (office != null && !isNaN(office)) {
            // console.log(office);
            getLista();
        }
    }, [office])

    const getLista = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    // const eliminarItem = () => {
    //     service.spinON();
    //     service.deleteOffice(bean[idBean]).subscribe(
    //         (resp: any) => {
    //             service.spinOFF();
    //             if (resp) {
    //                 getLista();
    //             }
    //             single.delete(bean[idBean], bean);
    //         }
    //     );
    //     setModalConfirm({ ...modal, "view": false });
    // }

    const updateItem = () => {
        service.spinON();
        service.updateCashDeskAccount(bean).subscribe((resp: any) => {
            service.spinOFF();
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });
        setModal({ ...modal, "view": false });
        // console.log(bean);
    };

    const agregarItem = () => {
        let b = {
            Name: "",
            AccountantID: 0,
            IDAccount: 0,
            BaseValue: 0,
            DestinityCash: 0,
            IDOffice: office,
            IDEmployee: service.idAccount(),
            DestinityBank: 0,

        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            // { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Name}` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <BasicPage titulo='Transferencia Bancaría'>
            <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e); console.log(e) }}></CompanyEditor>
            {conpany > 0 &&

                <OfficeEditor id={office} idconpany={conpany} onchange={(e: any) => { setOffice(e) }}></OfficeEditor>
            }




            <div className="row mt-3">
                <div className="col">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                </div>
            </div>

            {/* <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm> */}

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="col-md-12">
                    <label className="form-label">Nombre:</label>
                    <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
                </div>
                {/* <div className="col-md-12">
                    <label className="form-label">Descripción:</label>
                    <MyEditor val={bean.Description} onChange={(e: any) => { setBean({ ...bean, "Description": e }) }}></MyEditor>
                </div> */}
                <div className="col-md-12">
                    <label className="form-label">Cuenta Contable:</label>
                    <input type="number" className="form-control" value={bean.AccountantID} onChange={(e) => { setBean({ ...bean, "AccountantID": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Responsable de la Caja:</label>
                    <AccountEditor idAccount={bean.IDAccount} label="Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Valor de la Base:</label>
                    <input type="number" className="form-control" value={bean.BaseValue} onChange={(e) => { setBean({ ...bean, "BaseValue": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Caja Destino:</label>
                    <input type="number" className="form-control" value={bean.DestinityCash} onChange={(e) => { setBean({ ...bean, "DestinityCash": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Creado por:</label>
                    <AccountEditor idAccount={bean.IDEmployee} label="Funcionario" onChange={({ IDAccount }: any) => {
                        setBean({ ...bean, "IDEmployee": IDAccount });
                    }}></AccountEditor>
                </div>


                <div className="col-md-12">
                    <label className="form-label">Banco de destino:</label>
                    <input type="number" className="form-control" value={bean.DestinityBank} onChange={(e) => { setBean({ ...bean, "DestinityBank": e.target.value }); }} />
                </div>

            </Modal>
        </BasicPage>
    )
}