import { AdminService } from "../../core/services/AdminService";
import { Singleton } from "../../core/services/Singleton";
import { BasicPage } from "../theme/Component/BasicPage"
import { useEffect, useState } from 'react';
import { BasicTable } from "../theme/Component/BasicTable";
import { BasicButton } from '../theme/Component/BasicButton';
import { Modal } from '../theme/Component/Modal';
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const StepDevops = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service: AdminService = new AdminService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const head = [
        { title: 'ID', property: 'IDStepDevops' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Fecha', property: 'Since' }

    ]

    useEffect(() => {
        getStepDevopsCatalogPorPropiedad();
    }, [])
    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])

    const getStepDevopsCatalogPorPropiedad = () => {

        setLoading(true);
        service.getStepDevopsCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                setLista([]);
                console.log("Application", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 1,
            InitDate: single.getCurrenDate(),
            EndingDate: single.getCurrenDate()
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteStepDevops(bean.IDStepDevops).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getStepDevopsCatalogPorPropiedad();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateStepDevops(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getStepDevopsCatalogPorPropiedad();
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    return (
        <>
            <BasicPage titulo="Etapas DevOps">
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean.IDStepDevops &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }

                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm >

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}