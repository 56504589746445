import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { FileInput } from "../../theme/Component/FileInput";
import { DocumentTemplateFileTypeCatalog } from "./DocumentTemplateFileTypeCatalog";
import { DictionaryWords } from "./DictionaryWords";
import { DictionaryPropertyes } from "./DictionaryPropertyes";
import { DocumentTemplateParsed } from "./DocumentTemplateParsed";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";

interface Props {
    idDocumentTemplate: number
}
export const DocumentTemplateVersion = ({ idDocumentTemplate }: Props) => {

    const single = Singleton.getInstance();
    const service = new AdminService();

    const [lista, setLista] = useState<any>([]);
    const [listaTemplate, setListaTemplate] = useState<any>([]);

    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Versión', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalVersion, setModalVersion] = useState<any>({ name: 'Plantillas', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalDiccionario, setModalDiccionario] = useState<any>({ name: 'Diccionario', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalProsp, setModalProps] = useState<any>({ name: 'Propiedades', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalView, setModalView] = useState<any>({ name: 'Ejecutar Plantilla', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalDelete, setModalDelete] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = "IDDocumentTemplateVersion";

    useEffect(() => {
        getDocumentTemplateVersionCatalog();
    }, [idDocumentTemplate])

    const getDocumentTemplateVersionCatalog = () => {
        single.spinner(true);
        service.getDocumentTemplateVersionCatalog(idDocumentTemplate).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                } else {
                    setLista([]);
                }

                single.spinner(false);
            }
        )
    }
    const setActiveDocumentTemplateVersion = () => {
        single.spinner(true);
        service.setActiveDocumentTemplateVersion(bean.IDDocumentTemplateVersion).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getDocumentTemplateVersionCatalog();
                }

                single.spinner(false);
            }
        )
    }

    const createDocumentTemplateVersion = () => {
        single.spinner(true);
        service.createDocumentTemplateVersion(idDocumentTemplate, bean.VersionCode, bean.Media, bean.MediaContext, bean.replaceVersion ?? false, single.getAccountID()).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getDocumentTemplateVersionCatalog();
                    setModal({ ...modal, "view": false });
                }

                single.spinner(false);
            }
        )
    }
    const deleteDocumentTemplateVersion = () => {
        single.spinner(true);
        service.deleteDocumentTemplateVersion(bean.IDDocumentTemplateVersion).subscribe(
            (resp: any) => {

                
                    getDocumentTemplateVersionCatalog();
                    setModalDelete({ ...modalDelete, "view": false });

                single.spinner(false);
            }
        )
    }


    const head = [
        { title: 'ID', property: 'IDDocumentTemplateVersion', visible: true },
        { title: 'Código de Versión', property: 'VersionCode', visible: true },
        { title: 'Versión Activa', property: 'IsEnabled', visible: true },
        { title: 'Última Actualización', property: 'LastUpdate', visible: true },
        { title: 'Funcionario que actualiza', property: 'EmployeeName', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            // { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: true, evento: () => { setModalDelete({ ...modalDelete, "view": true }) } },
            { titulo: 'Setear versión activa', icono: 'ri-file-add-line', visible: true, evento: setActiveDocumentTemplateVersion },
            { titulo: 'Ver plantilla', icono: 'ri-attachment-fill', visible: true, evento: () => { setModalVersion({ ...modalVersion, "view": true }) } },
           
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { setBean({}); setModal({ ...modal, "view": true }) } },
      
    ]

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
            </div>
            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={createDocumentTemplateVersion}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Código de Versión</label>
                            <input type="text" className="form-control" value={bean.VersionCode ?? ''} onChange={(e) => { setBean({ ...bean, "VersionCode": e.target.value }) }} />
                        </div>
                        <div className="col-md-6 mt-4">
                            <BasicSwitch label="reemplaza la versión anterior si esta disponible" estado={bean.replaceVersion ?? false} eventChange={(e: boolean) => { setBean({ ...bean, "replaceVersion": e }) }}></BasicSwitch>
                        </div>
                        <div className="col-md-6">
                            <FileInput directory="templates" label="Formato" onCreate={(e: any) => {
                                setBean({ ...bean, "Media": e.Media, "MediaContext": e.MediaContext });
                            }}></FileInput>
                        </div>
                    </div>
                </Modal>
                <Modal modal={modalVersion} updateModal={setModalVersion}>

                    <DocumentTemplateFileTypeCatalog idDocumentTemplateVersion={bean.IDDocumentTemplateVersion} />


                </Modal>
                
                <ModalConfirm modal={modalDelete} eventModal={deleteDocumentTemplateVersion} updateModal={setModalDelete}>
                    <p>Eliminar la version de la plantilla</p>
                </ModalConfirm>
            </div>
        </div>
    )
}