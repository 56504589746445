import { useState } from 'react'
import check from '../../../../../assets/dashboard/success.svg'

interface Props {
    title: string
    message: string
    procedure: any
    exportMap: Function
}
export const GenericApproval = ({ title, message, exportMap }: Props) => {

    const [viewer, setViewer] = useState(0)

    const onExportMap = (value: boolean) => {
        exportMap({
            APROBO: value
        })
    }
    return (
        <>
            {viewer === 0 &&
                <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                    <div className='my-3'>
                        <strong>{title}</strong>
                    </div>
                    <div className='text-muted' style={{ width: '80%' }}>
                        <small>{message}</small>
                    </div>
                    <div className='w-50 d-flex justify-content-between my-3'>
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { setViewer(2); onExportMap(false) }}>
                            NO
                        </button>
                        <button type="button" className="btn btn-primary " onClick={() => { setViewer(1); onExportMap(true) }}>
                            SI
                        </button>
                    </div>
                </div>
            }
            {viewer === 1 &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>APROVADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
            {viewer === 2 &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>RECHAZADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
        </>
    )
}
