
interface Props {
    className?: string
    title: string
    description?: string
}

export const Divider = ({ className, title, description }: Props) => {
    return (
        <div className={`border-bottom my-2 d-flex flex-column w-100 ${className}`} >
            <h5 className="mb-0">{title}</h5>
            <small className="mb-2">{description}</small>
        </div>
    )
}
