
interface Props {
    icon?: string
    size?: 'sm' | 'md' | 'lg'
    fw?: number
    loading?: boolean
    event: Function
}

export const IconButton = ({ icon, size, event, fw, loading }: Props) => {
    return (
        <>
            <button className={`btn btn-outline-primary btn-${size ?? 'sm'}`} onClick={() => event()}>
                {!loading && <i className={`${icon ?? 'ri-plus-fill'} fs-${fw ?? 15}`}></i>}
                {loading && <span className="_main_loader mt-1"></span>}
            </button>
        </>
    )
}
