import { useEffect, useState } from "react";
import { FunctionalIDChilds } from "./FunctionalIDChilds";
import { ButtonFuntionalID } from './ButtonFuntionalID';
import { FinanceService } from '../../../../core/services/FinanceService';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface Tree {
  id: number | null;
  edit?: boolean;
}
export const FunctionalID = ({ id, edit }: Tree) => {
  const service = new FinanceService();

  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [idchild, setIDchild] = useState(0);

  useEffect(() => {
    getListatree(idchild);
    getFunctionalIDSeparator();
  }, []);

  const getListatree = (idArbol: any) => {
    setLoading(true);
    service.getTreeForFunctionalID(idArbol).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        setLista(resp.DataBeanProperties.ObjectValue.Childs);
      }
    });
  };

  const getFunctionalIDSeparator = () => {
    service.getFunctionalIDSeparator().subscribe(
      (resp: any) => {
        let separador = resp.DataBeanProperties.ObjectValue;
        localStorage.setItem('separador', separador);
      }
    );
  }



  return (
    <>
      {edit &&
        <ButtonFuntionalID
          id={0}
          bean={{}}
          updateEvent={() => { console.log("Actualizacion evento"); getListatree(id); }}
        />
      }
      <div className="row">
        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any) => {
                return (
                  <li className="list-group-item itemh" key={item.EnvolvedObject.DataBeanProperties.IDLn}>
                    {edit &&
                      <ButtonFuntionalID
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => { console.log("Actualizacion evento"); getListatree(id); }}
                      />
                    }
                    <strong className="cursor" onClick={() => {
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                      localStorage.setItem('FuntionalID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.FunctionalViewCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>
                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                    {idchild === item.EnvolvedObject.DataBeanProperties.IDLn &&
                      // renderSon(item.EnvolvedObject.DataBeanProperties.IDLn)
                      <FunctionalIDChilds edit={edit} idChild={idchild} />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

    </>
  );
};
