import { GenericService } from "../../../../core/services/GenericService";

export class CurrencyService extends GenericService {

  public getCurrencyCatalog(args: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getCurrencyCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  public updateCurrency(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.admin.Currency_updateCurrency_com.orange.bean.admin.Currency",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.admin.Currency'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  // public deleteOffice(IDOffice: number | null) {
  //   const parametros = {
  //     ServiceName: "OrangeBase",
  //     MethodHash: "",
  //     ArgumentList: [IDOffice],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return this.myApi.post(this.url, data);
  // }
}