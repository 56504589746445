
import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { AdminService } from '../../../core/services/AdminService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    label?: string;
    code: number;
    value: number | undefined;
    onChange: Function;
}

export const SelectConstantsValue = ({ label, code, value, onChange }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState<any>([]);
    const [id, setId] = useState<number | undefined>(0);


    useEffect(() => {
        if (id === 0 || id === undefined)
            setId(value);
    }, [value])

    useEffect(() => {
        getLista();
    }, [code])

    useEffect(() => {
        onChange(id);
    }, [id])


    const getLista = () => {
        setLoading(true);
        service.getSuppConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    setLista(lista['Code_' + code]);
                }
                setLoading(false);
            }
        )
    }


    return (
        <>
            <label>{label ? label : ''} </label>
            <select className="form-select" value={id} id={`codeid-${code}`} onChange={(e) => { setId(parseInt(e.target.value)) }} >
                <option value={0}>...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDSuppConstantsValue}>{item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}