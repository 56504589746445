import api from "../../../../../core/services/api/api";
import { env } from "../../../../../env";

export class ForpoService {

  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;

  public getCreditCampaignCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getCreditCampaignCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSuppConstantsValuesByCode(rows: number | null, lcodes: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.Map_getSuppConstantsValuesByCode_Number_java.util.List",
      ArgumentList: [rows, lcodes],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  //SlideItem

  public getMediaSlideItemCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "java.util.List_getMediaSlideItemCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateMediaSlideItem(bean: any) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "com.orange.bpm.theme.bean.MediaSlideItem_updateMediaSlideItem_com.orange.bpm.theme.bean.MediaSlideItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.theme.bean.MediaSlideItem'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //MediaSlide

  public getMediaSlideCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "java.util.List_getMediaSlideCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateMediaSlide(bean: any) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "com.orange.bpm.theme.bean.MediaSlide_updateMediaSlide_com.orange.bpm.theme.bean.MediaSlide",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.theme.bean.MediaSlide'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Portal tercero

  public getPortalTerceroCatalogRender(rows: number | null, Pages: number | null, props: any | null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getPortalTerceroCatalogRender_Number_Number_java.util.Map",
      ArgumentList: [rows, Pages, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  public updatePortalTercero(bean: any) {
    const parametros = {
      ServiceName: "Integration",
      MethodHash: "com.orange.bpm.integration.bean.PortalTercero_updatePortalTercero_com.orange.bpm.integration.bean.PortalTercero",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.integration.bean.PortalTercero'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // public deletePortalTercero(IDpropertie: number) {
  //   const parametros = {
  //     ServiceName: "ParametersBPM",
  //     MethodHash: "boolean_deleteSuppConstants_Number",
  //     ArgumentList: [IDpropertie],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }



  public getCreditCatalogPorPropiedades(properties: any, maxRows: number | null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getCreditCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [properties, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCreditRender(rows: number | null, Pages: number | null, props: any | null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getCreditRender_Number_Number_java.util.Map",
      ArgumentList: [rows, Pages, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateCredit(bean: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.Credit_updateCredit_com.orange.bpm.forpo.bean.Credit",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.forpo.bean.Credit'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateCreditCampaign(bean: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.CreditCampaign_updateCreditCampaign_com.orange.bpm.forpo.bean.CreditCampaign",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.forpo.bean.CreditCampaign'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAffiliateRender(page: number | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getAffiliateRender_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getRoleCommitteeCatalogPorPropiedad(nombrePropiedad: string, value: any, maxRows: number) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getRoleCommitteeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateRoleCommittee(bean: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.RoleCommittee_updateRoleCommittee_com.orange.bpm.forpo.bean.RoleCommittee",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.forpo.bean.RoleCommittee'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //



  public updateAffiliatebyEmployee(idEmploye: number, bean: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.Affiliate_updateAffiliatebyEmployee_Number_java.util.Map",
      ArgumentList: [idEmploye, bean],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSuppConstantsCatalogPorPropiedadForpo(name: string | null, value: any | null, number: null) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "java.util.List_getSuppConstantsCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSuppConstantsForpo(bean: any) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "com.orange.bpm.parameters.bean.SuppConstants_updateSuppConstants_com.orange.bpm.parameters.bean.SuppConstants",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bpm.parameters.bean.SuppConstants"
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public deleteSuppConstantsForpo(IDpropertie: number) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "boolean_deleteSuppConstants_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSuppConstantsValueCatalogPorPropiedadForpo(propertieName: string | null, value: number | null, maxrows: any) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "java.util.List_getSuppConstantsValueCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertieName, value, maxrows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSuppConstantsValueForpo(bean: any) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "com.orange.bpm.parameters.bean.SuppConstantsValue_updateSuppConstantsValue_com.orange.bpm.parameters.bean.SuppConstantsValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bpm.parameters.bean.SuppConstantsValue"
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteSuppConstantsValueForpo(idsuppconstantsvalue: number | null) {
    const parametros = {
      ServiceName: "ParametersBPM",
      MethodHash: "boolean_deleteSuppConstantsValue_Number",
      ArgumentList: [idsuppconstantsvalue],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getStoreBranchCatalogPorPropiedad(propertieName: string | null, value: number | null, maxrows: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getStoreBranchCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertieName, value, maxrows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getStoreBranchCatalogPorPropiedades(propertieName: any | null, maxrows: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getStoreBranchCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [propertieName, maxrows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateStoreBranch(bean: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "com.orange.bpm.Store.bean.StoreBranch_updateStoreBranch_com.orange.bpm.Store.bean.StoreBranch",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.Store.bean.StoreBranch'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getStoreCategoryCatalogPorPropiedad(propertieName: string | null, value: number | null, maxrows: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getStoreCategoryCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertieName, value, maxrows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateStoreCategory(bean: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "com.orange.bpm.Store.bean.StoreCategory_updateStoreCategory_com.orange.bpm.Store.bean.StoreCategory",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.Store.bean.StoreCategory'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getInventoryCatalogPorPropiedad(nombrePropiedad: any | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getInventoryCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateInventory(bean: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "com.orange.bpm.Store.bean.Inventory_updateInventory_com.orange.bpm.Store.bean.Inventory",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.Store.bean.Inventory'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getStoreItemCatalogPorPropiedades(properties: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getStoreItemCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [properties, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getItemStoreRender(page: number | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "java.util.List_getItemStoreRender_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateStoreItem(bean: any) {
    const parametros = {
      ServiceName: "StoreControl",
      MethodHash: "com.orange.bpm.Store.bean.StoreItem_updateStoreItem_com.orange.bpm.Store.bean.StoreItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.Store.bean.StoreItem'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPolicyCatalogPorPropiedad(name: string | null, value: any | null, number: null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getPolicyCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPolicyCatalogPorPropiedades(properties: any, maxRows: null) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getPolicyCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [properties, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updatePolicy(bean: any) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.Policy_updatePolicy_com.orange.bpm.forpo.bean.Policy",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.forpo.bean.Policy'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getInternalMenuCatalogPorPropiedad(propertie: string, value: any, maxRows: null | number) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "java.util.List_getInternalMenuCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertie, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateInternalMenu(bean: any) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "com.orange.bpm.theme.bean.InternalMenu_updateInternalMenu_com.orange.bpm.theme.bean.InternalMenu",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.theme.bean.InternalMenu'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getInternalMenuItemCatalogPorPropiedades(properties: any, maxRows: null | number) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "java.util.List_getInternalMenuItemCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [properties, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateInternalMenuItem(bean: any) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "com.orange.bpm.theme.bean.InternalMenuItem_updateInternalMenuItem_com.orange.bpm.theme.bean.InternalMenuItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.theme.bean.InternalMenuItem'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getIntroCatalogPorPropiedad(propertie: string, value: any, maxRows: null | number) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "java.util.List_getIntroCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertie, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateIntro(bean: any) {
    const parametros = {
      ServiceName: "ThemeApp",
      MethodHash: "com.orange.bpm.theme.bean.Intro_updateIntro_com.orange.bpm.theme.bean.Intro",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bpm.theme.bean.Intro'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Receipt
  public getPayrollReceiptCatalogPorPropiedad(propertie: string, value: any, maxRows: null | number) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "java.util.List_getPayrollReceiptCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [propertie, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



}