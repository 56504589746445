import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { PrintJson } from "../../theme/Component/PrintJson";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { InboxActions } from "./InboxActions";

export const InboxEmployeeSupplier =()=>{

    
    const single = Singleton.getInstance();
    const contract = new ContractService();
    const[inboxList,setInboxList] = useState<any>([]);
    const [row,setRow] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Gestión Oferta', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });

    useEffect(()=>{
        getInboxByEmployee();

    },[])

    const getInboxByEmployee =()=>{
        single.spinner(true);
        contract.getInboxByEmployee(single.getAccountID(),{IDEmployee:single.getAccountID()}).subscribe(
            (resp:any)=>{
                console.log(resp);
                if(resp.DataBeanProperties.ObjectValue)
                    {
                        setInboxList(resp.DataBeanProperties.ObjectValue);
                    }
                single.spinner(false);
            }
        )
    }

    const getActionListByOfferEmployee=()=>{
        setModal({...modal,"view":true,"name":row.SupplierName});
      
    }


     const head = [
        {title:'ID',property:'IDOfferSupplier',visible:true},
        {title:'Actualizado',property:'UpdateStage',visible:true},
        {title:'Proveedor',property:'SupplierName',visible:true},
        {title:'Justificación',property:'Justification',visible:true}        
    ]

     const listaBotones =()=>{
        const botones = [
            {titulo:'Gestionar',icono:' ri-bar-chart-horizontal-line',visible:true,evento:getActionListByOfferEmployee},
           

        ]
        return(
            <ListMenu listaBotones={botones}/>
        )
    }

    return(
        <BasicPage titulo="Gestión Contratos">
            {/* <PrintJson json={inboxList}/> */}
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={inboxList} head={head} rowSet={setRow} listButtons={listaBotones()}  ></BasicTable>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} >
                        <InboxActions idOfferSupplier={row.IDOfferSupplier}/>
                    </Modal>
                </div>
            </div>
        </BasicPage>
    )
}