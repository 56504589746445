import { useEffect, useState } from "react";
import { DropDown } from "../theme/Component/DropDown";
import { Modal } from "../theme/Component/Modal";
import { AdquisitionPeople } from "./AdquisitionPeople";
import { ContractService } from "../../core/services/ContractService";
import { single } from "rxjs";
import { Singleton } from "../../core/services/Singleton";

import { DataBeanProperties, ObjectValue } from '../../core/services/model/server-response.interface';
import { BasicTable } from "../theme/Component/BasicTable";
import { Alert } from "../theme/Component/Alert";
import { ChartArea } from "../theme/charts/ChartArea";
import { PrintJson } from "../theme/Component/PrintJson";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    bean: any;
    updateEvent: any;
    permisos?: any;
}
export const PlanDropDown = ({ bean, updateEvent, permisos }: Props) => {

    const service = new ContractService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [modal, setModal] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [listaHistorico, setHistorico] = useState<any>([]);
    const [listaPhase, setListaPhase] = useState<any>([]);
    const [listaGrafica, setListaGrafica] = useState<any>([]);
    const [listaRestricciones, setListaRestricciones] = useState<any>([]);
    const [phase, setPhase] = useState<any>(0);
    const [btn, setBTN] = useState<any>({});
    const [obs, setObs] = useState<string | undefined>('');
    const [message, setMessage] = useState<string | null>('');


    useEffect(() => {
        setObs('');
        setMessage('');
    }, [bean])
    useEffect(() => {
        if (message !== '') {
            updateEvent();
        }
    }, [message])

    const actionsDropDown = [
        { id: 1, name: 'Cambiar Etapa del Plan de Adquisiones', permiso: null },
        { id: 2, name: 'Colocar en Estado Revisado', permiso: null },
        { id: 3, name: 'Colocar en Estado Aprobado', permiso: null },
        { id: 4, name: 'Colocar en Estado Cancelado', permiso: null },
        // { id: 5, name: 'Colocar en Estado de Configuración',permiso:null },
        // { id: 6, name: 'Historial de aprobación',permiso:null },
        // { id: 7, name: 'Responsables del Plan',permiso:null },
        // { id: 8, name: 'Información de Estados de los Ítems del Plan',permiso:null},
        // { id: 9, name: 'Información de Estados de los Artículos del Plan',permiso:null},
        { id: 10, name: 'Validar cierre plan', permiso: null },
    ]


    // const actionsDropDown = [
    //     { id: 1, name: 'Actualizar Etapa del Plan de Adquisiones' ,permiso:'setPhaseToAdquisitionPlan' },
    //     { id: 2, name: 'Colocar en Estado Revisado',permiso:'setAdquisitionPlanOnReviewedState' },
    //     { id: 3, name: 'Colocar en Estado Aprobado',permiso:'setAdquisitionPlanOnApprovedState' },
    //     { id: 4, name: 'Colocar en Estado Cancelado',permiso:'setAdquisitionPlanOnCancelledState' },
    //     { id: 5, name: 'Colocar en Estado de Configuración',permiso:'updateAdquisitionPlanStructuringState' },
    //     { id: 6, name: 'Historial de aprobación',permiso:'getAdquisitionPlanApprovalNoteCatalog' },
    //     { id: 7, name: 'Responsables del Plan',permiso:null },
    //     { id: 8, name: 'Información de Estados de los Items del Plan',permiso:'getAdquisitionItemStructuringStateList'},
    //     { id: 9, name: 'Información de Estados de los Artículos del Plan',permiso:'getAdquisitionArticleStructuringStateListByAdquisitionPlan'},
    // ]

    const handleEvent = (item: any) => {

        setBTN(item);
        if (item.id === 10) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);

            // falta en el back unir las dos funciones para poder cerrar el plan 
            service.closeAdquisitionPlan(bean.IDAdquisitionPlan).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    } else {

                        let lista = resp.DataBeanProperties.ObjectValue;
                        if (lista.length > 0)
                            setListaRestricciones(resp.DataBeanProperties.ObjectValue);
                        else
                            setMessage("Items consolidados correctamente");
                    }

                    setLoading(false);
                }
            )
        }
        if (item.id === 1) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": true });
            setLoading(true);
            service.getAdquisitionPlanPhaseConstants().subscribe(
                (resp: any) => {
                    setListaPhase(resp.DataBeanProperties.ObjectValue);
                    setPhase(bean.PhaseState);
                    setLoading(false);
                }
            )
        }
        else if (item.id >= 2 && item.id <= 5) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": true });

        }
        else if (item.id === 6) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionPlanApprovalNoteCatalog(bean.IDAdquisitionPlan, null, null, null).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        let temp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                        temp.forEach((element: any) => {
                            element.DataBeanProperties.Approved =
                                element.DataBeanProperties.Approved ? `<i class=' mdi mdi-check-circle-outline'/>` : `<i class='mdi mdi-checkbox-blank-off-outline'/>`;
                        });
                        setHistorico(temp);
                        console.log(temp);

                    }
                    setLoading(false);
                }
            )
        }
        else if (item.id === 7) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
        }
        else if (item.id === 8) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionItemStructuringStateList(bean.IDAdquisitionPlan).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        setListaGrafica(resp.DataBeanProperties.ObjectValue);
                    }
                    single.makeAcction("El usuario consultó la información de estados de los ítems del plan " + (bean.IDAdquisitionPlan), bean);
                    setLoading(false);
                }
            )
        }
        else if (item.id === 9) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionArticleStructuringStateListByAdquisitionPlan(bean.IDAdquisitionPlan).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        setListaGrafica(resp.DataBeanProperties.ObjectValue);
                    }
                    single.makeAcction("El usuario consultó la información de estados de los Artículos del plan " + (bean.IDAdquisitionPlan), bean);
                    setLoading(false);
                }
            )
        }

    }





    const updateAction = () => {

        if (btn.id === 10) {



        }
        if (btn.id === 2) {
            setLoading(true);
            service.setAdquisitionPlanOnReviewedState(bean.IDAdquisitionPlan, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Plan Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 3) {
            setLoading(true);
            service.setAdquisitionPlanOnApprovedState(bean.IDAdquisitionPlan, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Plan Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 4) {
            setLoading(true);
            service.setAdquisitionPlanOnCancelledState(bean.IDAdquisitionPlan, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Plan Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 5) {
            setLoading(true);
            service.updateAdquisitionPlanStructuringState(bean.IDAdquisitionPlan).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Plan Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 1) {
            setLoading(true);
            service.setPhaseToAdquisitionPlan(bean.IDAdquisitionPlan, phase, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Plan Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }


    }

    const head = [
        { title: 'Fecha', property: 'EventDate' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Aprobado', property: 'Approved' },
        { title: 'Tipo Evento', property: 'TypeName' },
        { title: 'Descripción', property: 'Description' }
    ]
    const headRestricciones = [
        { title: 'ID', property: 'Item', visible: true },
        { title: 'Novedad', property: 'Message', visible: true },

    ]
    const listaBotones = () => {
        const botones: any = [
            // {titulo:'Agregar',icono:'ri-file-add-line',visible:true,evento:agregarItem},
            // {titulo:'Editar',icono:'ri-edit-2-line',visible:bean[idBean],evento:editarItem},
            // {titulo:'Ver Items',icono:'ri-file-list-line',visible:bean[idBean],evento:() => { }},
            // {titulo:'Ver Areas',icono:'ri-git-merge-line',visible:bean[idBean],evento:() => { }},

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const iconButtonsItem: any = [

    ]

    return (
        <>
            <DropDown lista={actionsDropDown} permisos={permisos} eventClick={(e: any) => {
                handleEvent(e);
            }}></DropDown>

            <Modal modal={modal} updateModal={setModal} eventModal={updateAction}>


                <div className="row">
                    <div className="col-md-12">
                        {btn.id === 10 &&
                            <>
                                {/* <PrintJson json={listaRestricciones}/> */}
                                <BasicTable head={headRestricciones} iconButtons={iconButtonsItem} listButtons={listaBotones()} body={listaRestricciones} rowSet={(e: any) => { console.log(e) }}></BasicTable>

                            </>
                        }
                        {btn.id === 1 &&
                            <div>
                                <label>Etapa</label>
                                <select className="form-control" value={phase} onChange={(e) => {
                                    setPhase(e.target.value);

                                }}>
                                    {
                                        listaPhase.map((item: any) => {
                                            return (
                                                <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {btn.id >= 2 && btn.id <= 4 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                                </div>
                            </div>

                        }
                        {btn.id === 5 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        Cambiar Plan a Modo Configuración ?
                                    </p>
                                </div>
                            </div>

                        }
                        {btn.id === 6 &&
                            <BasicTable head={head} body={listaHistorico} rowSet={() => { }}></BasicTable>
                        }
                        {btn.id === 8 &&
                            <ChartArea lista={listaGrafica} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                        }
                        {btn.id === 9 &&
                            <ChartArea lista={listaGrafica} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                        }
                        {btn.id === 7 &&
                            <div className="row">
                                <div className="col">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>NIVEL DE APROBACIÓN</th>
                                                <th>FUNCIONARIO</th>
                                                <th>FECHA DE EVENTO</th>
                                                {/* <th>Opción</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>ESTRUCTURADOR</td>
                                                <td>{bean.StructurerAccountName}</td>
                                                <td>{bean.StructuredDate}</td>

                                            </tr>
                                            <tr>
                                                <td>REVISA</td>
                                                <td>{bean.ReviewerAccountName}</td>
                                                <td>{bean.ReviewedDate}</td>

                                            </tr>
                                            <tr>
                                                <td>APRUEBA</td>
                                                <td>{bean.ApproverAccountName}</td>
                                                <td>{bean.ApprovedDate}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                    </div>

                    <div className="col-md-12">
                        {message !== '' &&
                            <Alert clase="warning">{message}</Alert>
                        }
                    </div>

                </div>

            </Modal>
        </>
    )
}