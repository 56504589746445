import { BasicPage } from '../../../theme/Component/BasicPage';

import { BasicTable } from '../../../theme/Component/BasicTable';
import { CurrencyService } from './Currency-service';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { MyEditor } from '../../../theme/Component/MyEditor';


export const Currency = () => {

    const single = Singleton.getInstance();
    const service = new CurrencyService();
    
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDCurrency';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Codigo', property: 'Code' },
        { title: 'Codigo de Ciudad', property: 'CountryCode' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
        console.log(bean)
    }

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        service.spinON();
        service.getCurrencyCatalog(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    // const eliminarItem = () => {
    //     service.spinON();
    //     service.deleteOffice(bean[idBean]).subscribe(
    //         (resp: any) => {
    //             service.spinOFF();
    //             if (resp) {
    //                 getLista();
    //             }
    //             single.delete(bean[idBean], bean);
    //         }
    //     );
    //     setModalConfirm({ ...modal, "view": false });
    // }

    const updateItem = () => {
        service.spinON();
        service.updateCurrency(bean).subscribe((resp: any) => {
            service.spinOFF();
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });
        setModal({ ...modal, "view": false });
    };

    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            Code: "",
            CountryCode: 0,
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            // { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Name}` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <BasicPage titulo='Catálogo de Monedas'>
            <div className="row mt-3">
                <div className="col">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                </div>
            </div>

            {/* <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm> */}

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="col-md-12">
                    <label className="form-label">Nombre:</label>
                    <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Descripción:</label>
                    <MyEditor val={bean.Description} onChange={(e: any) => { setBean({ ...bean, "Description": e }) }}></MyEditor>
                </div>
                <div className="col-md-12">
                    <label className="form-label">Codigo de Moneda:</label>
                    <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, "Code": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Código del País:</label>
                    <input type="number" className="form-control" value={bean.CountryCode} onChange={(e) => { setBean({ ...bean, "CountryCode": e.target.value }); }} />
                </div>
            </Modal>
        </BasicPage>
    )
}