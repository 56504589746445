interface Page {
    titulo: string;
    children: any;
}
export const BasicPage = ({ titulo, children }: Page) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h5>{titulo}</h5>
                    <hr />
                    <div className="">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}