import { Singleton } from "../../../core/services/Singleton"
import { BasicPage } from "../../theme/Component/BasicPage"
import { Bandeja } from "./Bandeja/Bandeja"



export const VisualBandeja = () => {

    const single = Singleton.getInstance()



    return (
        <>
            {/* <BasicPage titulo="Bandeja">



                <Messagesupplier IDAccount={single.getAccountID()} />

                {lista.length === 0 &&
                    <Alert clase="primary">
                        <strong>No tiene actividad registrada.</strong>
                    </Alert>
                }



            </BasicPage> */}
            <BasicPage titulo='Categorias de Daños'>
                <>

                    <Bandeja IDAccount={single.getAccountID()} />



                </>
            </BasicPage>
        </>
    )
}