import { env } from "../../env";
import api from "./api/api";


export class TalentService {

  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  public baseUrl: string = env.REACT_APP_ENDPOINT || "";

  public exportMenuAndRoles(args: string | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.Report_exportMenuAndRoles_String",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getEducationalSupportCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "PayRollERP",
      MethodHash: "java.util.List_getEducationalSupportCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getEducationalSupportCatalogRenderPorPropiedades(properties: any, pages: number, maxRows: number) {
    const parametros = {
      ServiceName: "PayRollERP",
      MethodHash: "java.util.List_getEducationalSupportCatalogRenderPorPropiedades_java.util.Map_Number_Number",
      ArgumentList: [properties, pages, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateEducationalSupport(bean: any) {
    const parametros = {
      ServiceName: "PayRollERP",
      MethodHash: "com.orange.people.payroll.bean.EducationalSupport_updateEducationalSupport_com.orange.people.payroll.bean.EducationalSupport",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.payroll.bean.EducationalSupport'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFortnightVacationsByEmployee(year: number, idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getFortnightVacationsByEmployee_Number_Number",
      ArgumentList: [year, idAccount],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getActiveEnjoyedVacationListByDocument(accountID: number, idDocument: number, documentDate: string, isDeleted: boolean) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getActiveEnjoyedVacationListByDocument_Number_Number_java.util.Date_Boolean",
      ArgumentList: [accountID, idDocument, documentDate, isDeleted],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getActiveEnjoyedVacationAnticipated(accountID: number, unassigned: boolean) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getActiveEnjoyedVacationAnticipated_Number_Boolean",
      ArgumentList: [accountID, unassigned],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addAnticipatedEnjoyedVacation(accountID: number, fromDate: string, uptoDate: string, employeeID: number, description: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.employees.EnjoyedVacation_addAnticipatedEnjoyedVacation_Number_java.util.Date_java.util.Date_Number_String",
      ArgumentList: [accountID, fromDate, uptoDate, employeeID, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addAnticipatedEnjoyedVacationToVacation(idEnjoyedVacation: number, idDocument: number, documentDate: string, employeeID: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.employees.EnjoyedVacation_addAnticipatedEnjoyedVacationToVacation_Number_Number_java.util.Date_Number",
      ArgumentList: [idEnjoyedVacation, idDocument, documentDate, employeeID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addEnjoyedVacation(accountID: number, idDocument: number, documentDate: string, fromDate: string, uptoDate: string, employeeID: number, description: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.employees.EnjoyedVacation_addEnjoyedVacation_Number_Number_java.util.Date_java.util.Date_java.util.Date_Number_String",
      ArgumentList: [accountID, idDocument, documentDate, fromDate, uptoDate, employeeID, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public releaseEnjoyedVacation(idEnjoyedVacation: number, idEmployee: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_releaseEnjoyedVacation_Number_Number",
      ArgumentList: [idEnjoyedVacation, idEmployee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateEnjoyedVacation(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.employees.EnjoyedVacation_updateEnjoyedVacation_com.quickdataerp.bean.employees.EnjoyedVacation",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.employees.EnjoyedVacation'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteEnjoyedVacation(idEnjoyedVacation: number, idEmployee: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteEnjoyedVacation_Number_Number",
      ArgumentList: [idEnjoyedVacation, idEmployee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

}
