import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idPadre: number;
    idSelector: number;
    onChange: any;
}
export const SelectRequirementType = ({ idPadre, idSelector, onChange }: Props) => {

    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [idPadre])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        // let cache = Singleton.getInstance().getCacheItem("RequirementTypeCatalog");
        // if (cache.DataBeanProperties.List) {
        //     setLista(cache.DataBeanProperties.List);
        // }
        // else {

        // }
        service.getRequirementTypeCatalog(idPadre, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                    Singleton.getInstance().setCacheItem("RequirementTypeCatalog", resp.DataBeanProperties.ObjectValue);

                }
            }
        );

    }

    return (
        <>
            <label>Requisito</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0} key={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (

                            <option key={item.DataBeanProperties.IDRequirementType} value={item.DataBeanProperties.IDRequirementType} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}