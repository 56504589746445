import { useState } from 'react';
import { useEffect } from 'react';
import { BasicSwitch } from '../theme/Component/BasicSwitch';
import { BasicButton } from '../theme/Component/BasicButton';

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const DocsAnexos = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});

    useEffect(() => {
        if (formSupplier.forma9 === undefined)
            formSupplier.forma9 = {};
        setFormulario(formSupplier.forma9);
    }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        temp.forma9 = formulario;
        updateInfo(temp);
    }

    return (
        <>
            <h5 className="titul-form" style={{ textAlign: 'center' }}>DOCUMENTOS ANEXOS - Documentos para vinculación y actualización</h5>
            <hr />
            <div className="row text-justify">
                <div className="col-md-12">
                    <ul>
                        <li>Fotocopia del documento de identificación Representante Legal o delegado: cedula de ciudadanía, carnet diplomático, pasaporteo cedula de extranjería.</li>
                        <li>Fotocopia libreta militar (varones).</li>
                        <li>  Original del Certificado de Existencia y Representación Legal con vigencia no superior a tres (3)</li>
                        <li> Declaración de renta del último periodo gravable disponible (si está obligado).</li>
                        <li>Estados Financieros últimos certificados o dictaminados de los dos (2) últimos periodos Fiscales.</li>
                        <li>Notas Explicativas a los Estados Financieros.</li>
                        <li>Registro Único Tributario (RUT).</li>
                        <li>Fotocopia legible de la Tarjeta Profesional del Contador y Revisor Fiscal.</li>
                        <li>Formato de conocimiento de cliente persona natural del Representante Legal.</li>
                        <li>Formato de conocimiento del cliente persona natural de cada uno de los socios con representación igual o mayor al 5% del capital social.</li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <strong>Nota 1: Caja Honor reservará la confidencialidad y seguridad de la información y documentos soporte suministrados en el presente formulario.</strong>
                </div>
                <div className="col-md-12">
                    <strong>Nota 2: Todo lo anterior, sin perjuicio de los aspectos y conceptos que estén incluidos en el manual de SARLAFT de la Entidad, de conformidad con las modificaciones que se introduzcan y en concordancia con lo ordenado por la circular Básica Jurídica de la Superintendencia Financiera de Colombia.</strong>
                </div>
            </div>
            <div className="col-md-6">
                <label className="titul-form">Autoriza:</label>
                <BasicSwitch label="NO / SI" estado={formulario.autorizacion} eventChange={(e: any) => {
                    setFormulario({ ...formulario, "autorizacion": e });
                }} />
            </div>
            <div className="col-md-12 mt-4">
                <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
            </div>
        </>
    )
}