import React, { useEffect, useState } from 'react'
import { RiskItem, RiskMatrixData } from '../../interfaces'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'

export interface RiskFactorData {
    apply: boolean,
    probability: number,
    impact: number,
    priority: number,
    attendant: string,
    assessment: string

}
interface Props {
    value: RiskItem,
    onChange: () => void
    riskCategoryIndex: number
    riskFactorIndex: number
    register: UseFormRegister<RiskMatrixData>
    setValue: UseFormSetValue<RiskMatrixData>
}

export const RiskFactor = ({ value, onChange, setValue, riskCategoryIndex, riskFactorIndex, register }: Props) => {

    const [riskData, setRiskData] = useState<RiskItem>({ ...value })
    const [apply, setApply] = useState(value.Apply)

    useEffect(() => {
        onChange()
    }, [riskData, value])

    const calValuation = (): string => {
        const priority = riskData.Impact * riskData.Probability;
        if (priority >= 0 && priority <= 6) {
            setValue(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Valuation`, 'Riesgo Bajo')
            return 'Riesgo Bajo'
        } else if (priority >= 7 && priority <= 12) {
            setValue(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Valuation`, 'Riesgo Moderado')
            return 'Riesgo Moderado'
        } else if (priority >= 13 && priority <= 19) {
            setValue(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Valuation`, 'Riesgo Alto')
            return 'Riesgo Alto'
        } else if (priority >= 20 && priority <= 25) {
            setValue(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Valuation`, 'Riesgo Extremo')
            return 'Riesgo Extremo'
        }
        /* Make here the calculation */
    }

    return (
        <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2 justify-content-center">
                <div className='d-flex flex-column gap-2 align-items-center'>
                    <small className='align-self-start'>¿Aplica?</small>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={apply}
                            {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Apply`)}
                            onChange={() => { setApply(!apply); onChange() }}
                        />
                    </div>
                </div>
                <div className='d-flex flex-column flex-1'>
                    <small>Probalidad</small>
                    <select className="form-select form-select-sm"
                        disabled={!apply}
                        value={riskData.Probability}
                        {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Probability`)}
                        onChange={(e) => setRiskData({ ...riskData, Probability: parseInt(e.target.value) })}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>
                </div>
                <div className='d-flex flex-column flex-1'>
                    <small>Impacto</small>
                    <select className="form-select form-select-sm"
                        disabled={!apply}
                        value={riskData.Impact}
                        {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Impact`)}
                        onChange={(e) => setRiskData({ ...riskData, Impact: parseInt(e.target.value) })}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>
                </div>
                <div className='d-flex flex-column flex-1'>
                    <small>Prioridad</small>
                    <input className='form-control form-control-sm' type="text" disabled
                        value={riskData.Impact * riskData.Probability}
                        {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Priority`)}
                    />
                </div>
            </div>
            <div className="d-flex flex-column">
                <div>
                    <label>Valoración del riesgo:</label>
                    <input className="form-control"
                        {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Valuation`)}
                        value={calValuation()}
                        onChange={(e) => setRiskData({ ...riskData, Valuation: e.target.value })}
                        disabled placeholder="Valoración del riesgo" id="valoracion" />
                </div>
                <div>
                    <label>Responsable:</label>
                    <input className='form-control' type="text" placeholder='Responsable' disabled={!apply}
                        value={riskData.Responsible}
                        {...register(`risks.${riskCategoryIndex}.Childs.${riskFactorIndex}.Responsible`)}
                        onChange={(e) => setRiskData({ ...riskData, Responsible: e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}
