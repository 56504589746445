import { useEffect, useState } from "react";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ASSIGN_USER_VIEW, MANAGE_VIEW, VERIFY_VIEW, useInboxStore } from "../hooks/useInboxStore";
import { ModalGeneric } from "../../../../interfaces";
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Modal } from "../../../theme/Component/Modal";
import { FileInput } from "../../../theme/Component/FileInput";
import { CardTramitesHistory } from "../../../pages/components/CardTramitesHistory";
import { PDFViewer } from "./PDFViewer";
import { IconButton } from "../../../../core/shared/buttons/IconButton";
import { ProcedureAction } from "./ProcedureAction";
import { ForpoService } from "../../../custom/forpo/components/service/ForpoService";
import { Singleton } from "../../../../core/services/Singleton";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BpmService } from "../../../../core/services/BpmService";
import { toast } from "react-toastify";

const head = [
    { title: 'ID', property: 'IDAccount' },
    { title: 'Nombre', property: 'AccountName' },
    { title: 'Área de trabajo', property: 'FunctionalIDName' },
    { title: 'Desde', property: 'Since' },
]

export const ReactiveSide = () => {

    const single = Singleton.getInstance();
    const service = new ForpoService();
    const _service = new BpmService();
    const [selectedUserToAssign, setSelectedUserToAssign] = useState(0)
    const { procedureImpSelected, modalDoc } = useInboxStore()
    const { reactiveView, userAssignList, requestDocs, docSelected, assignProcedureToWorkGroup, responseProcedureAction } = useInboxStore()
    const [temp, setTemp] = useState<any>({});
    const [credit, setCredit] = useState<any>({});
    const [actionMap, setActionMap] = useState({});
    const [modalConfirm, setModalConfirm] = useState<ModalGeneric>({ name: 'Asignación', tabIndex: -1, btnClose: true, btnSubmit: true, format: 'modal-sm', view: false })
    const [modalUpload, setModalUpload] = useState<ModalGeneric>({ name: 'Acción de proceso', tabIndex: -1, btnClose: true, btnSubmit: false, format: 'modal-md', view: false })
    const [mDoc, setMDoc] = useState<ModalGeneric>(modalDoc)

    useEffect(() => {
        getCredit();
        getProcedures();
    }, [])

    // servicio Forpo
    const getProcedures = () => {
        const properties = {
            IDProcedureImp: procedureImpSelected.IDProcedureImp
        }
        single.spinner(true)
        service.getCreditCatalogPorPropiedades(properties, null)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    setCredit(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties);
                    console.log(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties);
                    console.log("Se encontro el credito correspondiente");
                } else {
                    console.log("Error")
                }
                single.spinner(false);
            })
    }

    //Servicio QuickBPM
    const getCredit = () => {
        single.spinner(true)
        _service.getProcedureImpByAccount(single.getAccountID()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setCredit(resp.DataBeanProperties.ObjectValue);
                    single.spinner(false)
                } else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
                else {
                    toast.success("No tiene tareas pendientes")
                }
            }
        )
    }

    const renderSwicth = () => {
        switch (reactiveView) {
            case ASSIGN_USER_VIEW:
                return (
                    <div className="p-4 animate__animated animate__fadeInRight">
                        <h4>¿Quién debe realizar este proceso?</h4>
                        <BasicTable head={head} body={userAssignList} rowSet={(e: any) => {
                            setSelectedUserToAssign(e.IDAccount)
                            setModalConfirm({ ...modalConfirm, view: true })
                        }} />
                    </div>
                );
            case MANAGE_VIEW:
                return (
                    <div className="p-4 m-5 card animate__animated animate__fadeInRight"
                        style={{ minHeight: 400, maxHeight: 400 }}
                    >
                        {requestDocs.length > 0 &&
                            <h4 className="mb-5">
                                Por favor cargue los siguientes documentos / formularios
                            </h4>
                        }
                        <ul className="p-0 m-0 overflow-y-hidden mh-100">
                            {

                                requestDocs.map((_e: any, _i: number) => (
                                    <li key={_i} className="d-flex mb-4 pb-3 border-bottom">
                                        <div className="row" style={{ width: '100%' }}>
                                            <div className="col-12 d-flex">
                                                <div className="px-2 py-1 d-flex justify-content-center animate__animated animate__pulse" style={{ marginRight: 20, backgroundColor: 'rgb(0 53 104 / 50%)', borderRadius: 10, maxWidth: 65 }}>
                                                    <i className={`${'ri-file-upload-line'} fs-2`} style={{ color: '#003568' }}></i>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <div className="me-2">
                                                        <small className="text-muted d-block mb-1">{_e.DataBeanProperties.DocumentTypeName}</small>
                                                        <h6 className="mb-0">{_e.DataBeanProperties.ProcedureActionName}</h6>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        {_e.DataBeanProperties.IsOptional
                                                            ? <h6 className="mb-0 text-success">
                                                                OPCIONAL
                                                            </h6>
                                                            : <h6 className="mb-0 text-danger">
                                                                REQUERIDO
                                                            </h6>
                                                        }
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <IconButton
                                                            icon="ri-upload-fill fs-5"
                                                            event={() => {
                                                                setModalUpload({ ...modalUpload, view: true });
                                                                setTemp({ ...temp, IDAction: _e.DataBeanProperties.IDAction, FormCode: _e.DataBeanProperties.FormCode })
                                                                // console.log("El temporal modificado", temp)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {_e.DataBeanProperties.Observations &&
                                                <div className="col-12 mo-2 mt-3">
                                                    <div className="alert alert-info" role="alert">
                                                        <small>{_e.DataBeanProperties.Observations}</small>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                );
            case VERIFY_VIEW: return (
                <Modal modal={mDoc} updateModal={setMDoc}>
                    <PDFViewer doc={docSelected} />
                </Modal>

            );
            default: return (
                <div className="animate__animted animate__fadeIn">
                    <CardTramitesHistory ID={procedureImpSelected?.IDProcedureImp ?? 0} />
                </div>
            )
        }
    }

    return (
        <>
            {renderSwicth()}
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={() =>
                    assignProcedureToWorkGroup(selectedUserToAssign, modalConfirm, setModalConfirm)}>
                </ModalConfirm>
            }
            {modalUpload.view &&
                <Modal modal={modalUpload} updateModal={setModalUpload}>
                    {temp.FormCode !== null &&
                        <>
                            <ProcedureAction credit={credit} Procedure={temp} closeModal={() => { setModalUpload({ ...modalUpload, "view": false }) }} />
                        </>
                    }
                    {(temp.FormCode === null) &&
                        <>
                            <FileInput onCreate={({ Media, MediaContext }: any) => {
                                setTemp({ ...temp, Media, MediaContext });
                                setActionMap({ Media, MediaContext })
                            }} />
                        </>
                    }
                </Modal>
            }
        </>
    )
}
