import { useEffect, useState } from "react";

interface Props{
    json:any;
}
export const PrintJson=({json}:Props)=>{


    const [miJson,setJson] = useState<any>();

    useEffect(()=>{

            try {
                setJson(JSON.parse(json));    
            } catch (error) {
                setJson(json);
            }
            
        return ()=>{
            setJson(null);
        }

    },[json])

    return(
        
        <div><pre>{JSON.stringify(miJson, null, 2) }</pre></div>
        
    )

}