import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { Singleton } from '../../../../core/services/Singleton';
import { ContractService } from '../../../../core/services/ContractService';





interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
}

export const SelectorModulesst = ({ titulo, idSelector, onChange }: Props) => {


    const _contractService = new ContractService()
    const _single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();

    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector);
    const [modules, setModules] = useState([])

    useEffect(() => {
        setID(idSelector)
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        let cache = Singleton.getInstance().getCacheItem("ModuleSSTCatalogPorPropiedades");
        if (cache) {
            setModules(cache);
        }
        else {
            _contractService.getModuleSSTCatalogPorPropiedades()
                .subscribe((resp: any) => {
                    _single.spinner(false)
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setModules(resp.DataBeanProperties.ObjectValue)
                    }
                })
        }
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={idSelector ?? id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {modules &&
                    modules.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDModuleSST} value={item.DataBeanProperties.IDModuleSST} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}