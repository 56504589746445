

interface Props {
    formulario: any;
}

export const Forma4Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center celda">
                    <h5>DECLARACIÓN VOLUNTARIA DE ORIGEN Y DESTINO DE FONDOS</h5>
                </div>
                <div className="col-12 text-justify color-celda">
                    <strong>Declaro que los ingresos y los activos de la empresa que represento no provienen de ninguna de las actividades ilícitas contempladas en la Ley y que no admitiré que terceros efectúen pagos o abonos a cargo de la institución que represento, con fondos provenientes de actividades ilícitas contempladas en el Código Penal Colombiano o cualquier norma que modifique o adicione, ni se efectuarán transacciones destinadas a tales actividades o a favor de personas relacionadas con las mismas. Acepto que las relaciones jurídicas que vinculen a la empresa que represento con la CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA, en cuanto utilice sus productos y/o servicios, se rijan por los contratos y políticas internas de la Entidad. Los recursos propios y/o adicionales que manifiesto en el trámite solicitado provienen de:</strong>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form">Descripción: </label><br />
                    <strong>{formulario.declaracion}</strong>
                </div>
            </div>
            {/* <PrintJson  json={formulario}/> */}
        </>
    )
} 