import { MyEditor } from "../../../theme/Component/MyEditor";
import { CheckradioParam } from "../../../theme/Component/ChcekradioParam";
import { useEffect, useState } from "react";
import { BasicButton } from "../../../theme/Component/BasicButton";

interface Props {
    desRecruitment: any,
    setDesRecruitment
}
export const DescRecruiment = ({ desRecruitment, setDesRecruitment }: Props) => {

    const [recruimentStep, setRecruimentStep] = useState<number>(1);
    const recruimentSteps = [
        { id: 1, title: "Nombre" },
        { id: 2, title: "Formación" },
        { id: 3, title: "Experiencia" },
    ]

    const [_obj, setObj] = useState<any>(desRecruitment ?? {
        Name: '',
        Exp: '',
        Trainning: '',
    });

    useEffect(() => {
        setDesRecruitment(_obj);
    }, [_obj]);

    return (

        <>
            <div className="row mt-3">
                <div className="col-md-12">
                    <ul className="nav nav-tabs">
                        {recruimentSteps.map((list: any, index: number) => {
                            return (
                                <li key={index} className="list-group-item cursor"
                                    onClick={() => { setRecruimentStep(list.id) }}>
                                    <button className="nav-link" style={{
                                        backgroundColor: list.id === recruimentStep ? '#043072d9' : '',
                                        color: list.id === recruimentStep ? 'white' : 'darkslategray'
                                    }} id={list.id} data-bs-toggle="tab" type="button" role="tab">{list.title}</button>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>

            <div className="row mt-3">
                {recruimentStep == 1 &&
                    <div className="col-md-12">
                        <h4>Nombre de la persona a contratar</h4> <hr />
                        <MyEditor val={_obj.Name ?? ''} onChange={(e: any) => { setObj({ ..._obj, 'Name': e }) }}></MyEditor>
                    </div>

                }
                {recruimentStep == 2 &&
                    <div className="col-md-12">
                        <h4>Formación</h4> <hr />
                        <CheckradioParam code={16} label="" updateLista={(e: any) => { setObj({ ..._obj, 'Trainning': e.Name }) }} value={_obj.Trainning ?? ''} byID={false}></CheckradioParam>
                    </div>
                }
                {recruimentStep == 3 &&
                    <div className="col-md-12">
                        <h4>Experiencia</h4> <hr />
                        <MyEditor val={_obj.Exp ?? ''} onChange={(e: any) => { setObj({ ..._obj, 'Exp': e }) }}></MyEditor>
                    </div>
                }
            </div>
        </>
    )
}