import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { AdminService } from '../../../core/services/AdminService';
import { BasicPage } from '../../theme/Component/BasicPage';
import { InputDate } from '../../theme/Input/InputDate';
import { FileInput } from '../../theme/Component/FileInput';
import { BasicButton } from '../../theme/Component/BasicButton';
import { PrintJson } from '../../theme/Component/PrintJson';
import { Alert } from '../../theme/Component/Alert';
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';

export const ImportSupplier = () => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const adminService = new AdminService();
  const [excelData, setExcelData] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const [fileInp, setFileInp] = useState<any>({});
  const [imports, setImports] = useState<any>([]);

  const [props, setProps] = useState<any>({
    fecha: single.getCurrenDate(),
    tipo: 1
  });
  const [tipoArchivo] = useState<any>([
    { id: 1, name: 'Hipotecario' },
    { id: 2, name: 'Leasing' }

  ]);

  useEffect(() => {
    console.log("archivo cargado");
    importSupplier();
  }, [excelData]);

  const importSupplier = () => {
    if (excelData.length > 0) {
      setLoading(true);
      adminService.importSupplier(single.getAccountID(), JSON.stringify(excelData)).subscribe(
        (resp: any) => {
          console.log(resp);
          setLoading(false);
          setFile(undefined);
          setProps({ ...props, "message": 'Archivo Importado' });
          if(resp.DataBeanProperties.ObjectValue)
          {
            setImports(resp.DataBeanProperties.ObjectValue);
          }
        }
      );
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array', cellDates: true });

        // Puedes elegir una hoja específica si hay varias en el archivo Excel
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convierte la hoja de cálculo en un arreglo de objetos
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });



        setExcelData(sheetData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const cargarArchivo = () => {
    console.log(props);
    

  }

  const handleChange = (e: any) => {


    if (e.target.name) {
      setProps({ ...props, [e.target.name]: e.target.value });
    }

  }

  return (
    <BasicPage titulo='Cargue Masivo'>
      <div className="row">

       
        <div className="col-md-12">
          <label >Archivo</label>
          <input type="file" className="form-control" name='archivo' accept=".xlsx" value={file} onChange={handleFileChange} />
          {/* <FileInput label='Archivo' onCreate={(e: any) => { setFileInp(e) }} /> */}
        </div>

        <div className="col-md-6 mt-3">
          <BasicButton icon='' clase='primary' eventClick={cargarArchivo}>Cargar</BasicButton>
        </div>

      </div>
      <div className="row">
        <div className="col">
          {imports.length>0 &&
            <Alert clase='primary'><strong>Se han importado :</strong> {props.length}</Alert>
          }
          {/* <PrintJson json={excelData} /> */}
          {/* {excelData && JSON.stringify(excelData)} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {props.message &&
            <Alert clase='primary'> {props.message}</Alert>
          }
        </div>
      </div>
    </BasicPage>
  )
}