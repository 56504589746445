import { useEffect } from 'react'
import { FactorMatrixCategory } from '../../interfaces'
import { useFieldArray, useForm } from 'react-hook-form'
import { FactorCheck } from './FactorCheck'
import { Singleton } from '../../../../core/services/Singleton'
import { BpmService } from '../../../../core/services/BpmService'

interface Props {
    value: FactorMatrixData,
    onChange: (nValue: FactorMatrixData) => void
    updateMatrix: () => void
}

const _single = Singleton.getInstance()
const _service = new BpmService()

type FactorMatrixData = {
    factors: FactorMatrixCategory[]
}
export const FactorMatrix = ({ value, onChange, updateMatrix }: Props) => {

    const { control, register, handleSubmit, getValues, setValue } = useForm<FactorMatrixData>({ defaultValues: value })
    const { fields } = useFieldArray({
        control,
        name: `factors`
    })

    useEffect(() => {
        console.log(value);
        if (value.factors.length === 0) {
            getMatrixFactorsTemplete()
        }
    }, [])


    const getMatrixFactorsTemplete = () => {
        _single.spinner(true)
        _service.getFactorMatrizTemplate()
            .subscribe((resp: any) => {
                _single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    const x = JSON.parse(resp.DataBeanProperties.ObjectValue[0]);
                    setValue('factors', x)
                }
            })
    }

    const onSubmit = (data: FactorMatrixData) => {
        console.log(data);
        setValue('factors', data.factors)
        onChange(data)
    }


    return (
        <div className='w-100'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="accordion" id="accordionExample">
                    {fields.map((e: FactorMatrixCategory, _i) =>
                        <div key={e.IDSuppConstantsValue} className='mt-5 mx-5'>
                            <strong>{e.Name}</strong>
                            <hr />
                            {fields[_i]?.Child.map((e, i) =>
                                <FactorCheck key={e.IDFactorMatrix}
                                    value={e.isActive}
                                    handleChange={() => { }}
                                    register={register}
                                    index={_i}
                                    _subIndex={i}
                                    description={e.Description}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className='d-flex justify-content-start'>
                    <button className="btn btn-warning btn-md mt-5" type='submit'
                    /* onClick={() => onSaveInfo()} */
                    >
                        Guardar
                    </button>
                </div>
            </form>
            {/* <div>
                <BasicButton icon=" ri-logout-circle-r-line" clase="warning"

                    eventClick={() => { onSaveInfo() }}>
                    Guardar
                </BasicButton>
            </div> */}
        </div >
    )
}
