import { useEffect, useState } from 'react';
import { Singleton } from '../../../core/services/Singleton';

interface Props {
    children: any;
    modal: any;
    eventModal?: any;
    updateModal: any;
    onSubmit?: any;
    onSubmitLabel?:string;
}
// const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
export const Modal = ({ children, modal, eventModal, updateModal, onSubmit , onSubmitLabel }: Props) => {

    const [ver, setVer] = useState('none');
    const single = Singleton.getInstance()
    const [keyRender, setkeyRender] = useState(single.getRandomInt());

    // useEffect(()=>{

    //     return ()=>{
    //         console.log("Cerrando modal close");

    //     }
    // },[])

    useEffect(() => {

        setVer(modal.view ? 'block' : 'none');


        // if(view)
        // {
        //     //overflow: hidden; padding-right: 0px;
        //     document.body.classList.add('modal-open');
        //     document.body.style.overflow='hidden';
        //     document.body.style.paddingRight='0px';

        // }else{
        //     document.body.classList.remove('modal-open');
        //     document.body.style.removeProperty('overflow');
        //     document.body.style.removeProperty('padding-right');
        // }

        // return ()=>{
        //     console.log("Cerrando modal close");
        //     if(modal.view == false)
        //         setkeyRender(single.getRandomInt());

        // }

    }, [modal.view])


    return (
        <>
            <div className={`modal fade show ${modal.format} animate__animated animate__fadeIn`} tabIndex={modal.tabIndex} style={{ display: ver }}>

                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">{modal.name}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { updateModal({ ...modal, ['view']: false }) }}> </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {modal.view &&
                                    children
                                }

                            </div>
                        </div>
                        <div className="modal-footer">
                            {modal.btnClose &&
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => {
                                    updateModal({ ...modal, ['view']: false });

                                }}>Cerrar</button>
                            }
                            {(modal.btnSubmit && eventModal) &&
                                <button type="button" className="btn btn-primary " onClick={eventModal}>{onSubmitLabel ? onSubmitLabel : 'Guardar'}</button>
                            }
                        </div>
                    </div>
                </div>

            </div>

            {ver === 'block' &&
                <div className="modal-backdrop fade show"></div>
            }

        </>
    )
}