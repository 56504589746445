import { useState, useEffect } from 'react';
import { AdminService } from '../../../core/services/AdminService';
interface Props{
    idSoftware:number;
    idSelector:number;
    onChange:any;
}
export const SelectHistory =({idSoftware,idSelector,onChange}:Props)=>{

    const service = new AdminService();
    const[lista,setLista] = useState([]);
    const[id,setID] = useState(0);

    useEffect(()=>{

        setID(idSelector);
        getLista(idSoftware);

    },[idSoftware])
    useEffect(()=>{

        onChange(id);

    },[id])

    const setValue=(e:any)=>{
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = (id:number) => {

        
        service.getHistoryCatalogPorPropiedad("IDSoftwareProject", id, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }                
            }
        );
    }

    return(
        <>
        <label>Historia</label>
        <select className="form-control" value={id} onChange={(e)=>{setValue(e.target.value)}}> 
            <option value={0}>...</option>
            {lista && 
                lista.map((item:any)=>{
                    return(
                    <>
                    <option key={item.DataBeanProperties.IDHistory} value={item.DataBeanProperties.IDHistory} >{item.DataBeanProperties.Code}</option>
                    </>)
                })
            }
        </select>
        </>
    )
}