import { Singleton } from '../../../core/services/Singleton';
import { BasicButton } from '../../theme/Component/BasicButton';
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../core/services/FinanceService';
import { BudgetStructureItem } from './BudgetStructureItem';
import { OptionButton } from '../../theme/Component/OptionButton';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
export const BudgetStructure = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new FinanceService();
    const [step, setStep] = useState(1);
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDBudgetStructure';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Formato Genérico del Código', property: 'CodeFormat' },
        { title: 'Formato Rubro Presupuestal', property: 'ViewCodeFormat' },
        { title: 'Separador de Código', property: 'CodeSeparator' },
        { title: 'País', property: 'Country' }

    ]

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        getLista();
    }, [step])



    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])

    const getLista = () => {

        setLoading(true);
        service.getBudgetStructureCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);
                console.log("Lista", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            CodeFormat: '',
            Country: ''
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteBudgetStructure(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateBudgetStructure(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                                <OptionButton value="Ver Items" icon="ri-file-list-2-line" eventClick={() => { setStep(2) }} />

                            </>
                        }
                    </div>
                </div>
            </>
        )
    }


    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
            { titulo: 'Ver Items', icono: 'ri-file-list-2-line', visible: true, evento: () => { setStep(2) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Rubro Presupuestal">
                {step === 1 &&

                    <>
                        <div className="row">


                            <div className="col-md-12 p-2">
                                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                            </div>
                        </div>
                        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                            {bean.Name}
                        </ModalConfirm>

                        <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">Nombre</label>
                                    <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Formato de Código</label>
                                    <input type="text" className="form-control" value={bean.CodeFormat} onChange={(e) => { setBean({ ...bean, ['CodeFormat']: e.target.value }) }} />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">País</label>
                                    <input type="text" className="form-control" value={bean.Country} onChange={(e) => { setBean({ ...bean, ['Country']: e.target.value }) }} />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Descripción</label>
                                    <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                                </div>
                            </div>
                        </Modal>
                    </>

                }
                {step === 2 &&
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <h4><strong>Estructure : </strong><small>{bean.Name}</small></h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <BudgetStructureItem BudgetStructure={bean} regresar={() => { setStep(1); setBean({}) }} />
                            </div>
                        </div>
                    </>

                }
            </BasicPage>
        </>
    );
}