import { useEffect, useState } from "react"
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectorAplication } from "../../bpm/config/Componentes/SelectorAplication";
import { SelectorMenuTypeConstants } from "../../bpm/config/Componentes/SelectorMenuTypeConstants";
import { SelectorAplicationMenu } from "../../bpm/config/Componentes/SelectorAplicationMenu";
import { CardTaps } from "../../theme/Component/CardTaps";


export const MenuPerfil = ({ role }: any) => {

    const service: AdminService = new AdminService();
    const [listaID, setListaID] = useState([]);
    const [listaRemote, setListaRemote] = useState([]);
    const [idApplication, setIDApplication] = useState(0);
    const [idTipo, setIDTipo] = useState(0);
    const [idModule, setIDModule] = useState(0);
    const [idType, setIDType] = useState(0);
    const [modulo, setModulo] = useState<any>({});
    const [row, setRowItem] = useState<any>({});
    const [valueMenu, setValueMenu] = useState<number>(0);
    const [bpermiso, setBPermiso] = useState<any>({
        ver: false,
        crear: false,
        editar: false,
        eliminar: false
    });

    const taps = [
        { id: 0, name: 'Menu Aplicación' },
        { id: 1, name: 'Menu Acción' }
    ]
    const [indexTap, setIndexTap] = useState(0);
    const [modal, setModal] = useState<any>({ name: 'Permisos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        setIDApplication(0);
        setListaID([]);
    }, [role])

    useEffect(() => {
        setModulo(0);
    }, [idTipo])

    useEffect(() => {
        console.log(row);
    }, [row])



    useEffect(() => {



        if (indexTap === 0) {
            getApplicationIDAtLevel(idModule);
        } else {
            getRemoteAbstractAction(idModule);
        }


    }, [idModule, indexTap]);

    const addCheckRelation = (idRole: number, idLn: number, type: number) => {
        //console.log(idRole, idLn)
        setListaID([]);
        Singleton.getInstance().spinner(true);
        service.addBusinessLogicToRole(idRole, idLn).subscribe((resp: any) => {
            if (resp) {
                Singleton.getInstance().spinner(false);
                getApplicationIDAtLevel(type);

            }
        });
    };

    const addBusinessLogicToRoleJson = () => {
        Singleton.getInstance().spinner(true);
        // service.addBusinessLogicToRoleJson(role.IDRole, modulo.IDLn, JSON.stringify(bpermiso)).subscribe((resp: any) => {
        console.log(indexTap === 0 ? 5 : 8, role.IDRole, indexTap === 0 ? modulo.IDLn : modulo.IDRemoteAbstractAction)
        service.addBusinessLogicToRoleType(indexTap === 0 ? 5 : 8, role.IDRole, indexTap === 0 ? modulo.IDLn : modulo.IDRemoteAbstractAction, JSON.stringify(bpermiso)).subscribe((resp: any) => {
            if (resp) {
                Singleton.getInstance().spinner(false);
                // console.log(resp);
                getApplicationIDAtLevel(idModule);
                getRemoteAbstractAction(idModule);
            }
        });
    };

    const deleteCheckRelation = () => {
        console.log(indexTap === 0 ? 5 : 8, role.IDRole, indexTap === 0 ? modulo.IDLn : modulo.IDRemoteAbstractAction)
        Singleton.getInstance().spinner(true);
        setListaID([]);
        service.deleteBusinessLogicToRole(indexTap === 0 ? 5 : 8, role.IDRole, indexTap === 0 ? modulo.IDLn : modulo.IDRemoteAbstractAction).subscribe((resp: any) => {
            if (resp) {
                Singleton.getInstance().spinner(false);
                getApplicationIDAtLevel(idModule);
                getRemoteAbstractAction(idModule);
            }
        });
    };

    const getApplicationIDAtLevel = (id: number) => {

        Singleton.getInstance().spinner(true);
        service.getRolePermissionByMenu(id, role.IDRole, indexTap === 0 ? 5 : 8).subscribe((resp: any) => {
            if (resp.DataBeanProperties.ObjectValue) {
                resp = resp.DataBeanProperties.ObjectValue;

                resp.forEach((ele: any) => {

                    if (ele.DataBeanProperties.IDLnPermissions) {
                        ele.DataBeanProperties.Permisos = JSON.parse(ele.DataBeanProperties.IDLnPermissions);
                    } else {
                        ele.DataBeanProperties.Permisos = {
                            ver: false,
                            crear: false,
                            editar: false,
                            eliminar: false,
                            exportar: false,
                            nivel: 0
                        };
                    }
                });
                setListaID(resp);
            }
            Singleton.getInstance().spinner(false);
        })
    };
    const getRemoteAbstractAction = (id: number) => {

        Singleton.getInstance().spinner(true);
        service.getRolePermissionByMenu(id, role.IDRole, indexTap === 0 ? 5 : 8).subscribe((resp: any) => {
            // service.getRemoteAbstractActionByApplicationType(id, null).subscribe((resp: any) => {
            if (resp.DataBeanProperties.ObjectValue) {
                resp = resp.DataBeanProperties.ObjectValue;

                resp.forEach((ele: any) => {

                    if (ele.DataBeanProperties.IDLnPermissions) {
                        ele.DataBeanProperties.Permisos = JSON.parse(ele.DataBeanProperties.IDLnPermissions);
                    } else {
                        ele.DataBeanProperties.Permisos = {
                            ver: false,
                            crear: false,
                            editar: false,
                            eliminar: false,
                            exportar: false,
                            nivel: 0
                        };
                    }
                });
                setListaRemote(resp);
                console.log(resp);

            }
            Singleton.getInstance().spinner(false);
        })
    };



    const cambiarPermiso = () => {

        if (modulo.isActive) {
            addBusinessLogicToRoleJson();
            setModal({ ...modal, "view": false, "name": '' });
        } else {
            deleteCheckRelation();
            setModal({ ...modal, "view": false, "name": '' });
        }

    }

    const idBean = 'bean_tabla';

    const head = [
        { title: 'ID', property: 'IDLn', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'URL', property: 'URL', visible: true },
        { title: 'En el Rol', property: 'isActive', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },

        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
    ]
    const headRemote = [
        { title: 'ID', property: 'IDRemoteAbstractAction' },
        { title: 'Nombre', property: 'Name' },
        { title: 'ClassForName', property: 'ClassForName' },
        { title: 'En el Rol', property: 'isActive', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
        // { title: 'Descripción', property: 'Description' },
        // { title: 'URL', property: 'URL' }
    ]

    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Modificar', icono: 'ri-file-add-line', visible: true, evento: () => {
                    setModulo(row);
                    setBPermiso(row.Permisos);
                    setModal({ ...modal, "view": true, "name": row.Name });
                }
            },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <SelectorMenuTypeConstants idSelector={valueMenu} onChange={(e: any) => { setValueMenu(parseInt(e)) }}></SelectorMenuTypeConstants>
                </div>
                {/* <div className="col-md-6">
                    <SelectorBusinessLogicTypeConstants idSelector={idType} onChange={(e) => { setIDType(e) }}></SelectorBusinessLogicTypeConstants>
                </div> */}
                <div className="col-md-6">
                    <SelectorAplication idSelector={idApplication} onChange={(e: any) => { setIDApplication(parseInt(e)) }}></SelectorAplication>
                </div>
                <div className="col-md-6">
                    <SelectorAplicationMenu _MenuType={valueMenu} _idAplication={idApplication} idSelector={idModule} onChange={(e) => { setIDModule(parseInt(e)); }}></SelectorAplicationMenu>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12 mt-2">
                    <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap}>

                        {indexTap == 0 ?
                            <BasicTable listButtons={listaBotones()} head={head} body={listaID} rowSet={setRowItem}></BasicTable>
                            :
                            <BasicTable listButtons={listaBotones()} head={headRemote} body={listaRemote} rowSet={setRowItem}></BasicTable>
                        }

                    </CardTaps>


                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={cambiarPermiso}>
                {/* <PrintJson json={modulo}></PrintJson> */}
                <div className="row">
                    <div className="col-md-12">
                        <BasicSwitch estado={modulo.isActive} label="Activar Función" eventChange={(e: any) => {
                            setModulo({ ...modulo, "isActive": e });

                            if (!e) {
                                setBPermiso({ ...bpermiso, "ver": false });
                                setBPermiso({ ...bpermiso, "crear": false });
                                setBPermiso({ ...bpermiso, "editar": false });
                                setBPermiso({ ...bpermiso, "eliminar": false });
                            }


                        }}></BasicSwitch>
                    </div>
                    {modulo.isActive &&
                        <>

                            <div className="col-md-6">
                                <BasicSwitch estado={bpermiso.ver} label="Ver" eventChange={(e: any) => {
                                    setBPermiso({ ...bpermiso, "ver": e })
                                }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bpermiso.crear} label="Crear" eventChange={(e: any) => {
                                    setBPermiso({ ...bpermiso, "crear": e })
                                }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bpermiso.editar} label="Editar" eventChange={(e: any) => {
                                    setBPermiso({ ...bpermiso, "editar": e })
                                }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bpermiso.eliminar} label="Eliminar" eventChange={(e: any) => {
                                    setBPermiso({ ...bpermiso, "eliminar": e })
                                }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bpermiso.exportar} label="Exportar" eventChange={(e: any) => {
                                    setBPermiso({ ...bpermiso, "exportar": e })
                                }}></BasicSwitch>
                            </div>
                        </>
                    }
                </div>
            </Modal>



        </>
    )
}