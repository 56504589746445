import { useEffect, useState } from "react"
import { BasicTable } from "../../../../theme/Component/BasicTable"
import { PrintJson } from "../../../../theme/Component/PrintJson"
import { DataBeanProperties } from '../../../../../core/services/model/server-response.interface';
import '../comparative.css';
interface Props{
    offer:any
}
export const ComparativePrice=({offer}:Props)=>{

    const[lista,setLista] = useState<any>([]);

    useEffect(()=>{

        if(offer)
            {
                offer.forEach((element:any) => {
                    console.log(parseFloat(element.DataBeanProperties.EstimatedUnitaryValue) < parseFloat(element.DataBeanProperties.Price) );
                    
                    if(isMenor(parseFloat(element.DataBeanProperties.EstimatedUnitaryValue) , parseFloat(element.DataBeanProperties.Price)) )
                        {
                            // element.DataBeanProperties.Up = `SI`;
                            
                            element.DataBeanProperties.Up = `<p><i class='ri-funds-box-line align-middle  me-2 mgren'/></p>`;
                        }
                        else{
                            element.DataBeanProperties.Up = "<p><i class='ri-funds-box-line align-middle me-2 mred'/></p>";
                            
                        }
                });
                setLista(offer);
            }
            
        

    },[offer])

    const isMenor=(val1:number,val2:number)=>{

        if(val1>val2)
            return true;
        else
            return false;

    }

    const head = [
        {title:'Item',property:'Description',visible:true},               
        {title:'Vr Estimado',property:'EstimatedUnitaryValue',visible:true},        
        {title:'Vr Cotizado',property:'Price',visible:true},        
        {title:'Presupuesto',property:'Up',visible:true},
        {title:'Iva',property:'Iva',visible:true},        
        {title:'Cantidad',property:'Quantity',visible:true},        
        {title:'Total',property:'TotalPrice',visible:true},        
    ]

    return(
        <div className="row">
            <div className="col">
            
                <BasicTable head={head} body={lista} rowSet={(e:any)=> {}}></BasicTable>
                
            </div>
        </div>
    )
}