import { useEffect, useState } from "react";
import { BpmService } from "../../../core/services/BpmService";
import { useInboxStore } from "../../bpm/inbox/hooks/useInboxStore";
import { useDashboardStore } from "../hooks/useDashboardStore";

interface Props {
    idaccount: any,
    inbox: boolean
}

export const CardTramitesProfile = ({ idaccount, inbox }: Props) => {


    const { procedureImpSelected } = useInboxStore();
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [bean, setBean] = useState<any>({});

    useEffect(() => {
        obtenerCuenta();
    }, [idaccount]);

    const obtenerCuenta = () => {
        setLoading(true);
        service.getAccountbyID(idaccount).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp)
                    setBean(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                setLoading(false);
            }
        );
    }

    return (
        <>
            {!inbox &&
                <div className="card" style={{ backgroundColor: '#003568' }}>
                    <div className="d-flex row">
                        <div className="col-sm-9">
                            <div className="card-body">
                                <h3 className='text-white'>{procedureImpSelected?.AlphaCode ?? 'Validar informacion'}</h3>
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className='text-amber'>
                                            {bean.EntityName ? bean.EntityName.toUpperCase() : 'No se encuentra Nombre de la persona'}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-end align-items-end mt-3">
                                <div className="col-md-12 text-end">
                                    <small style={{ color: 'white' }}>{bean.Nit ?? 'No se encuentra Informacion de la persona'}</small>
                                </div>
                                <div className="col-md-12 text-end">
                                    <small style={{ color: 'white' }}>{bean.Tel ?? 'No se encuentra Telefono de la persona'}</small>
                                </div>
                                <div className="col-md-12 text-end">
                                    <small style={{ color: 'white' }}>{bean.eMail ?? 'No se encuentra correo de la persona'}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <div className='avatar_container'>
                                <div className="avatar-xl">
                                    <img
                                        className='img-thumbnail rounded-circle'
                                        src="assets/images/core/avatar.png"
                                        alt="profile_image" />
                                </div>
                                <span className='sposition mt-3'>
                                    <span className='sposition mt-3'>
                                        {bean.trabajo ?? 'Ingeniero'}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>}
            {inbox &&
                <div className="card" style={{ backgroundColor: '#003568' }}>
                    <div className="card-body text-white p-3">
                        <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                                <label>Codigo:</label>
                                <strong className="text-warning">{procedureImpSelected?.AlphaCode ?? ''}</strong>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Solicitante:</label>
                                <strong className="text-warning">{bean.EntityName ? bean.EntityName.toUpperCase() : 'No se encuentra Nombre de la persona'}</strong>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Identificación:</label>
                                <strong className="text-warning">{bean.Nit ?? 'No info'}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}


{/* <br />
                                <small>Cc.{bean.Nit ?? 'No info'}</small>
                                <small>{bean.Tel ?? 'No info'}</small>
                                <small>{bean.eMail ?? 'No info'}</small> */}
{/* <div className="col-4">
                                <div className="avatar-md mx-auto">
                                    <img
                                        className='img-thumbnail rounded-circle'
                                        src="assets/images/core/avatar.png"
                                        alt="profile_image" />
                                </div>
                            </div> */}