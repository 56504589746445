import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";

import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { TalentService } from "../../../core/services/TalentService";

interface Props {
    rol: number;
}

export const useEnjoyedVacation = ({ rol }: Props) => {
    const single = Singleton.getInstance();
    const employeeID = single.getAccountID();
    const [user, setUser] = useState<number>(employeeID);
    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ tabIndex: 0, view: false, btnClose: true, btnSubmit: false, format: "modal-lg", });
    const { setLoading } = useDashboardStore();
    const service = new TalentService();

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    useEffect(() => {
        if (rol == 2) {
            getLista();
        }
    }, [year])



    const getLista = () => {
        setLoading(true);
        service.getFortnightVacationsByEmployee(year, user)
            .subscribe((resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            });
    };

    const head = [
        { title: "ID", property: "IDDocument", visible: false },
        { title: "Consecutivo", property: "Consecutive", visible: (rol == 1) },
        { title: "Año", property: "Year", visible: (rol == 1) },
        { title: "Mes", property: "Month", visible: (rol == 1) },
        { title: "Tipo de Documento", property: "Mnemonic", visible: (rol == 1) },
        { title: "Tipo de Novedad", property: "NoveltyTypeName" },
        { title: "Nombre", property: "Name" },
        { title: "Número de días", property: "Units" },
        { title: "Fecha Inicio", property: "NoveltyInitDate", },
        { title: "Fecha finalización", property: "NoveltyUptoDate", },
        // { title: "Datos del cliente", property: "Foreach" },
    ];

    return {
        lista,
        bean,
        setBean,
        modal,
        setModal,
        head,
        setRowBean,
        year,
        setYear,
        user,
        setUser,
        single,
        getLista,
    };
};
