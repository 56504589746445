declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_ENDPOINT: string,
    REACT_APP_ENDPOINT_FILE: string,
    REACT_APP_SERVICE: string,
    REACT_APP_FILE: string,
    REACT_APP_DEPLOY: string,
    REACT_APP_W_ENDPOINT: string,
    REACT_HASH:boolean,
    LDAP_DOMAIN:string,
    LOGIN_TYPE:number,
}
export const env: EnvType = { ...process.env, ...window.env }
