import { useState, useEffect } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { SelectConstantsValue } from "../../../theme/Component/SelectConstantsValue";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

export const FactorMatrix = () => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new BpmService();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [code, setCode] = useState(0);
  const [modal, setModal] = useState<any>({
    name: "Editar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });

  const idBean = "IDFactorMatrix";

  useEffect(() => {
    getLista();
  }, []);


  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  const getLista = () => {
    setLoading(true);
    service
      .getFactorMatrixCatalogPorPropiedad("State", 1, null)
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }

        setLoading(false);
      });
  };

  const agregarItem = () => {
    let b = {
      Description: "",
      IDTypeFactor: 0,
      TypeCamp: 0,
      State: 1,
    };
    setBean(b);
    setModal({ ...modal, "view": true, name: "Agregar" });
  };

  const updateItem = () => {
    setModal({ ...modal, "view": false });
    setLoading(true);
    service.updateFactorMatrix(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      single.update(bean);
    });
  };

  const head = [
    { title: "ID", property: idBean },
    { title: "Factor", property: "IDTypeFactor" },
    { title: "Tipo de campo", property: "TypeCamp" },
    { title: "Descripción", property: "Description" },
    { title: "fecha", property: "Since" },
  ];

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Editar",
        icono: "ri-file-list-line",
        visible: single.canEdit(),
        evento: () => {
          setModal({ ...modal, view: true, name: "Editar" });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: single.canCreate(),
      icono: "ri-file-add-line",
      evento: agregarItem,
    },
  ];

  return (
    <>
      <BasicPage titulo="Factores de evaluación.">
        <div className="row">
          <div className="col-md-12">
            <BasicTable
              listButtons={listaBotones()}
              iconButtons={iconButtonsItem}
              head={head}
              body={lista}
              rowSet={setRowBean}
            />
          </div>
        </div>
      </BasicPage>

      <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Tipo Factor</label>
            <SelectConstantsValue
              key={code}
              code={12}
              value={bean.IDTypeFactor}
              onChange={(e: any) => {
                setBean({ ...bean, IDTypeFactor: e });
              }}
            />
          </div>
          <div className="col-md-12">
            <label className="form-label">Descripción del Factor</label>
            <textarea
              className="form-control mt-2"
              value={bean.Description}
              onChange={(e) => {
                setBean({ ...bean, Description: e.target.value });
              }}
            ></textarea>
          </div>
          <div className="col-md-12">
            <label className="form-label">Tipo de campo</label>
            <select className="form-control" value={bean.TypeCamp} onChange={(e) => { setBean({ ...bean, 'TypeCamp': e.target.value }) }}>
              <option value={0}>...</option>
              <option value={10}>Numerico</option>
              <option value={11}> Cumple / No cumple</option>
              <option value={12}>Campo Abierto</option>


            </select>
          </div>
        </div>
      </Modal>
    </>
  );
};
