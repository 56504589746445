import { useEffect, useState } from "react";

import { ButtonFuntionalID } from "./ButtonFuntionalID";
import { FinanceService } from '../../../../core/services/FinanceService';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface Tree {
    idChild: number;
    edit?: boolean;
}
export const FunctionalIDChilds = ({ idChild, edit }: Tree) => {

    const service = new FinanceService();
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);

    const [idchild, setIDchild] = useState(0);

    useEffect(() => {
        if (idchild != null && idchild != undefined)
            getListatree(idChild);
        else
            setLista([]);
        // editBean();
    }, [idchild])

    const getListatree = (idArbol: any) => {
        setLoading(true);
        service.loadFunctionalIDTree(idArbol).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                setLista(resp.DataBeanProperties.ObjectValue.Childs);
            }
        });
    };


    return (
        <>

            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn} >
                                {edit &&
                                    <ButtonFuntionalID
                                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { console.log("Actualizacion evento"); getListatree(idChild); }}
                                    />
                                }

                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'}
                                    onClick={() => {
                                        localStorage.setItem('FuntionalID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                                        // if(item.EnvolvedObject.DataBeanProperties.ChildrenCount>0)
                                        setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);

                                    }}
                                >
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.FunctionalViewCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {idchild === item.EnvolvedObject.DataBeanProperties.IDLn &&

                                    <FunctionalIDChilds edit={edit} idChild={idchild} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}