import { AdminService } from "../../../core/services/AdminService";
import { useState, useEffect } from 'react';
import { Modal } from "../../theme/Component/Modal";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicButton } from "../../theme/Component/BasicButton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";


export const Application = (props: any) => {

    const service: AdminService = props.service;
    const [lista, setLista] = useState([]);

    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const { setLoading } = useDashboardStore();

    const [modal1, setModal1] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


    const [beanType, setBeanType] = useState<any>({});

    const idBean = 'IDApplication';

    useEffect(() => {

        getApplicationCatalog();
    }, []);



    const getApplicationCatalog = () => {

        setLoading(true);
        service.getApplicationCatalog().subscribe(
            (resp: any) => {
                console.log("Application", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const updateApplication = () => {
        console.log("update ", beanType);

        setModal1({ ...modal1, ['view']: false });

        setLoading(true);
        service.updateApplication(beanType).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getApplicationCatalog();
                }
            }
        );

    }

    const editarItem = () => {
        setModal1({ ...modal1, ['view']: true });
    }

    const setRowBean = (bean: any) => {
        setBeanType(bean);
    }


    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },


    ]

    const setNuevoMenu = () => {
        let bean = {
            IDLn_1: 0,
            Name: '',
            URL: '',
            Code: 1,
            LnLevel: 0,
            State: 0
        }
        setBeanType(bean);
        setModal1({ ...modal1, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteApplication(beanType).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getApplicationCatalog();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={setNuevoMenu} ></BasicButton>
                        {beanType[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal1({ ...modal1, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', icono: 'ri-file-add-line', evento: setNuevoMenu }

    ]


    return (
        <>
            {/* <div className="row">
            <div className="col">
                <h4>Tipos de Aplicación</h4>
            </div>
            <div className="col">
            
            </div>
        </div> */}


            <BasicPage titulo="">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {beanType.Name}
            </ModalConfirm>

            <Modal modal={modal1} updateModal={setModal1} eventModal={updateApplication}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={beanType.Name} onChange={(e) => { setBeanType({ ...beanType, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={beanType.Description} onChange={(e) => { setBeanType({ ...beanType, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

        </>

    );
}