import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

export const useCredit = () => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({
        Name: "",
        Descripcion: "",
        State: 1,
        InitDate: single.getCurrenDate(),
        EndDate: '',
        BudgetValue: 0,
        IDEmployee: single.getAccountID(),
        QuotaRange: 1,
        MinValue: 0.0,
        MaxValue: 0.0,
        ScaleValue: 0.0,
        RateValue: 0.0,
        Since: "",
    });


    useEffect(() => {
        getLista();
    }, []);


    const idBean = "IDCredit";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        service.getCreditRender(null, null, {}).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((e: any) => {
                        e.DataBeanProperties.AccountName =
                            (e.DataBeanProperties.Name1 ?? '') + " " +
                            (e.DataBeanProperties.Name2 ?? '') + " " +
                            (e.DataBeanProperties.Surname1 ?? '') + " " +
                            (e.DataBeanProperties.Surname2 ?? '')

                        setLista(list);
                    })
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Solicitud", property: "AlphaCode" },
        { title: "Estado del credito", property: "EstadoCredito" },
        { title: "Afiliado", property: "AccountName" },
        { title: "Prestamo Total", property: "Amount" },
        { title: "Cuota", property: "FeeValue", visible: false },
        { title: "Tasa de Interes", property: "RateValue", visible: false },
        { title: "Numero de Cuotas", property: "QuotaCredit" },
        { title: "Total de intereses", property: "TotalRate" },
        { title: "Token", property: "TokenCredit" },
        { title: "Fecha de registro de Token", property: "TokenDate", visible: false },
        { title: "Funcionario que registra Token", property: "RegisterCredit" },

    ];

    return {
        single,
        service,
        lista,
        setLista,
        bean,
        setBean,
        idBean,
        head,
        setRowBean,
    };
};
