import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../../theme/Component/Modal";
import { Tablaaccionesformularios } from "../../Tablaaccionesformularios";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { useSuppConstantsValueForpo } from './useSuppConstantsValueForpo';

interface Props {
    idSuppConstants: number;
}

export const SuppConstantsValueForpo = ({ idSuppConstants }: Props) => {

    const { veracciones, single, bean, eliminarItem, head, iconButtonsItem, lista, modal, modalConfirm, setBean, setModal, setModalConfirm, setRowBean, updateItem, setModaljson, modaljson, modalacciones, setModalacciones } = useSuppConstantsValueForpo({ idSuppConstants });

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Codigo</label>
                        <input type="text" className="form-control" value={bean.CodeID} onChange={(e) => { setBean({ ...bean, ['CodeID']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>


                </div>
            </Modal>
        </>
    )
}