import { useEffect, useState } from 'react'
import check from '../../../../../assets/dashboard/success.svg'
import { Singleton } from '../../../../../core/services/Singleton';
import { NavTabs } from '../../../../../core/shared/buttons/NavTabs';
import { SelectConstantsValueForpo } from '../../../../custom/forpo/components/SelectConstantsValue.Forpo';
import { PrintJson } from '../../../../theme/Component/PrintJson';
import { FileService } from '../../../../../core/services/FileService';
import { toast } from 'react-toastify';
import { ForpoService } from '../../../../custom/forpo/components/service/ForpoService';


interface Props {
    title: string;
    message: string;
    _credit: any;
    exportMap: Function;
    updateCredit: Function;
    _user: any;
}

export const RequestToken = ({ title, exportMap, _credit, updateCredit, _user }: Props) => {

    const single = Singleton.getInstance();
    const fileService = new FileService();
    const service = new ForpoService();
    const [viewer, setViewer] = useState("1")
    const [token, setToken] = useState<string>("");
    const [value, setValue] = useState<number>(undefined);
    const [info, setInfo] = useState<any>({});
    const [doc, setDoc] = useState<any>({});
    const [receipt, setReceipt] = useState<any>({});

    useEffect(() => {
        console.log(_user)
        if (_user.JSONBasicData != null) {
            setInfo(JSON.parse(_user.JSONBasicData))
            console.log("datos del usuario correctos")
        } else {
            console.error("hubo un error al cargar la cuenta");
        }
    }, [_user])

    useEffect(() => {
        // console.log(_credit)
        getLista(_credit.IDProcedureImp)
        if (_credit.SupportDoc != null) {
            setDoc(JSON.parse(_credit.SupportDoc))
            console.log("documento cargado")
        } else {
            console.error("hubo un error al cargar el documento de la cuenta")
        }
    }, [_credit])

    const getLista = (IdImp: number) => {
        single.spinner(true);
        service.getPayrollReceiptCatalogPorPropiedad('IDProcedureImp', IdImp, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    setReceipt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties)
                    console.log(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties)
                } else {
                    toast.error("no se pudo cargar la información");
                }
                single.spinner(false)
            }
        )
    }

    const onExportMap = () => {
        console.log(value)
        exportMap({
            RESERVA: value
        })
    }

    const trays = [
        {
            value: "1",
            label: "Credito",
            show: true
        },
        {
            value: "2",
            label: "Cuenta",
            show: true
        },
    ];

    const updateToken = () => {
        let x = { ..._credit, TokenCredit: token, TokenEmployee: single.getAccountID() }
        console.log(x)
        updateCredit(x);
    }

    const actualizar = () => {
        if (value == 1 && token == "") {
            toast.error("no se permite registrar un token vacio");
        }
        else {
            setViewer("1");
            onExportMap();
            updateToken();
        }
    }

    return (
        <>
            <div className="col-12 text-center mb-2">
                <h4>
                    <strong>{title}</strong>
                </h4>
            </div>
            <div className="col-md-12 d-flex justify-content-center ">
                <NavTabs
                    defalutValue={viewer}
                    items={trays}
                    value={viewer}
                    onChange={(e) => { setViewer(e) }}
                />
            </div>
            {viewer == "1" &&
                <div className="row">
                    <div className="col-md-12">
                        <label>Persona:</label>
                        <input type="text" className="form-control" value={_user.Name1 + " " + _user.Name2 + " " + _user.Surname1 + " " + _user.Surname2} disabled />
                    </div>
                    <div className="col-md-6">
                        <label>Valor del Prestamo:</label>
                        <input type="text" className="form-control" value={single.formatMoney(_credit.Amount)} disabled ></input>
                    </div>
                    <div className="col-md-6">
                        <label>Numero de Cuotas:</label>
                        <input type="text" className="form-control" value={_credit.QuotaCredit} disabled />
                    </div>
                    <div className="col-md-6">
                        <label>PIN:</label>
                        <input type="text" className="form-control" value={_credit.Pin} disabled />
                    </div>
                    <div className="col-md-6">
                        <label>Desprendible de nomina</label><br />
                        {(receipt.Media && receipt.MediaContext) ?
                            <a href={fileService.getUrlFile(receipt.MediaContext, receipt.Media)} target='_blank'>Descargar Desprendible</a>
                            :
                            <p>No presenta Documneto de soporte</p>
                        }
                    </div>
                </div>
            }
            {viewer == "2" &&
                <div className="row">
                    {info.Bank &&
                        <div className="col-6">
                            <label className="form-label">Banco: </label>
                            <SelectConstantsValueForpo code={2} onChange={() => { }} value={info.Bank} disabled />
                        </div>
                    }
                    {info.BankType &&
                        <div className="col-6">
                            <label className="form-label">Tipo de cuenta: </label>
                            <SelectConstantsValueForpo code={1} onChange={() => { }} value={info.BankType} disabled />
                        </div>
                    }
                    {info.BankAccNo &&
                        <div className="col-12">
                            <label className="form-label">No:</label>
                            <input type="number" className="form-control" value={info.BankAccNo} disabled />
                        </div>
                    }
                    <div className="col-md-12">
                        <label>Documento de soporte de cuenta:</label><br />
                        {(doc.Media && doc.MediaContext) ?
                            <a href={fileService.getUrlFile(doc.MediaContext, doc.Media)} target='_blank'>{doc.Name}</a>
                            :
                            <p>No presenta Documneto de soporte</p>
                        }
                    </div>
                </div>
            }
            <div className="col-md-12" >
                <label>Token:</label>
                <input disabled={(value != 1)} type="text" className="form-control" value={token} onChange={(e) => {
                    setToken(e.target.value);
                    localStorage.setItem("token", e.target.value);
                }} />
            </div>
            <div className="col-8 mt-3">
                <select className="form-select" onChange={(e) => { setValue(parseInt(e.target.value)) }} >
                    <option value={undefined}>...</option>
                    <option value={1}>Token registrado y tiene cuenta en SIIF</option>
                    <option value={2}>No tiene cuenta en SIIF Nacion</option>
                    <option value={3}>Ya tiene Token activo</option>
                    <option value={4}>Tiene Embargo</option>
                </select>
                {/* <SelectConstantsValueForpo code={6} onChange={(e) => { setValue(e.target.value) }} value={value} /> */}
            </div>

            <div className="col-4 mt-3">
                <button disabled={isNaN(value)} type="button" className="btn btn-primary " style={{ width: "100%" }} onClick={() => { actualizar() }}>
                    Guardar
                </button>
            </div>
            {viewer == "3" &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>APROBADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
        </>
    )
}
