
import { useState, useEffect } from 'react';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { ForpoService } from './service/ForpoService';

interface Props {
    label?: string;
    code: number;
    value: number | undefined;
    onChange: Function;
    disabled?: boolean;
}

export const SelectConstantsValueForpo = ({ label, code, value, onChange, disabled }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState<any>([]);
    const [id, setId] = useState<number>(0);


    useEffect(() => {
        setId(value);
    }, [value])

    useEffect(() => {
        getLista();
    }, [code])

    useEffect(() => {
        onChange(id);
    }, [id])


    const getLista = () => {
        setLoading(true);
        service.getSuppConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    setLista(lista['Code_' + code]);
                }
                setLoading(false);
            }
        )
    }


    return (
        <>
            <label>{label ? label : ''} </label>
            <select className="form-select" value={id} id={`codeid-${code}`} onChange={(e) => { setId(parseInt(e.target.value)) }} disabled={disabled} >
                <option value={0}>...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDSuppConstantsValue}>{item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}