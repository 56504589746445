import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicButton } from "../../theme/Component/BasicButton";
import { Alert } from "../../theme/Component/Alert";
import { SelectModality } from "../select/SelectModality";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from '../../theme/Component/Modal';
import { ListMenu } from "../../theme/Component/ListMenu";
import { toast } from "react-toastify";


interface Props {
    beanOficina: any;
    beanH: any;
    updateHiring: any;
}
export const ConfigHiringProcess = ({ beanOficina, beanH, updateHiring }: Props) => {

    const service = new ContractService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [message, setMessage] = useState('');
    const [bean, setBean] = useState<any>({});
    const [beanItem, setBeanItem] = useState<any>({});
    const [listaItems, setListaItems] = useState<any>([]);
    const [items, setItems] = useState<any>([]);
    const [listItems, setListItems] = useState<any>([]);
    const [modalI, setModalI] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    // useEffect(() => {

    //     return () => {
    //         initBean();
    //     }
    // }, [])



    useEffect(() => {


        if (!beanH.IDAdquisitionPlanFunctionalID) {
            initBean();
        } else {
            setBean(beanH);
        }


        getAdquisitionItemAprovedState();

    }, [beanH])




    const initBean = () => {
        let b = {
            IDAdquisitionPlanFunctionalID: beanOficina.IDAdquisitionPlanFunctionalID,
            IDEmployee: single.getAccountID(),
            IDStructuringDocument: null,
            Justification: '',
            ObjectContract: '',
            StructuringState: 10,
            IDHiringModality: 0
        };
        setBean(b);
    }


    const editarHiring = () => {
        console.log(bean);
        setLoading(true);
        service.updateHiringProcess(bean).subscribe(
            (resp: any) => {

                setLoading(false);
                setMessage("Proceso actualizado");
            }
        )
    }

    const crearHiring = () => {

      console.log(beanItem);
      
      const AdquisitionItems = [];
      AdquisitionItems.push(beanItem.IDAdquisitionItem);
      beanItem.AdquisitionItems = AdquisitionItems;

        if (beanItem.IDHiringModality > 0 && beanItem.Justification != '' && beanItem.Description != '') {
            setLoading(true);
            service.createHiringProcess(beanItem).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        toast.success("Se a solicitado la contratación de la necesidad.");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    else {
                        toast.error("No se pudo iniciar la solicitud.");             
                    }
                    setLoading(false);
                    getAdquisitionItemAprovedState();
                    setModalI({...modalI,"view":false});
                }
            )
        } else {
            setMessage("Faltan campos por diligenciar");
            toast.error("No se ha seleccionado una necesidad para contratar.");
        }

    }
    const getAdquisitionItemAprovedState = () => {

        setLoading(true);
        service.getAdquisitionItemAprovedState(beanOficina.IDAdquisitionPlanFunctionalID).subscribe(
            (resp: any) => {

                setLoading(false);

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp: any = [];
                    setListItems(resp.DataBeanProperties.ObjectValue);


                    resp.DataBeanProperties.ObjectValue.forEach((element: any) => {
                        tmp.push({
                            id: element.DataBeanProperties.IDAdquisitionItem,
                            Name: `$ ${single.formatMoney(element.DataBeanProperties.TotalValue)} : ${element.DataBeanProperties.Description}`
                        })
                    });


                    setItems(tmp);
                }

            }
        )

    }

    const head = [
        { title: 'ID', property: 'IDAdquisitionItem', visible: true },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Justificación', property: 'Justification', visible: true },
        { title: 'Valor total estimado', property: 'TotalValue' },


    ]

    const openModal=()=>{
        setModalI({...modalI,"view":true});
    }

    const listaBotonesItems = () => {
        const botones = [
            { titulo: 'Solicitar', icono: 'ri-file-list-line', visible: true, evento: openModal },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col">
                    <strong>Necesidades Aprobadas</strong>
                    <hr />
                </div>
            </div>
            <div className="row mt-4 p-4">

                {listItems.length > 0 ?
                    <BasicTable head={head} body={listItems} listButtons={listaBotonesItems()} rowSet={setBeanItem}></BasicTable>
                    :
                    <Alert clase="warning">
                        No hay Necesidades en estado aprobado disponibles para el plan de compras.
                    </Alert>
                }


                <div className="col-md-12">
                    {message != '' &&
                        <Alert clase="warning" key={'mi-message'}>{message}</Alert>
                    }
                </div>
                

            </div>
            <div className="row">
                <div className="col">
                <Modal modal={modalI} updateModal={setModalI} >
                    <div className="row">
                        <div className="col">
                            
                            <div className="col-md-12">
                                <strong>{beanOficina.IDLnFunctionalIDName}</strong>
                            </div>
                            <div className="col-md-12">
                                <SelectModality idSelector={beanItem.IDHiringModality} onChange={(e: any) => { setBeanItem({ ...beanItem, "IDHiringModality": e }) }} />

                            </div>


                            <div className="col-md-12">
                                {!bean.IDHiringProcess ?
                                    <BasicButton disable={!beanItem.IDAdquisitionItem} icon="ri-file-add-line" eventClick={crearHiring}>Crear solicitud contratación</BasicButton> :
                                    <BasicButton icon="ri-file-add-line" eventClick={editarHiring}>Editar</BasicButton>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
                </div>
            </div>
        </>
    )
}