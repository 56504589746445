import { BasicPage } from '../../../theme/Component/BasicPage';
import './AccountantBook-service';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { AccountantBookService } from './AccountantBook-service';
import { useEffect, useState } from 'react';
import { CompanyEditor } from '../Company/CompanyEditor';
import { toast } from 'react-toastify';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { CurrencyEditor } from '../Currency/CurrencyEditor';
import { SelectProcessTypeConstants } from '../../../bpm/config/Componentes/SelectProcessTypeConstants';

export const AccountantBook = () => {
    const service = new AccountantBookService();
    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [modal, setModal] = useState<any>({ name: "Editar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDAccountantBook';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Código', property: 'Code' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Tipo de Proceso', property: 'ProcessType' },
        { title: 'Tipo de Moneda ', property: 'CurrenyName' },
        { title: 'Compañía Relacionada', property: 'CompanyName' }
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {
        if (conpany != null && !isNaN(conpany)) {
            console.log(conpany);
            getAccountantBookCatalog();
        }
    }, [conpany]);


    const getAccountantBookCatalog = () => {
        service.spinON();
        service.getAccountantBookCatalog(conpany).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    const eliminarItem = () => {
        service.spinON();
        service.deleteAccountantBook(bean[idBean]).subscribe(
            (resp: any) => {
                service.spinOFF();
                if (resp) {
                    getAccountantBookCatalog();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, "view": false })
    }

    const updateItem = () => {
        service.spinON();
        service.updateAccountantBook(bean).subscribe((resp: any) => {
            service.spinOFF();
            if (resp.DataBeanProperties.ObjectValue) {
                getAccountantBookCatalog();
            }
            single.update(bean);
        });
        setModal({ ...modal, "view": false })
    };

    const agregarItem = () => {
        let b = {
            Code: "",
            Name: "",
            ProcessType: null,
            IDCurrency: null,
            IDCompany: null,
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <BasicPage titulo='Catálogo de Libros Contables'>
            <div className="row">
                <div className="col">
                    <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }}></CompanyEditor>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Código:</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, "Code": e.target.value }); }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Nombre:</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
                    </div>
                    <div className="col-md-6">
                        {/* <label className="form-label">Tipo de Proceso:</label> */}
                        <SelectProcessTypeConstants idSelector={bean.ProcessType} onChange={(e) => { setBean({ ...bean, "ProcessType": e }); }}></SelectProcessTypeConstants>
                    </div>
                    <div className="col-md-6">
                        {/* <label className="form-label">Tipo de Moneda:</label> */}
                        <CurrencyEditor id={bean.IDCurrency} onchange={(e: any) => { setBean({ ...bean, "IDCurrency": e }) }}></CurrencyEditor>
                    </div>
                    <div className="col-md-6">
                        {/* <label className="form-label">Compañia:</label> */}
                        <CompanyEditor id={conpany} onchange={(e: any) => { setBean({ ...bean, "IDCompany": e }) }}></CompanyEditor>
                    </div>
                </div>
            </Modal>
        </BasicPage>
    )
}