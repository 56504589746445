import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ItemDropDown } from "../ItemDropDown";
import { Modal } from "../../theme/Component/Modal";
import { AdquisitionItemBudgetID } from "../AdquisitionItemBudgetID";
import { SelectProjectID } from "../../admin/configuracion/ProjectID/SelectProjectID";
import { SelectBudgetResource } from "../select/SelectBudgetResource";
import { SelectMonths } from "../select/SelectMonths";
import { InputMoney } from "../../theme/Input/InputMoney";
import { SelectPreparadorCatalog } from "../select/SelectPreparadorCatalog";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { AdminService } from "../../../core/services/AdminService";
import { AdquisitionArticle } from "./AdquisitionArticle";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";


interface Props {
    idAdquisitionPlanFunctionalID: number;
    modo?: boolean;
    selectRow?: any;
    rol: number;
    beanOficina: any;
}
export const AdquisitionItem = ({ idAdquisitionPlanFunctionalID, modo, selectRow, rol, beanOficina }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const adminService = new AdminService();

    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [editar, setEditar] = useState(true);
    const [plan, setPlan] = useState<any>({});
    const [token, setToken] = useState<number>(0);

    //Modales
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    // const [modalObligacion, setModalObligacion] = useState<any>({ name: 'Obligaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalItem, setModalItem] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalArticulo, setModalArticulo] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDAdquisitionItem';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Justificación', property: 'Justification', visible: false },
        // { title: 'Fecha Estimada Inicio Proceso', property: 'MonthName' },
        // { title: 'Duración estimada del contrato (Meses)', property: 'EstimatedMonths' },
        { title: 'Modalidad de selección u Otro si de Contrato ', property: 'IDHiringModalityName', visible: false },
        { title: 'Fuente de los recursos', property: 'IDBudgetResourceName', visible: false },
        { title: 'Valor total estimado', property: 'TotalValue' },
        { title: 'Valor total Bien/servicio', property: 'TotalArticle' },
        { title: 'Iniciativa Estratégica/Proyecto', property: 'NameProject', visible: true },
        // { title: 'Rubro presupuestal', property: 'IDLnBudgetIDNameList' },
        { title: 'Dependencia Solicitante', property: 'OfficeName' },
        // { title: 'Etapa', property: 'PhaseStateName', visible: false },
        // { title: "Estado", property: "PreparationStateName" },
        { title: "Preparador", property: "PreparertName" },
        { title: "Estado", property: "PreparationStateName" },
        { title: "Analista", property: "AnalystName" , visible:false},

    ]

    useEffect(() => {
        getPlan();
        getLista();
        if (modo != undefined) {
            setEditar(modo);
        }

    }, [idAdquisitionPlanFunctionalID])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const renderEstados = (item: any) => {

        let render = item.DataBeanProperties.PhaseState;
        if (item.DataBeanProperties.PhaseState === 0) {
            render = item.DataBeanProperties.PlanningStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 1) {
            render = item.DataBeanProperties.StructuringStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 2) {
            render = item.DataBeanProperties.HiringStateName;
        }
        return render;
    }

    //Servicios CRUD

    const getPlan = () => {
        adminService.getAdquisitionPlan(beanOficina.IDAdquisitionPlan).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setPlan(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                        // console.log("Plan", resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                    }
                }
            }
        );
    }

    const getLista = () => {

        setLoading(true);

        // si es cargado desde la vista de un preparador 
        if (rol == 2) {
            service.getAdquisitionItemByFuncionalIDAndPreparer2(beanOficina.IDFunctionalIDPreparer, null, null).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue)
                        if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                            let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                            setLista(ll);
                        }

                    setLoading(false);
                }
            );
        }
        // si es cargado desde la vista de un administrador
        else {
            //con este servicio se consume para traer la informacionb respectiva sobre los items funcionales.
            service.getAdquisitionItemByFuncionalID(idAdquisitionPlanFunctionalID, null, null, null).subscribe(
                (resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                        ll.forEach((element: any) => {
                            element.DataBeanProperties.Estados = renderEstados(element);
                        });
                        setLista(ll);
                    } else {
                        setLista([]);
                    }

                    setLoading(false);
                }
            );
        }
    }

    const agregarItem = () => {


        let b = {
            IDAdquisitionPlanFunctionalID: idAdquisitionPlanFunctionalID,
            Description: '',
            EstimatedValue: 0,
            ItemOrder: 1,
            ExecutionMonth: 0,
            EstimatedMonths: 1,
            IDBudgetIDType: plan.IDBudgetIDType,
            IDLnBudgetID: 0,
            Justification: '',
            IDFunctionalIDPreparer: beanOficina.IDFunctionalIDPreparer != null ? beanOficina.IDFunctionalIDPreparer : 0,

        }

        setBean(b);
        setModalItem({ ...modalItem, "view": true, "name": 'Agregar Ítem' });

    }

    const updateMItem = () => {

        console.log(bean);
        service.updateAdquisitionItem(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {
                    single.update(bean);
                }
                else {
                    single.create(bean);
                }
                setModalItem({ ...modalItem, "view": false });
            }

        )
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteAdquisitionItem(bean.IDAdquisitionItem).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, "view": false });

    }

    const editarMItem = () => {
        setModalItem({ ...modalItem, "view": true, "name": 'Editar' });
    }



    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate(), evento: agregarItem }
    ]

    useEffect(() => {
        setToken(single.getRandomInt());
    }, [modalArticulo])


    const listaBotonesItems = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: editarMItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete() && (bean.PreparationState === 30 || bean.PreparationState === 7 || bean.PreparationState == null), evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true }) } },
            { titulo: 'Rubros', icono: 'ri-git-branch-line', visible: true, evento: () => { setModalRubro({ ...modalRubro, "view": true }) } },
            // { titulo: 'Obligaciones', icono: 'ri-list-ordered', visible: true, evento: () => { setModalObligacion({ ...modalObligacion, "view": true }) } },
            { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setModalArticulo({ ...modalArticulo, 'view': true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        {/* funcional item  */}
                        {editar &&
                            <div className="row">
                                <>
                                    <div className="col-md">
                                        {bean[idBean] &&
                                            <ItemDropDown rol={rol} bean={bean} updateEvent={() => { getLista(); setBean({}) }}></ItemDropDown>
                                        }
                                    </div>
                                </>
                            </div>

                        }
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotonesItems()} headButtons={renderButton()} head={head} body={lista} rowSet={setRowBean}></BasicTable>

            {/* adquisitionItem debe recibir el IDAdquisitionPlanFunctionalID*/}
            <Modal modal={modalItem} updateModal={setModalItem} eventModal={updateMItem}>
                <div className="row">
                    {rol != 2 &&
                        <div className="col-md-6">
                            <SelectPreparadorCatalog key={token} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} idSelector={bean.IDFunctionalIDPreparer != null ? bean.IDFunctionalIDPreparer : 0}
                                onChange={(id: number) => { setBean({ ...bean, "IDFunctionalIDPreparer": id }) }}
                            />
                        </div>
                    }
                    <div className="col-md-6">
                        <label>Valor Estimado</label>
                        <InputMoney readonly={(bean.IDAdquisitionItem >= 0 && bean.PhaseState == 2)} id={0} name='Valor Estimado' value={bean.TotalValue} onChange={(e: any) => { setBean({ ...bean, ['TotalValue']: parseInt(e) }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectMonths idSelector={bean.ExecutionMonth} onChange={(e: number) => { setBean({ ...bean, "ExecutionMonth": e }) }}></SelectMonths>
                    </div>
                    <div className="col-md-6">
                        <label>Meses Estimados</label>
                        <input type="number" className="form-control mt-2" value={bean.EstimatedMonths} onChange={(e) => { setBean({ ...bean, "EstimatedMonths": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectBudgetResource idSelector={bean.IDBudgetResource} onChange={(e: any) => {
                            setBean({ ...bean, "IDBudgetResource": e })
                        }}></SelectBudgetResource>
                    </div>
                    <div className="col-md-12">
                        <SelectProjectID renderValue={bean.NameProject} edit={false} onChange={(e: any) => {
                            console.log(e);
                            setBean({ ...bean, "IDLnProjectID": e.IDLn })
                        }}></SelectProjectID>
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Justificación</label>
                        <textarea className="form-control" placeholder='Incluya los aspectos legales, procedimentales, funcionales que sustentan la solicitud'
                            value={bean.Justification != null ? bean.Justification : ''} onChange={(e) => { setBean({ ...bean, "Justification": e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label"> Necesidad</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            {/* articulos de la necesidad  */}
            <Modal modal={modalArticulo} updateModal={setModalArticulo}>
                <div className="row">
                    <div className="col-md-12">
                        <AdquisitionArticle key={token} idAdquisitionItem={bean.IDAdquisitionItem} rol={rol}></AdquisitionArticle>
                        <p>{bean.IDAdquisitionItem}</p>
                    </div>
                </div>
            </Modal>

            {/* rubros de la necesidad  */}
            <Modal modal={modalRubro} updateModal={setModalRubro} eventModal={() => { }}>
                <div className="row">
                    <div className="col">

                        <AdquisitionItemBudgetID CanModify={bean.PreparationState} idAdquisitionItem={bean.IDAdquisitionItem} iDBudgetIDType={bean.IDBudgetIDType} />
                    </div>
                </div>
            </Modal>
          
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Description}
            </ModalConfirm>
        </>
    )
}