import { GenericService } from "../../../../core/services/GenericService";

export class InboxCashService extends GenericService {

  public getCashDeskAccountCatalog(officeID: number | null, accountID: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getCashDeskAccountCatalog_Number_Number",
      ArgumentList: [
        officeID, accountID
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  // public updateAccountantBook(bean: any) {
  //   const parametros = {
  //     ServiceName: "QuickDataERPCore",
  //     MethodHash: "admin.AccountantBook_updateAccountantBook_admin.AccountantBook",
  //     ArgumentList: [
  //       {
  //         DataBeanProperties: bean,
  //         DataBeanName: 'admin.AccountantBook'
  //       }
  //     ],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return this.myApi.post(this.url, data);
  // }

  // public deleteAccountantBook(IDAccountant: number | null) {
  //   const parametros = {
  //     ServiceName: "QuickDataERPCore",
  //     MethodHash: "void_deleteAccountantBook_Number",
  //     ArgumentList: [IDAccountant],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return this.myApi.post(this.url, data);
  // }
}