import { BasicPage } from '../../../theme/Component/BasicPage';

import { BasicTable } from '../../../theme/Component/BasicTable';
import { useEffect, useState } from 'react';
import { CompanyEditor } from '../Company/CompanyEditor';
import { toast } from 'react-toastify';
import { Singleton } from '../../../../core/services/Singleton';
import { OfficeEditor } from '../Office/OfficeEditor';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { Modal } from '../../../theme/Component/Modal';
import { cashReimbursementcajamenor } from './CashReimbursement-service';
import { Bankeditor } from '../BankTransfer.tsx/BankTEditor';




export const Cashcajamenor = () => {
    const service = new cashReimbursementcajamenor();
    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);
    const [modala, setModala] = useState<any>({
        name: "Agregar Item",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-lg",
    });
    const [meliminar, setMeliminar] = useState<any>({
        name: "Confirmar",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-sm",
    });
    const [modal, setModal] = useState<any>({ name: "Rembolso", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalHistory, setModalHistory] = useState<any>({ name: "Historial", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDCashReimbursement';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'IDAccount' },
        { title: 'Valor de la Base', property: 'BaseValue' },
        { title: 'Caja Destino', property: 'DestinityCash' },
        { title: 'Cuenta Contable', property: 'AccountantID' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    // useEffect(() => {
    //     if (office != null && !isNaN(office)) {
    //         getLista();
    //     }
    // }, [office])

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        service.spinON();
        service.getCashReimbursementCatalog(office, service.idAccount(), null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }
    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",

        };
        setBean(b);
        setModala({ ...modala, ["view"]: true });
    };

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Editar",
                icono: "ri-file-list-line",
                visible: true,
                evento: () => {
                    setModala({ ...modala, view: true, name: 'Editar' });
                },
            },
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setMeliminar({ ...meliminar, view: true });
                },
            },

        ];
        return <ListMenu listaBotones={botones} />;
    };

    const iconButtonsItem = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: agregarItem,
        },
    ];

    const updateItem = () => {

        // setModala({ ...modala, 'view': false });

        // setLoading(true);
        // service.updateCashReimbursement(bean).subscribe((resp: any) => {
        //     setLoading(false);
        //     if (resp.DataBeanProperties.ObjectValue) {
        //         getLista();
        //     }
        //     single.update(bean);
        // });
        console.log(bean);

    };

    return (
        <>
            <BasicPage titulo='Consultar Reembolsos de Caja Menor'>
                <div className="row">
                    <div className="col">
                        <Bankeditor id={bean.IDCashDeskAccount} onchange={(e: any) => { setBean({ ...bean, 'IDCashDeskAccount': e }) }}></Bankeditor>
                        {/* <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }}></CompanyEditor> */}
                    </div>
                    <div className="col">
                        <OfficeEditor id={office} onchange={(e: any) => { setOffice(e) }}></OfficeEditor>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modala} updateModal={setModala} eventModal={updateItem}>
                <>
                </>
            </Modal>
        </>
    )
}