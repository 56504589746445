import { useEffect, useState } from "react";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { Singleton } from '../../../core/services/Singleton';
import { BasicPage } from "../../theme/Component/BasicPage"
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicButton } from "../../theme/Component/BasicButton";
import { InportRealesCredit } from "./InportRealesCredit";
import { Alert } from "../../theme/Component/Alert";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";

interface Props {
    typeGuarante?: number;
}

export const CreditMonthReport = ({ typeGuarante }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new GarantiasService();
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [message, setMessage] = useState('');
    const [year, setYear] = useState(single.getCurrenYear);
    const [monthID, setMonthID] = useState(1);

    useEffect(() => {
        if (typeGuarante)
            setModalidad(typeGuarante);
    }, [typeGuarante])

    useEffect(() => {
        if (message !== '') {
            setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    }, [message])

    const [tipoArchivo] = useState<any>([
        { id: 1, name: 'Hipotecario' },
        { id: 2, name: 'Leasing' }
    ]);

    const [month, setMonth] = useState([
        { id: 0, name: "Enero" },
        { id: 1, name: "Febrero" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Abril" },
        { id: 4, name: "Mayo" },
        { id: 5, name: "Junio" },
        { id: 6, name: "Julio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Septiembre" },
        { id: 9, name: "Octubre" },
        { id: 10, name: "Noviembre" },
        { id: 11, name: "Diciembre" }
    ]);

    const idBean = 'IDCreditMonth';
    const head = [

        { title: 'Tipo', property: 'TypeGuaranteeName', visible: false },
        
        { title: 'Radicado', property: 'IDProcedure' },
        { title: 'Documento', property: 'nit' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Apellidos', property: 'AccountSurname' },
        { title: 'Ciudad ', property: 'CiuMun' },

        { title: 'Fecha Inicial', property: 'StartDate' },
        { title: 'Fecha Final', property: 'EndDate' },
        
        { title: 'Tasa', property: 'PercentValue' },
        { title: 'Valor Inmueble ', property: 'PropertyValue' },
        
        { title: 'Tasa', property: 'PercentValue', visible: false },
        { title: 'Ajuste Garantia', property: 'FixedValueMonth' },
        { title: 'Valor Ajustado', property: 'FixedValueAcu' },
        { title: 'Total', property: 'Total' },

        

    ]


    const [modalidad, setModalidad] = useState<number>(1);

    const getCreditMonthRender = () => {
        setLoading(true);
        service.getAllCreditMonthRender(year,monthID,1,modalidad).subscribe(
        // service.getCreditMonthRender(null, null, { Year: year, Month: monthID, TypeGuarantee: modalidad,State:1}).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((ele: any) => {

                        try {
                            ele.DataBeanProperties.AccountName =
                                (ele.DataBeanProperties.Name1 ?? '') + " " +
                                (ele.DataBeanProperties.Name2 ?? '') + " ";

                            ele.DataBeanProperties.AccountSurname =
                                (ele.DataBeanProperties.Surname1 ?? '') + " " +
                                (ele.DataBeanProperties.Surname2 ?? '');

                            ele.DataBeanProperties.TypeGuaranteeName =
                                ele.DataBeanProperties.TypeGuarantee === 1 ? 'Hipotecario' : 'Leasing';

                            ele.DataBeanProperties.CiuMun = ele.DataBeanProperties.NameCiu + " / " + ele.DataBeanProperties.Departamento;
                            ele.DataBeanProperties.StartDate = ele.DataBeanProperties.StartDate.substring(0, 10);
                            ele.DataBeanProperties.EndDate = ele.DataBeanProperties.EndDate.substring(0, 10);

                            ele.DataBeanProperties.FixedValueMonth = ele.DataBeanProperties.FixedValueMonth ?? 0;
                            ele.DataBeanProperties.Total = ele.DataBeanProperties.PropertyValue + ele.DataBeanProperties.FixedValueAcu;
                            

                        } catch (error) {

                        }

                    });
                    setLista(list);

                }
                setLoading(false);
            }
        );
    }
    const runGuaranteeByMonth = () => {
        setLoading(true);
        console.log(year, monthID)
        if (year > 0 && monthID >= 0)
            service.runGuaranteeByMonth(year, monthID, {}, single.getAccountID(), modalidad).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        getCreditMonthRender();
                    }

                    setLoading(false);
                }
            );
        else {
            setLoading(false)
            setMessage('debe seleccionar un periodo valido');
        }
    }
    const eliminarValorizacionbyHash = () => {

        let calculateHash = undefined;
        if (lista.length > 0) {
            calculateHash = lista[0].DataBeanProperties.CalculateHash;
        }

        if (calculateHash) {
            setLoading(true);
            console.log(year, monthID)
            if (year > 0 && monthID >= 0)
                service.eliminarValorizacionbyHash(calculateHash, modalidad).subscribe(
                    (resp: any) => {
                        console.log(resp);
                        if (resp.DataBeanProperties.ErrorMessage) {
                            setMessage(resp.DataBeanProperties.ErrorMessage);
                        }
                        else if (resp.DataBeanProperties.ObjectValue) {
                            getCreditMonthRender();
                        }

                        setLoading(false);
                    }
                );
            else {
                setLoading(false)
                setMessage('debe seleccionar un periodo valido');
            }
        }
        else {
            toast.error("No hay datos para eliminar");
        }
    }

    return (
        <BasicPage titulo="Reporte Garantias">
            <div className="row">
                <div className="col-md-12">
                    <div className="col-md-12">
                        {!typeGuarante &&
                            <>
                                <label>Modalidad</label>
                                <select name="tipo" className='form-select mt-2' value={modalidad} onChange={(e) => { setModalidad(parseInt(e.target.value)) }}>
                                    {tipoArchivo.map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })

                                    }
                                </select>
                            </>
                        }
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3">
                            <label>Año</label>
                            <input type="number" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-md-3">
                            <label>Mes</label>
                            <select className="form-select" value={monthID} onChange={(e) => { setMonthID(parseInt(e.target.value)) }}>
                                {month.map((mes: any) => {
                                    return (
                                        <option value={mes.id}>{mes.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-md-4 mt-3 d-flex gap-2">
                            <BasicButton icon="ri-file-search-line" eventClick={getCreditMonthRender}>Buscar</BasicButton>
                           

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {message != '' &&
                                <Alert clase="warning">
                                    {message}
                                </Alert>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <BasicTable head={head} body={lista} rowSet={setBean}></BasicTable>
                </div>
            </div>

        </BasicPage>
    )
}