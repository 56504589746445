import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { ButtonBudgetID } from './ButtonBudgetID';
import { BudgetIDChilds } from './BudgetIDChilds';
import { BasicButton } from "../../../theme/Component/BasicButton";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";


interface Props {
  tipoPresupuesto: number | null;
  id: number | null;
  edit: boolean;
}
export const BudgetID = ({ id, edit, tipoPresupuesto }: Props) => {
  const service = new FinanceService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [listaType, setListaType] = useState([]);
  const [name, setName] = useState('');
  const [txt, setTxt] = useState<string>('');
  const [bean, setBean] = useState({});



  const [idchild, setIDchild] = useState(0);

  useEffect(() => {

    if (tipoPresupuesto)
      setType(tipoPresupuesto);
    getSeparator();
    getListaType();

  }, []);

  const getListaType = () => {
    setLoading(true);
    service.getBudgetIDTypeCatalog(null).subscribe((resp: any) => {

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue;

        setListaType(resp.DataBeanProperties.ObjectValue);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const getListatree = () => {
    setLoading(true);
    service.getTreeForBudgetID(type).subscribe((resp: any) => {

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
        setName(arbol.Name);
        setLista(resp.DataBeanProperties.ObjectValue.Childs);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const getListatreebyName = () => {
    setLoading(true);
    service.getBudgetIDByName(type, txt).subscribe((resp: any) => {
      console.log(resp);

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue;
        setName("");
        let lis = resp.DataBeanProperties.ObjectValue;

        lis.forEach((element: any) => {

          element.EnvolvedObject = element;
        });
        console.log(lis);

        setLista(lis);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getSeparator = () => {

    localStorage.setItem('separador', '-');
    // service.getBudgetIDSeparator().subscribe(
    //   (resp: any) => {
    //     let separador = resp.DataBeanProperties.ObjectValue;
    //     localStorage.setItem('separador', separador);
    //   }
    // );

  }

  const [type, setType] = useState(0);

  useEffect(() => {
    getListatree();
  }, [type])

  return (
    <>
      {/* <BasicPage titulo="Árbol Rubros Presupuestales"> */}
      <div className="row mb-4">
        <div className="col-md-6">

          <label> Tipo Presupuesto</label>

          <select className="form-control " value={type} onChange={(e) => { setType(parseInt(e.target.value)) }}>
            <option value={0}>...</option>
            {listaType.length > 0 &&
              listaType.map((item: any) => {
                return (
                  <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Name}</option>
                )
              })
            }
          </select>

        </div>
        {type > 0 &&
          <>
            <div className="col-md-4">
              <label>Nombre</label>
              <input type="text" name="name" className="form-control" value={txt} onChange={(e) => { setTxt(e.target.value) }} />
            </div>
            <div className="col-md-2 mt-3">
              <BasicButton icon="" clase="success" eventClick={getListatreebyName}>Buscar</BasicButton>
            </div>
          </>

        }


      </div>
      <div className="row">
        <div className="col">
          {lista.length === 0 && type != 0 &&
            <ButtonBudgetID
              idType={type}
              id={0}
              bean={{}}
              updateEvent={() => { console.log("Actualizacion evento"); getListatree(); }}
            />
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ul className="list-group" key={'root-budget'}>
            {lista.length > 0 &&
              lista.map((item: any) => {
                return (
                  <li className="list-group-item itemh" onClick={() => { setBean(item.EnvolvedObject.DataBeanProperties) }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>
                    {edit &&
                      <ButtonBudgetID
                        idType={type}
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => { console.log("Actualizacion evento"); getListatree(); }}
                      />
                    }
                    <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                      localStorage.setItem('BudgetID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                    }}>
                      {/* <strong style={{marginLeft:'15px'}}>{item.EnvolvedObject.DataBeanProperties.BudgetViewCode} </strong>  */}
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.JsonCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>

                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>

                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <BudgetIDChilds _type={type} idChild={idchild} edit={edit} editBean={(item: any) => { setBean(item) }}
                      />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {/* </BasicPage> */}
    </>
  );
};
