import React from 'react'

interface Props {
    message?: string
    description?: string
    className?: string
}

export const NoResults = ({ message, description, className }: Props) => {
    return (
        <div className={`text-center ${className}`}>
            <div className="d-flex justify-content-center mb-3">
                <div className="px-3 py-2 d-flex justify-content-center animate__animated animate__headShake" style={{ backgroundColor: 'rgb(0 53 104 / 50%)', borderRadius: 100, maxWidth: 65 }}>
                    <i className="ri-error-warning-line fs-1" style={{ color: '#003568' }}></i>
                </div>
            </div>
            <h4 className="card-title bg-soft-blue">{message ?? 'No existe información para mostrar'}</h4>
            <p className="card-text text-muted">
                {description ?? 'Si tiene alguna duda con la información suministrada,porfavor comuníquese con el aréa encargada.'}
            </p>
        </div>
    )
}
