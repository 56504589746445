import { FinanceService } from "../../core/services/FinanceService";
import { useState, useEffect } from 'react';
import { BasicTable } from "../theme/Component/BasicTable";
import { BasicButton } from "../theme/Component/BasicButton";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { Modal } from "../theme/Component/Modal";
import { SelectBudgetID } from "../admin/configuracion/BudgetID/SelectBudgetID";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Singleton } from "../../core/services/Singleton";

interface Props {
    idAdquisitionItem: number;
    iDBudgetIDType: number;
    CanModify?: number;
}
export const AdquisitionItemBudgetID = ({ idAdquisitionItem, iDBudgetIDType, CanModify }: Props) => {


    const service = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = "IDAdquisitionItemBudgetID";

    useEffect(() => {

        getLista();
    }, [idAdquisitionItem])

    const getLista = () => {
        service.getAdquisitionItemBudgetIDCatalog(idAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }
    const setRow = (b: any) => {
        console.log(b);

        setBean(b);
    }
    const agregarItem = () => {
        let b = {
            IDLnBudgetID: 0,

            EstimatedValue: 0,
            Description: '',
            IDEmployee: single.getAccountID(),
            Since: single.getCurrenDate(),
            IDAdquisitionItem: idAdquisitionItem,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true, 'name': 'Agregar Rubro' });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateAdquisitionItemBudgetID(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {
                    single.update(bean);
                }
                else {
                    single.create(bean);
                }
            }
        );

    }
    const editarItem = () => {
        setModal({ ...modal, ['view']: true, 'name': 'Editar Rubro' });

    }
    const eliminarItem = () => {
        setLoading(true);
        service.deleteAdquisitionItemBudgetID(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Rubro Presupuestal', property: 'IDLnBudgetIDName' },
        { title: 'Valor Estimado', property: 'EstimatedValue' },
        // { title: 'Valor Estructurado', property: 'StructuredValue' },        
        { title: 'Funcionario', property: 'StructurerAccountName' },
        { title: 'Observaciones', property: 'Description' },
    ]

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const iconButtons = [
        { nombre: 'Agregar', visible: (single.canCreate()), icono: 'ri-file-add-line', evento: agregarItem }
        // { nombre: 'Agregar', visible: (single.canCreate() && (CanModify === 30 || CanModify === 7 || CanModify == null)) , icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (single.canEdit() && (CanModify === 30 || CanModify === 7 || CanModify == null)), evento: editarItem },
            {
                titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete()
                // && (CanModify === 30 || CanModify === 7 || CanModify == null)
                , evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true }) }
            },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <div className="row mt-4">

                <div className="col-md-12">
                    <BasicTable iconButtons={iconButtons} listButtons={listaBotones()} head={head} body={lista} rowSet={setRow}></BasicTable>

                    <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                        {bean.Description}
                    </ModalConfirm>

                    <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <SelectBudgetID edit={false} renderValue={bean.IDLnBudgetIDName} tipoPresupuesto={iDBudgetIDType} onChange={(e: any) => {
                                    setBean({ ...bean, "IDLnBudgetID": e.IDLn });
                                }} />
                            </div>
                            <div className="col-md-6">
                                <label>Valor Estimado</label>
                                <input type="number" className="form-control mt-2" value={bean.EstimatedValue} onChange={(e) => { setBean({ ...bean, "EstimatedValue": e.target.value }) }} />
                            </div>
                            {/* <div className="col-md-6">
                                <label>Valor Estructurado</label>
                                <input type="number" className="form-control mt-2" value={bean.StructuredValue} onChange={(e)=>{setBean({...bean,"StructuredValue":e.target.value})}}/>
                            </div> */}

                            <div className="col-md-12">
                                <label className="form-label">Observaciones</label>
                                <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}