import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { AdminService } from '../../../../core/services/AdminService';
import { FileInput } from '../../../theme/Component/FileInput';
import { FileService } from '../../../../core/services/FileService';
import { DataBeanProperties } from '../../../../core/services/model/server-response.interface';

interface Props {
    _idGroupTemplate: number;
}

export const Templates = ({ _idGroupTemplate }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const fileService = new FileService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDTemplate';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
        { title: 'Archivo', property: 'URL' },
    ]

    useEffect(() => {
        getLista();
    }, [_idGroupTemplate])


    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getTemplateCatalogPorPropiedades({
            IDGroupTemplate: _idGroupTemplate,
            State: 1
        }).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((element: any) => {
                        let url = fileService.getUrlFile(
                            element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media
                        );
                        element.DataBeanProperties.URL = `<a href='${url}' target='_blank'>${element.DataBeanProperties.Media}</a>`

                    });

                    setLista(tmp);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            IDGroupTemplate: _idGroupTemplate,
            Name: '',
            Description: '',
            State: 1,
            IDEmployee: single.getAccountID(),
            Media: '',
            MediaContext: '',
        }
        setBean(b);
        setModal({ ...modal, 'view': true, "name": "Agregar plantilla" });
    }

    const eliminarItem = () => {
        bean.State = 2;
        console.log(bean)
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        console.log(bean)
        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.updateTemplate(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Edistar plantilla" }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <FileInput
                            Media={bean.Media}
                            MediaContext={bean.MediaContext}
                            directory='Template'
                            label='Archivo'
                            docAcept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf'
                            onCreate={(Filemap: any) => { setBean({ ...bean, "Media": Filemap.Media, "MediaContext": Filemap.MediaContext }); }}
                        ></FileInput>
                    </div>
                </div>
            </Modal>
        </>
    )
}