import { useEffect, useState } from "react";
import { BasicButton } from "../theme/Component/BasicButton";
import { Singleton } from "../../core/services/Singleton";
import { ContractService } from "../../core/services/ContractService";
import { Alert } from "../theme/Component/Alert";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    idSupplier: number;
    handleEvent: Function;
}
export const FindSupplier = ({ idSupplier, handleEvent }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [supp, setSupp] = useState<any>({});
    const [txt, setTXT] = useState('');
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {

        if(idSupplier === 0)
            {
                setLista([]);
                setTXT("");
            }

    }, [idSupplier])
    useEffect(() => {
        return () => {
            setSupp({})
        }
    }, [])

    const findSupplier = () => {
        if (txt.length > 1) {
            setLoading(true);
            service.getSupplierFindByName(null, txt).subscribe(
                (resp: any) => {
                    setSupp({});
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }

                    setLoading(false);
                }
            )
        }
    }

    useEffect(() => {

        handleEvent(supp);
    }, [supp])


    return (
        <>
            <div className="row">
                <div className="col-8">
                    <label>Buscar Proveedor</label>
                    <input type="text" className="form-control" value={txt} onChange={(e) => { setTXT(e.target.value) }} />
                </div>
                <div className="col-4 mt-3">
                    <BasicButton icon=" ri-search-line" eventClick={findSupplier}>Buscar</BasicButton>
                </div>
                <div className="col-md-12" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <ul className="list-group">
                        {lista.map((item: any, index: number) => {
                            return (
                                <li className="list-group-item">
                                    <div style={{ display: 'flex' }}>
                                        <div className="cursor" style={{ margin: '10px' }} onClick={() => { setSupp(item.DataBeanProperties) }}>
                                            <i className={supp.IDSupplier === item.DataBeanProperties.IDSupplier ? 'ri-checkbox-line' : 'ri-checkbox-blank-line'}
                                            ></i>
                                        </div>
                                        <div>
                                            <strong>{item.DataBeanProperties.nit}</strong>
                                            <br />
                                            {item.DataBeanProperties.Name1}
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    {lista.length === 0 &&
                        <Alert clase="primary">
                            sin datos
                        </Alert>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                        
                </div>
            </div>
        </>
    )
}