import { useEffect, useState } from 'react';

interface Props{
    children:any;
    modal:any;
    eventModal?:any;
    updateModal:any ;
    onSubmit?:any;
}
// const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
export const Modalcompras = ({ children, modal, eventModal,updateModal,onSubmit}: Props) => {

    const[ver,setVer]=useState('block');

    useEffect(()=>{

        setVer(modal.view ? 'block' : 'none');
        

        // if(view)
        // {
        //     //overflow: hidden; padding-right: 0px;
        //     document.body.classList.add('modal-open');
        //     document.body.style.overflow='hidden';
        //     document.body.style.paddingRight='0px';
            
        // }else{
        //     document.body.classList.remove('modal-open');
        //     document.body.style.removeProperty('overflow');
        //     document.body.style.removeProperty('padding-right');
        // }
        

    },[modal.view])
  

    return (
        <>       
            <div  className={`modal fade show ${modal.format}`}  tabIndex={modal.tabIndex} style={{display:ver}}>

                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myModalLabel">{modal.name}</h5>
                                <button type="button" className="btn-close"  aria-label="Close" onClick={()=>{updateModal({...modal,['view']:false})}}> </button>
                            </div>
                            <div className="modal-body">
                                {modal.view &&
                                <>
                                {children}
                                </>
                                }
                                
                            </div>
                            <div className="modal-footer">
                                {modal.btnClose &&
                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={()=>{updateModal({...modal,['view']:false})}}>Cerrar</button>
                                }
                                {modal.btnSubmit &&
                                    <button type="button" className="btn btn-primary " onClick={onSubmit}>Guardar</button>
                                }                                                                
                            </div>
                        </div>
                    </div>

            </div>
            
            {ver==='block' &&
                    <div className="modal-backdrop fade show"></div>
            }
            
        </>
    )
}