import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { MyEditor } from "../../../../theme/Component/MyEditor";
import { usePolicy } from "./usePolicy";


export const Policy = () => {
    const {
        bean,
        setBean,
        lista,
        modal,
        modalConfirm,
        setModal,
        setModalConfirm,
        single,
        eliminarItem,
        iconButtonsItem,
        head,
        setRowBean,
        updateItem,
    } = usePolicy();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: `Editar ${bean.Name}` }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Politicas de Campañas de Credito">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control mt-2" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <MyEditor onChange={(e: any) => { setBean({ ...bean, 'Descripcion': e }) }} val={bean.Descripcion}></MyEditor>
                    </div>
                    <div className="col-md-12 mt-3">
                        <BasicSwitch estado={bean.IsActive} label="¿Esta Activa?" eventChange={(e: any) => { setBean({ ...bean, "IsActive": e }) }}></BasicSwitch>
                    </div>
                </div>
            </Modal >
        </>
    );
};
