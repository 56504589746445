import { useState, useEffect } from 'react';
import { BpmService } from '../../../core/services/BpmService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface Props {
    _idBusinessProcess?: number;
    _publicBusiness?: boolean;
    _privateBusiness?: boolean;
    _idBusinessClass?: number;
    _idCustomerType?: number;
    _idCharacterization?: number;
    onChange: any;
}
export const SelectTramite = ({ _idBusinessProcess, _publicBusiness, _privateBusiness, _idBusinessClass, _idCustomerType, _idCharacterization, onChange }: Props) => {

    //Para el componente Solicita un tipo de filtro,  

    const { setLoading } = useDashboardStore();
    const bpm = new BpmService();
    const [IDBusinessClass, setIDBusinessClass] = useState(0);
    const [listaTipo, setListaTipo] = useState<any>([]);

    useEffect(() => {
        getBusinessProcessCatalog();
    }, [_idBusinessProcess, _publicBusiness, _privateBusiness, _idBusinessClass, _idCustomerType, _idCharacterization])

    //Se retira la manipulacion de datos enen la cual se agrega el objeto señuelo con id 0 a la lista, ya que esto puede causar inconvenientes con las base de datos que tiene eluso del id 0 en los datos.

    const getBusinessProcessCatalog = () => {
        setLoading(true);
        bpm.getBusinessProcessCatalog(_idBusinessProcess, _publicBusiness, _privateBusiness, _idBusinessClass, _idCustomerType, _idCharacterization).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaTipo(resp.DataBeanProperties.ObjectValue);
                }
            }
        )
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <label>Proceso</label>
                    <select className='form-select' value={IDBusinessClass} onChange={(e) => {
                        onChange(parseInt(e.target.value));
                        setIDBusinessClass(parseInt(e.target.value))
                    }}>
                        <option value={null}>...</option>
                        {listaTipo.map((item: any, i: number) => {
                            return (
                                <option key={i} value={item.DataBeanProperties.IDBusinessProcess}>
                                    {item.DataBeanProperties.Name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </>
    )
}