
import { RenderParametrica } from "../RenderParametrica";

interface Props {
    formulario: any;
}

export const Forma3Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>ENTREVISTA - USO EXCLUSIVO DE CAJA HONOR SOLO PARA VINCULACIÓN</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">

                    <label className="titul-form">94. Su profesión es: </label>

                    <p>{formulario.profesion}</p>

                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <label className="titul-form">Actividad:</label>
                    <RenderParametrica IDSuppConstantsValue={formulario.actividad} />
                </div>
            </div>
            <div className="row">
                <div className="col-6 color-celda">
                    <label className="titul-form">Nombre de la Empresa donde Trabaja:</label>
                    <p>{formulario.empresalabor}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Cargo:</label>
                    <p>{formulario.cargo}</p>
                </div>

            </div>
            <div className="row">
                <div className="col-12 color-celda">

                    <label className="titul-form">Maneja usted recursos publicos: </label>

                    <p>{formulario.recursosentrevista ? 'SI' : 'NO'}</p>

                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">

                    <label className="titul-form">Entrevista realizada por: </label>

                    <p>Nombre completo del funcionario: {formulario.nombrefuncionario}</p>
                    <p>No Cédula de ciudadania del funcionario: {formulario.cedulafuncionario}</p>

                </div>
            </div>
            <div className="row">
                <div className="col-4 color-celda">
                    <label className="titul-form">Fecha: </label>

                    <p>{formulario.fechaentrevista}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Hora: </label>

                    <p>{formulario.hora}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Lugar: </label>

                    <p>{formulario.lugar}</p>
                </div>
            </div>
            {/* <PrintJson  json={formulario}/> */}
        </>
    )
} 