import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { encrypt } from "../../../utils/helpers";
import { env } from "../../../env";
import { toast } from "react-toastify";
import { Singleton } from "../Singleton";


let yekCilbup = "";
let auth = "";
let hash: any;
let clearHash: any;
const showHash = env.REACT_HASH ?? true;
const initialization = (config: AxiosRequestConfig): AxiosInstance => {

  
  const axiosInstance = axios.create(config);
  /*
        Add default headers, interceptors etc..
    */

  
        if(yekCilbup === '')
          {
            const cookies = document.cookie.split('; ');
            for (const cookie of cookies) {
              const [cookieName, cookieValue] = cookie.split('=');
              if (cookieName === "yek") {
                yekCilbup = decodeURIComponent(cookieValue);
                
              }      
            }
          }
    //  if(auth === '' || auth == undefined)
      {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.split('=');
          if (cookieName === "auth") {
            auth = decodeURIComponent(cookieValue);
            
          }
          
        }
      }
  



  axiosInstance.interceptors.request.use(
    function (request: any) {

      if (!(request.data instanceof FormData)) {

        try {
          hash = JSON.parse(request.data);

        } catch (error) {

          return request;
        }


        clearHash = hash.MethodHash;
        if (hash.MethodHash == '')
          return request;

        if (hash.MethodHash !== "com.advantage.bean.securitymanager.PublicKeyInfo_getPublicKeyForApiRest_String") {
          if (showHash)
          console.log("Request => ", clearHash);


          hash.MethodHash = encrypt(yekCilbup, hash.MethodHash);
          console.log("Encrypt Request => ", hash.MethodHash);
          request.data = JSON.stringify(hash);

        }

        if (auth !== "") {

          
          request.headers["Authorization"] = auth;
          
          // request.headers["Authorization"] = localStorage.getItem("authorization");

          return request;
        } else {

          return request;
        }

      } else {
        return request;
      }
    },
  );

  axiosInstance.interceptors.response.use(
    (response: any) => {
      // console.log(response);
      return response;
    },
    (error: any) => {
      // console.log("Validacion de Error 401", error);
      if (error.response.status === 401) {
          
      } else if (error.response.status === 400) {
        console.error(clearHash, "400");
        console.error(error.response.data.DataBeanProperties.ErrorMessage, "400");
      }
      return error.response;
    }
  );

  return axiosInstance;
};

export default initialization;
