import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectorMenuTypeConstants } from "../../bpm/config/Componentes/SelectorMenuTypeConstants";
import { SelectorAplication } from "../../bpm/config/Componentes/SelectorAplication";
import { SelectorAplicationMenu } from "../../bpm/config/Componentes/SelectorAplicationMenu";
import { CardTaps } from "../../theme/Component/CardTaps";

export const ApplicationID = () => {

    const single = Singleton.getInstance()
    const service: AdminService = new AdminService();
    const [lista, setLista] = useState([]);
    const [listaRemote, setListaRemote] = useState([]);
    const [beanID, setBeanID] = useState<any>({});
    const [idType, setIDtype] = useState<number | null>(null);
    const [idApplication, setIDApplication] = useState(0);
    const [idApp, setIDApp] = useState(0);
    const [idTiporemote, setIDType] = useState(0);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal1, setModal1] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valueMenu, setValueMenu] = useState<number>(0);

    useEffect(() => {
        getTreeApplicationId(idApp);
        getRemoteAction(idApp);
    }, [idApp])

    const getTreeApplicationId = (id: number | null) => {

        setIDtype(id);
        Singleton.getInstance().spinner(true);
        service.getTreeApplicationId(id).subscribe(
            (resp: any) => {
                console.log("Application ID ", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                Singleton.getInstance().spinner(false);
            }
        );
    }
    const getRemoteAction = (id: number | null) => {

        setIDtype(id);
        Singleton.getInstance().spinner(true);
        service.getRemoteAbstractActionByApplicationType(id, null).subscribe(
            (resp: any) => {
                console.log("Application ID ", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaRemote(resp.DataBeanProperties.ObjectValue);
                }
                Singleton.getInstance().spinner(false);
            }
        );
    }

    const updateApplicationID = () => {
        console.log(beanID)
        setModal1({ ...modal1, ['view']: false });
        Singleton.getInstance().spinner(true);

        if (indexTap === 0) {
            service.updateApplicationID(beanID).subscribe(
                (resp: any) => {
                    Singleton.getInstance().spinner(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getTreeApplicationId(idType);

                    }
                }
            );
        }
        else {
            service.updateRemoteAbstractAction(beanID).subscribe(
                (resp: any) => {
                    Singleton.getInstance().spinner(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getRemoteAction(idType);

                    }
                }
            );
        }



    }

    const eliminarItem = () => {

        Singleton.getInstance().spinner(true);
        service.deleteApplicationID(beanID.IDLn).subscribe(
            (resp: any) => {
                Singleton.getInstance().spinner(false);
                if (resp) {
                    getTreeApplicationId(idType);
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }

    const setNuevoMenu = () => {
        let bean = {
            IDApplicationType: idType,
            IDLn_1: 0,
            Name: '',
            Description: '',
            URL: '',
            Code: 1,
            LnLevel: 0,
            State: 1,
            Type: null,
            IDApplicationID: idApplication,
        }
        setBeanID(bean);
        setModal1({ ...modal1, 'view': true, name: 'Agregar' });
    }

    const head = [
        { title: 'ID', property: 'IDLn' },
        { title: 'Código', property: 'Code' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'URL', property: 'URL' },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
    ]
    const headRemote = [
        { title: 'ID', property: 'IDLn' },
        { title: 'Nombre', property: 'Name' },
        { title: 'ClassForName', property: 'ClassForName' },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
        // { title: 'Descripción', property: 'Description' },
        // { title: 'URL', property: 'URL' }
    ]

    const rowSet = (b: any) => {
        setBeanID({ ...b, "IDApplicationID": idApplication, });

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal1({ ...modal1, "view": true, name: 'Editar' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: setNuevoMenu }
    ]

    const taps = [
        { id: 0, name: 'Menu Aplicación' },
        { id: 1, name: 'Menu Acción' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    return (
        <>

            <div className="row">

                <div className="col-md-4">
                    <SelectorMenuTypeConstants idSelector={valueMenu} onChange={(e: any) => { setValueMenu(parseInt(e)) }}></SelectorMenuTypeConstants>
                </div>
                {/* <div className="col-md-4">
                    <SelectorBusinessLogicTypeConstants idSelector={idTiporemote} onChange={(e) => { setIDType(e) }}></SelectorBusinessLogicTypeConstants>
                </div> */}
                <div className="col-md-4">
                    <SelectorAplication idSelector={idApplication} onChange={(e: any) => { setIDApplication(parseInt(e)) }}></SelectorAplication>
                </div>

                <div className="col-md-4">
                    <SelectorAplicationMenu _MenuType={valueMenu} _idAplication={idApplication} idSelector={idApp} onChange={(e) => { setIDApp(parseInt(e)); console.log(e) }}></SelectorAplicationMenu>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12 mt-2">
                    <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap}>

                        {indexTap == 0 ?
                            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={rowSet}></BasicTable>
                            :
                            <BasicTable listButtons={listaBotones()} head={headRemote} body={listaRemote} rowSet={rowSet}></BasicTable>
                        }

                    </CardTaps>


                </div>
                {/* <div className="col-md-12 mt-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={rowSet}></BasicTable>
                </div> */}
            </div>
            <Modal modal={modal1} updateModal={setModal1} eventModal={updateApplicationID}>
                <div className="row">
                    {valueMenu != 1 &&
                        <>
                            <div className="col-md-6">
                                <label className="form-label">Código</label>
                                <input type="text" className="form-control" value={beanID.Code} onChange={(e) => { setBeanID({ ...beanID, 'Code': e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Nombre</label>
                                <input type="text" className="form-control" value={beanID.Name} onChange={(e) => { setBeanID({ ...beanID, 'Name': e.target.value }) }} />
                            </div>

                            <div className="col-md-6">
                                <label className="form-label">URL</label>
                                <input type="text" className="form-control" value={beanID.URL} onChange={(e) => { setBeanID({ ...beanID, 'URL': e.target.value }) }} />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Descripción</label>
                                <textarea className="form-control" value={beanID.Description} onChange={(e) => { setBeanID({ ...beanID, 'Description': e.target.value }) }}></textarea>
                            </div>
                        </>
                    }


                    <div className="col-md-6">
                        <label>Estado</label>
                        <select className="form-select" value={beanID.State ?? undefined} onChange={(e) => { setBeanID({ ...beanID, 'State': e.target.value }) }}>
                            <option value={0}>ACTIVO</option>
                            <option value={1}>INACTIVO</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label>Tipo Acceso</label>
                        <select className="form-select" value={beanID.Type ?? undefined} onChange={(e) => { setBeanID({ ...beanID, 'Type': e.target.value }) }}>
                            <option value={2}>TODOS LOS USUARIOS</option>
                            <option value={3}>EN ROL DE USUARIO</option>
                        </select>
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                <h4>Eliminar Menú {beanID.Name}</h4>
            </ModalConfirm>
        </>
    );
}