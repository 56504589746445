import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';




interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
}

export const SelectNovedades = ({ titulo, idSelector, onChange }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        let cache = Singleton.getInstance().getCacheItem("TypeNoveltyCatalogPorPropiedad");
        if (cache) {
            setLista(cache);
        }
        else {
            setLoading(true);
            service.getTypeNoveltyCatalogPorPropiedad(null, null, null).subscribe(
                (resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        Singleton.getInstance().setCacheItem("TypeNoveltyCatalogPorPropiedad", resp.DataBeanProperties.ObjectValue);
                    }

                    setLoading(false);
                }
            );
        }


    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDTypeNovelty} value={item.DataBeanProperties.IDTypeNovelty} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}