
import { BsChevronCompactDown } from 'react-icons/bs'
import { ReactNode } from 'react'

import './menudashed.scss'
import { MenuButton } from '../MenuButton/MenuButton'

export type QuickAction = {
    icon: ReactNode
    label: string
    event: (nValue: QuickAction) => void
}
interface Props {
    quick_actions: QuickAction[]
}

export const MenuDashed = ({ quick_actions }: Props) => {
    return (
        <div className='container_menu'>
            <div className="menu_title">
                Acciones Rápidas
            </div>
            <div className='menu_list'>
                {quick_actions.map((e, i) => <MenuButton key={i} item={e} />)}
            </div>
            <div className='scroll_icon'>
                <BsChevronCompactDown />
            </div>
        </div>
    )
}
