export const processState =[
    {id:1,name:'Elaboración'},
    {id:2,name:'Publicado'}
]

export const budgetTypes =[
    {id:4,name:'RECURSOS HUMANOS-NOMINA'},
    {id:5,name:'ACTIVOS FIJOS E INVENTARIOS'},
    {id:6,name:'CONTABILIDAD Y FINANZAS'},
    {id:7,name:'CONTRATACIÓN'},
    {id:8,name:'PRESUPUESTO'},
    {id:9,name:'CORE DEL NEGOCIO'},    
]

