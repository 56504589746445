import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { FunctionalID } from './FunctionalID';
interface Props{
    renderValue:string;
    onChange:any;
}
export const SelectFunctionalID =({renderValue,onChange}:Props)=>{

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor,setValor] = useState('');


    useEffect(()=>{
        setValor(renderValue);
    },[renderValue])

    const getItem = ()=>{
        let bean:any = JSON.parse(localStorage.getItem('FuntionalID')|| '{}');
        onChange(bean);
        setValor(bean.Name);
        
        setModal({...modal,"view":false});
    }

    return(
        <>
        <label>Área Funcional</label>
        <span className="form-control text-wrap " style={{minHeight:'35px'}}  onClick={()=>setModal({...modal,"view":true})}>{valor}</span>
        
        <Modal modal={modal} updateModal={setModal}  eventModal={getItem}>
                <FunctionalID edit={false} id={null}/>
        </Modal>

        </>
    )
}