import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Table from 'react-quill';
import ImageResize from 'react-quill';
// import 'quill-image-resize-module';

interface Props {
  val: any;
  onChange: Function;
}

export const MyEditor = ({ val, onChange }: Props) => {
  // Register the modules here
  // Quill.register('modules/table', Table);
  // Quill.register('modules/imageResize', ImageResize);

  const [value, setValue] = useState(val);

  const handleImageDrop = (event: any) => {
    const file = event.dataTransfer.files[0];
    // Handle image drop
  };

  const handleImageUpload = (file: any) => {
    // Handle image upload
  };

  const formats = ['image'];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['image'],
      ['table'],
    ],
    table: true,
    imageResize: {
      displaySize: true,
    },
    imageDrop: true,
  };

  // useEffect(() => {
  //   setValue(val);
  // }, [val]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <ReactQuill
      // modules={modules}
      // formats={formats}
      theme="snow"
      value={value}
      onChange={setValue}
    />
  );
};
