import { CardTramitesHistory } from "../../../components/CardTramitesHistory";


interface Procedure {
    IDProcedureImp: number;
}

export const PersonalHistory = ({ IDProcedureImp }: Procedure) => {

    return (
        <>
            <CardTramitesHistory ID={IDProcedureImp}></CardTramitesHistory>
        </>
    )
}