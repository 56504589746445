import { Modal } from "../../theme/Component/Modal"
import { useState, useEffect } from 'react';
import { ProxyForms } from "../customForms/ProxyForms";

interface Props {
    action: any
    eventUpdate: any;
}
export const GestionAction = ({ action, eventUpdate }: Props) => {

    const [modal, setModal] = useState<any>({ name: action.ActivityTypeName, tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        setModal({ ...modal, "view": true });
    }, [action])


    const eventoModal = () => {
        eventUpdate();
        setModal({ ...modal, "view": false });
    }

    return (
        <>
            <Modal modal={modal} updateModal={setModal} eventModal={eventoModal} >
                {action.FormCode ?
                    <ProxyForms action={action} setResponse={eventUpdate} />
                    :
                    <p>Subir Documentos</p>
                }
            </Modal>
        </>
    )
}