import { useEffect, useState } from "react";
import { AccountEditor } from "../editor/AccountEditor";
import { FinanceService } from "../../core/services/FinanceService";

interface Props{
    bean:any;
}
export const AdquisitionPeople = ({bean}:Props)=>{

    const serviceFinance = new FinanceService();
    const [adquisition, setAdquisition] = useState<any>({});
    const [tipo,setTipo] = useState(0);
    


    useEffect(()=>{
        setTipo(0);
        setAdquisition(bean);    
    },[bean])

    useEffect(()=>{
            
        serviceFinance.updateAdquisitionPlan(adquisition).subscribe(
            (resp:any)=>{
                serviceFinance.getAdquisitionPlan(adquisition.IDAdquisitionPlan).subscribe(
                    (resp:any)=>{
                        console.log(resp);
                        if(resp.DataBeanProperties.ObjectValue)
                            setAdquisition(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                        
                    }
                )
                
            }
        )
            
    },[adquisition])

    

    return(
        <>
        <div className="row">
            <div className="col">
                <table className="table">
                    <thead>
                        <tr>
                            <th>NIVEL DE APROBACIÓN</th>
                            <th>FUNCIONARIO</th>
                            <th>FECHA DE EVENTO</th>
                            {/* <th>Opción</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ESTRUCTURADOR</td>
                            <td>{adquisition.StructurerAccountName}</td>
                            <td>{adquisition.StructuredDate}</td>
                            {/* <td>
                            <span className="link-success cursor" onClick={()=>setTipo(1)}>Cambiar 
                                <i className="ri-arrow-right-line align-middle"></i>
                            </span>
                            </td> */}
                        </tr>
                        <tr>
                            <td>REVISA</td>
                            <td>{adquisition.ReviewerAccountName}</td>
                            <td>{adquisition.ReviewedDate}</td>
                            {/* <td>
                            <span className="link-success cursor" onClick={()=>setTipo(2)}>Cambiar 
                                <i className="ri-arrow-right-line align-middle"></i>
                            </span>
                            </td> */}
                        </tr>
                        <tr>
                            <td>APRUEBA</td>
                            <td>{adquisition.ApproverAccountName}</td>
                            <td>{adquisition.ApprovedDate}</td>
                            {/* <td>
                            <span className="link-success cursor" onClick={()=>setTipo(3)}>Cambiar 
                                <i className="ri-arrow-right-line align-middle"></i>
                            </span>
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
         <div className="row">
            {tipo === 1 &&
            <div className="col-md-6">                
            <AccountEditor idAccount={adquisition.IDAccountStructurer} label="Estructurador" onChange={(acc:any)=>{                    
                setAdquisition({...adquisition,['IDAccountStructurer']:acc.IDAccount});
            }} />
        </div>
            }
           {tipo === 2 &&
            <div className="col-md-6">                
            <AccountEditor idAccount={adquisition.IDAccountReviewer} label="Revisor" onChange={(acc:any)=>{
                setAdquisition({...adquisition,['IDAccountReviewer']:acc.IDAccount});
            }}/>
        </div>
           
           }
            {tipo === 3 &&
            
            <div className="col-md-6">                
                <AccountEditor idAccount={adquisition.IDAccountApprover} label="Aprueba" onChange={(acc:any)=>{
                    setAdquisition({...adquisition,['IDAccountApprover']:acc.IDAccount});
                }}/>
            </div>
            }
        </div>
        </>
       
    )
}