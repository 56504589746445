import { useEffect, useState } from "react";
import { ContractService } from "../../core/services/ContractService";


interface Props {
    IDSuppConstantsValue: number
}
export const RenderParametrica = ({ IDSuppConstantsValue }: Props) => {


    const service = new ContractService();
    const [valor, setValor] = useState<string>('');

    useEffect(() => {
        renderValue();
    }, [IDSuppConstantsValue])


    const renderValue = () => {
        service.getSuppConstantsValue(IDSuppConstantsValue).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let val: any = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    setValor(val.Name);
                } else {
                    setValor('...');
                }
            }
        )
    }

    return (
        <p>{valor}</p>
    )
}