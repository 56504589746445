import { NotFound } from './theme/NotFound';
import { useParams } from 'react-router-dom';
import { Process } from './contract/Process';
import { CalculoInteres } from './interes/CalculoInteres';
import { ImportIntereses } from './interes/ImportIntereses';



export const RouterInteres = (props: any) => {

    const { module, view }: any = useParams();

    const renderSwitch = (url: string) => {

        switch (url) {
            case 'calcular-interes':
                return (<CalculoInteres />);
            case 'cargue-masivo':
                return (<ImportIntereses />);
            
            default:
                return <NotFound />;
        }
    }

    return (
        <>
            {renderSwitch(view)}
        </>
    );
}