import { AdminService } from "../../core/services/AdminService";
import { Singleton } from '../../core/services/Singleton';
import { BasicPage } from "../theme/Component/BasicPage"
import { useEffect, useState } from 'react';
import { BasicTable } from "../theme/Component/BasicTable";
import { BasicButton } from '../theme/Component/BasicButton';
import { Modal } from '../theme/Component/Modal';
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { AccountEditor } from "../editor/AccountEditor";
import { MyEditor } from "../theme/Component/MyEditor";
import { AttachList } from "./AttachList";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";



export const History = () => {

    const { setLoading } = useDashboardStore();
    const service: AdminService = new AdminService();
    const [lista, setLista] = useState([]);
    const [IDSoftwareProject, setIDSoftwareProject] = useState(0);
    const [listaProject, setListaProject] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAdjunto, setModalAdjunto] = useState<any>({ name: 'Documentos Soporte', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const head = [
        { title: 'ID', property: 'IDHistory' },
        { title: 'Codigo', property: 'Code' },
        { title: 'Summary', property: 'Summary' },
        { title: 'Fecha Registro', property: 'Since' },
        // {title:'State',property:'State'}
    ]

    useEffect(() => {
        let b = {
            IDHistory: null,
            Code: '',
            Summary: '',
            Detail: '',
            State: 1,
            IDRecorder: 0,
            Since: Singleton.getInstance().getCurrenDate(),
            IDSoftwareProject: IDSoftwareProject
        }
        getSoftwareProjectCatalog();

    }, [])
    useEffect(() => {

        getHistoryCatalogPorPropiedad();

    }, [IDSoftwareProject])

    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])
    useEffect(() => {

    }, [bean])


    const getHistoryCatalogPorPropiedad = () => {

        if (IDSoftwareProject > 0) {
            setLoading(true);
            service.getHistoryCatalogPorPropiedad("IDSoftwareProject", IDSoftwareProject, null).subscribe(
                (resp: any) => {
                    setLista([]);
                    console.log("Lista de historias ", resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    setLoading(false);
                }
            );
        }


    }
    const getSoftwareProjectCatalog = () => {

        setLoading(true);
        service.getSoftwareProjectCatalog().subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {
                    setListaProject(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Code: '',
            Summary: '',
            Detail: '',
            State: 1,
            IDRecorder: 0,
            Since: Singleton.getInstance().getCurrenDate(),
            IDSoftwareProject: IDSoftwareProject
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteHistory(bean.IDHistory).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getHistoryCatalogPorPropiedad();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateHistory(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    getHistoryCatalogPorPropiedad();
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }
    const setBeanAccount = (b: any) => {
        console.log("bean ", b);
        let ba = bean;
        bean.IDRecorder = b.IDAccount;
        setBean(ba);
        console.log("Actualizacion bean ", bean);

    }
    const updateAttach = (listAttach: any) => {
        let ba = bean;
        ba.Attach = JSON.stringify(listAttach);
        setBean(ba);
        console.log("Update Attch : ", listAttach);
        updateItem();

    }




    return (
        <>

            <BasicPage titulo="Historias de Usuario">
                <div className="row">
                    <div className="col-md-12">
                        <label>Proyecto</label>
                        <select className="form-control" value={IDSoftwareProject} onChange={(e) => { setIDSoftwareProject(parseInt(e.target.value)) }}>
                            <option value="0">...</option>
                            {listaProject.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.IDSoftwareProject}>{item.DataBeanProperties.Name}</option>
                                )
                            })}
                        </select>
                    </div>

                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        {IDSoftwareProject > 0 && <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>}
                        {bean.IDHistory > 0 &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={() => { editarItem() }} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }

                    </div>
                    <div className="col-md-6">

                        {bean.IDHistory > 0 &&
                            <>
                                <BasicButton value="Adjuntos" icon="ri-attachment-line" eventClick={() => {
                                    setModalAdjunto({ ...modalAdjunto, ['view']: true })
                                }} />
                            </>
                        }

                    </div>

                    <div className="col-md-12 p-2 mt-2">
                        <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm >

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Código</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => {
                            // updateProperty('Code',e.target.value)
                            setBean({ ...bean, ['Code']: e.target.value })
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Documentador</label>
                        <AccountEditor idAccount={bean.IDRecorder} label="Usuario" onChange={setBeanAccount} />
                    </div>
                    <div className="col-md-12">
                        <label >Resumen </label>

                        <MyEditor val={bean.Summary} onChange={(e: any) => { setBean({ ...bean, ['Summary']: e }) }} />

                    </div>
                </div>
            </Modal>

            <Modal modal={modalAdjunto} updateModal={setModalAdjunto}>
                <div className="mt-2">
                    <AttachList lista={bean.Attach} listAttach={updateAttach} />
                </div>
            </Modal>
        </>
    )
}