import { useEffect, useState } from "react";
import { ContractService } from "../../../../core/services/ContractService";
import { DataBeanProperties, ObjectValue } from '../../../../core/services/model/server-response.interface';
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { Singleton } from "../../../../core/services/Singleton";

interface Props {
    idAdquisitionItem?: number;
    onChage: Function;
}
export const SearchItem = ({ idAdquisitionItem, onChage }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();

    const [idItem, setIDItem] = useState<number>(0);
    const [item, setItem] = useState<any>({});
    
    const [buscar, setBuscar] = useState<any>("");

    useEffect(() => {
        if (idAdquisitionItem) {
            setIDItem(idAdquisitionItem);
        }
    }, [])
    useEffect(() => {
        if (idItem > 0)
            getItem();
    }, [idItem])

    const getItem = () => {
        single.spinner(true);
        service.getAdquisitionItem(idItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let item = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    setItem(item);
                    if(item.IDAdquisitionItem)
                        {
                            onChage(item);
                        }
                }
                single.spinner(false);
            }
        )
    }




    return (
        <div className="row">
            {/* <div className="col-md-12">
                <PrintJson json={item} />
            </div> */}
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-10">
                        <label>ID Necesidad</label>
                        <input type="number" className="form-control" value={buscar} onChange={(e) => { setBuscar(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-2 mt-3">
                        <BasicButton clase="primary" icon=""  eventClick={()=>{setIDItem(buscar)}}>Buscar</BasicButton>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                    <textarea className="form-control" value={item.Description}></textarea>
                    </div>
                </div>

                
            </div>
        </div>
    )
}