import { BpmService } from "../../../../core/services/BpmService"
import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { BasicTable } from "../../../theme/Component/BasicTable"
import { Modal } from "../../../theme/Component/Modal"
import { ModalConfirm } from "../../../theme/Component/ModalConfirm"
import { ListMenu } from "../../../theme/Component/ListMenu";
import { SelectTrdsID } from "../../../admin/configuracion/TrdID/SelectTrdsID";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

export const DocSupplierConfig = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({
        Name: '',
        State: 1,
        Description: '',
        Validity: 0,
    });

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])



    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getDocSupplierCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
            }
        );
    }

    const updateItem = (Dbean: any) => {

        setLoading(true);
        service.updateDocSupplier(Dbean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        );
        setModal({ ...modal, ['view']: false });
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    // const deleteItem2 =()=>{
    //     setLoading(true);

    //     service.deleteDocSupplier(bean[idBean]).subscribe(
    //         (resp:any)=>{
    //             setLoading(false);
    //             if(resp) {
    //                 getLista();
    //             }
    //             single.delete(bean[idBean],bean);
    //         }
    //     );
    //     setModalConfirm({...modalConfirm,['view']:false});
    // }

    const updatebean = () => {
        if (bean[idBean]) {
            let u = bean;
            u.State = 1;
            console.log(u);
            updateItem(u);
        } else {
            let a = bean;
            a.State = 1;
            console.log(a);
            updateItem(a);
        }
    }

    const deletItem = () => {
        let e = bean;
        e.State = 2;
        console.log(e);
        updateItem(e);
    }

    const addItem = () => {
        let x = {
            Name: '',
            State: 1,
            Description: '',
            Validity: 0,
        }
        setBean(x);
        setModal({ ...modal, ['view']: true, name: 'Agregar' });
    }

    const idBean = 'IDDocSupplier';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Dias de Validez', property: 'Validity' },
        { title: 'Fecha', property: 'Since' },
        { title: 'TRD', property: 'IDLnTRDName' },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, ['view']: true, name: 'Editar' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canEdit(), evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true, name: 'Eliminar' }) } },
        ]
        return (
            <ListMenu listaBotones={botones}></ListMenu>
        )
    }

    const iconButtonsItem = [
        { nombre: 'agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: addItem }
    ]

    const mostrar = () => {
        console.log(bean);
    }

    return (
        <>
            <BasicPage titulo="Documentos de Proveedores">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deletItem}>
                {bean.Name}
            </ModalConfirm>
            <Modal modal={modal} updateModal={setModal} eventModal={updatebean}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Dias de validez</label>
                            <input type="number" className="form-control" value={bean.Validity} onChange={(e) => { setBean({ ...bean, ['Validity']: e.target.value }) }}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <SelectTrdsID edit={false} onChange={(e: any) => { setBean({ ...bean, "IDLnTRD": e.IDLn }); }} renderValue={bean.IDLnTRD} ></SelectTrdsID>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}