import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { useState, useEffect } from "react";
import { Modal } from "../../theme/Component/Modal";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectorMenuTypeConstants } from "../../bpm/config/Componentes/SelectorMenuTypeConstants";
import { SelectorAplication } from "../../bpm/config/Componentes/SelectorAplication";
import { SelectorBusinessLogicTypeConstants } from "../../bpm/config/Componentes/SelectorBusinessLogicTypeConstants";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const ApplicationType = (props: any) => {
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service: AdminService = props.service;
  const [bean, setBean] = useState<any>({});
  const [lista, setLista] = useState([]);
  const [idApplication, setIDApplication] = useState(0);
  const [valueMenu, setValueMenu] = useState(0);
  const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
  const [modal1, setModal1] = useState<any>({ name: "Editar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

  const idBean = "IDApplicationType";

  useEffect(() => {
    getLista();
  }, [valueMenu, idApplication]);

  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  const getLista = () => {
    setLoading(true);
    service.getApplicationTypeCatalogByMenuTypeandIdAplication(valueMenu, idApplication).subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {
        setLista(resp.DataBeanProperties.ObjectValue);
      }
      setLoading(false);
    });
  }

  const updateApplicationType = () => {
    setModal1({ ...modal1, ["view"]: false });
    console.log("update ", bean);
    setLoading(true);
    service.updateApplicationType(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
    });
  };

  const eliminarItem = () => {
    setLoading(true);
    console.log(bean);
    service.deleteApplicationType(bean[idBean]).subscribe((resp: any) => {
      setLoading(false);
      if (resp) {
        getLista();
      }
    });
    setModalConfirm({ ...modalConfirm, ["view"]: false });
  };

  const setNuevoMenu = () => {
    let temp = {
      type: 0,
      Name: "",
      State: 1,
      Purpose: "",
      ClassForName: "",
      IDApplication: idApplication,
      MenuType: valueMenu,
    };
    setBean(temp);
    setModal1({ ...modal1, "view": true });
  };

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "icono", property: "ClassForName" },
    { title: "Proposito", property: "Purpose" },
  ];
  const headDocument = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "Nombre del menu", property: "MenuValueName" },
    { title: "llave", property: "MenuKeyName" },
  ];

  const listaBotones = () => {
    const botones = [
      { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal1({ ...modal1, view: true }); }, },
      { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }); }, },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: setNuevoMenu },
  ];

  return (
    <>
      <BasicPage titulo="">
        <div className="row">
          <div className="col-md-5">
            <SelectorMenuTypeConstants idSelector={valueMenu} onChange={(e: any) => { setValueMenu(parseInt(e)) }}></SelectorMenuTypeConstants>
          </div>
          <div className="col-md-7">
            <SelectorAplication idSelector={idApplication} onChange={(e: any) => { setIDApplication(parseInt(e)) }}></SelectorAplication>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={valueMenu ? headDocument : head} body={lista} rowSet={setRowBean} />
          </div>
        </div>
      </BasicPage>

      <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem} >
        {bean.Name}
      </ModalConfirm>

      <Modal modal={modal1} updateModal={setModal1} eventModal={updateApplicationType} >
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Nombre</label>
            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
          </div>
          <div className="col-md-12">
            <label className="form-label">Icono</label>
            <input type="text" className="form-control" value={bean.ClassForName} onChange={(e) => { setBean({ ...bean, "ClassForName": e.target.value }); }} />
          </div>
          <div className="col-md-12">
            <label className="form-label">Proposito</label>
            <textarea className="form-control" value={bean.Purpose} onChange={(e) => { setBean({ ...bean, "Purpose": e.target.value }); }} ></textarea>
          </div>
          <div className="col-md-12">
            <SelectorBusinessLogicTypeConstants idSelector={bean.Type} onChange={(e) => { setBean({ ...bean, "Type": e }) }}></SelectorBusinessLogicTypeConstants>
          </div>
        </div>
      </Modal>
    </>
  );
};