import { AccountEditor } from "../../../editor/AccountEditor";
import { Modal } from "../../../theme/Component/Modal";
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';
import { Alert } from "../../../theme/Component/Alert";
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Singleton } from "../../../../core/services/Singleton";
import { BpmServiceall } from "../../../../core/services/BpmServiceall";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { SelectorOffice } from "../../../bpm/config/Componentes/SelectorOffice";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface FuntionalID {
    id: number;
    bean: any;
    updateEvent: any;

}
export const ButtonFuntionalID = ({ id, bean, updateEvent }: FuntionalID) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const bpmService = new BpmServiceall();
    const service = new FinanceService();
    const [workersModal, setworkersModal] = useState<any>({ name: 'Personal en el área', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [confirm, setConfirm] = useState<any>({ name: 'Atención!', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [confirm2, setConfirm2] = useState<any>({ name: 'Confirmación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAdd, setModalAdd] = useState<any>({ name: 'Agregar Item', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [miBean, setMibean] = useState<any>({});
    const [code, setCode] = useState('');
    const [nombre, setNombre] = useState('');
    const [message, setMessage] = useState('');
    const [workersList, setWorkersList] = useState([])
    const [idOffice, setIdOffice] = useState(0)
    const [toDelete, setToDelete] = useState(-1)

    useEffect(() => {
        setMibean(bean);
    }, [])

    const updateBean = () => {
        service.updateFunctionalID(miBean).subscribe(
            (resp: any) => {
                console.log("Update : ", resp);
                updateEvent();
                setModal({ ...modal, 'view': false });
                single.update(miBean);
            }
        );

    }
    const updateBoss = (account: any) => {
        setMibean({ ...miBean, 'IDBoss': account.IDAccount });
    }
    const updateDistributionChannel = (account: any) => {
        setMibean({ ...miBean, 'DistributionChannel': account.IDAccount });
    }
    const createBean = () => {
        if (code != '' && nombre != '') {
            let separador = localStorage.getItem('separador') || '-';
            let arr: any[] = code.split(separador);
            if (arr.length == 0) {
                console.log('error')
            }
            if (arr.length > 0) {
                let tmp: number[] = [];
                arr.forEach(element => {
                    tmp.push(parseInt(element));
                });
                arr = tmp;
            }
            service.createFunctionalID(arr, nombre).subscribe(
                (resp: any) => {
                    console.log("Crear Item ", resp);
                    updateEvent();
                    single.create(miBean);
                }
            );
            setModalAdd({ ...modalAdd, 'view': false });
        } else {
            setMessage('Codigo y Nombre son obligatorios');
        }
    }

    const deleteFunctionalID = () => {
        service.deleteFunctionalID(bean.IDLn).subscribe(
            (resp: any) => {
                updateEvent();
                setConfirm({ ...confirm, 'view': false });
                single.delete(bean.IDLn, miBean);
            }
        )
    }

    const getMemberList = (_idOffice: number) => {
        setLoading(true)
        bpmService.getWorkGroupMemberCatalog(miBean.IDLn, _idOffice)
            .subscribe((resp: any) => {
                setLoading(false)
                console.log("This is it", resp.DataBeanProperties.ObjectValue);
                setWorkersList(resp.DataBeanProperties.ObjectValue)
            })
    }

    const addMember = (selectedMember: any) => {
        setLoading(true)
        bpmService.addWorkGroupMember(miBean.IDLn, selectedMember.IDAccount, idOffice)
            .subscribe((resp) => {
                setLoading(false)
                console.log("Añadido", resp);
                getMemberList(idOffice)
            })
    }

    const removeWorkGroupMember = () => {
        setLoading(true)
        bpmService.removeWorkGroupMember(miBean.IDLn, toDelete, idOffice)
            .subscribe((resp) => {
                setLoading(false)
                getMemberList(idOffice)
                setConfirm2({ ...confirm2, 'view': false })
            })

    }

    const head = [
        { title: 'ID', property: 'IDAccount' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
    ]

    const takeOutButton = () => {
        const botones = [
            { titulo: 'Retirar de la lista', icono: 'ri-file-user-line', visible: true, evento: () => { setConfirm2({ ...confirm2, 'view': true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            {single.canEdit() &&
                <i className="ri-file-user-line cursor btnicon_sm" title="Ver personal" onClick={() => {
                    setworkersModal({ ...workersModal, 'view': true })
                }}></i>
            }
            {single.canCreate() &&
                <i className="ri-file-add-line cursor btnicon_sm" title="Agregar Hijo" onClick={() => {
                    setCode(bean.FunctionalViewCode);
                    setModalAdd({ ...modalAdd, 'view': true });
                }}></i>
            }
            {single.canDelete() &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => setConfirm({ ...confirm, 'view': true })}></i>
            }
            {single.canEdit() &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    setModal({ ...modal, 'view': true });
                }}></i>
            }
            <Modal modal={workersModal} updateModal={setworkersModal} eventModal={() => setworkersModal({ ...workersModal, 'view': false })}>
                <div className="row">
                    <div className="col-md-12">
                        <h4>{miBean.Name}</h4>
                    </div>
                    <div className="col-md-6 justify-content-end">
                        <SelectorOffice idSelector={idOffice} titulo="En la sucursal" onChange={(e: any) => { setIdOffice(parseInt(e)); getMemberList(parseInt(e)) }} />
                    </div>

                    <div className="col-md-6 justify-content-end">
                        <AccountEditor disabled={idOffice == 0} idAccount={miBean.IDBoss} label="Añadir personal" onChange={addMember} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 m-3 mt-5">
                        <BasicTable listButtons={takeOutButton()} head={head} body={idOffice === 0 ? [] : workersList} rowSet={(bean: any) => {
                            setToDelete(bean.IDAccount);
                            setModal({ ...modal, 'view': false });
                        }} ></BasicTable>
                    </div>
                </div>
            </Modal >
            <Modal modal={modal} updateModal={setModal} eventModal={updateBean}>
                <div className="row">
                    <div className="col-md-6">
                        <AccountEditor idAccount={miBean.IDBoss} label="Jefe" onChange={updateBoss} />
                    </div>
                    <div className="col-md-6">
                        <AccountEditor idAccount={miBean.DistributionChannel} label="Canal Distribución" onChange={updateDistributionChannel} />
                    </div>
                    <div className="col-md-6">
                        <label>Nombre</label>
                        <input type="text" className="form-control" value={miBean.Name} onChange={(e) => { setMibean({ ...miBean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Email Notificaciones</label>
                        <input type="text" className="form-control" value={miBean.EmailForNotifications} onChange={(e) => { setMibean({ ...miBean, 'EmailForNotifications': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Descripción</label>
                        <textarea className="form-control" value={miBean.Description} onChange={(e) => { setMibean({ ...miBean, 'Description': e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>
            <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={createBean}>
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="code">Código
                            <input type="text" className="form-control" value={code} onChange={(e) => { setCode(e.target.value) }} />
                        </label>
                        <label htmlFor="code">Nombre
                            <input type="text" className="form-control" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                        </label>
                    </div>
                    <div className="col-md-12">
                        {message !== '' &&
                            <Alert clase="warning">
                                <strong>Parametros</strong> {message}
                            </Alert>}
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={confirm} updateModal={setConfirm} eventModal={deleteFunctionalID}>
                <></>
            </ModalConfirm>
            <ModalConfirm modal={confirm2} updateModal={setConfirm2} eventModal={removeWorkGroupMember}>
                <></>
            </ModalConfirm>
        </>
    )
}