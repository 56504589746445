import { useState, useEffect, useRef } from 'react';
import { Modal } from '../theme/Component/Modal';
import { Singleton } from '../../core/services/Singleton';
import { AdminService } from '../../core/services/AdminService';
import { BasicTable } from '../theme/Component/BasicTable';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
import { toast } from 'react-toastify';
import { BpmService } from '../../core/services/BpmService';
import { DataBeanProperties } from '../../core/services/model/server-response.interface';

interface Editor {
    idAccount: number | null;
    label: string | undefined;
    disabled?: boolean
    onChange: any;
    canCreate?: boolean
}

const head = [
    { title: 'ID', property: 'IDAccount', visible: false },
    { title: 'Identificacion', property: 'Nit' },
    { title: 'Nombre', property: 'EntityName' },
]

export const AccountEditor = ({ idAccount, label, onChange, disabled, canCreate }: Editor) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service: AdminService = new AdminService();
    const serviceBPM = new BpmService();
    const [valor, setValor] = useState(idAccount);
    const [nit, setNit] = useState('');
    const [entityName, setEntityName] = useState('');
    const [bean, setBean] = useState<any>({});
    const [beanAcc, setBeanAcc] = useState<any>({});


    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Buscar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalAdd, setModalAdd] = useState<any>({ name: 'Crear Usuario', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


   
    useEffect(() => {
        setEntityName('');
        getAccountByID(idAccount);
        return () => {
            setLista([]);
            setNit('');
            // idAccount=0;
        }
    }, [idAccount])
 

    useEffect(() => {

        setEntityName(bean.EntityName);
        setNit(bean.EntityName);
        setValor(bean.IDAccount);

    }, [bean])


    const getAccountByID = (id: number | null) => {
        setLoading(true);
        service.getAccountByID(id).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let obj: any = resp.DataBeanProperties.ObjectValue;
                    setBean(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                setLoading(false);
            }
        )
    }

    const getAccount = () => {
        if (nit.length > 3) {
            setLoading(true);
            
            if (!isNaN(parseInt(nit))) {
                service.getAccountByNit(parseInt(nit)).subscribe(
                    (resp: any) => {
                        let list = [];
                        if (resp.DataBeanProperties.ObjectValue) {
                            list = resp.DataBeanProperties.ObjectValue;
                        }
                        if(list.length==0)
                            toast.success("No se encontraron resultados");
                        setLista(list);
                        setLoading(false);
                    }
                );
            } else {
                let listaTemp: any = [];
                setLoading(true);
                service.getAccountByName(nit, null).subscribe(
                    (resp: any) => {
                       
                        if (resp.DataBeanProperties.ObjectValue) {
                            
                            listaTemp = resp.DataBeanProperties.ObjectValue;

                            service.getAccountByName(null, nit).subscribe(
                                (resp: any) => {
                                    if (resp.DataBeanProperties.ObjectValue) {
                                        Array.prototype.push.apply(listaTemp, resp.DataBeanProperties.ObjectValue);

                                    }
                                    if(listaTemp.length==0)
                                        toast.success("No se encontraron resultados");
                                    setLista(listaTemp);
                                }
                            );
                        }
                        setLoading(false);
                    }
                );
            }
        }else{
            toast.error("De especificar un documento de identidad.");
        }
    }

    const agregarItem = () => {

        let b = {
            Nit: nit,
            Name1: '',
            Name2: '',
            Surname1: '',
            Surname2: '',
            Tel: undefined,
            eMail: '',
        }
        setBeanAcc(b);
        setModalAdd({ ...modalAdd, 'view': true });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: canCreate, icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const updateItem = () => {
        console.log(beanAcc)
        
        if(beanAcc.Nit>0)
            {
                setLoading(true);
                serviceBPM.createAccount(beanAcc).subscribe(
                    (resp: any) => {
                       
                        if (resp.DataBeanProperties.ObjectValue) {
                            
                            setNit(beanAcc.Nit);
                            toast.success("Se ha creado el usuario")
                            setModalAdd({ ...modalAdd, "view": false });
                           getAccount();
                        }
                        setLoading(false);
                    });
            }
        else{
            toast.error("No ha especificado un valor valido para documento de identidad.");
        }
       
    }

    return (
        <>
            <label >{label ? label : 'Usuario'}</label>
            <input type="text" className="form-control" aria-label="Recipient's username"
                disabled={disabled}
                aria-describedby="button-addon2" placeholder='Buscar...'
                value={entityName}
                onClick={() => { setModal({ ...modal, 'view': true }); }}
                onChange={() => {

                }}
            />

            <Modal modal={modal} updateModal={setModal} >
                <div className="row">
                    <div className="col-md">
                        <div className="input-group">
                            <input placeholder='Identificación / Nombre / Apellido ' type="text" name="identificacion" id="Identificacion" className='form-control text-uppercase' value={nit} onChange={(e) => { setNit(e.target.value) }} />
                            <button className="btn btn-success" type="button" onClick={getAccount}>Buscar</button>
                            <button className="btn btn-danger" type="button" onClick={() => { setNit('') }}>Limpiar</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-4">
                        <BasicTable iconButtons={iconButtonsItem} head={head} body={lista} rowSet={(bean: any) => {
                            onChange(bean);
                            setBean(bean);
                            setModal({ ...modal, 'view': false });
                            // setEntityName(bean.EntityName);
                        }} ></BasicTable>
                    </div>
                </div>
            </Modal>

            <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={updateItem} >
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">No. Identificación *</label>
                        <input type="number" className="form-control" value={beanAcc.Nit} onBlur={(e) => {                            
                        }} onChange={(e) => {
                            setBeanAcc({ ...beanAcc, 'Nit': parseInt(e.target.value) })
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Nombre *</label>
                        <input type="text" className="form-control" value={beanAcc.Name1} onChange={(e) => { setBeanAcc({ ...beanAcc, 'Name1': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Segundo Nombre </label>
                        <input type="text" className="form-control" value={beanAcc.Name2} onChange={(e) => { setBeanAcc({ ...beanAcc, 'Name2': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Primer Apellido *</label>
                        <input type="text" className="form-control" value={beanAcc.Surname1} onChange={(e) => { setBeanAcc({ ...beanAcc, 'Surname1': e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Segundo Apellido *</label>
                        <input type="text" className="form-control" value={beanAcc.Surname2} onChange={(e) => { setBeanAcc({ ...beanAcc, 'Surname2': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Celular *</label>
                        <input type="number" className="form-control" value={beanAcc.Tel} onChange={(e) => { setBeanAcc({ ...beanAcc, 'Tel': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Correo Electronico *</label>
                        <input type="text" className="form-control" value={beanAcc.eMail} onChange={(e) => { setBeanAcc({ ...beanAcc, 'eMail': e.target.value }) }} />
                    </div>
                    {/* <div className="col-md-12">                    
                        <label className="form-label">Lugar de expedición de documento *</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e)=>{setBean({...bean,['Name']:e.target.value})}}/>
                    </div>                                                                                       */}

                </div>
            </Modal>
        </>
    )
}