import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

export const useCreditCampaign = () => {
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new ForpoService();
  const [lista, setLista] = useState([]);
  const [procedureType, setProcedureType] = useState<number>(null)
  const [procedureType2, setProcedureType2] = useState<number>(null)
  const [listausers, setlistausers] = useState([]);
  const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
  const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
  const [modalComite, setModalComite] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
  const [bean, setBean] = useState<any>({
    Name: "",
    Descripcion: "",
    State: 1,
    InitDate: single.getCurrenDate(),
    EndDate: '',
    BudgetValue: 0,
    IDEmployee: single.getAccountID(),
    QuotaRange: 1,
    MinValue: 0.0,
    MaxValue: 0.0,
    ScaleValue: 0.0,
    RateValue: 0.0,
    Since: "",
    TypeCampaign: 0,
  });


  useEffect(() => {
    getLista();
    getListauser();
  }, []);


  const idBean = "IDCreditCampaign";

  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  const getLista = () => {
    setLoading(true);
    service.getCreditCampaignCatalogPorPropiedad('State', 1, null).subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "Descripción", property: "Description" },
    { title: "Valor de la tasa", property: "RateValue" },
    { title: "Escala de valores", property: "ScaleValue" },
    { title: "Valor minimo a prestar", property: "MinVal" },
    { title: "Valor maximo a prestar", property: "MaxVal" },
    { title: "Rango de cuotas", property: "QuotaRange" },
    { title: "Valor estimado", property: "BudgetValue" },
    { title: "Uso de campaña", property: "TypeCampaign" },
  ];

  const agregarItem = () => {
    let b = {
      Name: "",
      Description: "",
      State: 1,
      InitDate: single.getCurrenDate(),
      EndDate: '',
      BudgetValue: 0,
      IDEmployee: single.getAccountID(),
      QuotaRange: 1,
      MinVal: 0.0,
      MaxVal: 0.0,
      ScaleValue: 0.0,
      RateValue: 0.0,
      TypeCampaign: 0,
    };
    setBean(b);
    setModal({ ...modal, ["view"]: true, name: "Agregar" });
  };

  const editarItem = () => {
    setModal({ ...modal, ["view"]: true, name: 'Editar' });
  };

  const eliminarItem = () => {
    bean.State = 2;
    console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, ['view']: false });
  }

  const updateItem = () => {
    setLoading(true);
    service.updateCreditCampaign(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      if (bean.idBean > 0) {
        single.update(bean);
      } else {
        single.create(bean);
      }
    });
    setModal({ ...modal, ["view"]: false });
  };

  const mostrar = () => {
    console.log(bean);
  }

  const getListauser = () => {
    setLoading(true);
    service
      .getRoleCommitteeCatalogPorPropiedad("State", 1, null)
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          let temp = resp.DataBeanProperties.ObjectValue;
          const arr = temp.map((e, i) => ({
            id: e.DataBeanProperties.IDRoleCommittee,
            name: e.DataBeanProperties.Name
          }));
          setlistausers(arr);
        }
        setLoading(false);
      });
  };
  // const getListauser = () => {
  //   setLoading(true);
  //   service
  //     .getRoleCommitteeCatalogPorPropiedad("State", 1, null)
  //     .subscribe((resp: any) => {
  //       if (resp.DataBeanProperties.ObjectValue) {
  //         let temp = resp.DataBeanProperties.ObjectValue;
  //         const arr = temp.map((e, i) => ({
  //           id: e.DataBeanProperties.IDRoleCommittee,
  //           name: e.DataBeanProperties.Name
  //         }));
  //         setlistausers(arr);
  //       }
  //       setLoading(false);
  //     });
  // };


  const iconButtonsItem = [
    { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
  ];

  return {
    single,
    service,
    lista,
    setLista,
    bean,
    setBean,
    modal,
    modalComite,
    setModal,
    setModalComite,
    modalConfirm,
    setModalConfirm,
    idBean,
    updateItem,
    head,
    iconButtonsItem,
    editarItem,
    agregarItem,
    eliminarItem,
    setRowBean,
    listausers,
    mostrar,
    procedureType,
    procedureType2,
    setProcedureType,
    setProcedureType2
  };
};
