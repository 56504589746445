import { useEffect, useState } from "react";
import { OfficeService } from "./Office-service"
import { DataBeanProperties, ObjectValue } from '../../../../core/services/model/server-response.interface';
import { toast } from "react-toastify";
import { BasicTable } from "../../../theme/Component/BasicTable";

interface Props {
    id: number;
    onchange: Function;
    idconpany?: number;
}
export const OfficeEditor = ({ id, onchange }: Props) => {


    const service = new OfficeService();
    const [lista, setLista] = useState<any>([]);
    const [idLista, setIDLista] = useState<number>(null);

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        if (id) {
            setIDLista(id);
        }
    }, [id])

    useEffect(() => {
        if (id != idLista) {
            onchange(idLista);
        }
    }, [idLista])

    const getLista = () => {
        // service.spinON();
        service.getOfficeCatalog(idLista ? idLista : null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                // service.spinOFF();
            }
        )
    }

    return (
        <>
            <label>Oficina: </label>
            <select className="form-select" name="AccountantBook" value={idLista} onChange={(e) => setIDLista(parseInt(e.target.value))}>
                <option value={null}>....</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDOffice}>{item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}