import { useState } from 'react';
import { useEffect } from 'react';
import { InputDate } from '../theme/Input/InputDate';
import { BasicButton } from '../theme/Component/BasicButton';

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const VerificacionInfo = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});

    useEffect(() => {
        if (formSupplier.forma8 === undefined)
            formSupplier.forma8 = {};
        setFormulario(formSupplier.forma8);
    }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        temp.forma8 = formulario;
        // console.log(temp);

        updateInfo(temp);
    }

    return (
        <>
            <h5 className='titul-form' style={{ textAlign: 'center' }}>Verificación de Información Consignada en Este Formato</h5>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <label className='titul-form'>Nombre Completo del funcionario:</label>
                    <input type="text" className='form-control' value={formulario.nombrefuncioinfo} onChange={(e) => setFormulario({ ...formulario, "nombrefuncioinfo": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>No. Cedula de Ciudadania del funcionario:</label>
                    <input type="text" className='form-control' value={formulario.ciudadfuncionarioinfo} onChange={(e) => setFormulario({ ...formulario, "ciudadfuncionarioinfo": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Fecha de consulta - dd/mm/año</label>

                    <InputDate name='f1' label='' value={formulario.fechacreacioninfo} setDate={(e: any) => setFormulario({ ...formulario, "fechacreacioninfo": e })} />
                </div>
            </div>
            {/* <PrintJson json={formulario}/>
            <PrintJson json={supplier}/> */}

            <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
        </>
    )
}