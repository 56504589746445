import { useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { FactorMatrixCategory } from '../../interfaces'
interface Props {
    index: number,
    _subIndex: number,
    description: string,
    value: boolean,
    handleChange: () => void
    register: UseFormRegister<{ factors: FactorMatrixCategory[] }>
}
export const FactorCheck = ({ value, _subIndex, description, index, register, handleChange }: Props) => {

    const [isActive, setIsActive] = useState(value)

    return (
        <div key={_subIndex} className='d-flex align-items-start justify-content-star'>
            <div className="form-check">
                <input className="form-check-input" type="checkbox"
                    checked={isActive}
                    {...register(`factors.${index}.Child.${_subIndex}.isActive`)}
                    onChange={(e) => {
                        console.log(e.target.checked)
                        setIsActive(prev => !prev); handleChange()
                    }}
                />
            </div>
            <small>{description}</small>
        </div>
    )
}
