import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    idSelector: number;
    onChange: any;
}
export const SelectLegalRequirementTypeConstants = ({ idSelector, onChange }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        let cache = Singleton.getInstance().getCacheItem("LegalRequirementTypeConstants");
        if (cache) {
            setLista(cache);
        }
        else {
            service.getLegalRequirementTypeConstants().subscribe(
                (resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        Singleton.getInstance().setCacheItem("LegalRequirementTypeConstants", resp.DataBeanProperties.ObjectValue);
                    }
                }
            );
        }

    }

    return (
        <>
            <label>Tipo</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0} key={single.getRandomInt()}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <>
                                <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}