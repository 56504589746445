import { useEffect, useState } from "react";
import { BpmService } from "../../../core/services/BpmService";
import { Singleton } from "../../../core/services/Singleton";
import { useDashboardStore } from "../hooks/useDashboardStore";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { InputDate } from "../../theme/Input/InputDate";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { SelectTHConstantsValue } from "../../bpm/config/Componentes/SelectTHConstantsValue";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";


export const LegalMatrix = () => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const single = Singleton.getInstance();
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const idBean = 'IDLegalMatrix';

    const head = [
        { title: 'ID', property: idBean, visible: true },
        { title: 'Año de la Matriz', property: "YearMatrix", visible: true },
        { title: 'Tipo de riesgo', property: "RiskType", visible: true },
        { title: 'Numero de la norma', property: "RegulationNumber", visible: true },
        { title: 'Fecha de expedción', property: "ExpDate", visible: true },
        { title: 'Descripción de la norma', property: "Description", visible: true },
        { title: 'Articulo aplicable', property: "ValidArticle", visible: true },
        { title: 'Obligación', property: "Obligation", visible: true },
    ]

    const getLista = () => {
        setLoading(true);
        service.getLegalMatrixCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    setLista(tmp);
                }
                setLoading(false);
            }
        );
    }


    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    const agregarItem = () => {
        let b = {
            YearMatrix: single.getCurrenYear(),
            RiskType: "",
            IDLocation: 0,  //503
            IDAuthor: 0, //504
            IDRegulation: 0,//505
            RegulationNumber: 0,
            ExpDate: single.getCurrenDate(),
            IDRegulationState: 0, //506
            Description: "",
            ValidArticle: "",
            Obligation: "",
            IDFunctionalID: "",
            State: 1,
        }

        setBean(b);
        setModal({ ...modal, 'view': true, "name": "Agregar Matriz" });
    }

    const eliminarItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateLegalMatrix(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.IDGroupTemplate) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar Matriz" }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Matriz Legal">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <YearSelector tittle="Año de la matriz" value={bean.YearMatrix} onChange={(year) => { setBean({ ...bean, "YearMatrix": year }) }}></YearSelector>
                    </div>
                    <div className="col-md-6">
                        <SelectFunctionalID renderValue={bean.IDFunctionalID} onChange={(area: any) => { setBean({ ...bean, "IDFunctionalID": area.IDLn }) }}></SelectFunctionalID>
                    </div>
                    <div className="col-md-12">
                        <label>Tipo de riesgo</label>
                        <textarea value={bean.RiskType} onChange={(risk) => { setBean({ ...bean, "RiskType": risk.target.value }) }} className="form-control"></textarea>
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Ambitos de aplicación" code={503} value={bean.IDLocation} onChange={(location: any) => { setBean({ ...bean, "IDLocation": location }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Emisor" code={504} value={bean.IDAuthor} onChange={(autor: any) => { setBean({ ...bean, "IDAuthor": autor }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Tipo de Normatividad aplicable" code={505} value={bean.IDRegulation} onChange={(regulacion: any) => { setBean({ ...bean, "IDRegulation": regulacion }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <label>Numero de la Norma</label>
                        <input className="form-control" value={bean.RegulationNumber} onChange={(reguation) => { setBean({ ...bean, "RegulationNumber": reguation.target.value }) }}></input>
                    </div>
                    <div className="col-md-6">
                        <InputDate label="Fecha de Expedición" name="ExpDate" value={bean.ExpDate} setDate={(fecha: any) => { setBean({ ...bean, "ExpDate": fecha }) }}></InputDate>
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Vigencia de la Norma" code={506} value={bean.IDRegulationState} onChange={(regulstate: any) => { setBean({ ...bean, "IDRegulationState": regulstate }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-12">
                        <label>Descrición de la norma</label>
                        <textarea value={bean.Description} onChange={(descrip) => { setBean({ ...bean, "Description": descrip.target.value }) }} className="form-control"></textarea>
                    </div>
                    <div className="col-md-12">
                        <label>Articulo aplicable a la norma</label>
                        <textarea value={bean.ValidArticle} onChange={(varticle) => { setBean({ ...bean, "ValidArticle": varticle.target.value }) }} className="form-control"></textarea>
                    </div>
                    <div className="col-md-12">
                        <label>Descripción de la obligación</label>
                        <textarea value={bean.Obligation} onChange={(obligation) => { setBean({ ...bean, "Obligation": obligation.target.value }) }} className="form-control"></textarea>
                    </div>
                </div>
            </Modal>
        </>
    )
}