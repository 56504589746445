import { useState } from 'react'
import check from '../../../../../assets/dashboard/success.svg'
import { Singleton } from '../../../../../core/services/Singleton'

interface Props {
    title: string
    message: string
    affiliate?: any
    exportMap: Function
    credit?: any
    updateCredit: Function
    closeModal?: Function
}
export const SaveToken = ({ title, exportMap, affiliate, credit, updateCredit, closeModal }: Props) => {

    const [viewer, setViewer] = useState(0);
    const single = Singleton.getInstance();

    const onExportMap = (value: boolean) => {
        exportMap({
            APROBO: value,
            GRABADO: "true"
        });
        updateToken();
    }

    const updateToken = () => {
        let x = { ...credit, TokenDate: single.getCurrenDate() }
        console.log(x)
        updateCredit(x);
    }

    return (
        <>
            {viewer === 0 &&
                <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                    <div className='my-3'>
                        <strong>{title}</strong>
                    </div>
                    {credit && affiliate &&
                        <div className='text-muted' style={{ width: '80%' }}>
                            <small>
                                Esta seguro que desea Grabar el token: <br />
                                <strong>{credit.TokenCredit}</strong><br />
                                al afiliado:<br />
                                <strong> {affiliate.Name1 + " " + affiliate.Name2 + " " + affiliate.Surname1 + " " + affiliate.Surname2}</strong>
                            </small>
                        </div>
                    }

                    <div className='w-50 d-flex justify-content-between my-3'>
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { closeModal(); }}>
                            NO
                        </button>
                        <button type="button" className="btn btn-primary " onClick={() => { setViewer(1); onExportMap(true) }}>
                            SI
                        </button>
                    </div>
                </div>
            }
            {viewer === 1 &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>APROBADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
        </>
    )
}
