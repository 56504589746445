import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { ForpoService } from "../../custom/forpo/components/service/ForpoService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const useAfiliate = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const idBean = 'IDAffiliate';
    const [bean, setBean] = useState<any>({
        Name1: "",
        Name2: "",
        Nit: 0,
        Surname1: '',
        Surname2: '',
        Tel: '',
        eMail: '',
    });
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });


    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombres', property: 'AccountName' },
        { title: 'Identificación', property: 'Nit' },
        { title: 'Telefono', property: 'Tel' },
        { title: 'Correo', property: 'eMail' },

    ]



    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        setBean(bean);
        console.log(bean)
    }

    const getLista = () => {
        setLoading(true);
        service.getAffiliateRender(null, null, {}).subscribe((resp: any) => {
            if (resp.DataBeanProperties.ObjectValue) {
                let list = resp.DataBeanProperties.ObjectValue;
                list.forEach((ele: any) => {

                    ele.DataBeanProperties.AccountName =
                        (ele.DataBeanProperties.Name1 ?? '') + " " +
                        (ele.DataBeanProperties.Name2 ?? '') + " " +
                        (ele.DataBeanProperties.Surname1 ?? '') + " " +
                        (ele.DataBeanProperties.Surname2 ?? '')

                    setLista(list);
                });
            }
            setLoading(false);
        });
    }

    const agregarItem = () => {
        let b = {
            Name1: "",
            Name2: "",
            Nit: 0,
            Surname1: '',
            Surname2: '',
            Tel: '',
            eMail: '',
        };
        setBean(b);
        setModal({ ...modal, ["view"]: true, name: "Agregar afiliado" });
    }


    const update = () => {
        console.log(single.getAccountID(), bean)

        setModal({ ...modal, ["view"]: false });

        setLoading(true);
        service.updateAffiliatebyEmployee(single.getAccountID(), bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });

    }

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        iconButtonsItem,
        bean,
        setBean,
        head,
        setRowBean,
        getLista,
        update,
        lista,
        setLista,
        modal,
        setModal
    }
}