import { useEffect, useState } from "react";
import { useDashboardStore } from "../../modules/pages/hooks/useDashboardStore";

export const Spinner = () => {

    const { loading } = useDashboardStore()
    const [spin, setSpinner] = useState(false);

    useEffect(() => {
        window.addEventListener('storage', function () {
            let event = JSON.parse(localStorage.getItem('eventos') || "{}");
            setSpinner(event.spin);
        });
        return () => {
            window.removeEventListener('storage', null)
        }
    }, [])

    return (
        <>
            {(loading || spin) &&
                <div className='d-flex justify-content-center' style={{ position: 'fixed', width: '100vw', height: '100vh', background: '#00000080', zIndex: '99999' }}>
                    <div id="status">
                        <div role="status">
                            <span className="__loader"></span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}