import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../theme/NotFound";
import { Singleton } from "../../core/services/Singleton";
import { RouterAdmin } from "../admin/RouterAdmin";
import { RouterContract } from "../RouterContract";
import { RouterBpm } from "../RouterBpm";
import { RouterSupplier } from "../RouterSupplier";
import { MenuModulo } from "../theme/menu/MenuModulo";
import { NotPermision } from "../theme/NotPermision";
import { AuditService } from "../../core/services/AuditService";
import { Dashboard } from "../theme/Dashboard";
import { RouterAdminService } from "../RouterAdminService";
import { RouterInteres } from "../RouterInteres";
import { ToastContainer } from "react-toastify";
import { ForpoRouter } from "../custom/forpo/ForpoRouter";
import { HamburguerMenu } from "../admin/menu/HamburguerMenu";
import { useDashboardStore } from "./hooks/useDashboardStore";
import "react-toastify/dist/ReactToastify.css";
import SessionTimeout from "../theme/Component/SessionTimeout";
import { Dashed } from "./Dashed/Dashed";
import { Spinner } from "../../core/components/Spinner";

interface Props {
  setIsLogged: any;
  notFound: boolean;
}

export const Home: FC<Props> = ({ setIsLogged, notFound }) => {
  const { module, view }: any = useParams();
  const [user, setUser] = useState<any>({});
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const audit = new AuditService();
  const [open, setOpen] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    
    let user = single.getAccount() || {};

    if (user.IDAccount) {
      setUser(user);
      window.removeEventListener('resize', null)
    }
    else{
      navigate("/");
    }
  }, []);

  window.addEventListener('resize', () => {
    if (window.innerWidth > 900) {
      setOpen(false)
    }
  })

  useEffect(() => {
    setOpen(false)
  }, [module])


  const renderSwitch = (url: any) => {
    // console.log(module, view);
    if (module === undefined && view === undefined) {
      url = "admin";
    } else if (!single.havePermision("/" + module + "/" + view)) {
      url = "restrict";
    }

    if (module != undefined && view != undefined)
      single.view("Ingreso : " + "/" + module + "/" + view, null);

    switch (url) {
      case "admin":
        return <RouterAdmin ruta={view} />;
      case "contratos":
        return <RouterContract ruta={view} />;
      case "bpm":
        return <RouterBpm ruta={view} />;
      case "supplier":
        return <RouterSupplier ruta={view} />;
      case "servicios":
        return <RouterAdminService ruta={view} />;
      case "interes":
        return <RouterInteres />;
      case "restrict":
        return <NotPermision />;
      case "forpo":
        return <ForpoRouter />;
      case "dashboard-init":
        return <Dashboard />;
      default:
        return <Dashed />;
    }
  };

  

  const logout = () => {
    Singleton.getInstance().closeSesion();
    navigate("/login");
  };

  return (
    <div>
      <Spinner />
      <ToastContainer
        newestOnTop
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="d-flex h-100">
        <div className="nav_mobile d-flex justify-content-end">
          <HamburguerMenu open={open} handleClick={() => { setOpen(prev => !prev) }} />
        </div>
        <div
          style={{
            paddingTop: !open ? '0' : '3rem',
            transform: open ? 'translateX(0px)' : ''
          }}
          id="sidermenu">
          <MenuModulo />
        </div>
        <div
          id="containermain"
        // className={`
        //   ${window.location.href.includes("/admin/perfil") ? "" : "p-4"}
        //   mt-3`}
        >
          {notFound && <NotFound></NotFound>}
          {!notFound && <div className="_responsive">{renderSwitch(module)}</div>}
        </div>
      </div>
      <SessionTimeout login={user.IDAccount} setIsLogged={logout} />
    </div>
  );
};
