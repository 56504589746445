import { Singleton } from '../../../core/services/Singleton';
interface Props {
    listaBotones: any
}
export const ListMenu = ({ listaBotones: items }: Props) => {

    const single = Singleton.getInstance()

    return (
        <ul className="list-group">
            {items.length === 0 &&
                <li className='list-group-item'>Sin opciones</li>
            }
            {items.map((boton: any, index: number) => {
                return (
                    <>
                        {boton.visible === true &&
                            <li key={index} className='list-group-item' onClick={boton.evento}>
                                <i className={boton.icono ? boton.icono : 'ri-subtract-line'} style={{ paddingRight: '5px' }}></i>
                                {boton.titulo}
                            </li>
                        }
                    </>

                )
            })}
        </ul>
    )
}
