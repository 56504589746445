import React, { useState } from 'react'
import { Singleton } from '../../../../../core/services/Singleton';
import { BpmService } from '../../../../../core/services/BpmService';
import { useInboxStore } from '../../hooks/useInboxStore';
import { toast } from 'react-toastify';
import { useDashboardStore } from '../../../../pages/hooks/useDashboardStore';

interface Props {
    procedure: any,
    exportMap: Function
}

const service = new BpmService();
const single = Singleton.getInstance()

export const TokenAssign = ({ procedure, exportMap }: Props) => {

    const { setLoading } = useDashboardStore();
    const [inputValue, setInputValue] = useState('');
    const { procedureImpSelected } = useInboxStore()

    const assingCreditToken = () => {
        setLoading(true)
        service.assingCreditToken(single.getAccountID(), procedureImpSelected?.IDProcedureImp ?? 0, inputValue)
            .subscribe((resp: any) => {
                setLoading(false)
                console.log("ASIGNACION", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success('TOKEN asignado éxitosamente!')
                }
            })
    }


    const onChange = (e: string) => {
        setInputValue(e)
        if (e.length > 0) {
            exportMap({
                TOKEN: 'true'
            }, inputValue)
        } else {
            exportMap({
                TOKEN: 'false'
            }, inputValue)
        }
    }

    return (
        <div className='d-flex justify-content-center align-item-center flex-column'>
            <strong className='fs-5 mx-auto my-3'>ASIGNACIÓN DE TOKEN</strong>
            <div className="my-3 w-50 mx-auto">
                {/* <label className='m-1' htmlFor="token_ponal">Ingrese token a asignar</label> */}
                <input id='token_ponal' type="number" className="form-control" placeholder="XXXX - XXXX - XXXX - XXXX"
                    value={inputValue}
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
            <p className='text-muted'></p>

            <button className='my-1 btn btn-primary w-50 mx-auto' onClick={(() => {
                assingCreditToken()
            })}>
                ASIGNAR
            </button>
        </div>
    )
}
