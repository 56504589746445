import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { FileInput } from "../../../../theme/Component/FileInput";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { MyEditor } from "../../../../theme/Component/MyEditor";
import { InputDate } from "../../../../theme/Input/InputDate";
import { useIntro } from "./useIntro";


export const Intro = () => {
    const {
        bean,
        setBean,
        lista,
        modal,
        modalConfirm,
        setModal,
        setModalConfirm,
        single,
        eliminarItem,
        iconButtonsItem,
        head,
        setRowBean,
        updateItem,
        fileService
    } = useIntro();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: `Editar ${bean.Name}` }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Introduccion">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre: </label>
                        <input type="text" className="form-control " value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Estado: </label>
                        <select className="form-select" value={bean.State} onChange={(e)=>setBean({...bean, "State": e.target.value})}>
                            <option value={1}>Creado</option>
                            <option value={2}>Publicado</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <InputDate name="" label="Fecha Inicial" value={bean.FromDate} setDate={(e:any)=>{setBean({...bean, "FromDate": e})}}></InputDate>
                    </div>
                    <div className="col-md-6">
                        <InputDate name="" label="Fecha Final" value={bean.UptoDate} setDate={(e:any)=>{setBean({...bean, "UptoDate": e})}}></InputDate>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción: </label>
                        <MyEditor onChange={(e: any) => { setBean({ ...bean, 'Description': e }) }} val={bean.Description}></MyEditor>
                    </div>
                </div>
            </Modal >
        </>
    );
};
