import { useEffect, useState } from "react";
import { DropDown } from "../theme/Component/DropDown";
import { Modal } from "../theme/Component/Modal";
import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { BasicTable } from "../theme/Component/BasicTable";
import { Alert } from "../theme/Component/Alert";
import { ChartArea } from "../theme/charts/ChartArea";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";


interface Props {
    bean: any;
    updateEvent: any;
    permisos?: any;
    rol: number;
}
export const ItemDropDown = ({ bean, updateEvent, permisos, rol }: Props) => {

    const service = new ContractService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [modal, setModal] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [listaHistorico, setHistorico] = useState<any>([]);
    const [listaGra, setListaGra] = useState<any>([]);
    const [btn, setBTN] = useState<any>({});
    const [obs, setObs] = useState<string | undefined>('');
    const [message, setMessage] = useState<string | null>('');
    const [beanI, setBeanI] = useState<any>({});


    useEffect(() => {
        setObs('');
        setMessage('');
        // console.log("Preparador ",bean);
    }, [bean])
    useEffect(() => {
        if (message !== '') {
            updateEvent();
        }
    }, [message])

    const actionsDropDown = [
        // { id: 1, name: 'Ítem Revisado', permiso: null, activo: (rol != 2) },
        // { id: 2, name: 'Ítem Aprobado', permiso: null, activo: (rol != 2) },
        // { id: 3, name: 'Ítem Cancelado', permiso: null, activo: (rol != 2) },
        // { id: 4, name: 'Actualizar Estado de Estructuración' ,permiso:null,activo:!preparador},
        { id: 5, name: 'Ver histórico de aprobaciones', permiso: null },
        { id: 6, name: 'Información de Estados de los Artículos del Plan', permiso: null, activo: (rol != 2) },
        { id: 7, name: 'Enviar a Responsable del Área para revisión', permiso: null, activo: ((rol == 2) && (bean.PreparationState != 6)) },
        { id: 8, name: 'Aprobar Item', permiso: null, activo: ((rol != 2) && bean.PreparationState != 6) },
        // { id: 9, name: 'Rechazar Item' ,permiso:null,activo:((rol !=2) && bean.PreparationState !=7)}

    ]


    const aprobarItem = () => {

        setLoading(true);
        let b = bean;
        b.PreparationState = 6;
        service.updateAdquisitionItem(b).subscribe(
            (resp: any) => {
                console.log(resp);

                setLoading(false);
                updateEvent();
            }
        );

    }
    const rechazarItem = () => {

        setLoading(true);
        let b = bean;
        b.PreparationState = 7;
        service.updateAdquisitionItem(b).subscribe(
            (resp: any) => {
                console.log(resp);

                setLoading(false);
                updateEvent();
            }
        );

    }


    const handleEvent = (item: any) => {

        setBTN(item);
        if (item.id >= 8) {

            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": true });

        }
        if (item.id >= 1 && item.id <= 4 || item.id === 7) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": true });

        }
        else if (item.id === 6) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionArticleStructuringStateList(bean.IDAdquisitionItem).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setListaGra(resp.DataBeanProperties.ObjectValue);
                    }
                    single.makeAcction("El usuario consultó la información de estados de los Artículos del plan " + (bean.IDAdquisitionItem), bean);
                    setLoading(false);
                }
            )
        }
        else if (item.id === 5) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionItemApprovalNoteCatalog(bean.IDAdquisitionItem, null, null, null).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        setHistorico(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                    }
                    setLoading(false);
                    single.makeAcction("El usuario consultó el histórico de aprobaciones del ítem " + (bean.IDAdquisitionItem), bean);
                }
            )
        }


    }

    const updateAction = () => {

        if (btn.id === 8) {
            aprobarItem();
        }
        if (btn.id === 9) {
            rechazarItem();
        }
        if (btn.id === 1) {
            setLoading(true);
            service.setAdquisitionItemOnReviewedState(bean.IDAdquisitionItem, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setMessage("Item Actualizado");
                        updateEvent();
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 2) {
            setLoading(true);
            service.setAdquisitionItemOnApprovedState(bean.IDAdquisitionItem, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Item Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 3) {
            setLoading(true);
            service.setAdquisitionItemOnCancelledState(bean.IDAdquisitionItem, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Item Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }
        else if (btn.id === 7) {
            setLoading(true);
            service.setAdquisitionItemOnPreparedState(bean.IDAdquisitionItem, single.getAccountID(), obs + "").subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Item Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                    single.makeAcction("El usuario envió el ítem " + (bean.IDAdquisitionItem) + " a aprobación.", bean);
                    console.log(beanI);
                }
            )
        }
        else if (btn.id === 4) {
            setLoading(true);
            service.updateAdquisitionItemStructuringState(bean.IDAdquisitionItem).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        updateEvent();
                        setMessage("Item Actualizado");
                    }
                    else if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }
                    setLoading(false);
                }
            )
        }


    }

    const head = [
        { title: 'Fecha', property: 'EventDate' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Aprobado', property: 'Approved', visible: false },
        { title: 'Descripción', property: 'Description' }
    ]

    return (
        <>
            <DropDown lista={actionsDropDown} permisos={permisos} eventClick={(e: any) => {
                handleEvent(e);
            }}></DropDown>

            <Modal modal={modal} updateModal={setModal} eventModal={updateAction}>


                <div className="row">
                    <div className="col-md-12">

                        {(btn.id >= 1 && btn.id <= 3 || btn.id === 7) &&
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea className="form-control mt-2" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                                </div>
                            </div>

                        }
                        {btn.id === 5 &&
                            <BasicTable head={head} body={listaHistorico} rowSet={() => { }}></BasicTable>
                        }

                        {btn.id === 4 &&
                            <p>
                                <strong>Está seguro de actualizar el estado del Ítem.</strong>
                            </p>
                        }
                        {btn.id === 6 &&
                            <ChartArea lista={listaGra} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                        }
                        {btn.id === 8 &&
                            <p>Cambiar el item a estado aprobado ? </p>
                        }
                        {btn.id === 9 &&
                            <p>Cambiar el item a estado Rechazado ? </p>
                        }
                    </div>
                    <div className="col-md-12">
                        {message !== '' &&
                            <Alert clase="warning">{message}</Alert>
                        }
                    </div>
                </div>

            </Modal>
        </>
    )
}