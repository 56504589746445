import { useEffect, useState } from "react";
import { AccountEditor } from "../../editor/AccountEditor";
import { toast } from "react-toastify";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { Singleton } from "../../../core/services/Singleton";
import { AdminService } from "../../../core/services/AdminService";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';

interface Props {
    idAccount?: number;
    onChange: Function;
}
export const ContractEditor = ({ idAccount, onChange }: Props) => {

    const adminService = new AdminService();
    const single = Singleton.getInstance();
    const [year, setYear] = useState<number>(undefined);
    const [idc, setIdc] = useState<number>(undefined);
    const [account, setAccount] = useState<any>({});
    const [contract, setContract] = useState<any>([]);

    useEffect(() => {
        if (idAccount > 0) {
            setAccount({ IDAccount: idAccount });
        }
        setContract([]);
    }, [idAccount])
    useEffect(() => {
        if (idc > 0) {
            onChange(idc);
        }
    }, [idc])


    useEffect(() => {

        getContractList();

    }, [account])

    const getContractList = () => {
        if (account.IDAccount > 0) {
            single.spinner(true);
            adminService.getEmployeeContract(account.IDAccount).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        let list = resp.DataBeanProperties.ObjectValue;
                        if (list.length > 0) {
                            setIdc(list[0].DataBeanProperties.IDContract);
                        }
                        setContract(list);


                    }
                    single.spinner(false);
                }
            )

        }
    }

    return (
        <div className="row">
            {/* <div className="col-md-3">
                <YearSelector value={year} onChange={setYear} />
            </div> */}
            {idAccount === undefined &&
                <div className="col-md">
                    <AccountEditor idAccount={0} label="Funcionario" onChange={setAccount} />
                </div>
            }
            <div className="col-md">
                <label>Periodo / Contrato</label>
                <select className="form-select" value={idc} onChange={(e) => { setIdc(parseInt(e.target.value)) }} >
                    <option value={undefined}>...</option>
                    {contract.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDContract}>{item.DataBeanProperties.Period} / {item.DataBeanProperties.EntailmentName}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}