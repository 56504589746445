import { GenericService } from "../../../../core/services/GenericService";

export class cashReimbursementcajamenor extends GenericService {

    public getCashReimbursementCatalog(yearID: number | null, deskaccountID: number | null, state: number | null) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getCashReimbursementCatalog_Number_Number_Number",
            ArgumentList: [
                yearID, deskaccountID, state
            ],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);
    }



    public getCashReimbursementProcessConstants(cashID: number | null) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getCashDeskAccountCatalog_Number_Number",
            ArgumentList: [
                cashID
            ],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);
    }

    // public updateAccountantBook(bean: any) {
    //   const parametros = {
    //     ServiceName: "QuickDataERPCore",
    //     MethodHash: "admin.AccountantBook_updateAccountantBook_admin.AccountantBook",
    //     ArgumentList: [
    //       {
    //         DataBeanProperties: bean,
    //         DataBeanName: 'admin.AccountantBook'
    //       }
    //     ],
    //   };
    //   const data = JSON.stringify(parametros);
    //   return this.myApi.post(this.url, data);
    // }

    // public deleteAccountantBook(IDAccountant: number | null) {
    //   const parametros = {
    //     ServiceName: "QuickDataERPCore",
    //     MethodHash: "void_deleteAccountantBook_Number",
    //     ArgumentList: [IDAccountant],
    //   };
    //   const data = JSON.stringify(parametros);
    //   return this.myApi.post(this.url, data);
    // }
}