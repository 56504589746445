
import { RenderParametrica } from "../RenderParametrica";

interface Props {
    formulario: any;
}

export const Forma2Pdf = ({ formulario }: Props) => {

    return (
        <>
            <div className="row">
                <div className="col-12 text-center celda">
                    <h5>PERSONA JURIDICA</h5>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Razón social:</label><br />
                    <p> {formulario.razon_social}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">NIT:</label><br />
                    <p> {formulario.nit}</p>
                </div>
                <div className="col-12 text-center celda">
                    <h5>Información ubicación</h5>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Dirección Oficina:</label><br />
                    <p>{formulario.direccion_oficina}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Ciudad de Oficina:</label><br />
                    <p>{formulario.ciudad_oficina}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Departamento de Oficina:</label><br />
                    <p>{formulario.departamento_oficina}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Correo electrónico empresarial: </label><br />
                    <p>{formulario.correo_electronico}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Teléfono de Oficina:</label><br />
                    <p>{formulario.telefono_oficina}</p>
                </div>
                <div className="col-12 text-center celda">
                    <h5>Información de la actividad de la empresa</h5>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form">Tipo de empresa:</label><br />
                    <RenderParametrica IDSuppConstantsValue={formulario.tipo_empresa} />
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form">Actividad económica:</label><br />
                    <RenderParametrica IDSuppConstantsValue={formulario.actividad_economica} />
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Descripción actividad econónmica: </label><br />
                    <p>{formulario.descripcion_economica}</p>
                </div>
                <div className="col-6 color-celda color-celda">
                    <label className="titul-form">Código Actividad Económica (CIIU): </label><br />
                    <p>{formulario.codigo_economico}</p>
                </div>
                <div className="col-12 text-center celda">
                    <h5>Información Financiera y Económica</h5>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">¿Declara renta?</label><br />
                    <p>{formulario.renta ? 'SI' : 'NO'}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Información econoónmica a corte (último año): </label><br />
                    <p>{formulario.informacion_economica}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Ingresos mensuales: </label><br />
                    <p>${formulario.ingresos_menusales}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Otros ingresos o ingresos no operacionales (Originados en actividades diferentes a la principal, no incluidos como ingresos mensuales):</label><br />
                    <p>${formulario.otros_ingresos}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Detalle de ingresos no operacionales u originados en actividades diferentes a la principal:</label><br />
                    <p>{formulario.ingresos_noperacionales}</p>
                </div>
                <div className="col-6 color-celda" />
                <div className="col-6 color-celda">
                    <label className="titul-form">Total ingresos mensuales</label><br />
                    <p>$ {formulario.total_ingresos}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Egresos mensuales: </label><br />
                    <p>$ {formulario.egresos_mensuales}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Total Activo: </label><br />
                    <p>$ {formulario.total_activos}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Total Pasivo: </label><br />
                    <p>$ {formulario.total_pasivos}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Total Patrimonio: </label><br />
                    <p>$ {formulario.total_patrimonio}</p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Realiza transacciones en moneda extranjera: </label><br />
                    <p>{formulario.moneda_extranjera ? 'SI' : 'NO'}</p>
                </div>
                <div className="col-12 color-celda">
                    <p>Tipo de transacción:</p> <RenderParametrica IDSuppConstantsValue={formulario.tipo_transaccion} />
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Número de producto: </label><br />
                    <p>{formulario.numero_producto}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Moneda:</label><br />
                    <p>{formulario.moneda}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Monto:</label><br />
                    <p>{formulario.monto}</p>
                </div>

                <div className="col-12 text-center celda">
                    <h5>Información del Representante Legal</h5>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Primer Nombre:</label><br />
                    <p>{formulario.rep_name}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Segundo Nombre:</label><br />
                    <p>{formulario.rep_name2}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Primer Apellido:</label><br />
                    <p>{formulario.rep_surname}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Segundo Apellido:</label><br />
                    <p>{formulario.rep_surname2}</p>
                </div>

                <div className="col-3 color-celda">
                    <label className="titul-form">Tipo de Documento:</label><br />
                    <RenderParametrica IDSuppConstantsValue={formulario.rep_docType}></RenderParametrica>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">No de Documento:</label><br />
                    <p>{formulario.rep_nit}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Fecha de expedición:</label><br />
                    <p>{formulario.rep_expe_date}</p>
                </div>

                <div className="col-3 color-celda">
                    <label className="titul-form">Lugar de expedición:</label><br />
                    <p>{formulario.rep_expe_zone}</p>
                </div>

                <div className="col-12 text-center celda">
                    <h5>Dirección de residencia del Representante Legal</h5>
                </div>

                <div className="col-3 color-celda">
                    <label className="titul-form">Dirección de residencia: </label><br />
                    <p>{formulario.rep_name}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Ciudad/Municipio de residencia:</label><br />
                    <p>{formulario.rep_name2}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Departamento: </label><br />
                    <p>{formulario.rep_surname}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">País: </label><br />
                    <p>{formulario.rep_surname2}</p>
                </div>

                <div className="col-4 color-celda">
                    <label className="titul-form">Teléfono: </label><br />
                    <p>{formulario.rep_docType}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Celular: </label><br />
                    <p>{formulario.rep_nit}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Correo Electrónico: </label><br />
                    <p>{formulario.rep_expe_date}</p>
                </div>

                <div className="col-12 text-center celda">
                    <h5>Información económica del Representante Legal</h5>
                </div>

                <div className="col-md-4 color-celda">
                    <label className="titul-form">Realiza transacciones en moneda extranjera:</label><br />
                    <p>{formulario.ecoInfo_money ? 'SI' : 'NO'}</p>
                </div>
                <div className="col-md-4 color-celda">
                    <label className="titul-form">Declara renta:</label><br />
                    <p>{formulario.ecoInfo_money ? 'SI' : 'NO'}</p>
                </div>
                <div className="col-md-4 color-celda">
                    <label className="titul-form">Maneja Recursos Públicos</label><br />
                    <p>{formulario.ecoInfo_publicManage ? 'SI' : 'NO'}</p>
                </div>

                <div className="col-md-6 color-celda">
                    <label className="titul-form">Tipo de transacción:</label><br />
                    <RenderParametrica IDSuppConstantsValue={formulario.ecoInfo_transactionType}></RenderParametrica>
                </div>
                <div className="col-md-6 color-celda">
                    <label className="titul-form">Número de producto:</label><br />
                    <p>{formulario.ecoInfo_product}</p>
                </div>
                <div className="col-md-6 color-celda">
                    <label className="titul-form">Moneda:</label><br />
                    <p>{formulario.ecoInfo_coin}</p>
                </div>
                <div className="col-md-6 color-celda">
                    <label className="titul-form">Monto:</label><br />
                    <p>{formulario.ecoInfo_mount}</p>
                </div>

                <div className="col-12 text-center celda">
                    <h5>Información personas expuestas políticamente - PEP</h5>
                </div>
                <div className="col-12 color-celda">
                    <p>1. Persona Expuesta Políticamente (PEP): Se consideran PEP los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y
                        territorial, cuando tengan asignadas o delegadas funciones de: expedición de normas o regulaciones, dirección general, formulación de políticas institucionales y adopción de planes, programas
                        y proyectos, manejo directo de bienes, dineros o valores del Estado, administración de justicia o facultades administrativo sancionatorias, y los particulares que tengan a su cargo la dirección o
                        manejo de recursos en los movimientos o partidos políticos. Estas funciones podrán ser ejercidas a través de ordenación de gasto, contratación pública, gerencia de proyectos de inversión,
                        pagos, liquidaciones, administración de bienes muebles e inmuebles. PEP desde su nombramiento y hasta 2 años después de dejar su cargo.<br></br>
                        <br></br>
                        2. PEP extranjeras: Son aquellas personas que desempeñen funciones prominentes en otro país. Se entienden por PEP extranjeras: (i) jefes de Estado, jefes de Gobierno, ministros,
                        subsecretarios o secretarios de Estado; (ii) congresistas o parlamentarios; (ii) miembros de tribunales supremos, tribunales constitucionales u otras altas instancias judiciales cuyas decisiones no
                        admitan normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de tribunales o de las juntas directivas de bancos centrales: (v) embajadores, encargados de negocios altos
                        funcionarios de las fuerzas armadas, (vi) miembros de los órganos administrativos, de gestión o de supervisión de empresas de propiedad estatal y (vii) representantes legales, directores,
                        subdirectores y/o miembros de las juntas directivas de organizaciones internacionales.Parágrafo.La calidad de PEP se mantendrá en el tiempo durante el ejercicio del cargo y por dos (2) años
                        más después de dejar su cargo. Obligación de los PEP: Informarán su cargo, fecha de vinculación y fecha de desvinculación. Asi mismo deberán, además, declarar: (i) los nombres e
                        identificación de las personas con las que tengan sociedad conyugal, de hecho, o de derecho; (ii) los nombres e identificación de sus familiares hasta segundo grado de consanguinidad, primero
                        afinidad y primero civil; (ili) la existencia de cuentas financieras en algún país extranjero en caso de que tengan derecho o poder de firma o de otra índole sobre alguna; y (iv) los nombres e
                        identificación de las personas jurídicas o naturales, patrimonios autónomos o fiducias conforme lo dispuesto en el artículo 2.1.4.2.3. del Decreto 830 de 2021.</p>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form"><strong>Deacuerdo a la anterior info , porfavor indique si es PEP:</strong> </label>
                    <p>{formulario.mondedaextranjera ? 'SI' : 'NO'}</p>
                </div>

                <div className="col-12 text-center celda">
                    <h5>Información de los socios o accionistas de la empresa</h5>
                </div>
                {formulario.socios &&
                    <>
                        <div className="col-12 color-celda">
                            <table className="table table-nowrap table-striped-columns mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">Tipo de documento de Identidad</th>
                                        <th scope="col">No. Documento de Identidad</th>
                                        <th scope="col">Nombres y Apellidos</th>
                                        <th scope="col">Participación</th>
                                    </tr>
                                </thead>
                                {formulario.socios.map((item: any, index: number) => {
                                    return (
                                        <tbody key={index}>
                                            <tr>
                                                <td><RenderParametrica IDSuppConstantsValue={item.documentType}></RenderParametrica></td>
                                                <td>{item.document}</td>
                                                <td>{item.names}</td>
                                                <td>{item.participate}</td>

                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </table>
                        </div>
                    </>
                }
                <div className="col-md-12 color-celda">
                    <p className="mt-2">Si tiene socios o accionistas con participación directa o indirecta superior al 5% deberán diligenciar el formato de conocimiento del cliente persona natural.<br />
                        * En caso de manifestar Si en la casilla PEP de los numeral 52 y 53, adicionalmente deberan diligenciar el formato presentación de personas expuestas políticamente PEP "GR-NA-FM-038", para el
                        conocimiento ampliado de este tipo de personas.</p>
                </div>
            </div>
        </>
    )
} 