import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';



interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
    _flowControl: string;
}

export const SelectorConditionalOperators = ({ titulo, idSelector, onChange, _flowControl }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);

    const [id, setID] = useState('');

    useEffect(() => {
        getLista();
    }, [idSelector, _flowControl])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
    }

    const getLista = () => {
        service.getConditionalOperators(_flowControl).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <>
                                <option key={index} value={item.DataBeanProperties.Property} >{item.DataBeanProperties.Value}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}