import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmService } from "../../../core/services/BpmService";
import { SelectorClaseTramites } from "./Componentes/SelectorClaseTramites";
import { SelectorTramites } from "./Componentes/SelectorTramites";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { SelectorOffice } from "./Componentes/SelectorOffice";
import { ConditionStatement } from './ConditionStatement';
import { ProcedureDocument } from './ProcedureDocument';
import { ListMenu } from '../../theme/Component/ListMenu';
import { ModalGeneric } from '../../../interfaces';
import { SelectFunctionalID } from '../../admin/configuracion/functionalID/SelectFunctionalID';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface BusinessProcess {
    Name: string
    Description: string
    ActivityType: number
    IDOffice: number
    UseDistributionChannel: boolean,
    ValidateDocuments: boolean,
    LimitByOffice: boolean,
    IDBusinessProcess: number | null,
    IDLnFunctionalID: number,
    ProcedureRow: number,
    IDProcedure?: number,
    FalseIDLnPath?: string,
    TrueIDLnPath?: string,
}

export const Procedure = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<BusinessProcess>({
        Name: '', //
        Description: '', //
        ActivityType: 0, //
        IDOffice: 0, //
        UseDistributionChannel: false, //
        ValidateDocuments: false, //
        LimitByOffice: false, //
        IDBusinessProcess: null, //
        IDLnFunctionalID: 0,  //
        ProcedureRow: 0,
        FalseIDLnPath: '',
        TrueIDLnPath: '',
    });
    const [idProcess, setIDProcess] = useState(0);
    const [idModule, setIDModule] = useState(0);
    const [modal, setModal] = useState<ModalGeneric>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [selectof, setSelectOf] = useState(0);
    const [modalAF, setModalAF] = useState<any>({ name: 'Agregar Control de Flujo a Procedimientos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalDR, setModalDR] = useState<any>({ name: 'Documentos Requeridos', tabIndex: -1, view: false, btnClose: true, format: 'modal-lg' });
    const [modalChar, setModalChar] = useState<any>({ name: 'Lista de caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-sm' });

    const [charList, setCharList] = useState([])

    const idBean = 'IDProcedure';

    const head = [
        { title: 'ID', property: idBean },
        
        { title: 'Nombre', property: 'Estados1' },
        { title: 'Orden', property: 'ProcedureRow' },
        { title: 'Control de flujo', property: 'ConditionList' },
        { title: 'Actividad', property: 'Estados' },

    ]

    const renderActividad = (item: any) => {
        let render =
            ` 
        <div>            
            <strong>Actividad: </strong><small>${item.DataBeanProperties.ActivityTypeName ? item.DataBeanProperties.ActivityTypeName : 'NA'}</small><br />
            <strong>Rol: </strong><small>${item.DataBeanProperties.BusinessRoleName ? item.DataBeanProperties.BusinessRoleName : 'NA'}</small><br />
            <strong>Grupo: </strong><small>${item.DataBeanProperties.FunctionalIDValidateName ? item.DataBeanProperties.FunctionalIDValidateName : 'NA'}</small><br />
        </div>
        `
        return render;
    }


    const renderNombre = (item: any) => {
        let render1 = ` 
        <div>            
        <strong>Etapa: </strong><small>${item.DataBeanProperties.Name ? item.DataBeanProperties.Name : 'NA'}</small><br />
            <strong>Tipo: </strong><small>${item.DataBeanProperties.ActivityTypeName ? item.DataBeanProperties.ActivityTypeName : 'NA'} - (${item.DataBeanProperties.ProcedureRow ? item.DataBeanProperties.ProcedureRow : 'NA'})</small><br />
        </div>
        `
        return render1;
    }

    useEffect(() => {
        setBean({ ...bean, 'IDBusinessProcess': idProcess })
        getLista();
    }, [idProcess])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getProcedureCatalog(idProcess).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let ll = resp.DataBeanProperties.ObjectValue;
                    ll.forEach((element: any) => {
                        element.DataBeanProperties.Estados = renderActividad(element);
                        element.DataBeanProperties.Estados1 = renderNombre(element);
                    });
                    setLista(ll);
                }
            }
        );
    }


    const eliminarItem = () => {
        setLoading(true);
        service.deleteProcedure(bean.IDProcedure, null).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean.IDBusinessProcess ?? 0, bean);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });

    }

    const agregar = () => {
        setBean({
            Name: '', //
            Description: '', //
            ActivityType: 0, //
            IDOffice: 0, //
            UseDistributionChannel: false, //
            ValidateDocuments: false, //
            LimitByOffice: false, //
            IDBusinessProcess: idProcess, //
            IDLnFunctionalID: 0,  //
            ProcedureRow: 0,
            TrueIDLnPath: '',
            FalseIDLnPath: '',
        })

        setModal({ ...modal, "view": true });

    }

    const updateItem = () => {
        setLoading(true);
        service.updateProcedure(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                setModal({ ...modal, 'view': false });
                single.update(bean);
            });
    }

    const spam = () => {
        setLoading(true);
        service.getBusinessCharacterizationCatalog(idProcess)
            .subscribe((resp: any) => {
                console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setCharList(resp.DataBeanProperties.ObjectValue)
                }
                setLoading(false);

            })
        setModalChar({ ...modalChar, 'view': !(modalChar.view) });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Editar control de flujo', icono: 'ri-file-user-line', visible: true, evento: () => { setModalAF({ ...modalAF, "view": true }) } },
            { titulo: 'Documentos Requeridos', icono: 'ri-file-user-line', visible: true, evento: () => { setModalDR({ ...modalDR, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregar }
    ]

    return (
        <>
            <BasicPage titulo="Procedimientos">
                <div className="row">
                    <div className="col-md-6">
                        <SelectorTramites titulo="Clase de tramites" idSelector={idModule} onChange={(e: any) => { setIDModule(e) }} />
                    </div>
                    {idModule > 0 &&
                        <div className="col-md-6">
                            <SelectorClaseTramites idProcess={idModule} idSelector={idProcess} onChange={(e: any) => { setIDProcess(e) }} />
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-md-12 p-2">
                        {idProcess > 0 &&
                            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                        }
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className='form-label'>Tipo de actividad:</label>
                        <select className="form-control" value={bean.ActivityType} onChange={(e: any) => { setBean({ ...bean, 'ActivityType': e.target.value }) }}>
                            <option value={null}>...</option>
                            <option value={5}>INICIO</option>
                            <option value={6}>TAREA</option>
                            <option value={7}>GATEWAY</option>
                            <option value={8}>CONDICIONAL</option>
                            <option value={9}>FIN</option>
                            <option value={10}>INTERACCION CLIENTE TRAMITE</option>
                        </select>
                    </div>
                    {bean.ActivityType == 8 &&
                        <>
                            <div className="col-md-6">
                                <label className="form-label">Línea Opción Positiva</label>
                                <input type="number" className="form-control" value={bean.TrueIDLnPath} onChange={(e) => { setBean({ ...bean, 'TrueIDLnPath': e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Línea Opción Negativa</label>
                                <input type="number" className="form-control" value={bean.FalseIDLnPath} onChange={(e) => { setBean({ ...bean, 'FalseIDLnPath': e.target.value }) }} />
                            </div>
                        </>
                    }
                    <div className="col-md-12">
                        <label className="form-label">Descripcion</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-6">
                        <SelectorOffice idSelector={selectof} titulo="Seleccione una sucursal" onChange={(e: any) => { setSelectOf(e) }}></SelectorOffice>
                    </div>
                    <div className="col-md-6">
                        <SelectFunctionalID renderValue='' onChange={(e: any) => setBean({ ...bean, 'IDLnFunctionalID': e.IDLn })} />
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group mt-4">
                                ¿Usa canal de distribuición?
                                <BasicSwitch label="" estado={bean.UseDistributionChannel} eventChange={(e: any) => { setBean({ ...bean, "UseDistributionChannel": e }) }}></BasicSwitch>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mt-4">
                                ¿Validar documentos?
                                <BasicSwitch label="" estado={bean.ValidateDocuments} eventChange={(e: any) => { setBean({ ...bean, "ValidateDocuments": e }) }}></BasicSwitch>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mt-4">
                                ¿Limitar procedimiento a oficina de radicación?
                                <BasicSwitch label="" estado={bean.LimitByOffice} eventChange={(e: any) => { setBean({ ...bean, "LimitByOffice": e }) }}></BasicSwitch>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Caracaterizaciones a las que aplican</label>
                            <BasicButton value="Consultar caracterizaciones" icon="ri-edit-2-line" eventClick={spam} ></BasicButton></div>
                        <div className="col-md-6">
                            <label className="form-label">Orden en el flujo</label>
                            <input type="number" className="form-control" value={bean.ProcedureRow} onChange={(e) => { setBean({ ...bean, 'ProcedureRow': parseInt(e.target.value) }) }} />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal modal={modalAF} updateModal={setModalAF}>
                <ConditionStatement _idBussinesProcces={idProcess} refresh={getLista} _idProcedure={bean.IDProcedure}></ConditionStatement>
            </Modal>

            <Modal modal={modalDR} updateModal={setModalDR} >
                <ProcedureDocument idProcedure={bean.IDProcedure}></ProcedureDocument>
            </Modal>

            <Modal modal={modalChar} updateModal={setModalChar}>
                <ul>
                    {
                        charList.length > 0 ? charList.map((i: any) => <li>{i.DataBeanProperties.CustomerTypeName}</li>)
                            : <h3 className='m-3'>No se encuentran caracterizaciones vinculadas al trámite </h3>
                    }
                </ul>
            </Modal>
        </>
    )
}