import { useState } from 'react'
import { currencyFormatter, formatDate, today } from '../../../utils/helpers'
import { Divider } from './Divider'
import { MyEditor } from '../../theme/Component/MyEditor'

// FORMATO CUADRO COMPARATIVO DE OFERTAS
interface Props {
    data: any
}

interface SupplierOffer {
    name: string,
    totalPrice: number,
    payConditions: string,
    deliveryTimes: string,
    experience: string,
    warrantyTime: string,
}

const factors = [
    'Precio total',
    'Condiciones de pago',
    'Tiempos de entrega',
    'Experiencia',
    'Tiempo de garantía',
    'Cumplimiento de los requisitos exigidos en la Oferta',
    '% de implementación del SGSST'
]

const additionalFactors = [
    'Garantia adicional sin costo para la entidad',
    'Visita al proveeedor',
    'Año de constitución de la empresa',
    'Tiempo máximo de duración del bien y/o servicio',
    'Tiempo de transferencia de tecnología o actualización tecnológica',
    'Certificaciones de calidad ISO 901, ISO 27001, ISO 45001, Otra',
    'Estados financieros e indicadores financieros',
    'Descripción de materiales del bien',
    'Número de personas que prestarán el servicio'
]

export const OffersComparative = ({ }: Props) => {

    const [suppliers, setSuppliers] = useState<String[]>(['', '', ''])
    const [nonSuppliers, setNonSuppliers] = useState<String[]>(['', ''])
    const [items, setItems] = useState([])


    const renderSwitch = (index: number) => {
        switch (index) {
            case 0: return <span className='text-success'>{currencyFormatter(0)}</span>


            default:
                break;
        }
    }


    return (
        <div className='row'>
            <div className="w-100 my-2">
                <strong>Fecha</strong>&nbsp;{formatDate(today)}
            </div>
            <div className="mt-1 col-sm-12 col-md-6">
                <strong>Dependencia que radicó el estudio Previo</strong>
                <input className='form-control' type="text" disabled />
            </div>
            <div className="mt-1 col-sm-12 col-md-6">
                <strong>Número de rádicado del Estudio previo</strong>
                <input className='form-control' type="text" disabled />
            </div>
            <div className="mt-1 col-sm-12 col-md-6">
                <strong>Objeto contractual</strong>
                <input className='form-control' type="text" disabled />
            </div>
            <div className="mt-1 col-sm-12 col-md-6">
                <strong>Nombre del solicitante de la oferta</strong>
                <input className='form-control' type="text" disabled />
            </div>
            <div className="mt-1 col-sm-12 col-md-12">
                <strong>Causal</strong>
                <input className='form-control' type="text" disabled />
            </div>
            <Divider
                className='mt-5'
                title='PROVEEDORES QUE PRESENTARON OFERTA'
                description='(Se diligenciará de acuerdo con el número de empresas que presentaron oferta que mínimo deben ser 3)'
            />
            {suppliers.map((_e, _i) =>
                <div className="input-group input-group-sm mb-1" key={_i}>
                    <span className="input-group-text">Proveedor No.{_i + 1}</span>
                    <input type="text" className="form-control" />
                </div>
            )}
            <Divider
                className='mt-3'
                title='PROVEEDORES NO QUE PRESENTARON OFERTA'
                description='(Se diligenciará de acuerdo con el número de empresas que no presentaron oferta previa solicitud del funcionario)'
            />
            {nonSuppliers.map((_e, _i) =>
                <div className="input-group input-group-sm mb-1" key={_i}>
                    <span className="input-group-text">Proveedor No.{_i + 1}</span>
                    <input type="text" className="form-control" />
                </div>
            )}
            <div className="col-10">
                <Divider
                    className='mt-5 flex-grow-1'
                    title='COMPARACIÓN DE FICHA TÉCNICA'
                    description='(Se incluirán los ítems y el costo que remitió cada proveedor + iva + impuestos de ley *sí aplica*)'
                />
            </div>
            <div className='d-flex' style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Item</th>
                            {suppliers.map((_e, _i) =>
                                <th key={_i} scope="col">
                                    {_e === '' ? `Proveedor ${_i + 1}` : _e}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((_e, _i) =>
                            <tr key={_i}>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="col-10">
                <Divider
                    className='mt-5 flex-grow-1'
                    title='COMPARACIÓN DE FACTORES'
                    description='(El precio total que remitió cada proveedor incluye iva + impuestos de ley *sí aplica*)'
                />
            </div>
            <div className="accordion" id="accordionExample">
                {factors.map((_e, __i) =>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${__i}`} aria-expanded="false">
                                {_e}
                            </button>
                        </h2>
                        <div id={`collapse${__i}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className='d-flex justify-content-between'>
                                    {suppliers.map((_e, _i) =>
                                        <div key={_i} className='d-flex w-100 flex-column'>
                                            <strong>{_e === '' ? `Proveedor ${_i + 1}` : _e}</strong>
                                            {renderSwitch(__i)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>
            <div className="col-12">
                <Divider
                    className='mt-5 flex-grow-1'
                    title='INFORMACIÓN ADICIONAL PARA LA COMPARACIÓN DE OFERTAS'
                    description='(En los eventos de requerirse podrá adicionalmente solicitarse la siguiente información de acuerdo con el bien y servicio y/o proveedor)'
                />
            </div>
            <div className="accordion" id="#additonalFactors">
                {additionalFactors.map((_e, _i) =>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collap" data-bs-target={`#collap${_e}`} >
                                {_e}
                            </button>
                        </h2>
                        <div id={`collap${_e}`} className="accordion-collapse collapse show" data-bs-parent="#additonalFactors">
                            <div className="accordion-body">
                                <div className='d-flex justify-content-between'>
                                    {suppliers.map((_e, _i) =>
                                        <div key={_i} className='d-flex w-100 flex-column'>
                                            <strong>{_e === '' ? `Proveedor ${_i + 1}` : _e}</strong>
                                            {renderSwitch(_i)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-12">
                <Divider
                    className='mt-3 flex-grow-1'
                    title='Resultado de la comparación'
                    description='La persona que adelantó la comparación  debe mencionar los aspectos 
                    que se destacan y que son importantes al momento de seleccionar 
                    la Oferta de acuerdo con lo señalado en el Estudio Previo. 
                    Se le dará importancia al menor precio, 
                    siempre que cumpla con la calidad de lo solicitado.'
                />
                <div className='my-3'>
                    <MyEditor val={null} onChange={() => { }} />
                </div>
            </div>
            <div className="col-12">
                <strong>Propuesta más favorable:</strong>
                <p>____________________________________</p>
            </div>
            <div className="col-12">
                <strong>Recomendación del orden del gasto:</strong>
                <p>De acuerdo con la información presentada,
                    se recomienda la selección del proveedor _____________ por presentar
                    la propuesta más favorable para la contratación del bien y/o servicio.
                </p>
            </div>
            <div className=" mt-5 col-12 d-flex justify-content-between">
                <div>
                    <strong>Elaborado por:</strong>
                    <p>________________</p>
                </div>
                <div>
                    <strong>Revisado por:</strong>
                    <p>________________</p>
                </div>
                <div>
                    <strong>Revisado por:</strong>
                    <p>________________</p>
                </div>
            </div>

        </div>
    )
}
