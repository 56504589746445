export class CookieManager {

  private path = "/";

  public constructor(mPath: string | null) {
    if (mPath != null)
      this.path = mPath;
  }


  public setCookie(name: string, value: string, days: number) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    let cookieString = `${name}=${encodeURIComponent(value)}`;

    // Configurar SameSite y Secure
    cookieString += `; Secure`;


    if (days) {
      cookieString += `; expires=${expirationDate.toUTCString()}`;
    }

    document.cookie = cookieString;
  }

  public getCookie(name: string) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  public removeCookie(name: string) {
    this.setCookie(name, '', -1);
  }
  public deleteAllCookies() {

    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict";
    }
  }
}