
import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { AdminService } from '../../../core/services/AdminService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { BasicButton } from '../../theme/Component/BasicButton';
interface Props {
    label?: string;
    value: number | undefined;
    onChange: Function;
}

export const GenericCellEditorClassForNameList = ({ label,  value, onChange }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState<any>([]);
    const [txt, setTxt] = useState<any>('');
    const [id, setId] = useState<any>(0);


    useEffect(() => {
        if (id === 0 || id === undefined)
            setId(value);
    }, [value])



    useEffect(() => {
        onChange(id);
    }, [id])
    


    const getLista = () => {
        setLoading(true);
        service.getGenericCellEditorClassForNameList(txt.length !=0 ? txt : null).subscribe(
            (resp: any) => {
                console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }


    return (
        <div className="row">
            <div className="col-md-3">
                <label>Clases DataBean</label>
                <input type="text" placeholder='buscar...' className='form-control' value={txt ?? undefined} onChange={(e)=>{setTxt(e.target.value)}} />                           
                
            </div>
            <div className="col-md-3 mt-3">
            <BasicButton icon='' eventClick={getLista}>Buscar</BasicButton>
            </div>
            <div className="col-md-6">
            <label>{label ? label : ''} </label>
            <select className="form-select" value={id} onChange={(e) => {setId((e.target.value)) }} >
                <option value={null}>...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item}>{item}</option>
                    )
                })}
            </select>
            </div>
        </div>
    )
}