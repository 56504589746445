import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { ContractService } from "../../../../core/services/ContractService";
import { SelectRolOffer } from "./SelectRolOffer";
import { AccountEditor } from "../../../editor/AccountEditor";



interface Props {
    IDAdquisitionItem: number
}

export const OfferEmployee = ({ IDAdquisitionItem }: Props) => {

    const single = Singleton.getInstance()
    const service = new ContractService();
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalDesactivate, setModalDesactivate] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDOfferEmployee';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Rol', property: 'IDRoleName' },
        { title: 'Usuario', property: 'EntityName' },
        { title: 'Estado del Empleado', property: 'StateEmployeeName' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        getLista();
    }, [IDAdquisitionItem])


    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
       
        setLoading(true);
        service.getOfferEmployeeCatalogRender(IDAdquisitionItem).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }


    const agregarItem = () => {
        let b = {
            State: 1,
            StateEmployee: 10,
            IDRole: null,
            IDPostOffer: null,
            IDEmployee: 0,
            IDAdquisitionItem: IDAdquisitionItem,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateOfferEmployee(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.IDOfferEmployee) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        );
    }

    const eliminaritem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const desactivarItem = () => {
        bean.StateEmployee = 20;
        console.log(bean);
        updateItem();
        setModalDesactivate({ ...modalDesactivate, 'view': false });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Desactivar', icono: 'ri-user-unfollow-line', visible: true, evento: () => { setModalDesactivate({ ...modalDesactivate, "view": true, "name": "Desactivar usario" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>

            <div className="row">
                <div className="col-md-12">
                </div>
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <AccountEditor label="Funcionario" idAccount={bean.IDEmployee} onChange={({ IDAccount }) => { setBean({ ...bean, 'IDEmployee': IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectRolOffer idSelector={bean.IDRole} onChange={(e) => { setBean({ ...bean, 'IDRole': e }) }} titulo="" />
                    </div>
                </div>
            </Modal>
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} eventModal={eliminaritem} updateModal={setModalConfirm}></ModalConfirm>
            }
            {modalDesactivate.view &&
                <ModalConfirm modal={modalDesactivate} eventModal={desactivarItem} updateModal={setModalDesactivate}></ModalConfirm>
            }
        </>
    )
}