import { BasicPage } from '../../../theme/Component/BasicPage';
import './InboxCash-service';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { InboxCashService } from './InboxCash-service';
import { useEffect, useState } from 'react';
import { CompanyEditor } from '../Company/CompanyEditor';
import { toast } from 'react-toastify';
import { Singleton } from '../../../../core/services/Singleton';
import { OfficeEditor } from '../Office/OfficeEditor';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { Modal } from '../../../theme/Component/Modal';
import { CashReimbursement } from './CashReimbursement/CashReimbursement';


export const InboxCash = () => {
    const service = new InboxCashService();
    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);
    const [modal, setModal] = useState<any>({ name: "Rembolso", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalHistory, setModalHistory] = useState<any>({ name: "Historial", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDCashDeskAccount';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'IDAccount' },
        { title: 'Valor de la Base', property: 'BaseValue' },
        { title: 'Caja Destino', property: 'DestinityCash' },
        { title: 'Cuenta Contable', property: 'AccountantID' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {
        if (office != null && !isNaN(office)) {
            getLista();
        }
    }, [office])

    const getLista = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office, service.idAccount()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    const iconButtonsItem = [
        { nombre: "Historial de Rembolsos", visible: single.canCreate(), icono: "ri-history-line", evento: () => { setModalHistory({ ...modalHistory, "view": true }) } },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Rembolsos", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true }); }, },
            // { titulo: "Historial", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo='Mis Cajas Menores-Puntos de Venta'>
                <div className="row">
                    <div className="col">
                        <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }}></CompanyEditor>
                    </div>
                    <div className="col">
                        <OfficeEditor id={office} onchange={(e: any) => { setOffice(e) }}></OfficeEditor>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} >
                <CashReimbursement />
            </Modal>
            <Modal modal={modalHistory} updateModal={setModalHistory}>
                Aqui va el Historial de rembolsos
            </Modal>
        </>
    )
}