import {
  APPROVAL_VIEW,
  ASSIGN_TO_ME,
  ASSIGN_VIEW,
  MANAGE_VIEW,
  MY_REQUEST,
  VERIFY_VIEW,
  useInboxStore,
} from "../hooks/useInboxStore";
import { useCallback, useEffect } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { SelectorOffice } from "../../config/Componentes/SelectorOffice";
import { NavTabs } from "../../../../core/shared/buttons/NavTabs";
import no_results from '../../../../assets/img/no-results.png'
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import "../styles/inbox.css";
import 'animate.css'


const trays = [
  {
    value: ASSIGN_VIEW,
    label: "Asignación",
    show: true
  },
  {
    value: MANAGE_VIEW,
    label: "Gestión",
    show: true
  },
  {
    value: VERIFY_VIEW,
    label: "Verificación",
    show: true
  },
];

const single = Singleton.getInstance()

interface Props {
  my_requests: boolean
}

export const InboxSelector = ({ my_requests }: Props) => {

  const { setLoading } = useDashboardStore();

  const management_filters = [
    {
      value: MY_REQUEST,
      label: "Mis solicitudes",
      show: my_requests
    },
    {
      value: APPROVAL_VIEW,
      label: "Aprobación",
      show: true
    },
    {
      value: ASSIGN_TO_ME,
      label: "Asignados a mi",
      show: true
    },
  ];

  const {
    activeTab,
    activeMFilter,
    impList,
    officeSelected,
    refreshList,
    onSelectOffice,
    onChangeTab,
    onChangeMFilter,
    getProcedureImpForResponse,
    getProcedureImpForAssign,
    getProcedureImpForVerify,
    getProcedureImpByAccount,
    onSelectProcedure
  } = useInboxStore();

  useEffect(() => {
    getLista();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, officeSelected, activeMFilter, refreshList]);

  const getLista = useCallback(() => {
    switch (activeTab) {
      case ASSIGN_VIEW:
        officeSelected !== -1 &&
          getProcedureImpForAssign(single.getAccountID(), officeSelected);
        break;
      case MANAGE_VIEW:
        activeMFilter === ASSIGN_TO_ME
          ? getProcedureImpForResponse(single.getAccountID())
          : getProcedureImpByAccount(single.getAccountID());
        break;
      case VERIFY_VIEW:
        getProcedureImpForVerify(single.getAccountID())
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, officeSelected, activeMFilter, refreshList]);

  return (
    <div className="selector-side">
      <div className="navigator">
        <div className="d-flex justify-content-center">
          <NavTabs
            defalutValue={ASSIGN_VIEW}
            items={trays}
            value={activeTab}
            onChange={(e) => { onChangeTab(e) }}
          />
        </div>
        {activeTab === ASSIGN_VIEW &&
          <div style={{ maxWidth: '95%', marginTop: '1rem' }} className="d-flex justify-content-center">
            <SelectorOffice titulo="" idSelector={officeSelected} onChange={(e: number) => {
              console.log(e); onSelectOffice(e)
            }} />
          </div>
        }
        {activeTab === MANAGE_VIEW &&
          <div className="d-flex justify-content-center">
            <NavTabs
              defalutValue={MY_REQUEST}
              items={management_filters}
              value={activeMFilter}
              onChange={(e: string) => { onChangeMFilter(e) }}
            />
          </div>
        }
      </div>

      <div className="_inbox-selector">
        <ul className="p-0 m-0 overflow-y-hidden mh-100">
          {impList.length > 0 ? (
            impList.map((_e: any, _index) => (
              <li key={_index} className="d-flex py-3 pe-2 border-bottom _zoom justify-content-stretch" onClick={() => {
                onSelectProcedure(_e.DataBeanProperties);
              }} >
                <div className="animate__animated animate__fadeInLeft d-flex justify-content-between">
                  <span className='px-3 d-flex justify-content-center pt-1' style={{ marginRight: 35, backgroundColor: 'rgb(0 53 104 / 50%)', borderRadius: 100, height: 30, width: 30 }}>
                    <i className="ri-home-smile-line" style={{ color: '#003568', fontSize: 15 }}></i>
                  </span>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <small className="text-muted d-block mb-1">{`${_e.DataBeanProperties.ProcedureName}`}</small>
                      <h6 className="mb-0">{_e.DataBeanProperties.BusinessProcessName}</h6>
                      <small className="mt-3 " style={{ color: '#fa9d09' }}>{_e.DataBeanProperties.AlphaCode}</small>
                    </div>
                  </div>
                  <div>
                    <div className="badge badge-soft-info p-1">{`( ${_e.DataBeanProperties.StateName} )`}</div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-column no-results">
              <img src={no_results} alt="no_results" width={250} />
              <h5>No hay resultados para mostrar</h5>
            </div>
          )}
        </ul>
      </div>
    </div >
  );
};

