import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';

interface Props {
    idSelector: number;
    onChange: any;
    _disabled?: any;
}
export const SelectBudgetType = ({ idSelector, onChange, _disabled }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);



    useEffect(() => {


        setID(idSelector);
        getLista();

    }, [])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {



        service.getBudgetTypeCatalog(null).subscribe(
            (resp: any) => {

                console.log("BudgetType ", resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <label>Tipos de Presupuesto</label>
            <select disabled={_disabled} className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={item.DataBeanProperties.IDBudgetType} value={item.DataBeanProperties.IDBudgetType} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}