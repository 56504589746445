

interface Props {
    formulario: any;
}

export const Forma5Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center celda">
                    <h5>AUTORIZACIÓN CONSULTA EN LAS CENTRALES DE RIESGO</h5>
                </div>
                <div className="col-12 text-justify color-celda">
                    <strong>Declaro que la información que he suministrado es verídica y doy mi consentimiento expreso y voluntario a la CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA para consultar la información comercial y financiera ante la Central de Información del Sector Financiero (CIFIN), o cualquier otra entidad que maneje o administre base de datos, con los mismos fines.</strong>
                    <div className="col-12">
                        <label className='titul-form'> El cliente autorizo:</label>
                        <p><strong>{formulario.consultaR ? 'SI' : 'NO'}</strong></p>
                    </div>
                </div>
            </div>
            {/* <PrintJson  json={formulario}/> */}
        </>
    )
} 