import { useState, useEffect } from 'react';
interface Props {
    label?: string;
    lista: any;
    getLista:any;
}
export const MultiSelect = ({ label, lista,getLista }: Props) => {

    const [origen,setOrigen] = useState([]);
    const [destino,setDestino] = useState([]);
    const [cont,setCont] = useState(0);

    // uso 
    // para el uso del componente se debe pasar una lista de pares en forma 
    // [{id:1,Name:'nombre a mostrar'}]
    // el componente devuelve la lista de id seleccionados de la tabla de disponibles
    // [1,45,22,33]

    useEffect(()=>{
        // console.log(lista);
        setOrigen(lista);
        setDestino([]);
        
    },[lista])
    useEffect(()=>{
        let d = destino;
        let t:any = [];
        d.forEach((item:any)=>{
            t.push(item.id);
        })
        getLista(t);        
    },[cont])
    


    const add =(ele:any)=>{
        let d:any = destino;
        if(!d.includes(ele))
        {
            d.push(ele);
        }
        setCont(d.length);
        setDestino(d);                
    }
    const remove =(ele:any)=>{
        let d:any = destino;
        if(d.indexOf(ele)>=0)
        {                        
            d.splice(d.indexOf(ele),1);
        }
        setCont(d.length);
        setDestino(d);        
    }

    const contiene = (ele:any)=>{
        let d:any = destino;
        if(d.includes(ele))
            return true;
        else
            return false;
    }

    return (
        <>

            <div className="row">
                <div className="col">
                    <p>{label}</p>
                    <div className="row p-2">
                        <div className="col-md-6">
                            <p>Opciones</p>
                            <div className="list-group list-group-fill-success">
                                {
                                    origen.map((ele:any)=>{
                                        return(
                                            <span className={contiene(ele) ? 'list-group-item list-group-item-action cursor active' :'list-group-item list-group-item-action cursor'} onClick={()=>{add(ele)}}>
                                                <i className="ri-file-add-fill align-middle me-2"></i>{ele.Name}
                                            </span>
                                        )
                                    })
                                }
                                {origen.length === 0 &&
                                 <span className="list-group-item list-group-item-action">
                                 <i className=" ri-file-reduce-fill align-middle me-2"></i>Niguna Opción disponible
                                </span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p>Seleccionado</p>
                            <div className="list-group list-group-fill-success">
                                {
                                    destino.map((ele:any)=>{
                                        return(
                                            <span className="list-group-item list-group-item-action cursor" onClick={()=>{remove(ele)}}>
                                                <i className=" ri-file-reduce-fill align-middle me-2"></i>{ele.Name}
                                            </span>
                                        )
                                    })
                                }
                                {destino.length === 0 &&
                                 <span className="list-group-item list-group-item-action">
                                 <i className=" ri-file-reduce-fill align-middle me-2"></i>Niguna Opción seleccionada
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}