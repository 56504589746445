import { useEffect, useState } from "react";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { SelectConstantsValue } from "../../../../theme/Component/SelectConstantsValue";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { AdminService } from "../../../../../core/services/AdminService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";


interface Props {
    idFormType: number;
    handleUpdate: Function;
    modeTest?: boolean;
    data?: any;
    key?: any;
}
export const RenderForm = ({ idFormType, handleUpdate, modeTest, data, key }: Props) => {


    const { setLoading } = useDashboardStore();
    const service = new AdminService();

    const [propiedades, setPropiedades] = useState<any>([]);
    // let propiedades:any = [];
    const [bean, setBean] = useState<any>({});



    useEffect(() => {
        getProperties();

    }, [])
    useEffect(() => {
        if (data) {
            setBean(data);
        }
    }, [data])




    const getProperties = () => {
        setLoading(true);
        console.log(idFormType)
        service.getGenericFormAtributesByCode(idFormType).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setPropiedades(resp.DataBeanProperties.ObjectValue);
                    // propiedades = resp.DataBeanProperties.ObjectValue;
                    console.log(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    const renderField = (campo: any) => {
        return (
            <div className={`col-md-${campo.DataBeanProperties.ColumnSize}`}>
                <label>{campo.DataBeanProperties.Label}</label>
                {renderInput(campo.DataBeanProperties.FieldType, campo.DataBeanProperties.PropertyName, campo.DataBeanProperties.CodePar)}
            </div>
        )
    }

    const renderInput = (tipo: number, name: string, code: number) => {
        switch (tipo) {
            case 1:
                return <input type="text" className="form-control" value={bean.name} onChange={(e) => { setBean({ ...bean, name: e.target.value }) }} />
            case 2:
                return <input type="number" className="form-control" value={bean.name} onChange={(e) => { setBean({ ...bean, name: e.target.value }) }} />
            case 3:
                return <BasicSwitch estado={bean.name} eventChange={(e: any) => { setBean({ ...bean, name: e }) }} />
            case 4:
                return <textarea className="form-control" value={bean.name} onChange={(e) => { setBean({ ...bean, name: e.target.value }) }}></textarea>
            case 5:
                return <SelectConstantsValue key={code} code={code} value={bean.name} onChange={(e: any) => { setBean({ ...bean, name: e }) }} />
            case 6:
                return <input type="date" className="form-control" value={bean.name} onChange={(e) => { setBean({ ...bean, name: e.target.value }) }} />
            default:
                return <input type="text" className="form-control" value={bean.name} onChange={(e) => { setBean({ ...bean, name: e.target.value }) }} />
        }
    }

    const saveBean = () => {
        console.log(bean);
        handleUpdate(bean);
    }

    const renderForms = () => {
        return (
            propiedades.map((campo: any) => {
                return (
                    <>
                        {renderField(campo)}
                    </>
                )
            })
        )

    }

    return (
        <div className="row">
            <div className="col-md-12">

                {modeTest &&
                    <BasicButton icon="ri-refresh-line" clase="warning" eventClick={getProperties}>Actualizar</BasicButton>
                }
            </div>
            <div className="col">
                <div className="row" key={key ?? key}>
                    {renderForms()}

                </div>
                <div className="row">
                    <div className="col-md-6">
                        {propiedades.length > 0 &&
                            <BasicButton icon="ri-save-line" eventClick={saveBean}>Guardar</BasicButton>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}


