import { useEffect, useState } from "react";
import { AccountEditor } from "../../editor/AccountEditor";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicTable } from "../../theme/Component/BasicTable";
import { CardTaps } from "../../theme/Component/CardTaps";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { EnjoyedVacation } from "./EnjoyedVacation";
import { useEnjoyedVacation } from "./useEnjoyedVacation";
import { AnticipatedVacation } from "./AnticipatedVacation ";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
interface Props {
    rol: number;
}

export const EnjoyedVacationERP = ({ rol }: Props) => {
    const {
        user,
        year,
        setUser,
        setYear,
        lista,
        head,
        single,
        modal,
        setModal,
        bean,
        setRowBean,
        getLista,
    } = useEnjoyedVacation({ rol });

    // Propeiedad de roles define que tipo de vista tiene dependiendo desde donde abre el componente
    // 1: si tiene permisos para crear editar eliminar + los permisos de single y seleccionar otros usuarios
    // 2: solo vista de permisos con acceso al usuario personal

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Consultar vacaciones disfrutadas", icono: "ri-calendar-check-line", visible: single.canCreate(), evento: () => { setModal({ ...modal, "view": true, "name": "Vacaciones disfrutadas" }) }
            },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const [indexTap, setIndexTap] = useState(0);

    useEffect(() => {
        if (indexTap == 0) {
            getLista();
        }

    }, [indexTap])

    const taps = [
        { id: 1, name: 'Vacaciones Pagadas' },
        { id: 2, name: 'Vacaciones Anticipadas' }
    ]

    return (
        <>

            <div className="row">
                <div className="col-md-4 mt-2">
                    <YearSelector size="sm" value={year} onChange={(e) => setYear(e)} />
                </div>
                {rol == 1 &&
                    <>
                        <div className="col-md-6">
                            <AccountEditor idAccount={user} label="Funcionario" onChange={({ IDAccount }: any) => { setUser(IDAccount) }} disabled={rol != 1} />
                        </div>
                        <div className="col-md-2 mt-3">
                            <BasicButton icon="ri-user-search-line" eventClick={getLista}>Buscar</BasicButton>
                        </div>
                    </>
                }
            </div>
            <div className="mt-3">
                <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                    {indexTap === 0 &&
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <BasicTable body={lista} head={head} rowSet={setRowBean} listButtons={listaBotones()}  ></BasicTable>
                            </div>
                        </div>
                    }
                    {indexTap === 1 &&
                        <AnticipatedVacation _lista={lista} _rol={rol} _user={user} _vacation={bean} ></AnticipatedVacation >
                    }
                </CardTaps>
            </div>
            {/* Vacaciones disfutadas */}
            <Modal modal={modal} updateModal={setModal} >
                <EnjoyedVacation _rol={rol} _user={user} _vacation={bean} ></EnjoyedVacation>
            </Modal>
        </>
    )
}