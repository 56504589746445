import { GenericService } from "../../../../core/services/GenericService";

export class AccountantBookService extends GenericService {

  public getAccountantBookCatalog(args: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getAccountantBookCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  public updateAccountantBook(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "admin.AccountantBook_updateAccountantBook_admin.AccountantBook",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'admin.AccountantBook'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  public deleteAccountantBook(IDAccountant: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteAccountantBook_Number",
      ArgumentList: [IDAccountant],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }
}