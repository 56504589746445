import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { BpmService } from "../../../../../../core/services/BpmService";
import { ForpoService } from "../../../../../custom/forpo/components/service/ForpoService";
import { useDashboardStore } from "../../../../../pages/hooks/useDashboardStore";


interface Props {
    idSuppConstants: number;
}

export const useSuppConstantsValueForpo = ({ idSuppConstants }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalacciones, setModalacciones] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDSuppConstantsValue';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
        { title: 'Codigo', property: 'CodeID' },
    ]

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idSuppConstants])

    const getLista = () => {

        setLoading(true);
        service.getSuppConstantsValueCatalogPorPropiedadForpo("IDSuppConstants", idSuppConstants, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 1,
            Since: single.getCurrenDate(),
            IDSuppConstants: idSuppConstants,
            CodeID: 1,
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });
    }


    const veracciones = () => {
        setModalacciones({ ...modalacciones, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteSuppConstantsValueForpo(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateSuppConstantsValueForpo(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return {
        lista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        eliminarItem,
        setRowBean,
        modaljson,
        setModaljson,
        setUser,
        single,
        modalacciones,
        setModalacciones,
        veracciones
    };
};
