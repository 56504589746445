import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { FileService } from "../../../../../core/services/FileService";

interface Props {
    idMenu: any;
}


export const useInternalMenuItem = ({ idMenu }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const fileService = new FileService();
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

    useEffect(() => {
        if (idMenu) {
            getLista();
        }
    }, [idMenu]);


    const idBean = "IDInternalMenuItem";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        let propertie = {
            State: 1,
            IDInternalMenu: idMenu
        }
        setLoading(true);
        service.getInternalMenuItemCatalogPorPropiedades(propertie, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Titulo", property: "Title" },
        { title: "Descripción", property: "Descripction" },
        { title: "Imagen", property: "ImageCover" },
        { title: "URL", property: "URL" },
    ];

    const agregarItem = () => {
        let b = {
            Title: "",
            Description: "",
            State: 1,
            IDEmployee: single.getAccountID(),
            ImageCover: "",
            IDInternalMenu: idMenu,
            URL: ""
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar Sub Menu" });
    };

    const editarItem = () => {
        setModal({ ...modal, "view": true, name: 'Editar Sub Menu' });
    };

    const eliminarItem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateInternalMenuItem(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            if (bean.idBean > 0) {
                single.update(bean);
            } else {
                single.create(bean);
            }
        });
        setModal({ ...modal, "view": false });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        single,
        fileService,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
    };
};
