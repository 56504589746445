import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { InputDate } from "../../theme/Input/InputDate"
import { AccountEditor } from "../../editor/AccountEditor"
import { BasicButton } from "../../theme/Component/BasicButton"
import { BasicTable } from "../../theme/Component/BasicTable"
import { Singleton } from "../../../core/services/Singleton"
import { AuditService } from "../../../core/services/AuditService"
import { DropDown } from "../../theme/Component/DropDown"
import { Modal } from "../../theme/Component/Modal"
import { PrintJson } from "../../theme/Component/PrintJson"
import { BasicSwitch } from "../../theme/Component/BasicSwitch"
import { useDashboardStore } from "../../pages/hooks/useDashboardStore"
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';

export const AuditLog = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const audit = new AuditService();

    const [filtro, setFiltro] = useState<any>({
        init: single.getCurrenDate(),
        end: single.getCurrenDate(),
        idAccount: null
    });

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [action, setActions] = useState<any>({
        url: false,
        crear: true,
        editar: true,
        eliminar: true,
        xslx: true
    });
    const [filas, setFilas] = useState(100);
    const [btn, setBTN] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Mostrar JSON Before', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    const head = [
        { title: 'ID', property: 'IDAuditFunction', visible: true },
        { title: 'Fecha', property: 'Since', visible: true },
        { title: 'Nombre', property: 'EntityName', visible: true },
        { title: 'Servicio', property: 'MethodName', visible: true },
        { title: 'Argumentos', property: 'Args', visible: true },
    ]

    useEffect(()=>{

        return ()=>{
            setBean({});
        }
    },[])

    const getLista = () => {
        
        setBean({});
        setLoading(true);
        let m = filtro.init;
        m = m.replace("-", '');
        m = m.substring(0, 6);
        console.log(m);
        filtro.filtro = m;
        let ac = "";
        if (action.url) ac += 3 + ",";
        if (action.accion) ac += 5 + ",";
        if (action.crear) ac += 1 + ",";
        if (action.editar) ac += 2 + ",";
        if (action.eliminar) ac += 0 + ",";
        if (action.xslx) ac += 4 + ",";

        filtro.action = ac.substring(0, ac.length - 1);

        audit.getAuditRender(filas > 3000 ? 3000 : filas, filtro).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;

                    lista.forEach((ele: any) => {
                        ele.DataBeanProperties.EntityName =
                            (ele.DataBeanProperties.NAME1 ?? ' ') + " " +
                            (ele.DataBeanProperties.NAME2 ?? ' ') + " " +
                            (ele.DataBeanProperties.SURNAME1 ?? ' ') + " " +
                            (ele.DataBeanProperties.SURNAME2 ?? ' ');
                        if (ele.DataBeanProperties.JsonArgs)                        
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.JsonArgs.length > 100 ? ele.DataBeanProperties.JsonArgs.substring(0, 99) + "..." : '';
                        if (ele.DataBeanProperties.IDAction === 3)
                        {                            
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.Observations;
                            ele.DataBeanProperties.MethodName = "Navegación";
                        }
                        if (ele.DataBeanProperties.IDAction === 4)
                        {                            
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.Observations;
                            ele.DataBeanProperties.MethodName = "Exportar";
                        }
                            
                    });
                    setLista(lista);

                }
                setLoading(false);
            }
        )
    }

    const actions = [
        { id: 1, name: 'Mostrar Argumentos',activo: bean.JsonArgs != null},
        // { id: 2, name: 'Restarurar', activo: (bean.IDAction == 0) },
        // { id: 3, name: 'Revertir Cambios', activo: (bean.IDAction == 2) },
    ]

    const handleEvent = (item: any) => {

        setBTN(item);
        if (item.id === 1) {
            setModal({ ...modal, "view": true });
        }
    }




    return (
        <BasicPage titulo="Auditoria">
            <div className="row">
                <div className="col-md-2">
                    <label>Filas</label>
                    <input type="number" className="form-control mt-2" value={filas} onChange={(e) => setFilas(parseInt(e.target.value))} />
                </div>
                <div className="col-md-3">
                    <InputDate name="desde" label="Desde" value={filtro.init} setDate={(e: any) => { setFiltro({ ...filtro, "init": e }) }} />
                </div>
                <div className="col-md-3">
                    <InputDate name="hasta" label="Hasta" value={filtro.end} setDate={(e: any) => { setFiltro({ ...filtro, "end": e }) }} />
                </div>
                <div className="col-md-3">
                    <AccountEditor idAccount={filtro.idAccount} label="Funcionario" onChange={({ IDAccount }: any) => { setFiltro({ ...filtro, "idAccount": IDAccount }) }} />
                </div>
                <div className="col-md-8">
                    <div className="row mt-2 mb-2">
                        <div className="col">
                            <BasicSwitch label="URL" estado={action.url} eventChange={(e: any) => { setActions({ ...action, "url": e }) }}></BasicSwitch>
                        </div>
                        {/* <div className="col">
                            <BasicSwitch label="Accion" estado={action.accion} eventChange={(e: any) => { setActions({ ...action, "accion": e }) }}></BasicSwitch>
                        </div> */}
                        <div className="col">
                            <BasicSwitch label="Servicio" estado={action.crear} eventChange={(e: any) => { setActions({ ...action, "crear": e }) }}></BasicSwitch>
                        </div>
                        {/* <div className="col">
                            <BasicSwitch label="Editar" estado={action.editar} eventChange={(e: any) => { setActions({ ...action, "editar": e }) }}></BasicSwitch>
                        </div>
                        <div className="col">
                            <BasicSwitch label="Eliminar" estado={action.eliminar} eventChange={(e: any) => { setActions({ ...action, "eliminar": e }) }}></BasicSwitch>
                        </div> */}
                        <div className="col">
                            <BasicSwitch label="Exportar" estado={action.xslx} eventChange={(e: any) => { setActions({ ...action, "xslx": e }) }}></BasicSwitch>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <BasicButton icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                    {(bean.IDAccount && bean.JsonArgs) &&
                        <DropDown eventClick={handleEvent} lista={actions} ></DropDown>
                    }
                </div>

            </div>
            <div className="row">
                <BasicTable head={head} body={lista} rowSet={setBean} ></BasicTable>
            </div>

            <Modal modal={modal} updateModal={setModal} >
                {bean.JsonBefore &&
                    <>

                        <PrintJson json={bean.JsonArgs}></PrintJson>
                    </>
                }

            </Modal>
        </BasicPage>
    )
}