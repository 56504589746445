import { env } from "../../env";
import api from "./api/api";


export class FinanceService {

  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  public baseUrl: string = env.REACT_APP_ENDPOINT || "";

  //Fixedcost

  public getFixedCostCatalogPorPropiedad(nombrepropiedad: string | null, value: number | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFixedCostCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrepropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getFixedCostRender(year: number, state: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getFixedCostRender_Number_Number",
      ArgumentList: [year, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFixedCost(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.FixedCost_updateFixedCost_com.orange.bean.FixedCost",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FixedCost'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //

  //DeliveryType
  public getDeliveryTypeCatalog(page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getDeliveryTypeCatalog_Number_Number",
      ArgumentList: [page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteDeliveryType(idDeliveryType: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteDeliveryType_Number",
      ArgumentList: [idDeliveryType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateDeliveryType(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.DeliveryType_updateDeliveryType_com.orange.bean.supplier.DeliveryType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.DeliveryType'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //MeasurementUNIT
  public getMeasurementUnitCatalog(name: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getMeasurementUnitCatalog_Number_Number",
      ArgumentList: [name, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteMeasurementUnit(idMeasurementUnit: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteMeasurementUnit_Number",
      ArgumentList: [idMeasurementUnit],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateMeasurementUnit(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.MeasurementUnit_updateMeasurementUnit_com.orange.bean.supplier.MeasurementUnit",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.MeasurementUnit'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Budget Structure
  public getBudgetStructureCatalog(idBudgetStructure: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetStructureCatalog_Number	",
      ArgumentList: [idBudgetStructure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getBudgetIDTypeByType(id: any | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetIDTypeByType_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBudgetStructure(id: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteBudgetStructure_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateBudgetStructure(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.orange.bean.budget.BudgetStructure_updateBudgetStructure_com.orange.bean.budget.BudgetStructure",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.budget.BudgetStructure'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin Budget Structure

  //Budget Structure Item
  public getBudgetStructureItemCatalog(idBudgetStructure: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getBudgetStructureItemCatalog_Number",
      ArgumentList: [idBudgetStructure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBudgetStructureItem(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteBudgetStructureItem_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateBudgetStructureItem(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.budget.BudgetStructureItem_updateBudgetStructureItem_com.orange.bean.budget.BudgetStructureItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.budget.BudgetStructureItem'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin Budget Structure Item

  //BudgetID Type
  public getBudgetIDTypeCatalog(idBudgetIDType: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetIDTypeCatalog_Number",
      ArgumentList: [idBudgetIDType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBudgetIDTypeConstants(id: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetIDTypeConstants_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBudgetIDType(id: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteBudgetIDType_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateBudgetIDType(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.orange.bean.budget.BudgetIDType_updateBudgetIDType_com.orange.bean.budget.BudgetIDType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.budget.BudgetIDType'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin BudgetID Type
  //BudgetType

  public getBudgetTypeCatalog(budgetClass: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetTypeCatalog_Number",
      ArgumentList: [budgetClass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteBudgetType(id: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteBudgetType_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateBudgetType(bean: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.orange.bean.budget.BudgetType_updateBudgetType_com.orange.bean.budget.BudgetType	",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.budget.BudgetType'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin BudgetType
  //Functional ID

  public getTreeForFunctionalID(id: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.Tree_getTreeForFunctionalID_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public loadFunctionalIDTree(id: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.Tree_loadFunctionalIDTree_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // createFunctionalID(padre: number, bean: any) {
  //   const parametros = {
  //     ServiceName: "BpmService",
  //     MethodHash:
  //       "com.orange.bean.functional.FunctionalID_createFunctionalID_com.orange.bean.functional.FunctionalID_com.orange.bean.functional.FunctionalID",
  //     ArgumentList: [
  //       {
  //         DataBeanProperties: bean,
  //         DataBeanName: "com.orange.bean.functional.FunctionalID",
  //       },
  //       {
  //         DataBeanProperties: {
  //           IDLn: padre,
  //         },
  //         DataBeanName: "com.orange.bean.functional.FunctionalID",
  //       },
  //     ],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }

  //FunctionalID
  public getFunctionalIDSeparator() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "String_getFunctionalIDSeparator_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createFunctionalID(lista: any, name: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.functional.FunctionalID_createFunctionalID_java.util.List_String",
      ArgumentList: [
        lista, name
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteFunctionalID(id: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "int_deleteFunctionalID_Number	",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateFunctionalID(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.functional.FunctionalID_updateFunctionalID_com.orange.bean.functional.FunctionalID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.functional.FunctionalID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin Functional ID


  //projectID
  public getTreeForProjectID(year: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_getTreeForProjectID_Number",
      ArgumentList: [year],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getProjectIDSeparator() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "String_getProjectIDSeparator_Number	",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public loadProjectIDTree(IDLn: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_loadProjectIDTree_Number",
      ArgumentList: [IDLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createProjectID(year: number, lista: any, name: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.project.ProjectID_createProjectID_Number_java.util.List_String",
      ArgumentList: [
        year, lista, name
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteProjectID(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "int_deleteProjectID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateProjectID(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.project.ProjectID_updateProjectID_com.orange.bean.project.ProjectID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.project.ProjectID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin project ID

  //TrdID
  public getTreeForTrdID() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_getTreeForTrdID_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTrdIDSeparator() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "String_getTrdIDSeparator_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public loadTrdIDTree(idLn: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_loadTrdIDTree_Number",
      ArgumentList: [idLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createTrdID(lista: any, name: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.trd.TrdID_createTrdID_java.util.List_String",
      ArgumentList: [
        lista, name
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteTrdID(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "int_deleteTrdID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateTrdID(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.trd.TrdID_updateTrdID_com.orange.bean.trd.TrdID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.trd.TrdID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //fin TrdID

  //UnspscID
  public getTreeForUnspscID() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_getTreeForUnspscID_Number	",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUnspscIDSeparator() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "String_getUnspscIDSeparator_Number	",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public loadUnspscIDTree(idLn: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Tree_loadUnspscIDTree_Number",
      ArgumentList: [idLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createUnspscID(lista: any, name: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.unspsc.UnspscID_createUnspscID_java.util.List_String",
      ArgumentList: [
        lista, name
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteUnspscID(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "Number_deleteUnspscID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateUnspscID(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.unspsc.UnspscID_updateUnspscID_com.orange.bean.unspsc.UnspscID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.unspsc.UnspscID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin UnspscID
  //BudgetID
  public getTreeForBudgetID(id: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.advantage.shared.Tree_getTreeForBudgetID_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBudgetIDByName(id: number, name: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetIDByName_Number_String",
      ArgumentList: [id, name],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBudgetIDSeparator() {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "String_getBudgetIDSeparator_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public loadBudgetIDTree(idLn: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.advantage.shared.Tree_loadBudgetIDTree_Number",
      ArgumentList: [idLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createBudgetID(idType: number, lista: any, name: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.budget.BudgetID_createBudgetID_Number_java.util.List_String",
      ArgumentList: [
        idType, lista, name
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteBudgetID(id: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "boolean_deleteBudgetID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateBudgetID(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.unspsc.UnspscID_updateBudgetID_com.orange.bean.unspsc.UnspscID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.unspsc.UnspscID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin UnspscID
  public getAdquisitionItemBudgetIDCatalog(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionItemBudgetIDCatalog_Number	",
      ArgumentList: [
        idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteAdquisitionItemBudgetID(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteAdquisitionItemBudgetID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionItemBudgetID(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemBudgetID_updateAdquisitionItemBudgetID_com.orange.bean.supplier.AdquisitionItemBudgetID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.AdquisitionItemBudgetID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //AdquisitionItemBudgetID




  //Fin AdquisitionItemBudgetID


  // AdquisicionPlan

  public updateAdquisitionPlan(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_updateAdquisitionPlan_com.orange.bean.supplier.AdquisitionPlan",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.AdquisitionPlan'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public setAdquisitionPlanOnStructuringState(idAdquisitionPlan: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnStructuringState_Number_Number_String",
      ArgumentList: [
        idAdquisitionPlan, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnReviewedState(idAdquisitionPlan: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnReviewedState_Number_Number_String",
      ArgumentList: [
        idAdquisitionPlan, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnApprovedState(idAdquisitionPlan: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnApprovedState_Number_Number_String",
      ArgumentList: [
        idAdquisitionPlan, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnCancelledState(idAdquisitionPlan: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnCancelledState_Number_Number_String",
      ArgumentList: [
        idAdquisitionPlan, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnCreatedState(idAdquisitionPlan: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnCreatedState_Number_Number_String",
      ArgumentList: [
        idAdquisitionPlan, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlan(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_getAdquisitionPlan_Number	",
      ArgumentList: [
        idAdquisitionPlan
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanOnStructuringState(idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanOnStructuringState_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanOnHiringState(idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanOnHiringState_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanFunctionalIDCatalog(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanFunctionalIDCatalog_Number	",
      ArgumentList: [
        idAdquisitionPlan
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanFunctionalIDByIDAccount(idAdquisitionPlan: number, idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanFunctionalIDByIDAccount_Number_Number",
      ArgumentList: [
        idAdquisitionPlan, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionPlanPhaseConstants(id: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanPhaseConstants_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public setPhaseToAdquisitionPlan(idAdquisitionPlan: number | null, phase: number | null, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_setPhaseToAdquisitionPlan_Number_Number_Number_String	",
      ArgumentList: [
        idAdquisitionPlan, phase, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBudgetResourceCatalog(idBudgetResource: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getBudgetResourceCatalog_Number  ",
      ArgumentList: [
        idBudgetResource
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin AdquisicionPlan


}
