import { useEffect, useState } from "react";
import { ButtonSideID } from "./ButtonSideID";
import { SiteIDChilds } from "./SiteIDChilds";
import { AdminService } from "../../../../core/services/AdminService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import * as XLSX from 'xlsx';




interface Props {
  id: number | null;
  edit: boolean;
}
export const SiteID = ({ id, edit }: Props) => {
  const service = new AdminService();
  const adminService = new AdminService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [year, setYear] = useState(0);
  const [name, setName] = useState('');
  const [bean, setBean] = useState({});
  const [excelData, setExcelData] = useState<any>([]);
  const [idchild, setIDchild] = useState(0);
  const [file, setFile] = useState<any>();

  useEffect(() => {

    getListatree();
    getSeparator();

  }, []);
  useEffect(() => {

    console.log(excelData.length);

    if (excelData.length > 0) {
      setLoading(true);
      adminService.importSiteID(0, JSON.stringify(excelData)).subscribe(
        (resp: any) => {
          console.log(resp);
          setLoading(false);
          setFile(undefined);
        }
      );
    }

  }, [excelData]);

  const getListatree = () => {
    setLoading(true);
    service.getSiteIDTree().subscribe((resp: any) => {

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue.DataBeanProperties;

        console.log(arbol);


        setName(arbol.Name);
        setLista(arbol.ChildRootList);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getSeparator = () => {
    localStorage.setItem('separador', "-");

  }


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // Puedes elegir una hoja específica si hay varias en el archivo Excel
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convierte la hoja de cálculo en un arreglo de objetos
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        console.log(sheetData);

        setExcelData(sheetData);
      };

      reader.readAsArrayBuffer(file);
    }
  };


  return (
    <>

      {edit &&
        <div className="row">
          <div className="col">
            <strong>Importar Archivo</strong>
            <label>Archivo</label>
            <input type="file" className="form-control" accept=".xlsx" value={file} onChange={handleFileChange} />
          </div>
        </div>
      }

      <div className="row">

        <div className="col-md-12">
          {edit &&
            <ButtonSideID
              id={0}
              bean={{}}
              updateEvent={() => { console.log("Actualizacion evento"); getListatree(); }}
            />
          }
        </div>

        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any, index: number) => {
                return (
                  <li key={index} className="list-group-item itemh" onClick={() => { setBean(item.DataBeanProperties) }}>
                    {edit &&
                      <ButtonSideID
                        id={item.DataBeanProperties.IDLn}
                        bean={item.DataBeanProperties}
                        updateEvent={() => { console.log("Actualizacion evento"); getListatree(); }}
                      />
                    }


                    <strong className={idchild === item.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      localStorage.setItem('SiteID', JSON.stringify(item.DataBeanProperties));
                      setIDchild(item.DataBeanProperties.IDLn);

                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.DataBeanProperties.Code} </strong>
                      <small>{item.DataBeanProperties.Name}</small>
                    </strong>
                    {/* <span className="badge bg-success ms-1">{item.ChildrenCount}</span> */}
                    {(idchild === item.DataBeanProperties.IDLn) &&
                      <SiteIDChilds edit={edit} idChild={item.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

    </>
  );
};
