import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { AdminService } from '../../../core/services/AdminService';
import { Singleton } from '../../../core/services/Singleton';
import { InputDate } from '../../theme/Input/InputDate';
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicButton } from '../../theme/Component/BasicButton';
import { Alert } from '../../theme/Component/Alert';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';


export const InportRealesCredit = () => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const adminService = new AdminService();
  const [excelData, setExcelData] = useState<any>([]);
  const [file, setFile] = useState<any>();

  const [props, setProps] = useState<any>({
    fecha: single.getCurrenDate(),
    tipo: 1
  });
  const [tipoArchivo] = useState<any>([
    { id: 1, name: 'Hipotecario' },
    { id: 2, name: 'Leasing' }

  ]);

  useEffect(() => {
    console.log(excelData);
  }, [excelData]);

  const importGuaranteeList = () => {
    if (excelData.length > 0) {
      setLoading(true);
      adminService.importGuaranteeList(props.tipo, JSON.stringify(excelData), props.fecha).subscribe(
        (resp: any) => {
          console.log(resp);
          setLoading(false);
          setFile(undefined);
          setProps({ ...props, "message": 'Archivo Importado' });
        }
      );
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array', cellDates: true });

        // Puedes elegir una hoja específica si hay varias en el archivo Excel
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convierte la hoja de cálculo en un arreglo de objetos
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });



        setExcelData(sheetData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const cargarArchivo = () => {
    console.log(props);
    importGuaranteeList();

  }

  const handleChange = (e: any) => {


    if (e.target.name) {
      setProps({ ...props, [e.target.name]: e.target.value });
    }

  }

  return (
    <BasicPage titulo='Cargue Masivo'>
      <div className="row">
        <div className="col-md-6">
          <label>Tipo Archivo</label>
          <select name="tipo" className='form-select mt-2' value={props.tipo} onChange={handleChange}>
            {tipoArchivo.map((item: any) => {
              return (
                <option value={item.id}>{item.name}</option>
              )
            })

            }
          </select>
        </div>
        <div className="col-md-6">
          <InputDate label='Fecha de Corte' name='fecha' value={props.fecha} setDate={(e: any) => { setProps({ ...props, "fecha": e }) }}></InputDate>
        </div>
        <div className="col-md-6">
          <label >Archivo</label>
          <input type="file" className="form-control" name='archivo' accept=".xlsx" value={file} onChange={handleFileChange} />
        </div>
        <div className="col-md-6 mt-3">
          <BasicButton icon='' clase='primary' eventClick={cargarArchivo}>Cargar</BasicButton>
        </div>

      </div>
      <div className="row">
        <div className="col-md-12">
          {props.message &&
            <Alert clase='primary'> {props.message}</Alert>
          }
        </div>
      </div>
    </BasicPage>
  )
}