import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { useCredit } from "./useCredit";



export const Credit = () => {
    const {
        lista,
        head,
        setRowBean,
    } = useCredit();

    const listaBotones = () => {
        const botones = [
            {}
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Creditos Consultados/Financiados">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

        </>
    );
};
