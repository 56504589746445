import { useEffect, useState } from "react";
import { BasicButton } from "./BasicButton"
import * as XLSX from 'xlsx';
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import { Singleton } from "../../../core/services/Singleton";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    title: any;
    data: any;
    onExport: Function;
}
export const ExportXLSL = ({ title, data, onExport }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [miData, setMiData] = useState<any>([]);

    useEffect(() => {

        let tmp: any = [];


        try {
            data.forEach((element: any) => {

                let row = element.DataBeanProperties;
                let row1: any = {};
                title.forEach((ti: any) => {
                    row1[ti.title] = row[ti.property];
                });
                tmp.push(row1);

            });

            setMiData(tmp);
        } catch (error) {

        }




    }, [data])

    const exportToExcel = () => {



        const worksheet = XLSX.utils.json_to_sheet(miData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = single.basicName('reporte');


        onExport(fileName);

        const link = document.createElement('a');
        const url = URL.createObjectURL(dataBlob);
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };



    return (
        <>

            {single.canExport() &&
                <button title='Exportar XLSX' type="button" className="btn btn--primary btn-icon " onClick={exportToExcel}>
                    <i className=" ri-profile-line icontable icontable"></i>
                </button>
            }
        </>
        // <BasicButton icon="" eventClick={exportToExcel}>Exportar</BasicButton>
    )
}