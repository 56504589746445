import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { FileInput } from "../../../../theme/Component/FileInput";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { StoreBranch } from "./StoreBranch";
import { useStoreCategory } from "./useStoreCategory";



export const StoreCategory = () => {
    const {
        lista,
        head,
        setRowBean,
        modal,
        modalConfirm,
        setModal,
        setModalConfirm,
        single,
        updateItem,
        bean,
        setBean,
        iconButtonsItem,
        modalBranch,
        setModalBranch,
        eliminarItem
    } = useStoreCategory();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
            { titulo: "Agregar Sucursal", icono: "ri-community-line", visible: true, evento: () => { setModalBranch({ ...modalBranch, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Convenios">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control mt-2" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Descripción</label>
                        <input type="text" className="form-control mt-2" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <FileInput Media={bean.Media} MediaContext={bean.MediaContext} label="Imagen de la categoria" directory="StoreCategory"
                            onCreate={(e: any) => setBean({ ...bean, 'Media': e.Media, 'MediaContext': e.MediaContext })}
                        ></FileInput>
                    </div>
                </div>
            </Modal>

            <Modal modal={modalBranch} updateModal={setModalBranch} >
                <StoreBranch storeCategory={bean.IDStoreCategory}></StoreBranch>
            </Modal>

            <ModalConfirm eventModal={eliminarItem} modal={modalConfirm} updateModal={setModalConfirm}>
                Eliminar <strong>{bean.Name}</strong>
            </ModalConfirm>
        </>
    );
};
