import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID"
import { BasicPage } from "../../theme/Component/BasicPage"
import { InputMoney } from "../../theme/Input/InputMoney"
import { useState, useEffect } from 'react';
import { budgetTypes } from "../../Constants";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Singleton } from "../../../core/services/Singleton";
import { FinanceService } from "../../../core/services/FinanceService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const FixedCost = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalR, setModalR] = useState<any>({ name: 'Riesgos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});



    const idBean = 'IDFixedCost';

    const head = [

        { title: 'Nombre', property: 'Name' },
        { title: 'Valor Estimado', property: 'EstivatedValue' },

        { title: 'Tipo de Costo', property: 'NameType' },
        { title: 'Año', property: 'Year' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getRenderType = (id: any) => {
        let txt = '';


        budgetTypes.forEach((item: any) => {
            if (item.id == id)
                txt = item.name;
        })

        return txt;
    }

    const getLista = () => {

        setLoading(true);
        service.getFixedCostRender(2024, 20).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let lis = resp.DataBeanProperties.ObjectValue;
                    lis.forEach((element: any) => {

                        element.DataBeanProperties.NameType = getRenderType(element.DataBeanProperties.TypeCost);

                    });
                    setLista(lis);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            EstivatedValue: 1,
            State: 20,
            TypeCost: 1,
            BudgetID: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }



    const updateItem = (e: any) => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateFixedCost(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }


    ]

    const deletItem = () => {
        let e = bean;
        e.State = 21;
        console.log(e);
        updateItem(e);
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    return (
        <>
            <BasicPage titulo='Costos Fijos'>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deletItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label >Año</label>
                        <input type="text" className="form-control" value={bean.Year} onChange={(e) => { setBean({ ...bean, ['Year']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label >Tipo Presupuesto</label>
                        <SelectBudgetID renderValue="" tipoPresupuesto={0} edit={false} onChange={(e: any) => { setBean({ ...bean, "BudgetID": e.IDLn }) }} />
                    </div>

                    <div className="col-md-6">
                        <label >Valor Estimado</label>
                        <InputMoney id={0} name="Valor Estiamdo" value={bean.EstivatedValue} onChange={(e: any) => { console.log(e); setBean({ ...bean, "EstivatedValue": parseInt(e) }) }} />

                    </div>
                    {/* <div className="col-md-12">                    
                       
                        <label className="form-label">Fecha</label>
                       
                        <InputDate label="Desde" name="Desde" value={bean.Since} setDate={(e: any) => { setBean({ ...bean, 'Since': e }) }} ></InputDate>               
                    </div> */}


                    <div className="col-md-6">
                        <label >Tipo de Costo</label>
                        <select className="form-control" value={bean.TypeCost} onChange={(e) => { setBean({ ...bean, ['TypeCost']: e.target.value }) }}>
                            <option value={0}>...</option>
                            {budgetTypes.map((type) => (

                                <option value={type.id} >{type.name}</option>
                            ))}


                        </select>
                        {/* <PrintJson json={budgetTypes}/> */}
                    </div>

                </div>
            </Modal>



        </>
    )
}