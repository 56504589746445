import { NotFound } from './theme/NotFound';
import { useParams } from 'react-router-dom';
import { InboxSupplier } from './supplier/InboxSupplier';

export const RouterSupplier = (props: any) => {

    const { view } = useParams();

    const renderSwitch = (url: string) => {

        switch (url) {
            case 'supplier':
                return (<InboxSupplier area={1} />);
            case 'offers-inbox':
                return (
                    <div>Bandeja de Ofertas (Funcionarios)</div>
                );



            default:
                return <NotFound />;
        }
    }

    return (
        <div className='px-5'>
            {renderSwitch(view)}
        </div>
    );
}