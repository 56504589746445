import { BasicButton } from '../../theme/Component/BasicButton';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Singleton } from '../../../core/services/Singleton';
import { ContractService } from '../../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { Modal } from '../../theme/Component/Modal';
import { SelectAdquisitionArticleConstants } from "../select/SelectAdquisitionArticleConstants";
import { SelectUnspscID } from "../../admin/configuracion/UnspscID/SelectUnspscID";
import { FileInput } from "../../theme/Component/FileInput";
import { FileService } from "../../../core/services/FileService";
import { InputMoney } from "../../theme/Input/InputMoney";
import { ListMenu } from '../../theme/Component/ListMenu';
import { SelectorUmeasurement } from '../../bpm/config/Componentes/SelectorUmeasurement';
import { Alert } from '../../theme/Component/Alert';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
interface Props {
    idAdquisitionItem: number;
    selectRow?: any;
    rol?: number;
    onlyView?: boolean;
    itemName?: string;
    _ItemState?: number;
    _PlanState?: number;
    viewHiringProcces?: boolean;

}

export const AdquisitionArticle = ({ idAdquisitionItem, selectRow, rol, onlyView, itemName, _ItemState, _PlanState, viewHiringProcces }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const fileService = new FileService();
    const [listaA, setListaA] = useState([]);
    const [budgetList, setbudgetlist] = useState<any>([]);
    const [step, setStep] = useState(0);
    const [beanA, setBeanA] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBeanA = 'IDAdquisitionArticle';

    const headArticule = [
        // { title: 'Orden del Artículo', property: 'ItemOrder' },
        { title: 'ID', property: 'IDAdquisitionArticle', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Unidad de Medida', property: 'MeasurementUnit', visible: true },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Valor estimado unitario', property: 'EstimatedUnitaryValue', visible: true },
        { title: 'Valor Total', property: 'TotalValue', visible: true },
        { title: 'Presupuesto', property: 'BudgetTypeName', visible: false },
        { title: 'Especificaciones Técnicas', property: 'TechnicalSpecifications', visible: false },
        { title: 'Rubro', property: 'IDLnUnspscIDName', visible: true },
        { title: 'Estado', property: 'StructuringStateName', visible: true },
        { title: 'Adjunto', property: 'URL', visible: true },
        // { title: 'Mes a ejecutar', property: 'MonthName' },
        // { title: 'Meses estimados ejecución del bien/servicio', property: 'EstimatedMonths' },
    ]

    useEffect(() => {
        getAdquisitionArticleCatalog();
        getBudgetIDList();
    }, [idAdquisitionItem])

    const setRowBeanA = (bean: any) => {
        setBeanA(bean);
        validarAdd();
        validarDelete();
        validarUpdate();
    }

    const getBudgetIDList = () => {
        service.getBudgetIDList(idAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log("Rubros ", resp.DataBeanProperties.ObjectValue);
                    setbudgetlist(resp.DataBeanProperties.ObjectValue);
                }
                else {
                    setbudgetlist([]);
                }
            }
        );
        setModalRubro({ ...modalRubro, "view": false });
    }

    const getAdquisitionArticleCatalog = () => {

        setLoading(true);
        service.getAdquisitionArticleCatalog(idAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.MediaContext) {
                            element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`
                        } else {
                            element.DataBeanProperties.URL = "";
                        }
                    });
                    setListaA(tmp);
                } else {
                    setListaA([]);
                }
                setLoading(false);
            }
        );
    }

    const agregarItemArticule = () => {

        let b = {
            ItemOrder: 1,
            Description: '',
            TechnicalSpecifications: '',
            Quantity: 1,
            MeasurementUnit: '',
            Type: 0,
            UnspscViewCode: '',
            EstimatedUnitaryValue: 0,
            MonthName: '',
            EstimatedMonths: 1,
            Media: '',
            IDAccountStructurer: single.getAccountID(),
            IDAdquisitionItem: idAdquisitionItem,
            IDLnBudgetID: 0
        }
        setBeanA(b);
        setModal({ ...modal, "view": true });
    }

    const editarItem = () => {
        setModal({ ...modal, "view": true });
    }

    const eliminarItemArticulo = () => {

        setLoading(true);
        service.deleteAdquisitionArticle(beanA.IDAdquisitionArticle).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getAdquisitionArticleCatalog();
                }
                single.delete(beanA[idBeanA], beanA);
            }
        );
        setModalConfirm({ ...modalConfirm, "view": false });
    }


    const updateItemArticulo = () => {

        console.log(beanA);
        setModal({ ...modal, "view": false });
        setLoading(true);
        service.updateAdquisitionArticle(beanA).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getAdquisitionArticleCatalog();
                }
                if (beanA[idBeanA]) {
                    single.update(beanA);
                }
                else {
                    single.create(beanA);
                }
                setLoading(false);
            });
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {step === 1 &&
                                <div className="col-md">

                                    <BasicButton value="Regresar" icon="ri-arrow-left-fill" clase='primary' eventClick={() => { setStep(0); setBeanA({}) }}></BasicButton>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    let validarDelete = () => {
        let a = false
        if (single.canDelete() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
            a = true;
        } else {
            a = false;
        }
        return a;
    }
    let validarUpdate = () => {
        let b = false
        // if (single.canEdit() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
        if (single.canEdit() ) {
            b = true;
        } else {
            b = false;
        }
        return b;
    }
    let validarAdd = () => {
        let c = false
        // if (single.canCreate() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
        if (single.canCreate()) {
            c = true;
        } else {
            c = false;
        }
        return c;
    }

    const iconButtonsArticule = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: viewHiringProcces ? true : validarAdd(), evento: agregarItemArticule }
    ]

    const listaBotonesArticulos = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: viewHiringProcces ? true : validarUpdate(), evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: viewHiringProcces ? true : validarDelete(), evento: () => { setModalConfirm({ ...modalConfirm, 'view': true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const mostrar = () => {
        console.log(listaA)
    }

    return (
        <>
            <p>
                <small>Bienes/Servicios del Ítem / Articulo</small><br />
                <strong>{itemName ? itemName : ''}</strong>
            </p>
            {onlyView ?
                <BasicTable head={headArticule} body={listaA} rowSet={setRowBeanA}></BasicTable>
                :
                <>
                    <BasicTable listButtons={listaBotonesArticulos()} headButtons={renderButton()} iconButtons={iconButtonsArticule} head={headArticule} body={listaA} rowSet={setRowBeanA}></BasicTable>
                </>
            }


            <Modal modal={modal} updateModal={setModal} eventModal={updateItemArticulo}>

                {budgetList.length == 0 &&
                    <Alert clase='primary'>
                        La necesidad debe tener al menos un <b>Rubro</b>
                    </Alert>
                }
                {budgetList.length > 0 &&
                    <>
                        <div className="row">

                            <div className="col-md-4">
                                <label >Cantidad</label>
                                <input type="number" className="form-control mt-2" value={beanA.Quantity} onChange={(e) => { setBeanA({ ...beanA, "Quantity": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label >Valor estimado unitario</label>

                                <InputMoney id={0} name='Valor estimado unitario' value={beanA.EstimatedUnitaryValue} onChange={(e: any) => { setBeanA({ ...beanA, ['EstimatedUnitaryValue']: parseInt(e) }) }} />
                            </div>
                            <div className="col-md-4">
                                <SelectorUmeasurement titulo='Unidad de Medida' idSelector={beanA.MeasurementUnit} onChange={(e: any) => { setBeanA({ ...beanA, "MeasurementUnit": e }) }} />
                            </div>

                            <div className="col-md-12 mt-1">
                                <label >Especificaciones Técnicas</label>
                                <textarea className="form-control mt-2" value={beanA.TechnicalSpecifications} onChange={(e) => { setBeanA({ ...beanA, "TechnicalSpecifications": e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12 mt-2">
                                <label >Descripción</label>
                                <textarea className="form-control mt-2" value={beanA.Description} onChange={(e) => { setBeanA({ ...beanA, "Description": e.target.value }) }}></textarea>
                            </div>

                            <div className="col-md-3 mt-2">
                                <SelectAdquisitionArticleConstants idSelector={beanA.Type} onChange={(e: any) => { setBeanA({ ...beanA, "Type": e }) }}></SelectAdquisitionArticleConstants>
                            </div>
                            <div className="col-md-9 mt-2">
                                <SelectUnspscID renderValue={beanA.IDLnUnspscIDName} onChange={(e: any) => {
                                    setBeanA({ ...beanA, "IDLnUnspsc": e.IDLn })
                                }}></SelectUnspscID>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label>Rubro</label>
                                <select className='form-select mt-2' value={beanA.IDLnBudgetID} onChange={(e) => {
                                    setBeanA({ ...beanA, "IDLnBudgetID": parseInt(e.target.value) })
                                }}>
                                    <option key={0} value="0">...</option>
                                    {budgetList && budgetList.map((item: any, index: number) => {
                                        return (
                                            <>
                                                {item &&
                                                    <option key={index} value={item.DataBeanProperties ? item.DataBeanProperties.IDLn : 0}>
                                                        {item.DataBeanProperties &&
                                                            <>
                                                                {item.DataBeanProperties.BudgetViewCode} - {item.DataBeanProperties.Name}
                                                            </>
                                                        }

                                                    </option>
                                                }
                                            </>

                                        )
                                    })

                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <FileInput Media={beanA.Media} MediaContext={beanA.MediaContext} onCreate={(fileMap: any) => {
                                    setBeanA({ ...beanA, "Media": fileMap.Media, "MediaContext": fileMap.MediaContext })
                                }}></FileInput>
                            </div>
                        </div>
                    </>
                }
            </Modal>
            <ModalConfirm eventModal={eliminarItemArticulo} modal={modalConfirm} updateModal={setModalConfirm} children={''} />

        </>
    )
}