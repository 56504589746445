import { useEffect, useState } from "react";
import { ContractService } from "../../core/services/ContractService";
import { BasicTable } from "../theme/Component/BasicTable";

interface Props {
    bean: any;
}
export const AdquisitionHistory = ({ bean }: Props) => {

    const serviceContract = new ContractService();
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {
        console.log(bean);

        getLista();
    }, [])

    const getLista = () => {

        serviceContract.getAdquisitionPlanApprovalNoteCatalog(bean.IDAdquisitionPlan, null, null, null).subscribe(
            (resp: any) => {
                console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
            }
        )

    }


    const head = [
        { title: 'ID', property: 'IDAdquisitionPlanApprovalNote' },
        { title: 'Fecha de Evento', property: 'EventDate' },
        { title: 'Funcionario', property: 'AccountName' },
        { title: 'Tipo de Evento', property: 'TypeName' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Plan Aprobado para Ejecución', property: 'Approved' }
    ]

    return (
        <>
            <h4>Historico</h4>
            <hr />
            <BasicTable head={head} body={lista} rowSet={(e: any) => { console.log(e); }} ></BasicTable>
        </>
    )
}