import { useEffect, useState } from 'react';
import { PrintJson } from '../../../theme/Component/PrintJson';
import { BasicPage } from '../../../theme/Component/BasicPage';


export const ReaderUHF=()=>{

    const [lista,setLista] = useState<any>([]);
    const [elemento,setElemento] = useState<any>({});

    useEffect(() => {
        let ws = new WebSocket('wss://test.advantage.com.co/quickdataerp/listen');
      
        const connectWebSocket = () => {
          ws = new WebSocket('wss://test.advantage.com.co/quickdataerp/listen');
      
          ws.onopen = () => {
            console.log('Conexión establecida');
          };
      
          ws.onmessage = (event) => {
            setLista(prevMessages => [...prevMessages, event.data ? JSON.parse(event.data) :{}]);
          };
      
          ws.onclose = () => {
            console.log('Conexión cerrada');
            setTimeout(connectWebSocket, 2000);
          };
        };
      
        connectWebSocket();
      
        return () => {
          setLista([]);
          ws.close();
        };
      }, []);
      

    //   useEffect(()=>{
    //         console.log(elemento);
    //         lista.push(elemento);
    //         setLista({...lista})
    //   },[elemento])

      const additem = (item:any) =>
      {
           
        try {
            let i:any = JSON.parse(item);
            let tmp = lista;
            tmp.push(i);
            console.log(tmp);
            setLista(tmp);
            } catch (error) {
                
            }

      }


    return(
        <BasicPage titulo='Monitor Almácen'>
            <div className="row">
            {/* <div className="col">
                <PrintJson json={lista}/>
            </div> */}
            <div className="col">
                <div className="list-group">
                    <ul className="list-group">
                        {lista && lista.reverse().map((item:any)=>{
                            return(
                                <li className="list-group-item mt-1">
                                    <p>
                                        <strong>{item.FixedAssetName}</strong>
                                        <hr />
                                        <p>
                                            {item.Description}
                                        </p>
                                        <p>
                                            <strong>Fecha Inicial :</strong>{item.InitDate} <br />
                                            <strong>Última Depreciacion :</strong>{item.DepreciationLastDate}
                                        </p>
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        </BasicPage>
    )
}