import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmServiceall } from "../../../../core/services/BpmServiceall";
import { Tablaaccionesformularios } from "../Componentes/Tablaaccionesformularios";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface Props {
    idRiskCategory: number;
}

export const Riskitem = ({ idRiskCategory }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmServiceall();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalacciones, setModalacciones] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDRiskItem';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Risk' },
        { title: 'Descripcion', property: 'RiskDescription' },

    ]

    const total = () => {
        console.log(bean);
        updateItem();
    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idRiskCategory])


    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getRiskItemCatalogPorPropiedad('IDRiskCategory', idRiskCategory, null).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {

                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 1,
            Risk: '',
            RiskDescription: '',
            Since: single.getCurrenDate(),
            IDRiskCategory: idRiskCategory,
            CodeID: 1,



        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }

    const editarjson = () => {

        setModaljson({ ...modaljson, ['view']: true });

    }




    const veracciones = () => {

        setModalacciones({ ...modalacciones, ['view']: true });

    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteRiskItem(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateRiskItem(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Json', icono: 'ri-file-user-line', visible: true, evento: () => { setModaljson({ ...modaljson, "view": true }) } },
            { titulo: 'Acciones', icono: 'ri-file-user-line', visible: true, evento: () => { setModalacciones({ ...modalacciones, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]




    return (
        <>
            <div className="row">
                {/* <div className="col-md-12">
                        <SelectorRiskitem  idSelector={idModule} onChange={(e:any)=>{setIDModule(e) }}/>
                    </div> */}
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={total}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Risk} onChange={(e) => { setBean({ ...bean, ['Risk']: e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.RiskDescription} onChange={(e) => { setBean({ ...bean, ['RiskDescription']: e.target.value }) }}></textarea>
                    </div>


                </div>
            </Modal>


            <Modal modal={modalacciones} updateModal={setModalacciones} eventModal={veracciones}>
                <Tablaaccionesformularios IDform={bean.IDForm} />
            </Modal>
        </>
    )
}