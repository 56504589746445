import { useState, useEffect } from 'react';

import { FinanceService } from '../../../core/services/FinanceService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idSelector: number;
    onChange: any;
}
export const SelectBudgetResource = ({ idSelector, onChange }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);



    useEffect(() => {


        setID(idSelector);
        getLista();

    }, [idSelector])
    // useEffect(()=>{

    //     onChange(id);

    // },[id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        let cache = Singleton.getInstance().getCacheItem("BudgetResourceCatalog");
        if (cache) {
            setLista(cache);
        }
        else {
            service.getBudgetResourceCatalog(null).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        Singleton.getInstance().setCacheItem("BudgetResourceCatalog", resp.DataBeanProperties.ObjectValue);
                    }
                }
            );
        }

    }

    return (
        <>
            <label>Recursos</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (

                            <option key={item.DataBeanProperties.IDBudgetResource} value={item.DataBeanProperties.IDBudgetResource} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}