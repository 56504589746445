import { useState, useEffect } from "react";
import { BasicPage } from "../../theme/Component/BasicPage";

import { SelectSiteID } from "../../admin/configuracion/SiteID/SelectSiteID";
import { Singleton } from "../../../core/services/Singleton";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { DropDown } from "../../theme/Component/DropDown";
import { toast } from "react-toastify";

export const SiteIDRate = () => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new GarantiasService();
  const [lista, setLista] = useState([]);
  const [modal, setModal] = useState<any>({ name: "Permisos", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
  const [modalMasive, setModalMasive] = useState<any>({ name: "Agregar tasas de valorizacion automaticamente", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
  const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
  const [bean, setBean] = useState<any>({});
  const [year, setYear] = useState<number>(null);

  const idBean = "IDSiteIDRate";

  const head = [
    { title: "ID", property: idBean },
    { title: "Año ", property: "YearRate" },
    { title: "Ciudad ", property: "NameSite" },
    { title: "Porcentaje de valorización", property: "PercentValue" },
  ];

  useEffect(() => {
    getLista();
  }, [year]);

  const getLista = () => {
    setLoading(true);
    service.getSiteIDRateRender(null, null, {
      State: 1,
      YearRate: year
    }).subscribe((resp: any) => {
      // setLista([]);

      if (resp.DataBeanProperties.ObjectValue) {
        setLista(resp.DataBeanProperties.ObjectValue);
      }

      setLoading(false);
    });
  };

  const updateItem = (e: any) => {
    console.log(bean);
    setModal({ ...modal, "view": false });
    setLoading(true);
    service.updateSiteIDRate(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      single.update(bean);
    });
  };

  const agregarItem = () => {
    let b = {
      SiteIDLn: 0,
      PercentValue: 0,
      State: 1,
    };
    setBean(b);
    setModal({ ...modal, "view": true });
  };

  const deletItem = () => {
    let e = bean;
    e.State = 2;
    console.log(e);
    updateItem(e);
    setModalConfirm({ ...modalConfirm, "view": false });
  };

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Editar",
        icono: "ri-file-list-line",
        visible: true,
        evento: () => {
          setModal({ ...modal, view: true });
        },
      },
      {
        titulo: "Eliminar",
        icono: "ri-file-user-line",
        visible: true,
        evento: () => {
          setModalConfirm({ ...modalConfirm, view: true });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: single.canCreate(),
      icono: "ri-file-add-line",
      evento: agregarItem,
    },
    // {nombre:'Agregar',icono:'ri-file-add-line',evento:agregarItem}
  ];

  const actionsDropDown = [
    { id: 1, name: 'Cargue masivo', permiso: null, activo: true },
  ];

  const eventChange = (e: any) => {
    if (e.id === 1) {
      setModalMasive({ ...modalMasive, "view": true });
      let x = { PercentValue: 0.0 };
      setBean(x);
    }
  }

  const buttons = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
        </div>
      </div>
    )
  }

  const validate = () => {
    if (bean.PercentValue > 0 && bean.SiteIDLn > 0) {
      updateItem(bean);
      console.log(bean)
    } else if (bean.PercentValue <= 0) {
      toast.error('El porcentaje de valorizacion no puede ser igual o menor a 0');
    }
  }

  const bulkUpload = () => {
    setModalMasive({ ...modalMasive, "view": false });
    setLoading(true);
    service.completeCities(bean.YearRate, bean.PercentValue, single.getAccountID()).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      single.update(bean);
    });
  }

  return (
    <>
      <BasicPage titulo="Tasa por ciudad">
        <div className="row">
          <div className="col-md-6">
            <YearSelector value={year} onChange={(e) => setYear(e)}></YearSelector>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 p-2">
            <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setBean} ></BasicTable>
          </div>
        </div>
      </BasicPage>

      <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deletItem}>
        {bean.Name}
      </ModalConfirm>

      <Modal modal={modal} updateModal={setModal} eventModal={validate}>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Porcentaje de valorización</label>
            <input type="number" className="form-control" value={bean.PercentValue} onChange={(e) => { setBean({ ...bean, "PercentValue": e.target.value }); }} ></input>
          </div>
          <div className="col-md-6">
            <YearSelector tittle="Seleccione el año" value={bean.YearRate} onChange={(e: any) => { setBean({ ...bean, YearRate: e }); }}></YearSelector>
          </div>
          <div className="col-md-12">
            <SelectSiteID edit={false} onChange={(e: any) => { setBean({ ...bean, SiteIDLn: e.IDLn }); }} renderValue={bean.NameSite} />
          </div>
        </div>
      </Modal>
      <Modal modal={modalMasive} updateModal={setModalMasive} eventModal={bulkUpload}>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Porcentaje de valorización</label>
            <input type="number" className="form-control" value={bean.PercentValue} onChange={(e) => { setBean({ ...bean, "PercentValue": e.target.value }); }} ></input>
          </div>
          <div className="col-md-6">
            <YearSelector tittle="Seleccione el año" value={bean.YearRate} onChange={(e: any) => { setBean({ ...bean, YearRate: e }); }}></YearSelector>
          </div>
        </div>
      </Modal>
    </>
  );
};
