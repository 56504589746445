import { useState } from "react";
import { RenderForm } from "../../../../theme/Component/RenderForm";
import { FileInput } from "../../../../theme/Component/FileInput";
import { BasicButton } from "../../../../theme/Component/BasicButton";

interface Props {
    action: any
    exportMap: Function
}

export const RenderInbox = ({ action, exportMap }: Props) => {

    const [doc, setDoc] = useState<any>({});

    const updateDoc = () => {
        console.log("doc=======>", doc)
        exportMap(doc);
    }

    const updateForm = (map: any) => {
        exportMap(map);
        console.log("MPA======>", map)
    }

    const renderSwitch = (item: any) => {

        switch (item.FormCode) {
            default:
                return <RenderForm idFormType={item.FormCode} handleUpdate={(e: any) => { updateForm(e) }} />
        }
    }

    return (
        <>
            <div className="row">
                {/* <PrintJson json={action}></PrintJson> */}
                <div className="col-md-12">
                    {action.FormCode ?
                        <div className="text-center">
                            <h4>{action.Name}</h4>
                            <div className='d-flex justify-content-center mb-4' >
                                {renderSwitch(action)}
                            </div>
                        </div>
                        :
                        < div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>{action.Name}</h4>
                                        <FileInput
                                            docAcept="application/pdf"
                                            label=""
                                            Media={doc.Media}
                                            MediaContext={doc.MediaContext}
                                            directory="Antecedentes"
                                            onCreate={(e: any) => setDoc({ ...doc, 'Media': e.Media, 'MediaContext': e.MediaContext })}
                                        ></FileInput>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            {doc.Media &&
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="col-md-12 mt-3" style={{ display: "flex", justifyContent: "center" }}>
                                                            <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    }
                </div>
            </div >

        </>
    )
}