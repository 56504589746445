import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';

interface Props{
    idDocumentTemplateVersion:number
}
export const DocumentTemplateFileTypeCatalog=({idDocumentTemplateVersion}:Props)=>{

    const single = Singleton.getInstance();
    const service = new AdminService();
    const [bean,setBean] = useState<any>({});

    const [lista,setLista] = useState<any>([]);

    useEffect(()=>{
        getDocumentTemplateFileTypeCatalog();
    },[idDocumentTemplateVersion])

    const getDocumentTemplateFileTypeCatalog=()=>{
        single.spinner(true);
        service.getDocumentTemplateFileTypeCatalog(idDocumentTemplateVersion).subscribe(
            (resp:any)=>{

                if(resp.DataBeanProperties.ObjectValue)
                {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((element:any) => {
                            element.DataBeanProperties.URLLink = `<a href='${element.DataBeanProperties.URLLink}' target='_blank'>Ver</a>`
                    });
                    setLista(list);
                }
                else{
                    setLista([]);
                }
                single.spinner(false);
            }
        )
    }

    const head = [
        {title:'ID',property:'IDDocumentTemplateVersion',visible:true},
        {title:'Tipo de Archivo',property:'FileType',visible:true},
        {title:'Última Actualización',property:'LastUpdate',visible:true},
        {title:'Funcionario que actualiza',property:'IDEmployee',visible:true},
        {title:'URL de archivo',property:'URLLink',visible:true},
    ]

    return(
        <div className="row">
            <div className="col">
                <BasicTable body={lista} head={head} rowSet={setBean}></BasicTable>
            </div>
        </div>
    )
}