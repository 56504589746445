/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProfileDashed } from './components/ProfileDashed/ProfileDashed'
import { EventItem, NextEvents } from './components/NextEvents/NextEnvents'
import { MenuDashed, QuickAction } from './components/MenuDashed/MenuDashed'
import { MenuButton } from './components/MenuButton/MenuButton'
import { BsInboxes } from 'react-icons/bs'
import { IoGitPullRequestOutline } from "react-icons/io5";
import './dashed.scss'
import './components/ProfileDashed/profiledashed.scss'
import { BannerDashed } from './components/BannerDashed/BannerDashed'
import useDashed from './hooks/useDashed'
import { useNavigate } from 'react-router-dom'


export interface DashData {
    greetings: string,
    name: string
}


export const events: EventItem[] = []

export const Dashed = () => {

    const navigate = useNavigate();
    const { renderSwitch, example, activeQA, setActiveQA } = useDashed()

    return (
        <div className='dash_grid '>
            <div className="main_info animate__animated animate__fadeInDown">
                <ProfileDashed changeProfileImg={() => {
                    console.log('Change profile img');
                }}>
                    <div className='d-flex gap-2 w-100'>
                        <MenuButton item={{
                            label: 'Bandeja',
                            icon: <BsInboxes />,
                            event: () => { navigate("/app/admin/user-inbox") }
                        }} iconSize={60} labelSize={16} />
                        <MenuButton item={{
                            label: 'Mis Solcitudes',
                            icon: <IoGitPullRequestOutline />,
                            event: () => { }
                        }} iconSize={60} labelSize={16} />
                    </div>
                </ProfileDashed>
            </div>
            <div className="calendar animate__animated animate__fadeInRight">
                <NextEvents events={events} />
            </div>
            <div className="fixed_content animate__animated animate__fadeInLeft">
                <MenuDashed quick_actions={example} />
            </div>
            <div className="dynamic_content animate__animated animate__fadeInRight">
                {activeQA &&
                    <div
                        className='d-flex flex-column align-items-center w-100'
                        style={{
                            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                            borderRadius: "20px",
                            minHeight: "570px",
                            overflow: "hidden",
                            WebkitBorderRadius: "3%"
                        }}>
                        <BannerDashed quickAction={activeQA} />
                        <div style={{ maxHeight: "47vh", overflow: "auto" }}>
                            {renderSwitch()}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
