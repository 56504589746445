import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { Singleton } from '../../../../core/services/Singleton';
import { ButtonTrdID } from "./ButtonTrdID";
import { TrdIDChilds } from "./TrdIDChilds";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";




interface Props {
  id: number | null;
  edit: boolean;
}
export const TrdID = ({ id, edit }: Props) => {
  const service = new FinanceService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [year, setYear] = useState(0);
  const [name, setName] = useState('');
  const [bean, setBean] = useState({});

  const [idchild, setIDchild] = useState(0);

  useEffect(() => {

    getListatree();
    getSeparator();

  }, []);

  const getListatree = () => {
    setLoading(true);
    service.getTreeForTrdID().subscribe((resp: any) => {

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
        setName(arbol.Name);
        setLista(resp.DataBeanProperties.ObjectValue.Childs);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getSeparator = () => {
    service.getTrdIDSeparator().subscribe(
      (resp: any) => {
        let separador = resp.DataBeanProperties.ObjectValue;
        localStorage.setItem('separador', separador);
      }
    );
  }



  return (
    <>


      <div className="row">

        <div className="col-md-12">
          {/* {lista.length === 0 && edit &&
            
          } */}
          <ButtonTrdID
            id={0}
            bean={{}}
            updateEvent={getListatree}
          />
        </div>

        <div className="col-md-12">
          <ul className="list-group" key={id}>
            {lista.length > 0 &&
              lista.map((item: any, index: number) => {
                return (
                  <li key={index} className="list-group-item itemh" onClick={() => { setBean(item.EnvolvedObject.DataBeanProperties) }}>
                    {edit &&
                      <ButtonTrdID
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={getListatree}
                      />
                    }


                    <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.TrdViewCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>
                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <TrdIDChilds edit={edit} idChild={item.EnvolvedObject.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

    </>
  );
};
