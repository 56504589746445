import { useEffect, useState } from "react";
import { BpmService } from "../../../../core/services/BpmService";
import { Singleton } from "../../../../core/services/Singleton";
import { useForm } from "react-hook-form";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

export const useCaracterization = () => {
  const { setLoading } = useDashboardStore();
  const single = Singleton.getInstance()
  const service = new BpmService();
  const [lista, setLista] = useState([]);
  const [selector, setSelector] = useState(0);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [bean, setBean] = useState<any>({
    Name: "",
    Description: "",
    State: 0,
    Rules: [],
    Since: "",
    IDCustomerType: 0,
  });
  const [modal, setModal] = useState<any>({
    name: "Editar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });

  useEffect(() => {
    getLista();
  }, []);

  const [modalConfirm, setModalConfirm] = useState<any>({
    name: "Confirmar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-sm",
  });

  const idBean = "IDCharacterization";

  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  const getLista = () => {
    setLoading(true);
    service.getCharacterizationCatalog(null).subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {
        setLista(resp.DataBeanProperties.ObjectValue);
      }
      setLoading(false);
    });
  };

  const agregarItem = () => {
    let b = {
      Name: "",
      Description: "",
      State: 1,
      Rules: JSON.stringify([]),
      Since: single.getCurrenDate(),
      IDCustomerType: selector,
    };
    setBean(b);
    setModal({ ...modal, ["view"]: true, name: "Agregar" });
  };

  const editarItem = () => {
    setModal({ ...modal, ["view"]: true });
  };

  const eliminarItem = () => {
    setLoading(true);
    service.deleteCharacterization(bean[idBean]).subscribe((resp: any) => {
      setLoading(false);
      if (resp) {
        getLista();
      }
      single.delete(bean[idBean], bean);
    });
    setModalConfirm({ ...modalConfirm, ["view"]: false });
  };

  const updateItem = () => {
    setModal({ ...modal, ["view"]: false });

    setLoading(true);
    service.updateCharacterization(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      single.update(bean);
    });
  };

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "Descripción", property: "Description" },
    { title: "Tipo de cliente", property: "CustomerTypeName" },
  ];

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: single.canCreate(),
      icono: "ri-file-add-line",
      evento: agregarItem,
    },
  ];

  return {
    single,
    service,
    lista,
    setLista,
    selector,
    setSelector,
    register,
    errors,
    handleSubmit,
    bean,
    setBean,
    modal,
    setModal,
    modalConfirm,
    setModalConfirm,
    idBean,
    updateItem,
    head,
    iconButtonsItem,
    eliminarItem,
    editarItem,
    agregarItem,
    setRowBean,
  };
};
