import { SelectorOffice } from "../../../../bpm/config/Componentes/SelectorOffice";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { useStoreBranch } from "./useStoreBranch";

interface Props {
    storeCategory: number;
}

export const StoreBranch = ({ storeCategory }: Props) => {
    const {
        lista,
        head,
        setRowBean,
        modal,
        modalConfirm,
        setModal,
        setModalConfirm,
        single,
        updateItem,
        bean,
        setBean,
        iconButtonsItem,
        mostrar,
        eliminarItem
    } = useStoreBranch({ storeCategory });

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                </div>
            </div>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Dirección</label>
                        <input type="text" className="form-control" value={bean.Address} onChange={(e) => { setBean({ ...bean, 'Address': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Teléfono</label>
                        <input type="number" className="form-control" value={bean.Phone} onChange={(e) => { setBean({ ...bean, 'Phone': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Horario</label>
                        <input type="text" className="form-control" value={bean.Schedule} onChange={(e) => { setBean({ ...bean, 'Schedule': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectorOffice titulo="Seleccione una sucursal" idSelector={bean.SiteIDLN} onChange={(e) => setBean({ ...bean, 'SiteIDLN': e })}></SelectorOffice>
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalConfirm} eventModal={eliminarItem} updateModal={setModalConfirm} >
                <div></div>
            </ModalConfirm>
        </>
    );
};
