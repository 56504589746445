import { BasicPage } from "../theme/Component/BasicPage"

export const CalculoInteres=()=>{
    return(
        <BasicPage titulo="Calculo Intereses">
            <p></p>
            
            
        </BasicPage>
    )
}