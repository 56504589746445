import { useState, useEffect } from "react";
import { PrintJson } from "../theme/Component/PrintJson";
import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { DataBeanProperties, ObjectValue } from '../../core/services/model/server-response.interface';
import { BasicTable } from "../theme/Component/BasicTable";
import { BasicButton } from "../theme/Component/BasicButton";
import { Modal } from "../theme/Component/Modal";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { AccountEditor } from "../editor/AccountEditor";
import { Alert } from "../theme/Component/Alert";
import { ItemsPreparador } from "./ItemsPreparador";
import { ListMenu } from "../theme/Component/ListMenu";
import { InputMoney } from "../theme/Input/InputMoney";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { AdminService } from "../../core/services/AdminService";
interface Props {
    IDArea: any;
    updateEvent?: any;
}
export const FunctionalIDPreparer = ({ IDArea }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const contract = new ContractService();
    const adminservice = new AdminService();

    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [message, setMessage] = useState<any>('');
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalItems, setModalItems] = useState<any>({ name: 'Items Preparador', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDFunctionalIDPreparer';
    useEffect(() => {
        setBean({});
        setMessage('');
        getFunctionalIDPreparerCatalog();
    }, [IDArea])

    const getFunctionalIDPreparerCatalog = () => {
        setLoading(true);
        contract.getFunctionalIDPreparerCatalog(IDArea, null, null).subscribe(
            (resp: any) => {
                console.log(resp);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false);
            }
        )
    }


    const agregarItem = () => {
        setMessage('');
        let b = {
            idAdquisitionPlanFunctionalID: IDArea,
            idAccount: 0,
            idEmployee: single.getAccountID(),
            description: '',
            estimatedValue: 0
        }
        setBean(b);
        setModal({ ...modal, "view": true });
    }

    const eliminarItem = () => {
        setLoading(true);
        contract.removePreparerToAdquisitionPlanFunctionalID(bean.IDAdquisitionPlanFunctionalID, bean.IDAccount, single.getAccountID()).subscribe(
            (resp: any) => {
                setLoading(false);
                console.log(resp);
                getFunctionalIDPreparerCatalog();
                setModalConfirm({ ...modalConfirm, "view": false });
                single.makeAcction('El usuario deshabilto como preparador a: ' + (bean.AccountName), bean);
            }
        )
    }

    const eventModal = () => {
        console.log(bean);
        setLoading(true);
        contract.addPreparerToAdquisitionPlanFunctionalID(bean.idAdquisitionPlanFunctionalID, bean.idAccount, bean.idEmployee, bean.description, bean.estimatedValue).subscribe(
            (resp: any) => {
                setLoading(false);
                console.log(resp);
                getFunctionalIDPreparerCatalog();
                setModal({ ...modal, "view": false });
                single.makeAcction('El usuario agrego un preparador', bean);
                single.sendEmailMessage(bean.idAccount,"Plan de compras","Se agrego como preparador al proceso de plan de compras");
            }
        )

    }




    const head = [
        { title: 'ID', property: idBean },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Agregado Por', property: 'EmployeeName' },
        { title: 'Estado', property: 'StateName' }
    ]
    const renderButtons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                    {bean[idBean] &&
                        <>
                            <BasicButton icon="ri-file-forbid-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} >
                                Deshabilitar
                            </BasicButton>
                            <BasicButton icon="ri-file-list-line" eventClick={() => { setModalItems({ ...modalItems, "view": true }) }} >
                                Ver items
                            </BasicButton>
                        </>
                    }
                </div>
            </div>
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate(), evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Deshabilitar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true }) } },
            { titulo: 'Ver Items', icono: 'ri-file-list-line', visible: true, evento: () => { setModalItems({ ...modalItems, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            
            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setBean}></BasicTable>
            <Modal modal={modal} updateModal={setModal} eventModal={eventModal}>
                <div className="row">
                    <div className="col-md-6">
                        <AccountEditor idAccount={bean.idAccount} label="Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "idAccount": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor Estimado</label>
                        <InputMoney readonly={(bean.IDAdquisitionItem >= 0 && bean.PhaseState == 2)} id={0} name='Valor Estimado' value={bean.EstimatedValue} onChange={(e: any) => { setBean({ ...bean, ['EstimatedValue']: parseInt(e) }) }} />
                        {/* <input type="number" className="form-control mt-2" value={bean.EstimatedValue} onChange={(e)=>{setBean({...bean,"EstimatedValue":e.target.value})}}/> */}
                    </div>
                    <div className="col-md-12">
                        <label>Descripción</label>
                        <textarea className="form-control" value={bean.description} onChange={(e) => { setBean({ ...bean, "description": e.target.value }) }}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {message != '' &&
                            <Alert clase="primary"> {message} </Alert>
                        }
                    </div>
                </div>
            </Modal>
            <Modal modal={modalItems} updateModal={setModalItems} eventModal={() => { }}>
                <ItemsPreparador idFunctionalIDPreparer={bean.IDFunctionalIDPreparer} />
            </Modal>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>
        </>
    )
}