import { useEffect, useState } from "react"
import { BasicButton } from "../../modules/theme/Component/BasicButton";
import { AdminService } from '../services/AdminService';
import { Singleton } from "../services/Singleton";
import { DataBeanProperties, ObjectValue } from '../services/model/server-response.interface';
import { Alert } from "../../modules/theme/Component/Alert";

import { useDashboardStore } from "../../modules/pages/hooks/useDashboardStore";
import { env } from "../../env";
import { toast } from "react-toastify";

interface Props {

    user: string;
    domain:string;
    setAccount: any;
}
export const ActivateAccountLdap = ({ user,domain, setAccount }: Props) => {

    const admin = new AdminService();
    const { setLoading } = useDashboardStore();
    const [acc, setAcc] = useState<any>({});
    const [step, setStep] = useState(1);
    const [nit, setNit] = useState<number | undefined>();
    const [message, setMessage] = useState<string>('');

    const entidadDomain = env.LDAP_DOMAIN;

    useEffect(() => {
        console.log("LOGIN USER "+user);

        setMessage('');
        setAcc({});
        setStep(1);
    }, [user])

    const getAccountByNit = () => {

        setLoading(true);
        admin.getAccountByNit(nit ? nit : 0).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let d = resp.DataBeanProperties.ObjectValue;
                    console.log(resp.DataBeanProperties.ObjectValue);
                    if (d.length > 0) {
                        setAcc(d[0].DataBeanProperties);
                        setStep(2);
                        setMessage('Usuario encontrado. verifique y actualice la información.');
                    } else {
                        setAcc(null);
                        setMessage('No se encontro ningun usuario con esa identificación.');
                    }


                } else {
                    setMessage('No se encontro ningun usuario con esa identificación.');
                }
                setLoading(false);
            }
        )

    }
    const updateAccount = () => {

        setLoading(true);

        let acc1 = acc;
         acc1.LdapUser = user;
        acc1.Active = 1;

        if (!acc1.IDAccount) {
            setMessage('El usuario no existe. Consulte su administrador de sistema.');
            // admin.createAbstractAccount(acc1).subscribe(
            //     (resp:any)=>{
            //         console.log(resp);
            //         if(resp.DataBeanProperties)
            //         {
            //             setMessage('Usuario registrado correctamente, Intente ingresar Nuevamente.')
            //         }

            //     }
            // )
            console.log(acc1);
        } else {
            admin.updateAbstractAccount(acc1).subscribe(
                (resp: any) => {
                    console.log(resp);
                    if (resp.DataBeanProperties) {
                        activateLdapUser(acc1);
                    }

                }
            )
        }


    }

    const activateLdapUser = (user: any) => {
        setLoading(true);
        
        admin.activateLdapUserDomain(user.IDAccount, user.LdapUser,domain).subscribe(
            (resp: any) => {
                console.log(resp);
                setMessage('Usuario Actualizado correctamente, Intente ingresar Nuevamente.');
                
                setLoading(false);
            }
        )

    }

    return (
        <div className="row">
            <div className="col-md-12">
                {step === 1 &&
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="user">Identificación</label>
                                            <input type="number" className="form-control" id="user" value={nit} onChange={(e) => {
                                                setNit(parseInt(e.target.value));
                                            }} />
                                        </div>
                                        <div className="col mt-4">
                                            <BasicButton icon="ri-file-search-line" eventClick={getAccountByNit}>Consultar</BasicButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {step === 2 &&
                    <div className="row">
                        <div className="col-md-12">
                            <h6><span className="link-danger cursor" onClick={() => { setStep(1); setNit(undefined); setMessage('') }}>
                                Regresar
                            </span></h6>
                            {/* <PrintJson json={acc}></PrintJson> */}
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="form-label">Identificación</label>
                                    <input type="text" className="form-control" value={acc.Nit} readOnly />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Primer Nombre</label>
                                    <input type="text" className="form-control" value={acc.Name1} onChange={(e) => { setAcc({ ...acc, "Name1": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Segundo Nombre</label>
                                    <input type="text" className="form-control" value={acc.Name2} onChange={(e) => { setAcc({ ...acc, "Name2": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Primer Apellido</label>
                                    <input type="text" className="form-control" value={acc.Surname1} onChange={(e) => { setAcc({ ...acc, "Surname1": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Segundo Apellido</label>
                                    <input type="text" className="form-control" value={acc.Surname2} onChange={(e) => { setAcc({ ...acc, "Surname2": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Correo Electronico</label>
                                    <input type="text" className="form-control" value={acc.eMail} onChange={(e) => {
                                        setAcc({ ...acc, "eMail": e.target.value })
                                    }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Telefono</label>
                                    <input type="text" className="form-control" value={acc.Tel} onChange={(e) => { setAcc({ ...acc, "Tel": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <BasicButton icon="ri-send-plane-fill" eventClick={updateAccount}>Actualizar</BasicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="col-md-12">
                {message != '' &&
                    <Alert clase="primary">{message}</Alert>
                }
            </div>
        </div>
    )
}