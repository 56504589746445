import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { FileInput } from "../../theme/Component/FileInput";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    idApplicationGroup: number;
}
export const RemoteAbstractAction = ({ idApplicationGroup }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [types, setTypes] = useState<any>([]);
    const [type, setType] = useState<any>(0);
    const [accessTypes, setAccessTypes] = useState<any>([]);
    const [access, setAccess] = useState<any>(0);
    const [states, setStates] = useState<any>([]);
    const [state, setState] = useState<any>(0);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


    useEffect(() => {
        getAbstractActionTypeConstants();
        getBusinessLogicAccessTypeConstants();
        getBusinessLogicStateConstants();
    }, [])
    useEffect(() => {
        getRemoteAbstractActionCatalogByApplicationGroup();
    }, [idApplicationGroup])


    const getBusinessLogicStateConstants = () => {
        setLoading(true);
        service.getBusinessLogicStateConstants().subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setStates(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setState(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }
    const getBusinessLogicAccessTypeConstants = () => {
        setLoading(true);
        service.getBusinessLogicAccessTypeConstants().subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setAccessTypes(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setAccess(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }
    const getAbstractActionTypeConstants = () => {
        setLoading(true);
        service.getAbstractActionTypeConstants().subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setTypes(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setType(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }

    const getRemoteAbstractActionCatalogByApplicationGroup = () => {
        setLoading(true);
        service.getRemoteAbstractActionCatalogByApplicationGroup(idApplicationGroup, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                } else {
                    setLista([]);
                }
                setModalC({ ...modalC, "view": false });
                setLoading(false);
            }
        )
    }

    const update = () => {
        console.log(bean);

        setLoading(true);
        service.updateRemoteAbstractAction(bean).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getRemoteAbstractActionCatalogByApplicationGroup();
                    setModal({ ...modal, "view": false });
                }
                setLoading(false);
            }
        )
    }
    const agregarItem = () => {
        let b = {
            IDApplicationGroup: idApplicationGroup,
            Name: '',
            Code: '',
            Description: '',
            LastRequest: single.getCurrenDate(),
            Media: '',
            MediaContext: '',
            TotalCount: 0,
            Type: type,
            AccessType: access,
            State: state,
            ClassForName: ''
        }
        setBean(b);
        setModal({ ...modal, "view": true });
    }

    const handleChange = (e: any) => {
        if (e) {
            let name = e.target.name;
            if (name) {
                let value = e.target.value;
                // let value = single.renderValue(e.target.value);                
                setBean({ ...bean, [name]: value });
            }
        }
    }
    const editarItem = () => {
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        setLoading(true);
        service.deleteRemoteAbstractAction(bean.IDApplicationGroup).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    getRemoteAbstractActionCatalogByApplicationGroup();

                }
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDRemoteAbstractAction', visible: true },
        { title: 'Codigo', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'ClassForName', property: 'ClassForName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
    ]
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: true, evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setBean}></BasicTable>
            <Modal modal={modal} updateModal={setModal} eventModal={update} >
                <div className="row">

                    <div className="col-md-6">
                        <label>Codigo</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={handleChange} name="Code" />
                    </div>

                    <div className="col-md-6">
                        <label>Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={handleChange} name="Name" />
                    </div>
                    <div className="col-md-12">
                        <label>Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={handleChange} name="Description"></textarea>
                    </div>
                    <div className="col-md-6">
                        <label>Tipo</label>
                        <select className="form-select" value={bean.Type} onChange={handleChange} name="Type">

                            {types.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label>Tipo Acceso</label>
                        <select className="form-select" value={bean.AccessType} onChange={handleChange} name="AccessType">

                            {accessTypes.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label>Clase que implementa interface</label>
                        <input type="text" className="form-control" value={bean.ClassForName} onChange={handleChange} name="ClassForName" />
                    </div>
                    <div className="col-md-6">
                        <label>Estado</label>
                        <select className="form-select" value={bean.State} onChange={handleChange} name="State">

                            {states.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <FileInput Media={bean.Media} MediaContext={bean.MediaContext} label="Adjunto" onCreate={({ Media, MediaContext }: any) => {
                            setBean({ ...bean, "Media": Media, "MediaContext": MediaContext });
                        }} />
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                Eliminar item {bean.Name}
            </ModalConfirm>
        </>
    )
}