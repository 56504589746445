import { Link } from "react-router-dom";
import { BasicPage } from "./Component/BasicPage";

export const NotFound = () => {
    return (
        <div className="p-5">
            <BasicPage titulo="">
                <div className="card card-body text-center">
                    <div className="avatar-sm mx-auto mb-3">
                        <div className="avatar-title bg-soft-warning text-primary fs-17 rounded">
                            <i className="ri-add-line"></i>
                        </div>
                    </div>
                    <h4 className="card-title">Contenido no encontrado</h4>
                    <p className="card-text text-muted">Ir a pagina principal.</p>
                    <Link to="/app">
                        <span className="btn btn-Priamry">Ir</span>
                    </Link>
                </div>
            </BasicPage>
        </div>
    );
}