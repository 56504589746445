import { useEffect, useState } from "react";
import { CurrencyService } from "./Currency-service"
import { DataBeanProperties, ObjectValue } from '../../../../core/services/model/server-response.interface';
import { toast } from "react-toastify";
import { BasicTable } from "../../../theme/Component/BasicTable";

interface Props {
    id: number;
    onchange: Function;
}
export const CurrencyEditor = ({ id, onchange }: Props) => {


    const service = new CurrencyService();
    const [lista, setLista] = useState<any>([]);
    const [idLista, setIDLista] = useState<number>(0);

    useEffect(() => {
        getLista();
    }, [])
    useEffect(() => {
        if (id) {
            setIDLista(id);
        }
    }, [id])
    useEffect(() => {
        if (id != idLista) {
            onchange(idLista);
        }
    }, [idLista])

    const getLista = () => {
        service.spinON();
        service.getCurrencyCatalog(idLista ? idLista : null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    return (
        <>
            <label>Tipo de Monedas:</label>
            <select className="form-select" name="AccountantBook" value={idLista} onChange={(e) => setIDLista(parseInt(e.target.value))}>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDCurrency}>{item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}