import { useState, useEffect } from 'react'
import {
  ASSIGN_TO_ME,
  ASSIGN_USER_VIEW,
  ASSIGN_VIEW,
  MANAGE_VIEW,
  VERIFY_VIEW,
  useInboxStore,
} from "../hooks/useInboxStore";
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { PDFViewer } from './PDFViewer';

const _single = Singleton.getInstance()

export const ActionSide = () => {

  const { procedureImpSelected: pI,
    docSelected,
    activeTab,
    activeMFilter,
    modalC,
    requestDocs,
    onSelectDoc,
    onChangeReactive,
    assignProcedureToWorkGroup,
    getProcedureActionForResponse,
    getProcedureActionForVerify,
    verifyProcedureAction,
    declineProcedureAction
  } = useInboxStore();

  const [modalDoc, setModalDoc] = useState({ ...modalC, format: 'modal-xl', btnClose: false, btnSubmit: false })
  const [modalConfirm, setModalConfirm] = useState(modalC)
  const [modalConfirmA, setModalConfirmA] = useState({ ...modalC })
  const [modalConfirmD, setModalConfirmD] = useState({ ...modalC, format: 'md' })
  const [obs, setObs] = useState('')


  useEffect(() => {
    if (activeTab === VERIFY_VIEW) {
      getProcedureActionForVerify()
    }
    return () => {
      setObs('')
    }
  }, [pI])


  const ASSIGN_BUTTONS = [
    {
      icon: 'ri-user-received-fill',
      title: 'Auto-asignar',
      resume: 'Clic para autoasignar proceso',
      actionEvent: () => { setModalConfirm({ ...modalConfirm, view: true }) }
    },
    {
      icon: 'ri-group-line',
      title: 'Asignar a alguien más',
      resume: 'Clic para asignar a alguien en el área encargada',
      actionEvent: () => { onChangeReactive(ASSIGN_USER_VIEW) }
    },
  ]

  const MANAGE_BUTTONS = [
    {
      icon: 'ri-bar-chart-horizontal-fill',
      title: 'Ver acciones requeridas',
      resume: 'Clic para ver la documentos y/o fomularios requeridos',
      actionEvent: () => {
        activeMFilter === ASSIGN_TO_ME
          ? getProcedureActionForResponse(pI?.IDEmployee ?? 0, pI?.IDProcedureImp ?? 0, true, true)
          : getProcedureActionForResponse(pI?.IDAccount ?? 0, pI?.IDProcedureImp ?? 0, false, true)
      }
    }
  ]

  const renderSwicth = () => {
    switch (activeTab) {
      case ASSIGN_VIEW:
        return (
          <>
            {ASSIGN_BUTTONS.map((_e, _i) => (
              <div key={_i} className="d-flex align-items-center mb-3 border p-2 rounded-3 _zoom-lg w-50"
                title={_e.resume} onClick={() => _e.actionEvent()}>
                <div className="flex-grow-1">
                  <strong className='fw-semibold mb-1 me-2'>{_e.title}</strong>
                </div>
                <div className="avatar-xs me-2 flex-shrink-0 rounded-5">
                  <div className="avatar-title" style={{ backgroundColor: 'rgb(0 53 104 / 50%)' }}>
                    <i className={`${_e.icon} fs-5`} style={{ color: '#003568' }}></i>
                  </div>
                </div>
              </div>
            ))}
          </>
        );
      case MANAGE_VIEW:
        return <>
          {MANAGE_BUTTONS.map((_e, _i) => (
            <div key={_i} className="d-flex align-items-center mb-3 border p-2 rounded-3 _zoom-lg w-50"
              title={_e.resume} onClick={() => _e.actionEvent()}>
              <div className="flex-grow-1">
                <strong className='fw-semibold mb-1 me-2'>{_e.title}</strong>
              </div>
              <div className="avatar-xs me-2 flex-shrink-0 rounded-5">
                <div className="avatar-title" style={{ backgroundColor: 'rgb(0 53 104 / 50%)' }}>
                  <i className={`${_e.icon} fs-5`} style={{ color: '#003568' }}></i>
                </div>
              </div>
            </div>
          ))}
        </>;
      case VERIFY_VIEW:
        return <>
          <div className='mb-2'>
            <strong>Documentos adjuntados:</strong>
          </div>
          {requestDocs.map((_e: any, _i) => (
            <div key={_i} className='row mx-5 animate__animated animate__fadeIn'>
              <div key={_i} className="row mb-3 border p-2 rounded-3">
                <div className="col-12 d-flex flex-row _zoom">
                  <div className="avatar-xs me-2 flex-shrink-0 rounded-5">
                    <div className="avatar-title" style={{ backgroundColor: 'rgb(0 53 104 / 50%)' }}
                      onClick={() => {
                        onSelectDoc(_e.DataBeanProperties);
                        setModalDoc({ ...modalDoc, name: _e.DataBeanProperties.ProcedureActionName, view: true })
                      }}
                    >
                      <i className={`ri-eye-fill fs-5`} style={{ color: '#003568' }}></i>
                    </div>
                  </div>
                  <div className="d-flex flex-grow-1 flex-column">
                    <strong className='fw-semibold mb-0 me-2'>{_e.DataBeanProperties.ProcedureActionName}</strong>
                    <small className={`${_e.DataBeanProperties.IsOptional ? 'text-success' : 'text-danger'}`}>
                      {_e.DataBeanProperties.IsOptional ? 'Opcional' : 'Requerido'}
                    </small>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end gap-1">
                  <button type="button" className="btn btn-danger btn-sm waves-effect waves-light shadow-none"
                    onClick={() => { onSelectDoc(_e.DataBeanProperties); setModalConfirmD({ ...modalConfirmD, name: `Rechazar (${_e.DataBeanProperties.ProcedureActionName})`, view: true }) }}
                  >
                    Rechazar
                  </button>
                  <button type="button" className="btn btn-success btn-sm waves-effect waves-light shadow-none"
                    onClick={() => { onSelectDoc(_e.DataBeanProperties); setModalConfirmA({ ...modalConfirmA, name: `Aprobar (${_e.DataBeanProperties.ProcedureActionName})`, view: true }) }}
                  >
                    Aprobar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>;

      default:
        break;
    }
  }

  return (
    <>
      <div className='card mx-3'>
        <div className="card-body">
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-sm me-3 flex-shrink-0">
              <div className="avatar-title" style={{ backgroundColor: 'rgb(0 53 104 / 50%)' }}>
                <i className='ri-command-line fs-2' style={{ color: '#003568' }}></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <p className='text-muted mb-2'>{pI?.BusinessProcessName}</p>
              <h5 className='fs-15 fw-semibold mb-0'>{pI?.ProcedureName}</h5>
            </div>
          </div>
          <p className='p-1 text-muted'>
            Una breve descripción de qué se debe hacer, o cuales son los criterios de aceptación
            a tener en cuenta en el stage donde se encuetnra el Trámite
            {/* {pI.ProcedureDescription} */}
          </p>
          <div className='vstack gap-1 mx-2' style={{ maxHeight: '300px', minWidth: '350px', overflowY: 'auto' }}>
            {renderSwicth()}
          </div>
        </div>
      </div>
      {modalConfirmA.view &&
        <ModalConfirm modal={modalConfirmA} updateModal={setModalConfirmA} eventModal={() => {
          setModalConfirmA({ ...modalConfirmA, view: false });
          verifyProcedureAction()
        }}>
        </ModalConfirm>
      }
      {modalDoc.view &&
        <Modal modal={modalDoc} updateModal={setModalDoc}>
          <div className='mx-auto'>
            <PDFViewer doc={docSelected} />
          </div>
        </Modal>
      }
      {modalConfirmD.view &&
        <ModalConfirm modal={modalConfirmD} updateModal={setModalConfirmD} eventModal={() => {
          setModalConfirmD({ ...modalConfirmD, view: false });
          declineProcedureAction(obs)
        }

        }>
          <div className='m-3 mt-5'>
            <textarea
              className="form-control"
              placeholder='Razones por las cuales rechaza el documento'
              rows={8}
              style={{ resize: 'none' }}
              onChange={(e) => setObs(e.target.value)}
            >
            </textarea>
          </div>
        </ModalConfirm>
      }
      {modalConfirm.view &&
        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={() => assignProcedureToWorkGroup(_single.getAccountID(), modalConfirm, setModalConfirm)}>
        </ModalConfirm>
      }
    </>
  )
};
