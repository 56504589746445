import { useEffect, useState } from "react";
import { Singleton } from "../../core/services/Singleton";
import { useForm } from "react-hook-form";
import { InputDate } from "../theme/Input/InputDate";
import { SelectConstantsValue } from "../theme/Component/SelectConstantsValue";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { BasicButton } from "../theme/Component/BasicButton";



interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}

export const PersonaNatural = ({ supplier, formSupplier, updateInfo }: Props) => {



    const [formulario, setFormulario] = useState<any>({});
    const { register, formState: { errors }, handleSubmit } = useForm();

    useEffect(() => {
        console.log(formSupplier);
        if (formSupplier.forma1 === undefined) {
            setFormulario({});
        } else {
            setFormulario(formSupplier.forma1);
        }
    }, [formSupplier])


    const updateConsentimiento = () => {
        // let temp:any = formSupplier.forma1 !== undefined ? formSupplier.forma1 : {};
        let temp: any = formSupplier;
        formulario.state = true;
        formulario.isNatural = true;
        temp.forma1 = formulario;
        // console.log(temp);

        updateInfo(temp);
    }

    return (
        <>
            <form onSubmit={handleSubmit((data) => {
                if (Object.keys(errors).length === 0) {
                    // No hay errores, continúa guardando los datos del formulario
                    console.log(data);
                    updateConsentimiento();
                }
            })}>
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="titul-form" style={{ textAlign: 'center' }}>CONOCIMIENTO DEL CLIENTE PERSONA NATURAL</h5>
                        <h6 style={{ textAlign: 'center' }}>* Esta información deberá ser diligenciado por el representante legal de la empresa</h6>
                        <hr />
                    </div>
                    <div className="col-md-6">
                        <label className='titul-form'>Fecha de diligenciamiento: </label>
                        <InputDate name='f1' label='' value={formulario.fechacreacion} setDate={(e: any) => setFormulario({ ...formulario, "fechacreacion": e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Ingreso de datos por:</label>
                        <SelectConstantsValue code={5} value={formulario.ingresodatos} onChange={(e: any) => { setFormulario({ ...formulario, "ingresodatos": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Primer nombre:</label>
                        <input type="text" className='form-control' value={formulario.primer_nombre} onChange={(e) => setFormulario({ ...formulario, "primer_nombre": e.target.value })} />

                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Segundo nombre:</label>
                        <input type="text" className='form-control' value={formulario.segundo_nombre} onChange={(e) => setFormulario({ ...formulario, "segundo_nombre": e.target.value })} />

                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Primer apellido:</label>
                        <input type="text" className='form-control' value={formulario.primer_apellido} onChange={(e) => setFormulario({ ...formulario, "primer_apellido": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Segundo apellido:</label>
                        <input type="text" className='form-control' value={formulario.segundo_apellido} onChange={(e) => setFormulario({ ...formulario, "segundo_apellido": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Tipo de documento: </label>
                        <SelectConstantsValue code={6} value={formulario.tipo_documento} onChange={(e: any) => { setFormulario({ ...formulario, "tipo_documento": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">No documento:</label>
                        <input type="text" className='form-control' value={formulario.nodocumento} onChange={(e) => setFormulario({ ...formulario, "nodocumento": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className='titul-form'>Fecha de expedición: </label>
                        {/* <input type="text" className='form-control' value={formulario.fechaexp} onChange={(e)=>setFormulario({...formulario,"fechaexp":e.target.value})}/> */}
                        <InputDate name='f1' label='' value={formulario.fechaexp} setDate={(e: any) => setFormulario({ ...formulario, "fechaexp": e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Lugar de expedición:</label>
                        <input type="text" className='form-control' value={formulario.lugarexp} onChange={(e) => setFormulario({ ...formulario, "lugarexp": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className='titul-form'>Fecha de Nacimiento: </label>
                        <InputDate name='f1' label='' value={formulario.fechanacimiento} setDate={(e: any) => setFormulario({ ...formulario, "fechanacimiento": e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Lugar de nacimiento:</label>
                        <input type="text" className='form-control' value={formulario.lugarnacimiento} onChange={(e) => setFormulario({ ...formulario, "lugarnacimiento": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Departamento:</label>
                        <input type="text" className='form-control' value={formulario.departamento} onChange={(e) => setFormulario({ ...formulario, "departamento": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Ciudad: </label>
                        <input type="text" className='form-control' value={formulario.ciudaduno} onChange={(e) => setFormulario({ ...formulario, "ciudaduno": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Nacionalidad:</label>
                        <input type="text" className='form-control' value={formulario.nacionalidad} onChange={(e) => setFormulario({ ...formulario, "nacionalidad": e.target.value })} />
                    </div>
                    <h5 className="titul-form mt-2">Dirección de residencia del titular</h5>
                    <hr />
                    <div className="col-md-6">
                        <label className="titul-form">Dirección de residencia:</label>
                        <input type="text" className='form-control' value={formulario.direccion} onChange={(e) => setFormulario({ ...formulario, "direccion": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Ciudad / Municipio de residencia:</label>
                        <input type="text" className='form-control' value={formulario.ciudad} onChange={(e) => setFormulario({ ...formulario, "ciudad": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Departamento:</label>
                        <input type="text" className='form-control' value={formulario.departamentotitular} onChange={(e) => setFormulario({ ...formulario, "departamentotitular": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">País:</label>
                        <input type="text" className='form-control' value={formulario.pais} onChange={(e) => setFormulario({ ...formulario, "pais": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Teléfono de residencia:</label>
                        <input type="text" className='form-control' value={formulario.telefono} onChange={(e) => setFormulario({ ...formulario, "telefono": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Teléfono de trabajo:</label>
                        <input type="text" className='form-control' value={formulario.telefonotrabajo} onChange={(e) => setFormulario({ ...formulario, "telefonotrabajo": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Celular 1:</label>
                        <input type="text" className='form-control' value={formulario.celularuno} onChange={(e) => setFormulario({ ...formulario, "celularuno": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Celular 2:</label>
                        <input type="text" className='form-control' value={formulario.celulardos} onChange={(e) => setFormulario({ ...formulario, "celulardos": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Correo electrónico:</label>
                        <input type="text" className='form-control' value={formulario.correo} onChange={(e) => setFormulario({ ...formulario, "correo": e.target.value })} />
                    </div>
                    <div className="col-md-12">
                        <h5 className="titul-form mt-2">Información laboral del titular:</h5>
                        <hr />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Nombre unidad / empresa:</label>
                        <input type="text" className='form-control' value={formulario.nombreempresa} onChange={(e) => setFormulario({ ...formulario, "nombreempresa": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Código CIIU:</label>
                        <input type="text" className='form-control' value={formulario.codigo} onChange={(e) => setFormulario({ ...formulario, "codigo": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Maneja recursos públicos:</label>
                        <BasicSwitch label="NO / SI" estado={formulario.recursospublicos} eventChange={(e: any) => {
                            // setMiswitch(e);
                            setFormulario({ ...formulario, "recursospublicos": e });
                        }} />
                    </div>
                    <h5 className="titul-form mt-2">Información económica del titular</h5>
                    <hr />
                    <div className="col-md-6">
                        <label className="titul-form">Realiza Transacciones en moneda extranjera:</label>
                        <BasicSwitch label="NO / SI" estado={formulario.mondedaextranjera} eventChange={(e: any) => {
                            // setMiswitch(e);
                            setFormulario({ ...formulario, "mondedaextranjera": e });
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Tipo de transacción:</label>
                        <SelectConstantsValue code={7} value={formulario.tipotransaccion} onChange={(e: any) => { setFormulario({ ...formulario, "tipotransaccion": e }) }} />

                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Número de producto:</label>
                        <input type="text" className='form-control' value={formulario.numeroprod} onChange={(e) => setFormulario({ ...formulario, "numeroprod": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Moneda:</label>
                        <input type="text" className='form-control' value={formulario.moneda} onChange={(e) => setFormulario({ ...formulario, "moneda": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Monto:</label>
                        <input type="text" className='form-control' value={formulario.monto} onChange={(e) => setFormulario({ ...formulario, "monto": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Declara renta:</label>
                        <BasicSwitch label="NO / SI" estado={formulario.renta} eventChange={(e: any) => {
                            // setMiswitch(e);
                            setFormulario({ ...formulario, "renta": e });
                        }} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Información económica a corte ultimo año:</label>
                        <input type="text" className='form-control' value={formulario.informacion} onChange={(e) => setFormulario({ ...formulario, "informacion": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titu
                        l-form">Ingresos mensuales:</label>
                        <input type="text" className='form-control' value={formulario.ingresosmensules} onChange={(e) => setFormulario({ ...formulario, "ingresosmensules": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Otros ingresos:</label>
                        <input type="text" className='form-control' value={formulario.otrosingresos} onChange={(e) => setFormulario({ ...formulario, "otrosingresos": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Total ingresos mensuales:</label>
                        <input type="text" className='form-control' value={formulario.totalingresos} onChange={(e) => setFormulario({ ...formulario, "totalingresos": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Detalle de ingresos originados de otras actividades:</label>
                        <input type="text" className='form-control' value={formulario.detalle} onChange={(e) => setFormulario({ ...formulario, "detalle": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Egresos mensuales:</label>
                        <input type="text" className='form-control' value={formulario.egresos} onChange={(e) => setFormulario({ ...formulario, "egresos": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Total activo:</label>
                        <input type="text" className='form-control' value={formulario.totalactivo} onChange={(e) => setFormulario({ ...formulario, "totalactivo": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Total pasivo:</label>
                        <input type="text" className='form-control' value={formulario.totalpasivo} onChange={(e) => setFormulario({ ...formulario, "totalpasivo": e.target.value })} />
                    </div>
                    <div className="col-md-6">
                        <label className="titul-form">Total patrimonio:</label>
                        <input type="text" className='form-control' value={formulario.totalpatrimonio} onChange={(e) => setFormulario({ ...formulario, "totalpatrimonio": e.target.value })} />
                    </div>
                    <div className="row">
                        <h5 className="titul-form mt-2">Información personas expuestas políticamente - PEP</h5>
                        <hr />
                        <div className="col-md-12 text-justify">
                            <p>1. Persona Expuesta Políticamente (PEP): Se consideran PEP los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y territorial, cuando en los cargos que ocupen, tengan en las funciones del área a la que pertenecen o en las de la ficha del empleo que ocupan, bajo su responsabilidad directa o por delegación, la expedición de normas o regulaciones, la dirección general, la formulación de políticas institucionales y adopción de planes, programas y proyectos, el manejo directo de bienes, dineros o valores del Estado y la administración de
                                justicia o facultades administrativo sancionatorias. Funciones pueden ser a través de ordenación de gasto, contratación pública, gerencia de proyectos de inversión, pagos, liquidaciones, administración de bienes muebles e inmuebles. PEP desde su nombramiento y hasta 2 años después de dejar su cargo.</p>
                            <p>2. PEP de organizaciones internacionales: Son aquellas personas que ejercen funciones directivas en una organización internacional. Se entienden por PEP de organizaciones internacionales directores, subdirectores, miembros de juntas directivas o cualquier persona que ejerza una función equivalente. \f0Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo.  </p>
                            <p>3. PEP extranjeros: Son aquellas personas que desempeñan funciones públicas destacadas en otro país. Se entienden por PEP extranjeros: (i) jefes de Estado, jefes de Gobierno, ministros, subsecretarios o secretarios de Estado; (ii) congresistas o parlamentarios; (iii) miembros de tribunales supremos, tribunales constitucionales u otras altas instancias judiciales cuyas decisiones no admitan normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de tribunales o de las juntas directivas de bancos centrales; (v) embajadores, encargados de negocios y altos funcionarios de las fuerzas armadas, y (vi) miembros de los órganos administrativos, de gestión o de supervisión de empresas de propiedad estatal. \f0Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo.   Definición de vinculado:</p>
                            <p>3.1 Segundo grado de consanguinidad padres, abuelos, hermanos, hijos, nietos,</p>
                            <p>3.2 Segundo de afinidad yernos, nueras, suegros, cuñados, abuelos del cónyuge o compañero/a permanente del PEP,</p>
                            <p>3.3  Primero civil hijos adoptivos o padres adoptantes.</p>
                            <p>3.4 Asociado cercano cuando el PEP sea socio o asociado de una persona jurídica y,además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica en los términos del artículo 261 del Código de Comercio.</p>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <label>Deacuerdo a la anterior clasificación, por favor indique si es PEP:</label>
                        <BasicSwitch label="NO / SI" estado={formulario.peep} eventChange={(e: any) => {
                            // setMiswitch(e);
                            setFormulario({ ...formulario, "peep": e });
                        }} />

                    </div>
                </div>
                {/* <Prin tJson json={formulario}/> */}

                <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>

            </form>
        </>
    )
}