import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { AdminService } from '../../../../core/services/AdminService';

interface Props {
    idSelector: number;
    onChange: any;
}

export const SelectProcessTypeConstants = ({ idSelector, onChange }: Props) => {

    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector ?? null);

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        onChange(parseInt(e));
        setID(e)
    }

    const getLista = () => {
        service.getProcessTypeConstants().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setLista(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setID(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }
    return (
        <>
            <label>Tipo Aplicación</label>
            <select className="form-select" value={id} name="Type" onChange={(e) => { setValue(e.target.value) }} >
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                    )
                })}
            </select>
        </>
    )
}