import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { ForpoService } from "../../../../../custom/forpo/components/service/ForpoService";
import { useDashboardStore } from "../../../../../pages/hooks/useDashboardStore";

export const useSuppConstantsForpo = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [data, setData] = useState<any>([]);
    const [prevCode, setPrevCode] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalPC, setModalPC] = useState<any>({ name: 'Propiedades Constante', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});
    const idBean = 'IDSuppConstants';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Code', property: 'Code' },
    ]

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
        if (lista) {
            setData(lista);
        }
    }, [idBean])

    useEffect(() => {
        const extractedCodes = data.map((item: any) => item.DataBeanProperties.Code);
        setPrevCode(extractedCodes);
    }, [idBean])

    const getLista = () => {
        setLoading(true);
        service.getSuppConstantsCatalogPorPropiedadForpo('State', 1, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 1,
            Code: 0,
            Since: single.getCurrenDate(),
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const updateItem = () => {

        setLoading(true);
        service.updateSuppConstantsForpo(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModal({ ...modal, ['view']: false });
    }

    const eliminarItem = () => {


        setLoading(true);
        service.deleteSuppConstantsForpo(bean.IDSuppConstants).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return {
        lista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        eliminarItem,
        setRowBean,
        modalPC,
        setModalPC
    };
};
