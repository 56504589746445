import { ContractService } from "../../core/services/ContractService";
import { FileService } from "../../core/services/FileService";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { BasicTable } from "../theme/Component/BasicTable";
import { useState, useEffect } from 'react';

interface Props {
    IDAdquisitionItem: number;
}
export const AdqusitionArticle = ({ IDAdquisitionItem }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const fileService = new FileService();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});

    const head = [

        { title: 'ID', property: 'IDAdquisitionArticle', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Unidad de Medida', property: 'MeasurementUnit', visible: true },
        { title: 'Valor estimado unitario', property: 'EstimatedUnitaryValue', visible: true },
        { title: 'Valor Total', property: 'TotalValue', visible: true },
        { title: 'Presupuesto', property: 'BudgetTypeName', visible: false },
        { title: 'Especificaciones Técnicas', property: 'TechnicalSpecifications', visible: false },
        { title: 'Rubro', property: 'IDLnUnspscIDName', visible: true },
        { title: 'Estado', property: 'StructuringStateName', visible: true },
        { title: 'Adjunto', property: 'URL', visible: true },
    ]

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    useEffect(() => {
        getAdquisitionArticleCatalog();
    }, [IDAdquisitionItem])

    const getAdquisitionArticleCatalog = () => {

        setLoading(true);
        service.getAdquisitionArticleCatalog(IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.MediaContext) {
                            element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`

                        } else {
                            element.DataBeanProperties.URL = "";
                        }

                    });

                    setLista(tmp);
                } else {
                    setLista([]);
                }

                setLoading(false);
            }
        );
    }

    return (
        <>
            <div className="conatiner">
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <p>
                            <strong>Bienes/Servicios del Ítem</strong><br /><small>{bean.Description}</small>
                        </p>
                        <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>

                </div>
            </div>
        </>
    )
}