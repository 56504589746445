
interface Props {
    clase: string;
    children: any;
    margin?: string;
}

export const Alert = ({ clase, children, margin }: Props) => {
    return (
        <div className={`alert alert-${clase} shadow ${margin ? margin : "mt-4"} `} role="alert">
            {children}
        </div>
    )
}