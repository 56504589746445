import { GenericService } from "../../../../core/services/GenericService";

export class CompanyService extends GenericService {


  // public exportMenuAndRoles(args: string | null) {
  //   const parametros = {
  //     ServiceName: "OrangeBase",
  //     MethodHash: "com.advantage.shared.Report_exportMenuAndRoles_String",
  //     ArgumentList: [
  //       args
  //     ],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return this.myApi.post(this.url, data);
  // }

  public getCompanyCatalog(idCompany: number | null) {

    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getCompanyCatalog_Number",
      ArgumentList: [idCompany],
    };

    const data = JSON.stringify(parametros);

    return this.myApi.post(this.url, data);

  }

  public updateCompany(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.admin.Company_updateCompany_com.orange.bean.admin.Company",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.admin.Company'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }


  public deleteCompany(Company: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "void_deleteCompany_Number",
      ArgumentList: [Company],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }
}