import { env } from "../../env";
import http from "./api/api";
import { Singleton } from "./Singleton";

export class AuditService {
  
  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  public baseUrl: string = env.REACT_APP_ENDPOINT || "";

    public CREATE=1;
    public DELETE=0;
    public UPDATE=2;
    public VIEW=3;
    public DOWNLOAD=4;
    public ACTIVON=5;

 

  public updateAuditFunction(bean:any) {
    const dataObj = {      
      ServiceName: "OrangeBase",      
      MethodHash:"com.advantage.bean.securitymanager.AuditFunction_updateAuditFunction_com.advantage.bean.securitymanager.AuditFunction",
      ArgumentList: [
        {
          DataBeanProperties:bean,
          DataBeanName:"com.advantage.bean.securitymanager.AuditFunction"
        }
      ],
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }
  public getAuditRender(rows:number|null , maps:any) {
    const dataObj = {      
      ServiceName: "SupplierERP",      
      MethodHash:"java.util.List_getAuditRender_Number_java.util.Map",
      ArgumentList: [
        
         rows,maps
        
      ],
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }


}
