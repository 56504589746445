import { useEffect, useState } from "react";

interface Props{
    label?:string;
    eventChange:any;
    estado:boolean;
}
export const BasicSwitch = ({eventChange,label,estado}:Props) => {

    const[check,setCheck] = useState(false);

    useEffect(()=>{
        setCheck(estado);
    },[estado])

    return (
        <div className="form-check form-switch form-switch-success" style={{marginLeft:'15px'}} >
            <input className="form-check-input" checked={check} style={{marginTop:'10px',fontSize:'16px'}}  type="checkbox" role="switch" id="SwitchCheck1" onChange={(e) => {                
                eventChange(e.target.checked);
                setCheck(e.target.checked);
            }} />
            {label &&
                <label className="form-check-label" style={{paddingLeft:'5px'}} htmlFor="SwitchCheck1"> {label}</label>
            }
            
        </div>
    )
}