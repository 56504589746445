/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { AdminService } from "../../../../core/services/AdminService";
import { Singleton } from "../../../../core/services/Singleton"
import check from '../../../../assets/dashboard/success.svg'
import { IconButton } from "../../../../core/shared/buttons/IconButton";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { BiMoneyWithdraw } from "react-icons/bi";
import { NoResults } from "../../../../core/shared/NoResults";

const single = Singleton.getInstance()
const service = new AdminService();
export const _years = Array.from({ length: 50 }, (_, index) => new Date().getFullYear() - index);

export const PayrollProcess = () => {

    const { setLoading } = useDashboardStore();
    const [yearSelected, setYearSelected] = useState(single.getCurrenYear());
    const [props, setProps] = useState<any>(
        {
            view: 1,
            downloading: false,
            listFortnight: [],
            listDocuments: [],
            iDoc: undefined,
            url: '#',
        }
    );

    const [loadings, setLoadings] = useState<boolean[]>([])

    useEffect(() => {
        searchList();
    }, [yearSelected])

    const searchList = () => {
        setLoading(true);
        let listFortnight = []
        let ldgs = []
        service.getPayrollProcessListByEmployee(yearSelected, single.getAccountID()).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    listFortnight = resp.DataBeanProperties.ObjectValue;
                    listFortnight = listFortnight.reverse();
                    listFortnight.forEach(() => {
                        ldgs.push(false)
                    });
                    listFortnight.forEach((element: any) => {
                        element.DataBeanProperties.Doc = element.DataBeanProperties.DocMnemonic + "-" + element.DataBeanProperties.DocConsecutive;
                    });
                    setProps({ ...props, "listFortnight": listFortnight, view: 2 });
                }
            }
        )
    }

    const setDownloadingBtn = (_iLoading: number, value: boolean) => {
        setLoadings((prev) => {
            const newLoadings = [...prev]
            newLoadings[_iLoading] = value
            return newLoadings
        })
    }

    const getTicketReport = (idDocument: number, docDate: any, _iLoading: number) => {
        setDownloadingBtn(_iLoading, true)
        setProps({ ...props, downloading: true });
        service.getTicketReport(idDocument, docDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let listFortnight = resp.DataBeanProperties.ObjectValue;
                    setDownloadingBtn(_iLoading, false)
                    setProps({
                        ...props,
                        listDocuments: listFortnight,
                        url: listFortnight[0].DataBeanProperties.URLLink
                    });
                }
                else {
                    setProps({ ...props, downloading: false });
                }
            }
        )
    }

    const onSelectYear = (dateString: string) => {
        setYearSelected(parseInt(dateString))
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            {!(props.listDocuments.length > 0) &&
                                <div className="mx-5 mb-5 mt-2 d-flex flex-row gap-1">
                                    <YearSelector size="sm" value={yearSelected} onChange={(e) => onSelectYear(e)} />
                                </div>
                            }
                            {props.listDocuments.length > 0 &&
                                <div className="mx-3 _zoom" title="Atrás" onClick={() => setProps({ ...props, listDocuments: [], view: 1 })}>
                                    <h4 className="d-flex align-items-center">
                                        <i className="ri-arrow-left-s-line fs-1" /> Volver
                                    </h4>
                                </div>
                            }
                            {!(props.listDocuments.length > 0) &&
                                props.listFortnight.length > 0
                                && <div style={{ maxHeight: '50%', overflowY: 'scroll', paddingRight: '17px' }} >
                                    <ul className="p-0 m-0 mt-5">
                                        {props.listFortnight.map((_e: any, _i: number) => (
                                            <li key={_i} className="d-flex mb-4 pb-3 border-bottom mx-5">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <div style={{ color: '#006699', fontSize: '60px' }}>
                                                        <BiMoneyWithdraw />
                                                    </div>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-3">
                                                    <div className="me-2">
                                                        <h6 className="mb-0">{_e.DataBeanProperties.MonthName}</h6>
                                                        <small className="text-muted d-block mb-1">Desprendible de Nómina</small>
                                                        <small>{_e.DataBeanProperties.Description}</small>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-column gap-1">
                                                        <strong>{_e.DataBeanProperties.Doc}</strong>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <IconButton
                                                            icon='ri-file-download-line'
                                                            loading={loadings[_i]}
                                                            event={() => {
                                                                setProps({ ...props, iDoc: _e.DataBeanProperties.IDDocument })
                                                                getTicketReport(_e.DataBeanProperties.IDDocument, _e.DataBeanProperties.DocDate, _i)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {(props.listFortnight.length === 0 && props.view === 2) &&
                                <NoResults className="mt-5 pt-3" />
                            }
                            {props.listDocuments.length > 0 &&
                                <div className="animate__animated animate__fadeIn">
                                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                                    </div>
                                    <div className="mx-5 mt-5 alert alert-success">
                                        Su desprendible se ha generado exitósamente, para descargarlo haga clic
                                        <a href={props.url} target="_blank" rel="noreferrer" >
                                            &nbsp;aquí!
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}