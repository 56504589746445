import { useEffect, useState } from "react"
import { ContractService } from "../../../../core/services/ContractService"
import { Singleton } from "../../../../core/services/Singleton"
import { toast } from "react-toastify"
import { IoReload } from "react-icons/io5"

export interface TypeForm {
    GroupCode: string
    Name: string
}


const _single = Singleton.getInstance()
const _service = new ContractService()
interface Props {
    value: string
    onChange: (nValue: string) => void
}
export const SelectorTypeForm = ({ value, onChange }: Props) => {

    const [list, setList] = useState<TypeForm[]>([])

    useEffect(() => {
        getGroupCodeList()
    }, [])


    const getGroupCodeList = () => {
        _single.spinner(true)
        _service.getGroupCodeList()
            .subscribe((resp: any) => {
                _single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    setList(resp.DataBeanProperties.ObjectValue.map((e) => e.DataBeanProperties))
                } else {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
            })
    }

    return (
        <div className="d-flex flex-column w-100">
            <span>Código de Grupo</span>
            <div className="d-flex gap-2">
                <select className="form-select" aria-label="Default select example"
                    onChange={(e) => onChange(e.target.value)}
                >
                    <option selected value={''}>...</option>
                    {list.map((e, i) => <option key={i} value={e.GroupCode}>{e.GroupCode}</option>)}
                </select>
                <div className="mx-auto d-flex align-items-center fs-5 pointer" title="actualizar">
                    <IoReload onClick={() => getGroupCodeList()} />
                </div>
            </div>
        </div>
    )
}
