import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { ForpoService } from '../../../custom/forpo/components/service/ForpoService';

interface Props {
    IDinventory: number;
}


export const Inventory = ({ IDinventory }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDStoreItem';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Cantidad', property: 'Quantity' },
    ]

    useEffect(() => {
        getLista();
    }, [IDinventory])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getInventoryCatalogPorPropiedad('IDStoreItem', IDinventory, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            IDStoreItem: IDinventory,
            Quantity: 0,
            TypeEntry: 1,
            IDEmployee: single.getAccountID(),
            UpdateDate: single.getCurrenDate(),
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateInventory(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Quantity</label>
                        <input type="number" className="form-control" value={bean.Quantity} onChange={(e) => { setBean({ ...bean, ['Quantity']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">NOMBRE</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}