import { useState } from "react";
import { CardTaps } from "../../../../theme/Component/CardTaps";
import { PrintJson } from "../../../../theme/Component/PrintJson";

import { ComparativeFactor } from "./ComparativeFactor";
import { ComparativePrice } from "./ComparativePrice";

interface Props{
    offer:any;
}
export const ComparativeCard =({offer}:Props)=>{

    const taps = [
        { id: 0, name: 'Precios' },
        { id: 1, name: 'Factores' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    return(
        <div className="row">
            <div className="col">
                
                <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                    <div className="row">
                        {indexTap === 0 &&
                        <div className="col">
                            <ComparativePrice offer={offer.ListPrice}/>
                        </div>
                        }
                        {indexTap === 1 &&
                        <div className="col">
                            <ComparativeFactor offer={offer.ListFactor}/>
                        </div>
                        }
                    </div>
                </CardTaps>
            </div>
        </div>
    )
}