

interface Props {
    formulario: any;
}

export const Forma9Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center celda">
                    <h5>DOCUMENTOS ANEXOS - Documentos para vinculación y actualización</h5>
                </div>
                <div className="col-12 text-justify color-celda">
                    <strong>
                        <div className="row text-justify">
                            <div className="col-12">
                                <ul>
                                    <li>Fotocopia del documento de identificación Representante Legal o delegado: cedula de ciudadanía, carnet diplomático, pasaporteo cedula de extranjería.</li>
                                    <li>Fotocopia libreta militar (varones).</li>
                                    <li>  Original del Certificado de Existencia y Representación Legal con vigencia no superior a tres (3)</li>
                                    <li> Declaración de renta del último periodo gravable disponible (si está obligado).</li>
                                    <li>Estados Financieros últimos certificados o dictaminados de los dos (2) últimos periodos Fiscales.</li>
                                    <li>Notas Explicativas a los Estados Financieros.</li>
                                    <li>Registro Único Tributario (RUT).</li>
                                    <li>Fotocopia legible de la Tarjeta Profesional del Contador y Revisor Fiscal.</li>
                                    <li>Formato de conocimiento de cliente persona natural del Representante Legal.</li>
                                    <li>Formato de conocimiento del cliente persona natural de cada uno de los socios con representación igual o mayor al 5% del capital social.</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <strong>Nota 1: Caja Honor reservará la confidencialidad y seguridad de la información y documentos soporte suministrados en el presente formulario.</strong>
                            </div>
                            <div className="col-12">
                                <strong>Nota 2: Todo lo anterior, sin perjuicio de los aspectos y conceptos que estén incluidos en el manual de SARLAFT de la Entidad, de conformidad con las modificaciones que se introduzcan y en concordancia con lo ordenado por la circular Básica Jurídica de la Superintendencia Financiera de Colombia.</strong>
                            </div>
                            <div className="col-12">
                                <label className='titul-form'> El cliente autorizo:</label>
                                <p><strong>{formulario.autorizacion ? 'SI' : 'NO'}</strong></p>
                            </div>
                        </div>
                    </strong>
                </div>
            </div>
            {/* <PrintJson json={formulario} /> */}
        </>
    )
} 