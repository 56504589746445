import { useEffect, useState } from "react";
import { SelectConstantsValue } from "../theme/Component/SelectConstantsValue";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { InputDate } from "../theme/Input/InputDate";
import { BasicButton } from "../theme/Component/BasicButton";
import { Modal } from "../theme/Component/Modal";
import { RenderParametrica } from "./RenderParametrica";

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const PersonaJuridica = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});
    const [editingId, setEditingId] = useState(null);
    const [editedData, setEditedData] = useState({});

    //incio de const de formulario de socions
    const [tipoDoc, setTipoDoc] = useState<number>(0);
    const [doc, setDoc] = useState<number>(0);
    const [surname, setSurname] = useState<string>('');
    const [particion, setParticion] = useState<number>(0);

    const [modalSocios, setModalSocios] = useState({ name: 'Agregar informacion de los socios o accionistas', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' })

    const handleEdit = (id: any, field: any, value: any) => {
        setEditingId(id);
        setEditedData(prevData => ({ ...prevData, [field]: value }));
    };

    const handleSave = (id: any) => {
        // Aquí podrías implementar la lógica para guardar los datos editados
        console.log(`Guardando cambios para el ID ${id}`, editedData);

        setEditingId(null);
        setEditedData({});
    };

    useEffect(() => {

        if (formSupplier.forma2 === undefined)
            formSupplier.forma2 = {};
        setFormulario(formSupplier.forma2);
    }, [formSupplier])

    // useEffect(() => {
    //     setPreFormulario({formulario,supplier});
    // }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        formulario.isJuridic = true;
        temp.forma2 = formulario;
        updateInfo(temp);
    }

    const agregarElemto = () => {
        // Depuración: Verifica el valor de formulario y formulario.socios
        console.log("formulario:", formulario);
        console.log("formulario.socios:", formulario.socios);

        let nuevoSocio = {
            documentType: tipoDoc,
            document: doc,
            names: surname,
            participate: particion,
        };

        let antiguos = formulario.socios || []; // Inicializa antiguos como un arreglo vacío si es indefinido
        let nuevos = [];
        nuevos.push(nuevoSocio);

        const nuevosSocios = antiguos.concat(nuevos);

        formulario.socios = nuevosSocios;
        setModalSocios({ ...modalSocios, view: false });
        limpiarValores();
    }

    const eliminar = (documento: number) => {
        const valor = documento;
        const nuevoformulario = formulario;
        const nuevoSocios = formulario.socios;
        const resultado = nuevoformulario.socios.filter((cc: any) => cc.document !== valor);
        const nuevalista = nuevoSocios.filter((cc: any) => cc.document != valor);
        // setSocios(nuevosSocios);
        console.log(resultado);
        setFormulario({ ...formulario, 'socios': resultado });
    };


    const limpiarValores = () => {
        setDoc(0);
        setSurname('');
        setParticion(0);

    }

    return (
        <>
            <h5 className="titul-form" style={{ textAlign: 'center' }}>CONOCIMIENTO DEL CLIENTE PERSONA JURÍDICA</h5>
            <h6 style={{ textAlign: 'center' }}>* Esta información deberá ser diligenciado por el representante legal de la empresa</h6>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <label className='titul-form'>Nombre o Razón social:</label>
                    <input type="text" className='form-control' value={formulario.razon_social} onChange={(e) => setFormulario({ ...formulario, "razon_social": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">NIT:</label>
                    <input type="number" className='form-control' value={formulario.nit} onChange={(e) => setFormulario({ ...formulario, "nit": e.target.value })} />
                </div>

                <div className="col-md-12">
                    <h5 className="titul-form mt-3">Información demográfica persona jurídica</h5>
                    <hr />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Dirección:</label>
                    <input type="text" className='form-control' value={formulario.direccion_oficina} onChange={(e) => setFormulario({ ...formulario, "direccion_oficina": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Ciudad:</label>
                    <input type="text" className='form-control' value={formulario.ciudad_oficina} onChange={(e) => setFormulario({ ...formulario, "ciudad_oficina": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Departamento:</label>
                    <input type="text" className='form-control' value={formulario.departamento_oficina} onChange={(e) => setFormulario({ ...formulario, "departamento_oficina": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Corrreo electrónico empresarial (contacto principal):</label>
                    <input type="text" className='form-control' value={formulario.correo_electronico} onChange={(e) => setFormulario({ ...formulario, "correo_electronico": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Teléfono:</label>
                    <input type="number" className='form-control' value={formulario.telefono_oficina} onChange={(e) => setFormulario({ ...formulario, "telefono_oficina": e.target.value })} />
                </div>

                <h5 className='titul-form mt-3'>Actividad de la empresa</h5>
                <hr />
                <div className="col-md-6">
                    <div className="form-group">
                        <label className='titul-form'>Tipo de empresa:</label>
                        <SelectConstantsValue label='' code={8} value={formulario.tipo_empresa} onChange={(e: any) => { setFormulario({ ...formulario, 'tipo_empresa': e }) }} />
                    </div>
                </div>
                {formulario.tipo_empresa == 49 &&
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='titul-form'>¿Cuál?</label>
                            <textarea className="form-control" value={formulario.otro_tipo_empresa} onChange={(e) => { setFormulario({ ...formulario, ['otro_tipo_empresa']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                }
                <div className="col-md-6">
                    <div className="form-group">
                        <label className='titul-form'>Actividad económica:</label>
                        <SelectConstantsValue label='' code={9} value={formulario.actividad_economica} onChange={(e: any) => { setFormulario({ ...formulario, 'actividad_economica': e }) }} />
                    </div>
                </div>
                {formulario.actividad_economica == 50 &&
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='titul-form'>¿Cuál?</label>
                            <textarea className="form-control" value={formulario.otro_actividad_economica} onChange={(e) => { setFormulario({ ...formulario, ['otro_actividad_economica']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                }
                <div className="col-md-6">
                    <label className="titul-form">Descripción actividad económica:</label>
                    <textarea className="form-control" value={formulario.descripcion_economica} onChange={(e) => { setFormulario({ ...formulario, ['descripcion_economica']: e.target.value }) }}></textarea>
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Código actividad económica (CIIU):</label>
                    <input type="text" className='form-control' value={formulario.codigo_economico} onChange={(e) => setFormulario({ ...formulario, "codigo_economico": e.target.value })} />
                </div>

                <h5 className='titul-form mt-3'>Información Financiera y Económica</h5>
                <hr />
                <div className="col-md-12">
                    <label className="titul-form">¿Declara renta?:</label>
                    <BasicSwitch label='NO / SI' estado={formulario.renta} eventChange={(e: any) => setFormulario({ ...formulario, "renta": e })}></BasicSwitch>
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Información económica a corte (último año):</label>
                    <input type="text" className='form-control' value={formulario.informacion_economica} onChange={(e) => setFormulario({ ...formulario, "informacion_economica": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Ingresos mensuales:</label>
                    <input type="text" className='form-control' value={formulario.ingresos_menusales} onChange={(e) => setFormulario({ ...formulario, "ingresos_menusales": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Otros ingresos o ingresos no operacionales <br /> (Originados en actividades diferentes a la principal, no incluidos como ingresos mensuales):</label>
                    <input type="text-area" className='form-control' value={formulario.otros_ingresos} onChange={(e) => setFormulario({ ...formulario, "otros_ingresos": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Detalle de ingresos no operacionales u originados en actividades diferentes a la principal:</label>
                    <textarea className="form-control" value={formulario.ingresos_noperacionales} onChange={(e) => { setFormulario({ ...formulario, ['ingresos_noperacionales']: e.target.value }) }}></textarea>
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Total ingresos mensuales:</label>
                    <input type="text" className='form-control' value={formulario.total_ingresos} onChange={(e) => setFormulario({ ...formulario, "total_ingresos": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Realiza transacciones en moneda extranjera:</label>
                    <BasicSwitch label='' estado={formulario.moneda_extranjera} eventChange={(e: any) => setFormulario({ ...formulario, "moneda_extranjera": e })}></BasicSwitch>
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Egresos mensuales:</label>
                    <input type="text" className='form-control' value={formulario.egresos_mensuales} onChange={(e) => setFormulario({ ...formulario, "egresos_mensuales": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className='titul-form'>Tipo de transacción:</label>
                        <SelectConstantsValue label='' code={7} value={formulario.tipo_transaccion} onChange={(e: any) => { setFormulario({ ...formulario, 'tipo_transaccion': e }) }} />
                    </div>
                </div>
                {formulario.actividad_economica == 51 &&
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='titul-form'>¿Cuál?</label>
                            <textarea className="form-control" value={formulario.otro_tipo_transaccion} onChange={(e) => { setFormulario({ ...formulario, ['otro_tipo_transaccion']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                }
                <div className="col-md-6">
                    <label className="titul-form">Total activo:</label>
                    <input type="text" className='form-control' value={formulario.total_activos} onChange={(e) => setFormulario({ ...formulario, "total_activos": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Total pasivo:</label>
                    <input type="text" className='form-control' value={formulario.total_pasivos} onChange={(e) => setFormulario({ ...formulario, "total_pasivos": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className="titul-form">Total patrimonio:</label>
                    <input type="text" className='form-control' value={formulario.total_patrimonio} onChange={(e) => setFormulario({ ...formulario, "total_patrimonio": e.target.value })} />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4">
                    <label className="titul-form">Número de producto:</label>
                    <input type="text" className='form-control' value={formulario.numero_producto} onChange={(e) => setFormulario({ ...formulario, "numero_producto": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Moneda:</label>
                    <input type="text" className='form-control' value={formulario.moneda} onChange={(e) => setFormulario({ ...formulario, "moneda": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Monto:</label>
                    <input type="text" className='form-control' value={formulario.monto} onChange={(e) => setFormulario({ ...formulario, "monto": e.target.value })} />
                </div>

                <h5 className='titul-form mt-3'>Información del Representante Legal</h5>
                <hr />
                <div className="col-md-3">
                    <label className="titul-form">Primer Nombre:</label>
                    <input type="text" className='form-control' value={formulario.rep_name} onChange={(e) => setFormulario({ ...formulario, "rep_name": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Segundo Nombre:</label>
                    <input type="text" className='form-control' value={formulario.rep_name2} onChange={(e) => setFormulario({ ...formulario, "rep_name2": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Primer Apellido:</label>
                    <input type="text" className='form-control' value={formulario.rep_surname} onChange={(e) => setFormulario({ ...formulario, "rep_surname": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Segundo Apellido:</label>
                    <input type="text" className='form-control' value={formulario.rep_surname2} onChange={(e) => setFormulario({ ...formulario, "rep_surname2": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Tipo de Documento:</label>
                    <SelectConstantsValue label='' code={6} value={formulario.rep_docType} onChange={(e: any) => { setFormulario({ ...formulario, 'rep_docType': e }) }} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">No. Documento:</label>
                    <input type="number" className='form-control' value={formulario.rep_nit} onChange={(e) => setFormulario({ ...formulario, "rep_nit": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Fecha de expedición:</label>
                    <InputDate name='rep_expe_date' label='' value={formulario.rep_expe_date} setDate={(e: any) => setFormulario({ ...formulario, "rep_expe_date": e })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Lugar de expedición:</label>
                    <input type="text" className='form-control' value={formulario.rep_expe_zone} onChange={(e) => setFormulario({ ...formulario, "rep_expe_zone": e.target.value })} />
                </div>

                <h5 className='titul-form mt-3'>Dirección de residencia del Representante Legal</h5>
                <hr />
                <div className="col-md-3">
                    <label className="titul-form">Dirección residencia:</label>
                    <input type="text" className='form-control' value={formulario.residence_dir} onChange={(e) => setFormulario({ ...formulario, "residence_dir": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Ciudad/Municipio de residencia:</label>
                    <input type="text" className='form-control' value={formulario.residence_city} onChange={(e) => setFormulario({ ...formulario, "residence_city": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">Departamento:</label>
                    <input type="text" className='form-control' value={formulario.residence_dep} onChange={(e) => setFormulario({ ...formulario, "residence_dep": e.target.value })} />
                </div>
                <div className="col-md-3">
                    <label className="titul-form">País:</label>
                    <input type="text" className='form-control' value={formulario.residence_country} onChange={(e) => setFormulario({ ...formulario, "residence_country": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Teléfono:</label>
                    <input type="number" className='form-control' value={formulario.residence_tel} onChange={(e) => setFormulario({ ...formulario, "residence_tel": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Celular:</label>
                    <input type="number" className='form-control' value={formulario.residence_cel} onChange={(e) => setFormulario({ ...formulario, "residence_cel": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Correo electrónico:</label>
                    <input type="text" className='form-control' value={formulario.residence_eMail} onChange={(e) => setFormulario({ ...formulario, "residence_eMail": e.target.value })} />
                </div>

                <h5 className='titul-form mt-3'>Información económica del Representante Legal</h5>
                <hr />
                <div className="col-md-4">
                    <label className="titul-form">Realiza transacciones en moneda extranjera:</label>
                    <BasicSwitch label='NO / SI' estado={formulario.ecoInfo_money} eventChange={(e: any) => setFormulario({ ...formulario, "ecoInfo_money": e })}></BasicSwitch>
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Declara renta:</label>
                    <BasicSwitch label='NO / SI' estado={formulario.ecoInfo_rent} eventChange={(e: any) => setFormulario({ ...formulario, "ecoInfo_rent": e })}></BasicSwitch>
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Maneja Recursos Públicos</label>
                    <BasicSwitch label='NO / SI' estado={formulario.ecoInfo_publicManage} eventChange={(e: any) => setFormulario({ ...formulario, "ecoInfo_publicManage": e })}></BasicSwitch>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label className="titul-form">Tipo de transacción:</label>
                        <SelectConstantsValue label='' code={7} value={formulario.ecoInfo_transactionType} onChange={(e: any) => { setFormulario({ ...formulario, 'ecoInfo_transactionType': e }) }} />
                    </div>
                </div>
                {formulario.ecoInfo_transactionType == 51 &&
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='titul-form'>¿Cuál?</label>
                            <textarea className="form-control" value={formulario.otro_ecoInfo_transactionType} onChange={(e) => { setFormulario({ ...formulario, ['otro_ecoInfo_transactionType']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                }

                <div className="col-md-4">
                    <label className="titul-form">Número de producto:</label>
                    <input type="text" className='form-control' value={formulario.ecoInfo_product} onChange={(e) => setFormulario({ ...formulario, "ecoInfo_product": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Moneda:</label>
                    <input type="text" className='form-control' value={formulario.ecoInfo_coin} onChange={(e) => setFormulario({ ...formulario, "ecoInfo_coin": e.target.value })} />
                </div>
                <div className="col-md-4">
                    <label className="titul-form">Monto:</label>
                    <input type="text" className='form-control' value={formulario.ecoInfo_mount} onChange={(e) => setFormulario({ ...formulario, "ecoInfo_mount": e.target.value })} />
                </div>

                <div className="row">
                    <h5 className="titul-form mt-2">Información personas expuestas políticamente - PEP</h5>
                    <hr />
                    <div className="col-md-12 text-justify">
                        <p>1. Persona Expuesta Políticamente (PEP): Se consideran PEP los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y
                            territorial, cuando tengan asignadas o delegadas funciones de: expedición de normas o regulaciones, dirección general, formulación de políticas institucionales y adopción de planes, programas
                            y proyectos, manejo directo de bienes, dineros o valores del Estado, administración de justicia o facultades administrativo sancionatorias, y los particulares que tengan a su cargo la dirección o
                            manejo de recursos en los movimientos o partidos políticos. Estas funciones podrán ser ejercidas a través de ordenación de gasto, contratación pública, gerencia de proyectos de inversión,
                            pagos, liquidaciones, administración de bienes muebles e inmuebles. PEP desde su nombramiento y hasta 2 años después de dejar su cargo.<br></br>
                            <br></br>
                            2. PEP extranjeras: Son aquellas personas que desempeñen funciones prominentes en otro país. Se entienden por PEP extranjeras: (i) jefes de Estado, jefes de Gobierno, ministros,
                            subsecretarios o secretarios de Estado; (ii) congresistas o parlamentarios; (ii) miembros de tribunales supremos, tribunales constitucionales u otras altas instancias judiciales cuyas decisiones no
                            admitan normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de tribunales o de las juntas directivas de bancos centrales: (v) embajadores, encargados de negocios altos
                            funcionarios de las fuerzas armadas, (vi) miembros de los órganos administrativos, de gestión o de supervisión de empresas de propiedad estatal y (vii) representantes legales, directores,
                            subdirectores y/o miembros de las juntas directivas de organizaciones internacionales.Parágrafo.La calidad de PEP se mantendrá en el tiempo durante el ejercicio del cargo y por dos (2) años
                            más después de dejar su cargo. Obligación de los PEP: Informarán su cargo, fecha de vinculación y fecha de desvinculación. Asi mismo deberán, además, declarar: (i) los nombres e
                            identificación de las personas con las que tengan sociedad conyugal, de hecho, o de derecho; (ii) los nombres e identificación de sus familiares hasta segundo grado de consanguinidad, primero
                            afinidad y primero civil; (ili) la existencia de cuentas financieras en algún país extranjero en caso de que tengan derecho o poder de firma o de otra índole sobre alguna; y (iv) los nombres e
                            identificación de las personas jurídicas o naturales, patrimonios autónomos o fiducias conforme lo dispuesto en el artículo 2.1.4.2.3. del Decreto 830 de 2021.</p>
                    </div>
                </div>


                <div className="col-md-6">
                    <label className='titul-form'>Deacuerdo a la anterior clasificación, por favor indique si es PEP:</label>
                    <BasicSwitch label="NO / SI" estado={formulario.pep} eventChange={(e: any) => {
                        // setMiswitch(e);
                        setFormulario({ ...formulario, "peep": e });
                    }} /></div>

                <h5 className='titul-form mt-3'>Información de los socios o accionistas de la empresa</h5>
                <hr />
                <div className="col-md-12 mb-4">
                    <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={() => { setModalSocios({ ...modalSocios, ['view']: true }) }} ></BasicButton>
                </div>
                <div className="col-md-12">
                    <div className="table-responsive table-card">
                        <table className="table table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Tipo de documento de Identidad</th>
                                    <th scope="col">No. Documento de Identidad</th>
                                    <th scope="col">Nombres y Apellidos</th>
                                    <th scope="col">Participación</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            {formulario.socios &&
                                <>
                                    {formulario.socios.map((item: any, index: number) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td><RenderParametrica IDSuppConstantsValue={item.documentType}></RenderParametrica></td>
                                                    <td>{item.document}</td>
                                                    <td>{item.names}</td>
                                                    <td>{item.participate}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-light"
                                                            onClick={() => eliminar(item.document)}
                                                        >Eliminar</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        );
                                    })}
                                </>
                            }
                        </table>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <p>Si tiene socios o accionistas con participación directa o indirecta superior al 5% deberán diligenciar el formato de conocimiento del cliente persona natural.<br />
                        * En caso de manifestar Si en la casilla PEP de los numeral 52 y 53, adicionalmente deberan diligenciar el formato presentación de personas expuestas políticamente PEP "GR-NA-FM-038", para el
                        conocimiento ampliado de este tipo de personas.</p>
                </div>


                <div className="col-md-12 mt-4">
                    <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
                </div>
            </div>
            <Modal modal={modalSocios} updateModal={setModalSocios} eventModal={agregarElemto} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Tipo de Documento de indentidad:</label>
                            <SelectConstantsValue code={6} value={tipoDoc} onChange={(e: any) => { setTipoDoc(e) }} />
                        </div>
                        <div className="col-sm-6">
                            <label>No. Documento de Identidad:</label>
                            <input type="number" className='form-control' value={doc} onChange={(e: any) => setDoc(e.target.value)} />
                        </div>
                        <div className="col-md-6">
                            <label>Nombres y Apellidos:</label>
                            <input type="text" className='form-control' value={surname} onChange={(e: any) => setSurname(e.target.value)} />
                        </div>
                        <div className="col-md-6">
                            <label>Participación %:</label>
                            <input type="number" className='form-control' value={particion} onChange={(e: any) => setParticion(e.target.value)} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}