import { Singleton } from '../../../core/services/Singleton';
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../core/services/FinanceService';


import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
export const BudgetType = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new FinanceService();

    const [lista, setLista] = useState([]);
    const [listaType, setListType] = useState([]);
    const [idType, setIDType] = useState(0);
    const [listaIncome, setListIncome] = useState([]);
    const [listaExpense, setListExpense] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDBudgetType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Código de Tipo Presupuestal', property: 'Code' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Tipo De Catálogos De Ingresos', property: 'IDBudgetIDTypeIncome' },
        { title: 'Tipo de Catálogo de Gastos', property: 'IDBudgetIDTypeExpense' },

    ]

    useEffect(() => {
        getLista();
        getBudgetIDTypeConstants();
        getBudgetIDTypeByType();
    }, [])





    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])

    //listas desplegables
    const getBudgetIDTypeConstants = () => {

        setLoading(true);
        service.getBudgetIDTypeConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListType(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setIDType(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value);
                    }
                }

                setLoading(false);
            }
        );

    }

    const getBudgetIDTypeByType = () => {

        setLoading(true);
        let IDBudgetIDTypeIncome = 0;
        let IDBudgetIDTypeExpense = 0;
        service.getBudgetIDTypeByType(25).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListIncome(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        IDBudgetIDTypeIncome = resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.IDBudgetIDType;
                    }
                }
                setLoading(false);
            }
        );
        service.getBudgetIDTypeByType(26).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListExpense(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        IDBudgetIDTypeExpense = resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.IDBudgetIDTypeExpense;
                    }
                }
                setLoading(false);
            }
        );
        setBean({ ...bean, "IDBudgetIDTypeExpense": IDBudgetIDTypeExpense, "IDBudgetIDTypeIncome": IDBudgetIDTypeIncome });

    }
    //listas desplegables



    const getLista = () => {

        setLoading(true);
        service.getBudgetTypeCatalog(null).subscribe(
            (resp: any) => {
                console.log(resp);


                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }


    const agregarItem = () => {
        let b = {
            Name: '',
            Code: '',
            Type: 0,
            IDBudgetIDTypeExpense: null,
            IDBudgetIDTypeIncome: null,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {
        setLoading(true);
        service.deleteBudgetType(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean)
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {



        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateBudgetType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>

            <BasicPage titulo='Tipos de Presupuesto'>

                <div className="row">

                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Name}
                </ModalConfirm>

                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Código</label>
                            <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, ['Code']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                        </div>
                        {/* <div className="col-md-6">
                          <SelectBudgetIDType idSelector={idType} onChange={(e:number)=>{setIDType(e)}}></SelectBudgetIDType>
                        </div> */}
                        <div className="col-md-6">
                            <label>Tipo de Cátalogo de Ingresos</label>
                            <select className='form-control' value={bean.IDBudgetIDTypeIncome} onChange={(e) => { setBean({ ...bean, "IDBudgetIDTypeIncome": parseInt(e.target.value) }) }}>
                                <option value={0}>...</option>
                                {listaIncome.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label>Tipo de Catálogo de Gastos</label>
                            <select className='form-control' value={bean.IDBudgetIDTypeExpense} onChange={(e) => { setBean({ ...bean, "IDBudgetIDTypeExpense": parseInt(e.target.value) }) }}>
                                <option value={0}>...</option>
                                {listaExpense.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Name}</option>
                                    )
                                })}
                            </select>
                        </div>


                    </div>
                </Modal>

            </BasicPage>

        </>
    );
}