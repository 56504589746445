import React from 'react'

interface Props {
    open: boolean
    handleClick: () => void
}
export const HamburguerMenu = ({ open, handleClick }: Props) => {
    return (
        <div onClick={handleClick}
            className={`icon nav-icon-5 ${open ? 'open' : ''}`}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
