import { BasicPage } from "../../../modules/theme/Component/BasicPage"

interface Props{
    onChange:Function;
}
export const ReadInputFile=({onChange}:Props)=>{


    const readFile=(event: React.ChangeEvent<HTMLInputElement>)=>{
        const file = event.target.files?.[0];

        if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target?.result as string;
            onChange(content);
        };
        reader.readAsText(file);
        }
    }

    return(
        <input type="file"  className="form-control"  onChange={readFile}/>
    )
}