import { BasicPage } from "../../theme/Component/BasicPage";
import { InboxSelector } from "./components/InboxSelector";
import { InboxManagment } from "./components/InboxManagment";
import { useInboxStore } from "./hooks/useInboxStore";
import "./styles/inbox.css";


export const InboxBpm = () => {

    //Implementar la store que modala los estados aqui

    const { procedureImpSelected } = useInboxStore()

    return (
        <BasicPage titulo="Bandeja de Autogestión">
            <div className="main_container">
                <InboxSelector my_requests={false} />
                {procedureImpSelected
                    && <InboxManagment />
                }
            </div>
        </BasicPage>
    );
};
