import { useState } from "react";
import { Singleton } from "../../core/services/Singleton";
import certificates from "../../assets/dashboard/certificadoserp.png";
import pay_cetificates from "../../assets/dashboard/nominaerp.png";
import inbox from "../../assets/dashboard/bandejaerp.png";
import missing from "../../assets/dashboard/inboxerp.png";
import fixed_assets from "../../assets/dashboard/familiarerp.png";

import img1 from "../../assets/img/carrousel1.jpg"
import img2 from "../../assets/img/carrousel2.jpg"
import img3 from "../../assets/img/carrousel3.jpg"
import img4 from "../../assets/img/carrousel4.jpg"

import "./styles/dashboard.css";
import { CardProfile } from "./components/CardProfile";
import { IconButton } from "../../core/shared/buttons/IconButton";
import { EmployeeContract } from "../admin/perfil/elements/EmployeeContract";
import { EmployeeFixedAssets } from "../admin/perfil/elements/EmployeeFixedAssets";
import { PayrollProcess } from "../admin/perfil/elements/PayrollProcess";
import { EnjoyedVacationERP } from "../Talentohumano/payRoll/EnjoyedVacationERP";
const carouselItems = [
  {
    src: img1,
    alt: "Image 1",
    caption: "Caption 1",
  },
  {
    src: img2,
    alt: "Image 2",
    caption: "Caption 2",
  },
  {
    src: img3,
    alt: "Image 3",
    caption: "Caption 3",
  },
  {
    src: img4,
    alt: "Image 4",
    caption: "Caption 4",
  },
];

export const CERTIFICATES = 1;
export const FIXED_ASSETS = 2;
export const PAY_ROLL = 3;
export const VACATION = 4;

const quick_acitons = [
  {
    icon: inbox,
    resume: 'Ir a "Mis solicitudes"',
    label: "Mis Solicitudes",
    evento: 0,
  },
  {
    icon: missing,
    resume: 'Consulte sus vacaciones',
    label: "Vacaciones",
    evento: VACATION,
  },
  {
    icon: certificates,
    resume: 'Descargue su certificado laboral',
    label: "Certificados",
    evento: CERTIFICATES,
  },
  {
    icon: pay_cetificates,
    resume: 'Consulte sus desprendibles',
    label: "Nómina",
    evento: PAY_ROLL,
  },
  {
    icon: fixed_assets,
    resume: 'Certificado de activos fijos',
    label: "Activos fijos",
    evento: FIXED_ASSETS,
  },
];

export const ProfileDashboard = () => {

  const [reactiveView, setReactiveView] = useState(0);

  return (
    <div className="dcontainer_wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        {/* OVER SIDE */}
        <div className="row mx-2" style={{ position: 'absolute' }}>
          <div className="col-lg-8 mb-4 order-0 d-flex justify-content-center align-items-center">
            {/* QUICK PERSONAL INFO o carta de buenos dias */}
            <CardProfile />
          </div>

          {/* QUICK ACTIONS GRID */}
          <div className="col-lg-4 col-md-12 order-1 ">
            <div className="card" style={{ minHeight: '225px' }}>
              <div className="card-body p-2 px-4">
                <div className="card-title fs-5 mt-1 text-end">Próximos eventos</div>
                <ul className="list-group list-group-flush border-dashed">
                  <li className="list-group-item ps-0">
                    <div className="row align-item-center g-3">
                      <div className="col-auto">
                        <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 shadow">
                          <div className="text-center">
                            <h5>25</h5>
                            <div className="text-muted">Nov</div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <h6 className="text-muted">12:25 pm - 3:30 pm</h6>
                        <p className="fw-semibold">Nombre del evento</p>
                      </div>
                      <div className="col-sm-auto">
                        <IconButton
                          icon="ri-eye-fill"
                          event={() => { }}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="list-group-item ps-0">
                    <div className="row align-item-center g-3">
                      <div className="col-auto">
                        <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 shadow">
                          <div className="text-center">
                            <h5>7</h5>
                            <div className="text-muted">Dic</div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <h6 className="text-muted">16:45 pm - 18:00 pm</h6>
                        <p className="fw-semibold">Nombre del evento</p>
                      </div>
                      <div className="col-sm-auto">
                        <IconButton
                          icon="ri-eye-fill"
                          event={() => { }}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Carrousel */}
          <div className="col-md-12 col-lg-8 order-2 order-md-3 order-lg-2 mb-4 h-100">
            <div className="card">
              <div className="card-body reactive_zone animate__animated animate__fadeIn"
                style={{
                  maxHeight: '80%',
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                {reactiveView === 0 && (
                  <>
                    <div className="col-md-12">
                      {/* <CarouselUnique imgList={carouselItems} /> */}
                    </div>
                  </>
                )}
                {reactiveView === CERTIFICATES && (
                  <EmployeeContract />
                )}
                {reactiveView === FIXED_ASSETS && (
                  <EmployeeFixedAssets />
                )}
                {reactiveView === PAY_ROLL && (
                  <PayrollProcess />
                )}
                {reactiveView === VACATION && (
                  <EnjoyedVacationERP rol={2}  />
                )}
              </div>
            </div>
          </div>
          {/* QUICK ACTIONS GRID 2 */}
          <div className="col-md-12 col-md-8 col-lg-4 order-1" style={{ maxHeight: '80%' }}>
            <div className="card">
              <div className="card-body p-2 px-4">
                <div className="card-title fs-5 mt-1 text-end">Acciones rápidas</div>
                {
                  quick_acitons.map((_e, _i) => (
                    <div className="d-flex mb-1 p-2 _zoom" key={_i} onClick={() => setReactiveView(_e.evento)}>
                      <div className="flex-shrink-0">
                        <img className="rounded" src={_e.icon} alt={_e.label} height={40} />
                      </div>
                      <div className="d-flex flex-column ms-3 overflow-hidden">
                        <strong>{_e.label}</strong>
                        <small className="text-muted">{_e.resume}</small>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};



