import { processState } from "../Constants";
import { Modal } from '../theme/Component/Modal';
import { useState } from 'react';

interface Props {
    bean: any;
    eventChange: any;
}
export const ProcessActions = ({ bean, eventChange }: Props) => {

    const states = processState;
    const [modal, setModal] = useState<any>({ name: 'Contratos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


    const openModal = ()=>{
        setModal({...modal,['view']:true});
    }
    const closeModal = ()=>{
        setModal({...modal,['view']:false});
    }




    return (
        <>
            {bean.State === 1 &&
                <>
                    <button type="button" className="btn btn-outline-success btn-icon waves-effect waves-light shadow-none btnicon_sm" 
                        title="Publicar Proceso" onClick={()=>{
                            bean.State = 2;
                            eventChange(bean);
                        }}>
                        <i className="ri-mail-send-line"></i>
                    </button>
                    <button type="button" className="btn btn-outline-success btn-icon waves-effect waves-light shadow-none btnicon_sm" 
                    title="Configurar Responsables" onClick={()=>{

                    }}>
                        <i className="ri-folder-user-line"></i>
                    </button>
                    <button type="button" className="btn btn-outline-success btn-icon waves-effect waves-light shadow-none btnicon_sm" 
                    title="Documentos Requeridos">
                        <i className="ri-attachment-2"></i>
                    </button>

                    <button type="button" className="btn btn-outline-success btn-icon waves-effect waves-light shadow-none btnicon_sm" 
                    title="Configurar Proceso">
                        <i className="ri-folder-settings-line"></i>
                    </button>
                </>
            }
            {bean.State === 2 &&
                <>
                    <button type="button" className="btn btn-outline-danger btn-icon waves-effect waves-light shadow-none btnicon_sm"
                     title="Pausar Proceso"  onClick={()=>{
                        bean.State = 1;
                        eventChange(bean);
                    }}>
                        <i className="ri-mail-send-line"></i>
                    </button>
                    
                </>
            }

            <Modal modal={modal} updateModal={setModal} eventModal={(b:any)=>{eventChange(b)}}>
                <h4>Configuraciones</h4>
            </Modal>
        </>
    )
}