import { BasicPage } from "./Component/BasicPage";

export const Dashboard = () => {
    return (
        <BasicPage titulo="">
            <div className="card card-body text-center">
                <div className="avatar-sm mx-auto mb-3">
                    <div className="avatar-title bg-soft-success text-danger fs-17 rounded">
                        <i className="ri-wifi-off-line"></i>
                    </div>
                </div>
                <h4 className="card-title">QuickDataErp.</h4>
                {/* <p className="card-text text-muted">Ir a pagina principal.</p>
                <Link to="/app">
                    <span className="btn btn-primary">Ir</span>
                </Link> */}
            </div>
        </BasicPage>
    );
}