import { useState, useEffect } from 'react';

function usePaginator() {
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [records, setRecords] = useState(0);

    useEffect(() => {
        setPage(0);
    }, [pages]);

    const next = () => {
        setPage(prevPage => {
            const newPage = prevPage + 1;
            return newPage < pages ? newPage : prevPage;
        });
    };

    const back = () => {
        setPage(prevPage => {
            const newPage = prevPage - 1;
            return newPage >= 0 ? newPage : prevPage;
        });
    };

    return {
        page,
        pages,
        records,
        setPages,
        setRecords,
        next,
        back
    };
}

export default usePaginator;
