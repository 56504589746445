import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
    idCharacterization?: number;
}

export const Selectortipodecliente = ({ idCharacterization, idSelector, onChange, titulo }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [idCharacterization])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {

        if (idCharacterization) {
            service.getCharacterizationCatalogByCustomerType(idCharacterization).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue)
                    }
                }
            )
        } else {
            service.getCharacterizationCatalog(null).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    console.log("DataAcces", lista);
                }
            );
        }
    }

    return (
        <>
            <label key="{titulo}">{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any, _i) => {
                        return (
                            <>
                                <option key={_i} value={item.DataBeanProperties.IDCharacterization} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}