import { Singleton } from '../../../core/services/Singleton';
import { BpmService } from '../../../core/services/BpmService';
import { useState, useEffect } from 'react';
import { CardTaps } from '../../theme/Component/CardTaps';
import { GestionAction } from './GestionAction';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';


export const GestionarImp = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const bpm = new BpmService();

    const [lista, setLista] = useState<any>([]);
    const [listaDocs, setListaDocs] = useState<any>([]);
    const [tramite, setTramite] = useState<any>({});
    const [action, setAction] = useState<any>({});


    const taps = [
        { id: 0, name: 'Trámites' },
        { id: 1, name: 'Documentos' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    useEffect(() => {
        setIndexTap(0);
        getProcedureImpByAccount();
    }, [])
    useEffect(() => {
        getActualProcedureActionByProcedureImp();
    }, [tramite])

    const getProcedureImpByAccount = () => {
        setLoading(true);
        setAction({});
        setListaDocs([]);
        bpm.getProcedureImpByAccount(single.getAccountID()).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        )
    }
    const getActualProcedureActionByProcedureImp = () => {
        setLoading(true);
        bpm.getActualProcedureActionByProcedureImp(tramite.IDProcedureImp).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaDocs(resp.DataBeanProperties.ObjectValue);
                }
            }
        )
    }

    const update = () => {
        getProcedureImpByAccount();
        setIndexTap(0);
    }

    return (
        <div className="row">
            <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap}>
                {indexTap === 0 &&
                    <div className="col">
                        <ul className="list-group">
                            {lista?.map((item: any) => {
                                return (
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-md-1 text-center">
                                                <i title='Abrir' className="ri-inbox-archive-line link-success cursor" style={{ marginRight: '20px', fontSize: '1.2rem' }} onClick={() => {
                                                    setTramite(item.DataBeanProperties);
                                                    setIndexTap(1);
                                                }} />

                                            </div>
                                            <div className="col-md-7">
                                                <strong>{item.DataBeanProperties.AlphaCode} / {item.DataBeanProperties.BusinessProcessName}</strong> <br />
                                                <h6>{item.DataBeanProperties.AccountID} - {item.DataBeanProperties.AccountName}</h6>
                                            </div>
                                            <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                <small> {item.DataBeanProperties.Since}</small>
                                            </div>
                                        </div>


                                    </li>

                                )
                            })}
                        </ul>
                    </div>

                }
                {indexTap === 1 &&
                    <div className="col">
                        <h6><b>{tramite?.AlphaCode} {tramite?.BusinessProcessName}</b> <br /> {tramite?.AccountName} </h6>
                        <hr />
                        <ul className="list-group">
                            {listaDocs?.map((item: any) => {
                                return (
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <i title='Gestionar' className={item.DataBeanProperties.FormCode ? 'ri-send-plane-fill link-success cursor' : 'ri-attachment-line link-success cursor'} style={{ marginRight: '20px', fontSize: '1.2rem' }} onClick={() => {
                                                    setAction(item.DataBeanProperties);
                                                }} />
                                            </div>
                                            <div className="col-md-7">
                                                <strong> {item.DataBeanProperties.ProcedureName}</strong> <br />
                                                <b>{item.DataBeanProperties.StateName}</b> <br />
                                                <small>{item.DataBeanProperties.FormCode} - {item.DataBeanProperties.ProcedureActionName} </small>
                                            </div>
                                            <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                <small>{item.DataBeanProperties.UptoDate}</small>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                }
                {action.IDAction &&
                    <GestionAction action={action} eventUpdate={update}></GestionAction>
                }

            </CardTaps>


        </div>
    )
}