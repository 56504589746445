import { useState, useEffect } from 'react';
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BpmService } from "../../../core/services/BpmService";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from '../../theme/Component/Modal';
import { ProcedureAction } from './ProcedureAction';
import { ActualProcedure } from './ActualProcedure';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';


interface Props {
    bean: any;
}

export const ProcedureIMP = ({ bean }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [listaT, setListaT] = useState([]);
    const [beanT, setBeanT] = useState<any>({});
    const [modalTrack, setModalTrack] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalHisto, setModalHisto] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    const setRowBeanTramites = (beanT: any) => {
        console.log(beanT);
        setBeanT(beanT);
    }

    useEffect(() => {
        getTramites()
    }, [bean])

    const getTramites = () => {
        setLoading(true);
        service.getProcedureImpByAccount(bean.IDAccount).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaT(resp.DataBeanProperties.ObjectValue);
                    console.log(resp)
                }
                setLoading(false);
            }
        );
    }

    const headtramites = [
        { title: 'ID del Tramite', property: 'IDProcedureImp' },
        { title: 'Tramite', property: 'ProcedureName' },
        { title: 'No. Radicado', property: 'AlphaCode' },
        { title: 'Fecha de Inicio', property: 'Since' },
        { title: 'Estado', property: 'StateName' },
    ]


    const listaBotonesT = () => {
        const botones = [

            { titulo: 'Historico', icono: 'ri-hard-drive-2-line', visible: true, evento: () => { setModalHisto({ ...modalHisto, "view": true }) } },
            { titulo: '¿En donde esta el Tramite?', icono: 'ri-hard-drive-2-line', visible: true, evento: () => { setModalTrack({ ...modalTrack, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Información de Tramites">
                <>

                    <BasicTable listButtons={listaBotonesT()} body={listaT} rowSet={setRowBeanTramites} head={headtramites}></BasicTable>

                </>
            </BasicPage>

            <Modal modal={modalHisto} updateModal={setModalHisto}  >
                <>
                    <ProcedureAction IDProcedure={beanT.IDProcedureImp}></ProcedureAction>
                </>
            </Modal>

            <Modal modal={modalTrack} updateModal={setModalTrack}  >
                <>
                    <ActualProcedure IDProcedure={beanT.IDProcedureImp}></ActualProcedure>
                </>
            </Modal>
        </>
    )
}