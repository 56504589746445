

interface Props {
    formulario: any;
}

export const Forma6Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center celda">
                    <h5>ADMINISTRACIÓN DE DATOS - CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA</h5>
                </div>
                <div className="col-12 text-justify color-celda">
                    <p>


                        <p>Declaro que toda la información que he suministrado y suministraré a la CAJA PROMOTORA
                            DE VIVIENDA MILITAR Y DE POLICÍA a través del presente formato con los diferentes fines será utilizada para:   </p>

                        <p>I. Fines administrativos, comerciales, promocionales, informativos, de mercadeo y ventas, ofrecer todo tipo de servicios
                            comerciales; así como realizar campañas de promoción, marketing, publicidad. Búsqueda de un conocimiento más cercano con todos
                            sus afiliados, proveedores, empleados y terceros vinculados. El trámite de mi solicitud de vinculación como consumidor financiero,
                            deudor, contraparte contractual y/o proveedor de la Entidad. La ejecución y el cumplimiento de los contratos que celebre. El control
                            y la prevención del fraude. Controlar el cumplimiento de requisitos para acceder al Sistema General de Seguridad Social Integral. La
                            elaboración de estudios técnico-actuariales, estadísticas, encuestas, análisis de tendencias del mercado y, en general, estudios que
                            afecten directa o indirectamente a la Entidad. Envío de información relativa a la educación financiera, encuestas de satisfacción de afiliados
                            y ofertas comerciales, así como de otros servicios inherentes a la actividad de la Entidad. Realización de encuestas sobre satisfacción en los
                            servicios prestados por la Entidad. Intercambio o remisión de información en virtud de tratados y acuerdos internacionales e intergubernamentales
                            suscritos por Colombia. La prevención y control de lavado de activos, de la financiación del terrorismo y de la proliferación de armas de destrucción
                            masiva. Consulta, almacenamiento, administración, transferencia, procesamiento y reporte de información a las Centrales de Información o bases de
                            datos debidamente constituidas referentes al comportamiento crediticio, financiero y comercial.</p>

                        <p>II. Los datos aquí suministrados podrán ser compartidos, transmitidos, entregados o divulgados a: los titulares de los datos, sus herederos
                            o representantes en cualquier momento y a través de cualquier medio cuando así lo soliciten a la Entidad. A las entidades judiciales
                            o administrativas en ejercicio de funciones que eleven algún requerimiento a la Entidad para que le sea entregada la información.
                            A los terceros que sean autorizados por alguna Ley de la República de Colombia.</p>
                        <p>III. Datos sensibles: Que son facultativas las respuestas a las preguntas que me han hecho o me harán sobre datos personales sensibles,
                            de conformidad con la legislación vigente. En consecuencia, no he sido obligado a responderlas, por lo que autorizo expresamente para llevar
                            a cabo el tratamiento de mis datos sensibles, en especial a la salud y datos biométricos.</p>
                        <p>IV. En atención a lo dispuesto en la Ley Estatutaria 1581 de 2012 y el Decreto Reglamentario 1377 de 2013, la Entidad asegura que el tratamiento de los datos personales
                            de niños, niñas y adolescentes será realizado respetando sus derechos, en consecuencia, no he sido obligado(a) a responderlas.</p>
                        <p>V. Que, como titular de la información, me asiste el derecho a conocer, actualizar, rectificar y suspender la información que se ha recogido sobre mí.</p>
                        <p>VI. LA CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA tiene la calidad responsable del tratamiento a través de POLÍTICA MANEJO DE INFORMACIÓN Y DATOS PERSONALES, y puede ser consultada en www.cajahonor.gov.co</p>
                        <p>VII. De manera expresa, autorizo el tratamiento de los datos personales incluidos los sensibles y autorizo, de ser necesario, la transferencia nacional e internacional de los mismos, por las personas para las finalidades y en los términos que me fueron informados en este documento.</p>

                        <p >COMO CONSTANCIA DE HABER LEÍDO, ENTIENDO Y ACEPTANDO LO ANTERIOR , DECLARO QUE LA INFORMACIÓN QUE EH SUMINISTRADO ES EXACTA EN TODAS SUS PARTES
                            Y FIRMO EL PRESENTE DOCUMENTO EN LA CIUDAD DE <strong className="titul-form">{formulario.ciudaddatos} </strong> EL DÍA  <strong className="titul-form">{formulario.diadatos}</strong> DEL (MES)
                            <strong className="titul-form">{formulario.mesdatos}</strong> DEL (AÑO) <strong className="titul-form">{formulario.aniodatos}</strong> .</p>
                    </p>
                </div>
            </div>
            {/* <PrintJson json={formulario} /> */}
        </>
    )
} 