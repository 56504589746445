import { GenericService } from "../../../../core/services/GenericService";

export class OfficeService extends GenericService {

  public getOfficeCatalog(args: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getOfficeCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  public updateOffice(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "common.Office_updateOffice_common.Office",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'common.Office'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }

  public deleteOffice(IDOffice: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteOffice_Number",
      ArgumentList: [IDOffice],
    };
    const data = JSON.stringify(parametros);
    return this.myApi.post(this.url, data);
  }
}