import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props{
    idSelector:number;
    onChange:any;
    label?:string;
}
export const SelectMonths =({idSelector,onChange,label}:Props)=>{

    const service = new ContractService();
    const[lista,setLista] = useState([]);
    const[id,setID] = useState(idSelector);

    useEffect(()=>{

        // setID(idSelector);
        getLista();

    },[])
    useEffect(()=>{

        onChange(id);

    },[id])

    const setValue=(e:any)=>{
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {

        let cache = Singleton.getInstance().getCacheItem("MonthConstants");
        if(cache)
        {
            setLista(cache);
        }
        else{
            service.getMonthConstants(null).subscribe(
                (resp: any) => {
                    setLista([]);
    
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        Singleton.getInstance().setCacheItem("MonthConstants",resp.DataBeanProperties.ObjectValue);
                    }
                        
                }
            );
        }

        

    }

    return(
        <>
        <label>{label ? label : 'Mes'}</label>
        <select className="form-select mt-2" value={id} onChange={(e)=>{setValue(e.target.value)}}>             
            {lista && 
                lista.map((item:any)=>{
                    return(                    
                    <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                    )
                })
            }            
        </select>
        </>
    )
}