
import { RenderParametrica } from "../RenderParametrica";

interface Props {
    formulario: any;
}

export const Forma1Pdf = ({ formulario }: Props) => {
    return (
        <>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>CONOCIMIENTO DEL CLIENTE</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-3 color-celda">
                    <label className="titul-form">Fecha de diligenciamiento</label>
                    <p><strong>{formulario.fechacreacion}</strong></p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Ciudad</label>
                    <p><strong>{formulario.ciudaduno}</strong></p>
                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Ingreso de datos por: </label>
                    <RenderParametrica IDSuppConstantsValue={formulario.ingresodatos} />

                </div>
            </div>

            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>PERSONA NATURAL</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-3 color-celda">
                    <label className="titul-form" >Primer Nombre: </label>

                    <p><strong>{formulario.primer_nombre}</strong></p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Segundo Nombre: </label>
                    <p>{formulario.segundo_nombre}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Primer Apellido: </label>
                    <p>{formulario.primer_apellido}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Segundo Apellido: </label>
                    <p>{formulario.segundo_apellido}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-3 color-celda">
                    <label className="titul-form">Tipo de Documento: </label>
                    <RenderParametrica IDSuppConstantsValue={formulario.tipo_documento} />
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">No Documento: </label>
                    <p>{formulario.nodocumento}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Fecha de expedición: </label>
                    <p>{formulario.fechaexp}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Lugar de expedición: </label>
                    <p>{formulario.lugarexp}</p>
                </div>
            </div>

            <div className="row ">
                <div className="col-3 color-celda">
                    <label className="titul-form">Fecha de Nacimiento: </label>
                    <p>{formulario.fechanacimiento}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Lugar de nacimiento: </label>
                    <p>{formulario.lugarnacimiento}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Departamento: </label>
                    <p>{formulario.departamento}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Nacionalidad: </label>
                    <p>{formulario.nacionalidad}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>DIRECCIÓN DE RESIDENCIA DEL TITULAR</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-3 color-celda">
                    <label className="titul-form">Dirección de residencia: </label>
                    <p>{formulario.direccion}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">ciudad / Municipio de residencia: </label>
                    <p>{formulario.ciudad}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Departamento: </label>
                    <p>{formulario.departamentotitular}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">País: </label>
                    <p>{formulario.pais}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-2 color-celda">
                    <label className="titul-form" >Teléfono de residencia:</label>
                    <p>{formulario.telefono}</p>
                </div>
                <div className="col-2 color-celda">
                    <label className="titul-form">Teléfono de Trabajo: </label>
                    <p>{formulario.ciudad}</p>
                </div>
                <div className="col-2 color-celda">
                    <label className="titul-form" >Celular 1: </label>
                    <p>{formulario.departamentotitular}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Celular 2: </label>
                    <p>{formulario.pais}</p>
                </div>
                <div className="col-3 color-celda">
                    <label className="titul-form">Correo Electrónico: </label>
                    <p>{formulario.correo}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>INFORMACIÓN LABORAL DEL TITULAR</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-4 color-celda">
                    <label className="titul-form">Nombre unidad / empresa : </label>
                    <p>{formulario.nombreempresa}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Código CIIU:</label>
                    <p>{formulario.codigo}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Maneja Recursos Públicos: </label>

                    <p>{formulario.recursospublicos ? 'SI' : 'NO'}</p>
                </div>

            </div>
            <div className="row ">
                <div className="col-12 color-celda celda">
                    <h5>INFORMACIÓN ECONÓMICA DEL TITULAR</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <label className="titul-form">Realiza Transacciones en moneda extranjera: </label>
                    <p>{formulario.mondedaextranjera ? 'SI' : 'NO'}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <label className="titul-form">Tipo de transacción: </label>
                    <RenderParametrica IDSuppConstantsValue={formulario.tipotransaccion} />
                </div>
            </div>
            <div className="row ">
                <div className="col-4 color-celda">
                    <label className="titul-form">Número de producto: </label>
                    <p>{formulario.numeroprod}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Moneda:</label>
                    <p>{formulario.moneda}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Monto: </label>

                    <p>{formulario.monto}</p>
                </div>

            </div>
            <div className="row">
                <div className="col-6 color-celda">
                    <label className="titul-form">Declara renta: </label>
                    <p>{formulario.renta ? 'SI' : 'NO'}</p>

                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Información economica a corte(Ultimo año): </label>

                    <p>{formulario.informacion}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-6 color-celda">
                    <label className="titul-form">Ingresos Mensuales: </label>
                    <p>{formulario.totalingresos}</p>

                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form">Otros ingresos: </label>

                    <p>{formulario.otrosingresos}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <label className="titul-form">Detalle de ingresos originados de otras actividades: </label>
                    <p>{formulario.otrosingresos}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-4 color-celda">
                    <label className="titul-form">Total ingresos mensuales: </label>
                    <p>{formulario.totalingresos}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Egresos mensuales:</label>
                    <p>{formulario.egresos}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form">Total Activo: </label>

                    <p>{formulario.totalactivo}</p>
                </div>

            </div>
            <div className="row">
                <div className="col-6 color-celda">
                    <label className="titul-form">Total Pasivo: </label>
                    <p>{formulario.totalpasivo}</p>

                </div>
                <div className="col-6 color-celda">
                    <label className="titul-form" >Total Patrimonio: </label>

                    <p>{formulario.totalpatrimonio}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <p>1. Persona Expuesta Políticamente (PEP): Se consideran PEP los servidores públicos de cualquier sistema de nomenclatura y clasificación de empleos de la administración pública nacional y territorial, cuando en los cargos que ocupen, tengan en las funciones del área a la que pertenecen o en las de la ficha del empleo que ocupan, bajo su responsabilidad directa o por delegación, la expedición de normas o regulaciones, la dirección general, la formulación de políticas institucionales y adopción de planes, programas y proyectos, el manejo directo de bienes, dineros o valores del Estado y la administración de justicia o facultades administrativo sancionatorias. Funciones pueden ser a través de ordenación de gasto, contratación pública, gerencia de proyectos de inversión, pagos, liquidaciones, administración de bienes muebles e inmuebles. PEP desde su nombramiento y hasta 2 años después de dejar su cargo.

                        2. PEP de organizaciones internacionales: Son aquellas personas que ejercen funciones directivas en una organización internacional. Se entienden por PEP de organizaciones internacionales directores, subdirectores, miembros de juntas directivas o cualquier persona que ejerza una función equivalente. \f0Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo.

                        3. PEP extranjeros: Son aquellas personas que desempeñan funciones públicas destacadas en otro país. Se entienden por PEP extranjeros: (i) jefes de Estado, jefes de Gobierno, ministros, subsecretarios o secretarios de Estado; (ii) congresistas o parlamentarios; (iii) miembros de tribunales supremos, tribunales constitucionales u otras altas instancias judiciales cuyas decisiones no admitan normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de tribunales o de las juntas directivas de bancos centrales; (v) embajadores, encargados de negocios y altos funcionarios de las fuerzas armadas, y (vi) miembros de los órganos administrativos, de gestión o de supervisión de empresas de propiedad estatal. \f0Tendrán esta calidad desde su nombramiento y hasta 2 años después de dejar su cargo. Definición de vinculado:

                        3.1 Segundo grado de consanguinidad padres, abuelos, hermanos, hijos, nietos,

                        3.2 Segundo de afinidad yernos, nueras, suegros, cuñados, abuelos del cónyuge o compañero/a permanente del PEP,

                        3.3 Primero civil hijos adoptivos o padres adoptantes. 4. Asociado cercano cuando el PEP sea socio o asociado de una persona jurídica y,además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica en los términos del artículo 261 del Código de Comercio.</p>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form"><strong>Deacuerdo a la anterior info , porfavor indique si es PEP:</strong> </label>
                    <p>{formulario.mondedaextranjera ? 'SI' : 'NO'}</p>
                </div>
            </div>
            {/* <PrintJson  json={formulario}/> */}
        </>
    )
} 