import { ContractService } from "../../../../core/services/ContractService";
import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { BpmServiceall } from "../../../../core/services/BpmServiceall";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Application } from "../../../admin/menu/Application";
import { SuppConstantsValue } from "./SuppConstantsValue";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { DropDown } from "../../../theme/Component/DropDown";
import { toast } from "react-toastify";

export const SuppConstants = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [data, setData] = useState<any>([]);

    const [prevCode, setPrevCode] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalPC, setModalPC] = useState<any>({ name: 'Propiedades Constante', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});


    const idBean = 'IDSuppConstants';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Code', property: 'Code' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
        if (lista) {
            setData(lista);
        }
    }, [idBean])


    useEffect(() => {
        const extractedCodes = data.map((item: any) => item.DataBeanProperties.Code);
        setPrevCode(extractedCodes);
    }, [idBean])

    const getLista = () => {
        setLoading(true);
        service.getSuppConstantsCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }





    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 1,
            Code: 1,
            Since: single.getCurrenDate(),
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteSuppConstants(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateSuppConstants(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Propiedades', icono: 'ri-file-user-line', visible: true, evento: () => { setModalPC({ ...modalPC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]


    const handleCodeChange = (e: any) => {
        const newCode = e.target.value;
        setBean({ ...bean, Code: newCode });
    };


    //   const handleGuardarClick = () => {
    //     if (bean.Code !== prevCode) {
    //       // Aquí puedes realizar la acción de guardar
    //       // También puedes actualizar prevCode para reflejar el último valor guardado
    //       setPrevCode(bean.Code);
    //       // Luego, puedes realizar la acción de guardar
    //       // Ejemplo: guardarEnLaBaseDeDatos(bean.Code);
    //       setModal({ ...modal, ['view']: false });

    //       setLoading(true);
    //       service.updateSuppConstants(bean).subscribe(
    //           (resp: any) => {
    //               setLoading(false);
    //               if (resp.DataBeanProperties.ObjectValue) {
    //                   getLista();
    //               }
    //               single.update(bean);
    //           }
    //       );
    //     }
    //   };




    //   const listaconparardos = () => {

    //       let prevCode =  lista;
    //       {prevCode.map((item:any)=>{
    //         return(
    //             <>
    //                 <input type="text">{item.DataBeanProperties.Code}</input>
    //             </>
    //       )}) 

    //       }       
    //   };

    const eventChange = (e: any) => {

        if (e.id === 1) {
            service.cleanSuppConstantsValues(0).subscribe(
                (resp: any) => {
                    toast.success("Cahce actualizada.");
                }
            )
        }

    }


    const mostrar = () => {
        console.log(lista)
    }

    const actionsDropDown = [
        { id: 1, name: 'Borrar Cache', permiso: null, activo: true },
    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
                </div>
            </div>
        )
    }

    return (
        <>
            <BasicPage titulo="Constante">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>

                        {/* <div>
                            <h2>Lista de Códigos</h2>
                            <ul>
                                {prevCode.map((code: any) => (
                                    <li>{code}</li>
                                ))}
                            </ul>
                        </div> */}

                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, ['Code']: e.target.value }) }} />
                        {/* <input type="text" className="form-control" value={bean.Code} onChange={handleCodeChange}/> */}

                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            <Modal modal={modalPC} updateModal={setModalPC}>
                <SuppConstantsValue idSuppConstants={bean[idBean]}></SuppConstantsValue>
            </Modal>
        </>
    )
}