import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';



interface Props {
    idSelector: number;
    onChange: any;
    titulo?: string;
}

export const SelectorUmeasurement = ({ titulo, idSelector, onChange }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(id);
    }

    const getLista = () => {

        service.getMeasurementUnitCatalog(null, null).subscribe(
            (resp: any) => {
                setLista([]);
                console.log("Lista", resp);
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
            }
        );

    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-select mt-2" value={idSelector} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <option key={item.DataBeanProperties.Name} value={item.DataBeanProperties.Name} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}