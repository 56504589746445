import { useEffect, useState } from 'react';
interface Props {
    value?: string;
    icon: string;
    eventClick: any;
    clase?: string;
    children?: any;
    disable?: boolean;
}
export const BasicButton = ({ value, icon, eventClick, clase, children, disable }: Props) => {

    const [miClase, setClase] = useState('btn btn-primary btn-label waves-effect waves-light m-2');
    const [dis, setDisable] = useState(false);

    useEffect(() => {

        setDisable(disable ? disable : false);

    }, [disable])

    return (
        <button type="button" style={{ height: '38px', marginTop: '12px', flexWrap: 'nowrap', display: 'flex' }} className={clase ? `btn btn-${clase} ` : 'btn btn-primary '} onClick={eventClick} disabled={dis}>
            <i className={`${icon} label-icon align-middle fs-16 me-2`}></i> {value}{children}
        </button>
    )
}