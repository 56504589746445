import { GenericService } from "../../../../core/services/GenericService";

export class BankService extends GenericService {



    public getCashDeskAccountCatalog(officeid: number | null, accountid: number | null) {

        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getCashDeskAccountCatalog_Number_Number",
            ArgumentList: [officeid, accountid],
        };

        const data = JSON.stringify(parametros);

        return this.myApi.post(this.url, data);

    }


    public updateCashDeskAccount(bean: any) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "eBankTransfer.CashDeskAccount_updateCashDeskAccount_eBankTransfer.CashDeskAccount",
            ArgumentList: [
                {
                    DataBeanProperties: bean,
                    DataBeanName: 'eBankTransfer.CashDeskAccount'
                }
            ],
        };

        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);
    }


}