import { BasicPage } from "../theme/Component/BasicPage"
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { InputDate } from "../theme/Input/InputDate";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";


export const TipoNovedad = () => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [idTipo, setIDTipo] = useState(0);

    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    // Llave primaria del bean    

    const idBean = 'IDTypeNovelty';

    const head = [
        { title: 'ID', property: idBean },

        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Codigo', property: 'Code' },
        { title: 'Fecha', property: 'Since' },
        { title: 'Estado', property: 'State' },

    ]



    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getTypeNoveltyCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            // IDHiringModality :idTipo,
            Name: '',
            Description: '',
            Since: single.getCurrenDate(),
            Code: 0,
            State: 0,

        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteTypeNovelty(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateTypeNovelty(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">

                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>


                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>


                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', icono: 'ri-file-add-line', evento: agregarItem }

    ]

    return (
        <>
            <BasicPage titulo="Tipo de novedad">

                <div className="row">


                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <InputDate label="Fecha" name="InitDate" value={bean.Since} setDate={(e: any) => { setBean({ ...bean, ['Since']: e }) }}></InputDate>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Codigo</label>
                        <input type="number" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, ['Code']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Estado</label>
                        <input type="number" className="form-control" value={bean.State} onChange={(e) => { setBean({ ...bean, ['State']: e.target.value }) }} />
                    </div>

                </div>
            </Modal>
        </>
    )
}