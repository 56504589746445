import { useEffect, useState } from 'react';
interface Props {
    label: string;
    name: string;
    value: any;
    setDate: any;
}
export const InputDate = ({ label, name, value, setDate }: Props) => {

    const [fecha, setFecha] = useState(new Date().toISOString().slice(0, 10));
    useEffect(() => {


        try {
            let v: Date = new Date();
            if (value === undefined || value === null) {
                v = new Date();
            } else {
                v = new Date(value);
            }
            setFecha(v.toISOString().slice(0, 10));
        } catch (error) {

        }

        return () => {
            let v = new Date();
            setFecha(v.toISOString().slice(0, 10));
        }

    }, [])

    useEffect(() => {
        console.log("Update fecha ", `${fecha} 00:00:00`);
        setDate(`${fecha} 00:00:00`);
    }, [fecha])

    return (
        <>
            <label>{label}</label>
            <input type="date" className="form-control" name={name} value={fecha} onChange={(e) => { setFecha(e.target.value) }} />
        </>
    )
}