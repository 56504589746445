import { useEffect, useState } from "react";
import { BasicPage } from "../theme/Component/BasicPage"
import { BasicTable } from "../theme/Component/BasicTable"
import { DropDown } from "../theme/Component/DropDown";
import { SupplierService } from "../../core/services/SupplierService";
import { Singleton } from "../../core/services/Singleton";
import { Modal } from "../theme/Component/Modal";
import { UpdateStatus } from "./actions/UpdateStatus";
import { UpdateSupplier } from "./actions/UpdateSupplier";
import { DocsSupplier } from "./actions/DocsSupplier";
import { ListMenu } from "../theme/Component/ListMenu";
import { ConsentimientoInfo } from "./actions/ConsentimientoInfo";
import { ApprovalStates } from "./actions/ApprovalStates";
import { HistorySupplier } from "./actions/HistorySupplier";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { ImportSupplier } from "./imporData/ImportSupplier";

interface Props {
    area: number;
}

export const InboxSupplier = ({ area }: Props) => {

    const service = new SupplierService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();


    const areas = [
        { id: 1, name: "Compras" },
        { id: 2, name: "Riesgos" },
    ]

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [drop, setDrop] = useState<any>({});
    const [state, setState] = useState<number>(3);
    const [approvalState, setApprovalState] = useState<number>(0);
    const [message, setMensaje] = useState<any>('');
    const [idEmployee, setIdEmployee] = useState(0);
    const [modal, setModal] = useState<any>({ name: 'Proveedor', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalImport, setModalImport] = useState<any>({ name: 'Proveedor', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    const idBean = 'bean_tabla';


    const states = [
        { id: 1, name: "REGISTRADO" },
        { id: 2, name: "VALIDACION RIESGOS" },
        { id: 3, name: "VALIDACION COMPRAS" },
        { id: 4, name: "ACTIVO" },
        { id: 5, name: "RECHAZADO" },
        { id: 6, name: "INACTIVO" },
    ]

    const head = [
        { title: 'ID', property: 'IDSupplier', visible: false },
        { title: 'Identificación', property: 'nit' },
        { title: 'Empresa', property: 'Name1' },
        { title: 'Representante Legal', property: 'NameLegalRep' },
        { title: 'Correo', property: 'eMail' },
        { title: 'Telefono', property: 'Tel' },
        { title: 'Estado', property: 'StateName', visible: false },
    ];

    const actionsDropDown = [
        { id: 9, name: 'Ver Historico', permiso: null, activo: true },
        { id: 10, name: 'Aprobar Riesgos Proveedor', permiso: null, activo: area === 2 && bean.State === 2 },
        { id: 11, name: 'Rechazar Riesgos Proveedor', permiso: null, activo: area === 2 && bean.State === 2 },
        { id: 12, name: 'Aprobar Proveedor', permiso: null, activo: area === 1 && bean.State === 3 },
        { id: 13, name: 'Rechazar Proveedor', permiso: null, activo: area === 1 && bean.State === 3 },
        { id: 14, name: 'Inactivar cuenta', permiso: null, activo: area === 1 },
        { id: 15, name: 'Reactivar la Cuenta', permiso: null, activo: area === 1 && bean.State === 6 },
    ];

    useEffect(() => {
        getLista();
        setIdEmployee(single.getAccountID());
    }, [])

    useEffect(() => {
        getLista();

    }, [modal, state])

    useEffect(() => {
        runFunction();

        if (drop.id === 10)
            setApprovalState(3);//aprobacion de riesgos paso a compras
        if (drop.id === 11 || drop.id === 13)
            setApprovalState(5);//rechazo
        if (drop.id === 12)
            setApprovalState(4);//aprobar proveedor por compras paso activo
        if (drop.id === 14)
            setApprovalState(6);//Inactivar la cuenta 
        if (drop.id === 15)
            setApprovalState(2);//reactivar la cuenta 

    }, [drop])


    const getLista = () => {


        if (area === 2) {
            setLoading(true);
            service.getSupplierCatalogByRisk(null, null, null).subscribe(
                (resp: any) => {

                    setLoading(false);
                    console.log(resp)
                    if (resp.DataBeanProperties.ObjectValue) {
                        let temp: any = resp.DataBeanProperties.ObjectValue;
                        console.log(resp.DataBeanProperties.ObjectValue);
                        setLista(temp);
                    }

                }
            )
        }
        if (area === 1) {
            setLoading(true);
            service.getSupplierCatalogByState(state).subscribe(
                (resp: any) => {

                    setLoading(false);

                    if (resp.DataBeanProperties.ObjectValue) {
                        let temp: any = resp.DataBeanProperties.ObjectValue;
                        setLista(temp);
                    }

                }
            )
        }

    }

    const runFunction = () => {
        console.log(drop);
        if (bean.IDSupplier)
            setModal({ ...modal, "view": true, "name": drop.name });

    }
    const updateFunction = () => {
        console.log(drop);
    }


    const headerButtons = () => {
        return (
            <>
                {area === 1 &&
                    <>
                        <label>Estado</label>
                        <select className="form-select" value={state} onChange={(e) => { setState(parseInt(e.target.value)) }}>
                            {states.map((item: any, index: number) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </>
                }
                {bean.IDSupplier &&
                    <DropDown lista={actionsDropDown} eventClick={setDrop}></DropDown>

                }
            </>
        )
    }

    const listaHijo = () => {
        setModal({ ...modal, "view": false, "name": drop.name });
        getLista();
    }
    const listaBotones = () => {
        const botones = [

            {
                titulo: 'Información Basica', icono: 'ri-file-user-line', visible: area === 1 || area === 2, evento: () => {
                    setModal({ ...modal, "view": true, "name": "Información Basica" });
                    setDrop({ ...drop, "id": 1 })
                }
            },
            {
                titulo: 'Consentimiento Informado', icono: 'ri-todo-line', visible: area === 1 || area === 2, evento: () => {
                    setModal({ ...modal, "view": true, "name": "Consentimiento Informado" });
                    setDrop({ ...drop, "id": 4 })
                }
            },
            {
                titulo: 'Ver Documentos', icono: 'ri-folder-2-line', visible: area === 1 || area === 2, evento: () => {
                    setModal({ ...modal, "view": true, "name": "Documentos" });
                    setDrop({ ...drop, "id": 3 })
                }
            },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const openImport = () => {
        setModalImport({ modalImport, "view": true });
    }
    const importProveedores = () => {

    }

    const iconButtonsItem = [
        { nombre: 'Importar Proveedores', visible: single.canCreate(), icono: 'ri-file-add-line', evento: openImport }
    ]

    return (
        <>
            <BasicPage titulo="Base de datos proveedores ">
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} headButtons={headerButtons()} head={head} body={lista} rowSet={setBean}></BasicTable>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={updateFunction}>
                {drop.id === 2 &&
                    <UpdateStatus supplier={bean} updateEvent={(listaHijo)} />
                }
                {drop.id === 9 &&
                    <HistorySupplier idSupplier={bean.IDSupplier} />
                }
                {drop.id === 1 &&
                    <UpdateSupplier supplier={bean} updateEvent={() => {
                        setModal({ ...modal, "view": false })
                    }} />
                }
                {drop.id === 3 &&
                    <DocsSupplier _idSupplier={bean.IDSupplier} />
                }
                {drop.id === 4 &&
                    <ConsentimientoInfo supplier={bean} />
                }
                {drop.id >= 10 &&
                    <ApprovalStates supplier={bean} state={approvalState} updateSupplier={() => { }} />
                }
            </Modal>
            <Modal modal={modalImport} updateModal={setModalImport} eventModal={importProveedores}>
                <ImportSupplier />
            </Modal>
        </>
    )

}