import { ReactNode, useEffect, useState } from 'react';
import { FiEdit } from "react-icons/fi";
import { Singleton } from '../../../../../core/services/Singleton';
import { camelize, getGreeting } from '../../../../../utils/helpers';

interface Props {
    bg_img?: string | undefined
    profile_img?: string | undefined
    children?: ReactNode
    changeProfileImg: () => void
}



export const ProfileDashed = ({ profile_img = "", children, changeProfileImg }: Props) => {

    const [_single,setSingle] = useState<any>({});
    
    
    useEffect(()=>{
        let usr = Singleton.getInstance().getAccount();
        setSingle(usr);
        // if(usr=== null)
        //     navigate("/login");
        // else{
        //     setSingle(usr);
        // }
    },[])

    return (
        <div className='profile_box'>
            <div className='bg_image_container'>
                {/* <img className='blur_image' src={bg_img} alt={'bg-image'} /> */}
            </div>
            <div className="bg_blur">
                <div className='greetings_box'>
                    <span><strong>{getGreeting()}</strong></span>
                </div>
            </div>
            <div className="profile_circle">
                <img className='profile_img' src={profile_img} alt='profile_img' />
                <span className='icon_edit' title='Cabiar foto'
                    onClick={changeProfileImg}
                >
                    <FiEdit />
                </span>
                <div className="profile_info">
                    <h2>{`${camelize(_single.Name1)} ${camelize(_single.Surname1)}`}</h2>
                    {/* <div className="profile_badge">
                        {'Ingeniero'}
                    </div> */}
                </div>
            </div>
            <div className='dymanic_side' style={{ zIndex: '10', display: 'flex', justifyContent: 'end', width: '100%' }}>
                {children}
            </div>
        </div>
    )
}
