import { getGreeting } from "../../../utils/helpers";
import { Singleton } from "../../../core/services/Singleton";
import { Avatar } from "./Avatar";
import '../styles/dashboard.css'

export const CardProfile = () => {

    const _single = Singleton.getInstance()
    const account = _single.getAccount();

    return (
        <div className="card" style={{ backgroundColor: '#003568' }}>
            <div className="d-flex row">
                <div className="col-sm-7">
                    <div className="card-body">
                        <h3 className='text-white'>{getGreeting()}</h3>
                        <div className='m-3'>
                            <h3 className='text-amber'>{account.EntityName}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 d-flex justify-content-end">
                    <Avatar className="avatar_container" idAccount={account.IDAccount} size="xl" />
                </div>
            </div>
        </div >
    );
}
