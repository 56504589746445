
import { useEffect, useState } from 'react';
import { Singleton } from '../../../../../core/services/Singleton';
import { CashReimbursementsev } from './CashReimbursement-service';
import { ListMenu } from '../../../../theme/Component/ListMenu';
import { BasicPage } from '../../../../theme/Component/BasicPage';
import { CompanyEditor } from '../../Company/CompanyEditor';
import { OfficeEditor } from '../../Office/OfficeEditor';
import { BasicTable } from '../../../../theme/Component/BasicTable';
import { Modal } from '../../../../theme/Component/Modal';
import { toast } from 'react-toastify';
import { Inboxcashedit } from '../InboxCahsEditor';
import { Bankeditor } from '../../BankTransfer.tsx/BankTEditor';


export const CashReimbursement = () => {
    const service = new CashReimbursementsev();
    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [inbox, setInbox] = useState<number>(null);
    const [modal, setModal] = useState<any>({ name: "Rembolso", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalupdate, setModalupdate] = useState<any>({ name: "Agregar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalHistory, setModalHistory] = useState<any>({ name: "Historial", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDCashDeskAccount';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Caja que se relaciona', property: 'IDCashDeskAccount' },
        { title: 'Estado', property: 'ReimbursementStateName' },
        { title: 'Número de Referencia Externo', property: 'SecuenceName' },
        { title: 'Usuario logueado', property: 'IDEmployee' },
        { title: 'Número de Expediente/Radicado', property: 'IDFiledNumber' }

    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {
        // updateItem();
    }, [])


    const agregarItem = () => {
        let b = {
            IDCashDeskAccount: 0,
            SecuenceName: 0,
            IDEmployee: service.idAccount(),
            IDFiledNumber: 0,
            ProcessDate: single.getCurrenDate(),



        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };


    const updateItem = () => {
        service.spinON();
        service.updateCashReimbursement(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
        console.log();
    }

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];
    return (
        <>
            <BasicPage titulo='Reembolso'>

                <div className="row mt-3">
                    <div className="col">
                        <BasicTable iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="col-md-12">
                    <label className="form-label">Caja relacionada:</label>

                    <Bankeditor id={bean.IDCashDeskAccount} onchange={(e: any) => { setBean({ ...bean, 'IDCashDeskAccount': e }) }}></Bankeditor>
                </div>
                {/* <div className="col-md-12">
                    <label className="form-label">Número de Referencia Externo:</label>
                    <input type="Number" className="form-control" value={bean.SecuenceName} onChange={(e) => { setBean({ ...bean, "SecuenceName": e.target.value }); }} />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Número de Expediente/Radicado:</label>
                    <input type="Number" className="form-control" value={bean.IDFiledNumber} onChange={(e) => { setBean({ ...bean, "IDFiledNumber": e.target.value }); }} />
                </div> */}

                <div className="col-md-12">
                    <label className="form-label">Número de Referencia Externo:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bean.SecuenceName}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.value); // Convertir a entero
                            setBean({ ...bean, "SecuenceName": newValue || 0 }); // Si no se puede convertir, asignar 0
                        }}
                    />
                </div>
                <div className="col-md-12">
                    <label className="form-label">Número de Expediente/Radicado:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bean.IDFiledNumber}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.value); // Convertir a entero
                            setBean({ ...bean, "IDFiledNumber": newValue || 0 }); // Si no se puede convertir, asignar 0
                        }}
                    />
                </div>



            </Modal>

        </>
    )
}