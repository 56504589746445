import { useEffect, useState } from "react";
import { PrintJson } from "../theme/Component/PrintJson";
import { Singleton } from "../../core/services/Singleton";
import { ContractService } from "../../core/services/ContractService";
import { DataBeanProperties } from '../../core/services/model/server-response.interface';
import { BasicButton } from "../theme/Component/BasicButton";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    Item: any;
    idAdquisitionPlan: number;
    onChange: any;
}
export const ReclasificarItem = ({ Item, idAdquisitionPlan, onChange }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [bean, setBean] = useState<any>({});
    const [listaAreas, setListaAreas] = useState([]);
    const [listaPreparador, setListaPreparador] = useState([]);
    const [idArea, setIDArea] = useState(0);

    useEffect(() => {
        setBean(Item);
        setIDArea(Item.IDAdquisitionPlanFunctionalID);


    }, [Item])
    useEffect(() => {
        getAdquisitionPlanFunctionalIDCatalog();

    }, [idAdquisitionPlan])
    useEffect(() => {
        // getFunctionalIDPreparerCatalog(idArea);
        //  

        listaAreas.forEach((element: any) => {
            if (element.DataBeanProperties.IDAdquisitionPlanFunctionalID === idArea) {
                console.log(element.DataBeanProperties);
                setBean({
                    ...bean,
                    "IDAdquisitionPlanFunctionalID": element.DataBeanProperties.IDAdquisitionPlanFunctionalID,
                    "IDFunctionalIDPreparer": 0,
                    "PreparationState": 30,
                    "IDLnFunctionalID": element.DataBeanProperties.IDLnFunctionalID
                }
                );
                return;
            }
        });

    }, [idArea])

    const getAdquisitionPlanFunctionalIDCatalog = () => {

        setLoading(true);
        service.getAdquisitionPlanFunctionalIDCatalog(idAdquisitionPlan, null, null).subscribe(
            (resp: any) => {

                console.log(resp.DataBeanProperties.ObjectValue);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    console.log(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                    setListaAreas(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }

                setLoading(false);
            }
        );

    }
    const getFunctionalIDPreparerCatalog = (id: number) => {
        setLoading(true);
        service.getFunctionalIDPreparerCatalog(id, null, null).subscribe(
            (resp: any) => {
                console.log(resp);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setListaPreparador(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false);
            }
        )
    }


    const updateItem = () => {


        service.updateAdquisitionItem(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp.DataBeanProperties.ObjectValue);

                    onChange();
                }
            }

        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <label>Area</label>
                    <select className="form-select" value={idArea} onChange={(e) => {


                        setIDArea(parseInt(e.target.value));


                    }}>
                        {listaAreas.map((a: any, index: number) => {
                            return (
                                <option key={index} value={a.DataBeanProperties.IDAdquisitionPlanFunctionalID} >{a.DataBeanProperties.IDAdquisitionPlanFunctionalID} - {a.DataBeanProperties.Name}</option>
                            )
                        })}
                    </select>
                </div>
                {/* <div className="col-md-12">
                <label>Preparador</label>
                <select className="form-select" value={bean.IDFunctionalIDPreparer} onChange={(e)=>setBean({...bean,"IDFunctionalIDPreparer":parseInt(e.target.value)})}>
                    <option value="0">...</option>
                        {listaPreparador.map((a:any,index:number)=>{
                            return(
                                <option value={a.DataBeanProperties.IDFunctionalIDPreparer}>{a.DataBeanProperties.AccountName}</option>
                            )
                        })}
                </select>
            </div> */}
                <div className="col-md-12">
                    <BasicButton
                        // disable={bean.IDFunctionalIDPreparer === 0} 
                        icon="" eventClick={updateItem}>Actualizar</BasicButton>
                </div>
            </div>
            {/* <PrintJson json={bean}/> */}
        </>
    )
}