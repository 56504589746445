import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { SelectMonths } from './SelectMonths';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    idSelector: number;
    onChange: any;
}
export const SelectModality = ({ idSelector, onChange }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [id, setID] = useState(0);

    useEffect(() => {

        let d = new Date();
        setYear(d.getFullYear());

    }, [])
    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [year, month])

    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {
        /* let cache = Singleton.getInstance().getCacheItem("HiringModalityCatalog2");
        if (cache) {
            setLista(cache || []);
        }
        else { */
        setLoading(true);
        service.getHiringModalityCatalog2(year, month, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                    Singleton.getInstance().setCacheItem("HiringModalityCatalog2", resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    // console.log('lista -------->', lista);

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <label className="form-label">Año</label>
                    <input type="text" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                </div>
                <div className="col-md-4">
                    <SelectMonths idSelector={month} onChange={(e: any) => { setMonth(e) }}></SelectMonths>
                </div>
                <div className="col">
                    <label>Modalidad</label>
                    <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                        <option value={0}>...</option>
                        {lista.length > 0 &&
                            lista.map((item: any) => {
                                return (
                                    <option key={item.DataBeanProperties.IDHiringModality} value={item.DataBeanProperties.IDHiringModality} >{item.DataBeanProperties.Name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </>
    )
}