import { env } from "../../env";
import api from "./api/api";


export class BpmService {
  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  //FromParam
  public getFormParamCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormParamCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public cleanSuppConstantsValues(rows: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "Boolean_cleanSuppConstantsValues_Number",
      ArgumentList: [rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public cleanTHConstantsValues(rows: number) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "Boolean_cleanTHConstantsValues_Number",
      ArgumentList: [rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormParam(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.FormParam_updateFormParam_com.orange.bean.FormParam",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FormParam'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //

  //matriz compras
  public getFactorMatrixCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFactorMatrixCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFactorMatrix(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.FactorMatrix_updateFactorMatrix_com.orange.bean.FactorMatrix",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FactorMatrix'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //
  //FormType


  public getFormTypeCatalogPorPropiedadesLike(groupCode: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormTypeCatalogPorPropiedadesLike_java.util.Map_Number",
      ArgumentList: [{
        GroupCode: groupCode
      }, null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFormTypeCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormType(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.FormType_updateFormType_com.orange.bean.FormType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FormType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //
  //THconstants
  public getTHConstantsCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.List_getTHConstantsCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateTHConstants(bean: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "com.orange.people.talent.bean.THConstants_updateTHConstants_com.orange.people.talent.bean.THConstants",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.talent.bean.THConstants'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //

  //ROLESSST
  public getRoleSSTCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getRoleSSTCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateRoleSST(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.RoleSST_updateRoleSST_com.orange.people.sgsst.bean.RoleSST",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.RoleSST'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //finROLESST
  public getDangerTypes(maxRows) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getDangerTypes_Number",
      ArgumentList: [maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //COMITETIPOS
  public getYearPlanCatalogPorPropiedadesRender(mapProperties: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getYearPlanCatalogPorPropiedadesRender_java.util.Map_Number_Number",
      ArgumentList: [mapProperties, null, null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCommitteeTypeCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getCommitteeTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateCommitteeType(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.CommitteeType_updateCommitteeType_com.orange.people.sgsst.bean.CommitteeType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.CommitteeType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //FINCOMITETIPOS
  //DANGERCRUD
  public getDangerCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getDangerCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateDanger(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.Danger_updateDanger_com.orange.people.sgsst.bean.Danger",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.Danger'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //CRUDDANGERFIN
  //getRisk

  public getRiskMatrixTemplete() {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRiskMatrizTemplate_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getRiskCategoryCatalogPorPropiedad(name: null, value: null, number: null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRiskCategoryCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRiskCategory(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.RiskCategory_updateRiskCategory_com.orange.finance.supplier.bean.RiskCategory",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.supplier.bean.RiskCategory'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteRiskCategory(IDpropertie: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteRiskCategory_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // servicios suppcontants
  public getSuppConstantsCatalogPorPropiedad(name: null, value: null, number: null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSuppConstantsCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSuppConstants(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.SuppConstants_updateSuppConstants_com.orange.finance.supplier.bean.SuppConstants",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.supplier.bean.SuppConstants'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteSuppConstants(IDpropertie: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteSuppConstants_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //
  // Servicios de SystemPropertyValue 

  public getSystemPropertyValue(name: string | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "String_getSystemPropertyValue_String",
      ArgumentList: [name],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //Servicios RequirementSST

  public getRequirementSSTCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRequirementSSTCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows]
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteRequirementSST(idrequirement: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteRequirementSST_Number",
      ArgumentList: [idrequirement],
    }
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRequirementSST(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.RequirementSST_updateRequirementSST_com.orange.finance.supplier.bean.RequirementSST",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.finance.supplier.bean.RequirementSST"
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Servicios SystemProperties

  public getSystemPropertyList(IDpropertie: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getSystemPropertyList_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteSystemProperty(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "void_deleteSystemProperty_com.advantage.bean.account.SystemProperty",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.account.SystemProperty"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSystemProperty(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.SystemProperty_updateSystemProperty_com.advantage.bean.account.SystemProperty",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.account.SystemProperty'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //fin System properties

  //servicios creados 03/04/2023
  //servicios getBusinessClassCatalog
  public getBusinessClassCatalog(idBussinesClass: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBusinessClassCatalog_Number",
      ArgumentList: [idBussinesClass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureDocumentWithJsonFormAndJsonService(idProcedure: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureDocumentWithJsonFormAndJsonService_Number",
      ArgumentList: [idProcedure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getResponseValueForDocument(idDocument: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getResponseValueForDocument_Number",
      ArgumentList: [idDocument],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getConditionalOperators(flowControl: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getConditionalOperators_String",
      ArgumentList: [flowControl],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFlowControlOperators(args: any | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getFlowControlOperators_Number",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBusinessClass(idBussinesClass: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteBusinessClass_Number",
      ArgumentList: [idBussinesClass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // public updateBusinessClass(idBussinesClass:number) {
  //   const parametros = {
  //     ServiceName: "BpmService",
  //     MethodHash:"com.quickbpm.bean.BusinessClass_updateBusinessClass_com.quickbpm.bean.BusinessClass",
  //     ArgumentList: [idBussinesClass],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }

  public updateBusinessClass(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.BusinessClass_updateBusinessClass_com.quickbpm.bean.BusinessClass",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.BusinessClass'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final


  //tramites servicios

  public getBusinessCharacterizationCatalog(idBusinessProcess: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBusinessCharacterizationCatalog_Number",
      ArgumentList: [idBusinessProcess],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getDocumentCharacterizationCatalog(idDocument: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getDocumentCharacterizationCatalog_Number",
      ArgumentList: [idDocument],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addDocumentCharacterization(idDocument: number | null, idCharacterization: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.DocumentCharacterization_addDocumentCharacterization_Number_Number",
      ArgumentList: [idDocument, idCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteDocumentCharacterization(IDBusinessProcess: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteDocumentCharacterization_Number",
      ArgumentList: [IDBusinessProcess],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addBusinessCharacterization(idBusinessProcess: number | null, idCharacterization: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.BusinessCharacterization_addBusinessCharacterization_Number_Number",
      ArgumentList: [idBusinessProcess, idCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getBusinessProcessCatalog(idBusinessProcess: number | null, publicBusiness: boolean | null, privateBusiness: boolean | null, idBusinessClass: number | null, idCustomerType: number | null, idCharacterization: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBusinessProcessCatalog_Number_Boolean_Boolean_Number_Number_Number",
      ArgumentList: [idBusinessProcess, publicBusiness, privateBusiness, idBusinessClass, idCustomerType, idCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBusinessProcess(IDBusinessProcess: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "void_deleteBusinessProcess_Number",
      ArgumentList: [IDBusinessProcess],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBusinessCharacterization(idBusinessCharacterization: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteBusinessCharacterization_Number",
      ArgumentList: [idBusinessCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteProcedureDocument(idDocument: number, idAccount: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteProcedureDocument_Number_Number",
      ArgumentList: [idDocument, idAccount],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateBusinessProcess(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.BusinessProcess_updateBusinessProcess_com.quickbpm.bean.BusinessProcess",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.BusinessProcess'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final

  //Servicios BussinessRol

  public getBusinessRoleCatalog(idLnFunctionalID: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBusinessRoleCatalog_Number",
      ArgumentList: [idLnFunctionalID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final BussinesRol

  //servicios office
  public getOfficeCatalog(idoffice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getOfficeCatalog_Number",
      ArgumentList: [idoffice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteOffice(idoffice: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteOffice_Number",
      ArgumentList: [idoffice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Selector site
  public getSiteID(SiteID: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.site.SiteID_getSiteID_com.orange.bean.site.SiteID",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSiteIDByKey(idLn: number | null, state: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.site.SiteID_getSiteID_com.orange.bean.site.SiteID",
      ArgumentList: [idLn, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //fin
  // public updateOffice(idoffice:number) {
  //   const parametros = {
  //     ServiceName: "BpmService",
  //     MethodHash:"com.quickbpm.bean.Office_updateOffice_com.quickbpm.bean.Office",
  //     ArgumentList: [idoffice],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }

  public updateOffice(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.Office_updateOffice_com.quickbpm.bean.Office",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.Office'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final

  //servicios 	getJsonServiceClassCatalog 


  public getJsonServiceClassCatalog(idJsonservice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonServiceClassCatalog_Number",
      ArgumentList: [idJsonservice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteJsonServiceClass(idJsonservice: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteJsonServiceClass_Number",
      ArgumentList: [idJsonservice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  public updateJsonServiceClass(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.JsonServiceClass_updateJsonServiceClass_com.quickbpm.bean.JsonServiceClass",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.JsonServiceClass'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //final

  //getFormClassCatalog servicios
  public getFormClassCatalog(idFormclass: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getFormClassCatalog_Number",
      ArgumentList: [idFormclass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFormCatalog(idFormclass: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getFormCatalog_Number",
      ArgumentList: [idFormclass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteFormClass(idFormclass: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteFormClass_Number",
      ArgumentList: [idFormclass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormClass(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.FormClass_updateFormClass_com.quickbpm.bean.FormClass",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.FormClass'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //servicios cracterización

  public getCharacterizationCatalog(IDCharacterization: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getCharacterizationCatalog_Number",
      ArgumentList: [IDCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteCharacterization(idCaracterizacion: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteCharacterization_Number",
      ArgumentList: [idCaracterizacion],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // public updateCharacterization(idCaracterizacion:number) {
  //   const parametros = {
  //     ServiceName: "BpmService",
  //     MethodHash:"com.quickbpm.bean.Characterization_updateCharacterization_com.quickbpm.bean.Characterization",
  //     ArgumentList: [idCaracterizacion],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }

  public updateCharacterization(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.Characterization_updateCharacterization_com.quickbpm.bean.Characterization",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.Characterization'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final

  // CustomerType service
  public getCustomerTypeCatalog(id: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getCustomerTypeCatalog_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCharacterizationCatalogByCustomerType(idCustomType: number | undefined) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getCharacterizationCatalogByCustomerType_Number",
      ArgumentList: [idCustomType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteCustomerType(idCustomType: number | null) {
    const parametros = {
      ServiceName: "QuickTurnService",
      MethodHash: "void_deleteCustomerType_Number",
      ArgumentList: [idCustomType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateCustomerType(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.CustomerType_updateCustomerType_com.quickbpm.bean.CustomerType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.CustomerType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //final

  //officeBusinessCatalog
  public getOfficeBusinessProcessCatalog(idCustomType: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getOfficeBusinessProcessCatalog_Number",
      ArgumentList: [idCustomType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteOfficeBusinessProcess(idCustomType: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteOfficeBusinessProcess_Number",
      ArgumentList: [idCustomType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateOfficeBusinessProcess(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.OfficeBusinessProcess_updateOfficeBusinessProcess_com.quickbpm.bean.OfficeBusinessProcess",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.CustomerType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }





  //Servicios Servicios Quick turn

  public getJsonServiceCatalog(idJsonServiceClass: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonServiceCatalog_Number",
      ArgumentList: [idJsonServiceClass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteJsonService(idJsonService: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteJsonService_Number",
      ArgumentList: [idJsonService],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateJsonService(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.JsonService_updateJsonService_com.quickbpm.bean.JsonService",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.JsonService'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateJsonPropertiesFromJsonService(id: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_updateJsonPropertiesFromJsonService_Number",
      ArgumentList: [id],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final

  public getJsonService(idJsonService: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.JsonService_getJsonService_Number",
      ArgumentList: [idJsonService],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //Servicios Json service


  //Inicio Procedure services

  public getProcedureListExcluding(idProcedure: number | null | undefined) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureListExcluding_Number",
      ArgumentList: [idProcedure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureCatalog(idBusinessProcess: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureCatalog_Number",
      ArgumentList: [idBusinessProcess],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getConditionStatementCatalog(idProcedure: number | null | undefined) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getConditionStatementCatalog_Number",
      ArgumentList: [idProcedure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateConditionStatement(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ConditionStatement_updateConditionStatement_com.quickbpm.bean.ConditionStatement",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.ProcedureDocument'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureDocumentCatalog(idProcedure: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureDocumentCatalog_Number",
      ArgumentList: [idProcedure],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addFlowControlStatement(idConditionStatement: number | null, idProcedure: number | null | undefined, idProcedureDestinity: number | null, idProcedureSource: Number | null, flowControl: string | null, conditionalOperator: string | null, idDocument: Number | null, idResponseValue: number | null, value: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ConditionStatement_addFlowControlStatement_Number_Number_Number_Number_String_String_Number_Number_String",
      ArgumentList: [
        idConditionStatement,
        idProcedure,
        idProcedureDestinity,
        idProcedureSource,
        flowControl,
        conditionalOperator,
        idDocument,
        idResponseValue,
        value
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteConditionStatement(idConditionalStament: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteConditionStatement_Number",
      ArgumentList: [idConditionalStament],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateProcedureDocument(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ProcedureDocument_updateProcedureDocument_com.quickbpm.bean.ProcedureDocument",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.ProcedureDocument'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureList(idBusinessProcess: number | null, name: string | null, idProcedure: number | null, type: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureList_Number_String_Number_Number",
      ArgumentList: [idBusinessProcess, name, idProcedure, type],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteProcedure(idProcedure: number | null | undefined, idAccount: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteProcedure_Number_Number",
      ArgumentList: [idProcedure, idAccount],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateProcedure(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.Procedure_updateProcedure_com.quickbpm.bean.Procedure",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.Procedure'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //final

  //Inicio getAccount
  public getAccount(Name: String | null, Surname: String | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccount_String_String",
      ArgumentList: [Name, Surname],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAccountbyID(idAccount: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_getAccount_Number",
      ArgumentList: [idAccount],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAccountByNit(Nit: Number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountByNit_Number",
      ArgumentList: [Nit],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public createAccount(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_createAbstractAccount_com.advantage.bean.account.AbstractAccount",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.account.AbstractAccount'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAbstractAccount(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_updateAbstractAccount_com.advantage.bean.account.AbstractAccount",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.account.AbstractAccount'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createBusinessProcess(idBusinessProcess: number, idOffice: number, accountID: number, description: string, separatorCode: string, runNextStage: boolean, idCharacterization: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ProcedureImp_createBusinessProcess_Number_Number_Number_String_String_Boolean_Number",
      ArgumentList: [
        idBusinessProcess,
        idOffice,
        accountID,
        description,
        separatorCode,
        runNextStage,
        idCharacterization
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureImpForResponse(idAccount: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureImpForResponse_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureImpForAssign(idAccount: number, idOffice: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.advantage.shared.Report_getProcedureImpForAssign_Number_Number_Number_Number_Number_Number",
      ArgumentList: [
        idAccount,
        null,
        idOffice,
        null,
        0,
        100
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureImpByAccount(idAccount: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureImpByAccount_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureImpForVerify(idAccount: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureImpForVerify_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureActionForVerify(idAccount: number, idProcedureImp: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureActionForVerify_Number_Number",
      ArgumentList: [
        idAccount,
        idProcedureImp
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getActualProcedureActionByProcedureImp(idProcedureImp: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getActualProcedureActionByProcedureImp_Number",
      ArgumentList: [
        idProcedureImp
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureActionByProcedureImp(idProcedureImp: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureActionByProcedureImp_Number",
      ArgumentList: [
        idProcedureImp
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public cancelProcedureImp(idProcedureIMP: number, idAccount: number, obs: string) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "boolean_cancelProcedureImp_Number_Number_String",
      ArgumentList: [idProcedureIMP, idAccount, obs],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public verifyProcedureAction(idProcedureImp: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "boolean_verifyProcedureAction_Number_String",
      ArgumentList: [
        idProcedureImp,
        'aprobado'
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public declineProcedureAction(idProcedureImp: number, obs: string) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "boolean_declineProcedureAction_Number_String",
      ArgumentList: [
        idProcedureImp,
        obs
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public assingCreditToken(idEmployee: number, idProcedureImp: number, token: string) {
    const parametros = {
      ServiceName: "ForpoBpm",
      MethodHash: "com.orange.bpm.forpo.bean.Credit_assingCreditToken_Number_Number_String",
      ArgumentList: [
        idEmployee,
        idProcedureImp,
        token
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureActionByAccount(idAccount: number, idProcedureIMP: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureActionByAccount_Number_Number_Number",
      ArgumentList: [idAccount, idProcedureIMP, 0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getProcedureActionForResponse(idAccount: number, idProcedureImp: number, assign: boolean) {
    const parametrosAssign = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureActionForResponse_Number_Number",
      ArgumentList: [
        idAccount,
        idProcedureImp
      ],
    };
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getProcedureActionByAccount_Number_Number_Number",
      ArgumentList: [
        idAccount,
        idProcedureImp,
        0
      ],
    };
    const data = JSON.stringify(assign ? parametrosAssign : parametros);
    return api.post(this.url, data);
  }

  public responseProcedureAction(idProcedureImp: number, map: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_responseProcedureAction_Number_String_Boolean_java.util.Map_Boolean",
      ArgumentList: [
        idProcedureImp,
        "",
        true,
        map,
        false
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public goNextStage(idProcedureImp: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.Stage_getNextStage_Number",
      ArgumentList: [
        idProcedureImp
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // Servicios de doc-supplier-config

  public deleteDocSupplier(iddocsupplier: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteDocSupplier_Number",
      ArgumentList: [iddocsupplier],
    }
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateDocSupplier(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.DocSupplier_updateDocSupplier_com.orange.finance.supplier.bean.DocSupplier",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.finance.supplier.bean.DocSupplier",
        }
      ]
    }
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getDocSupplierCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getDocSupplierCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // Servicios de SelectioTestType

  public getSelectionTestTypeCatalogPorPropiedad(nombrePropiedad: string | null, value: number | null, maxRows: null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.List_getSelectionTestTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSelectionTestType(bean: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "com.orange.people.talent.bean.SelectionTestType_updateSelectionTestType_com.orange.people.talent.bean.SelectionTestType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.talent.bean.SelectionTestType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //servicios del selector thconstants value

  public getTHConstantsValuesByCode(rows: number | null, lcodes: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.Map_getTHConstantsValuesByCode_Number_java.util.List",
      ArgumentList: [rows, lcodes],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //servicios de los botones JSONServices

  public getResponseValueForJsonService(idJsonService: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getResponseValueForJsonService_Number",
      ArgumentList: [idJsonService],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getJsonDataTypes(arg: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonDataTypes_Number",
      ArgumentList: [arg],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getURLRequestType(args: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getURLRequestType_String",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getJsonPropertyCatalog(idJsonService: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonPropertyCatalog_Number",
      ArgumentList: [idJsonService],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getJsonPropertyType(args: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonPropertyType_String",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getBPMStructsForQuery(args: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBPMStructsForQuery_String",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getJsonPropertyDocumentType(args: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonPropertyDocumentType_String",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPropertiesForQuery(dataBeanClass: string | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getPropertiesForQuery_String",
      ArgumentList: [dataBeanClass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addResponseValue(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_addResponseValue_com.quickbpm.bean.ResponseValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.ResponseValue'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public invokeJsonServiceInPeriod(idJsonService: number, from: any, upto: any, maxRows: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Number_invokeJsonServiceInPeriod_Number_java.util.Date_java.util.Date_Number",
      ArgumentList: [idJsonService, from, upto, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public validateForInvokeJsonService(idJsonService: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_validateForInvokeJsonService_Number",
      ArgumentList: [idJsonService],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateJsonProperty(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.JsonProperty_updateJsonProperty_com.quickbpm.bean.JsonProperty",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.JsonProperty'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getFactorMatrizTemplate() {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getFactorMatrizTemplate_Number",
      ArgumentList: [12],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //plan sst
  public getPlanSGSSTCatalogPorPropiedad(name: string | null, value: number | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getPlanSGSSTCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPlanSGSSTCatalogPorPropiedades(map: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getPlanSGSSTCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        map, null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updatePlanSGSST(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.PlanSGSST_updatePlanSGSST_com.orange.people.sgsst.bean.PlanSGSST",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.PlanSGSST'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //plan sst
  public getYearPlanCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getYearPlanCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getYearPlanCatalogPorPropiedades(map: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getYearPlanCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        map, null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateYearPlan(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.YearPlan_updateYearPlan_com.orange.people.sgsst.bean.YearPlan",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.YearPlan'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // LegalMatrix

  public getLegalMatrixCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getLegalMatrixCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateLegalMatrix(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.LegalMatrix_updateLegalMatrix_com.orange.people.sgsst.bean.LegalMatrix",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.LegalMatrix'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // MatrixRisk

  public getRiskMatrixCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getRiskMatrixCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRiskMatrix(bean: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.RiskMatrix_updateRiskMatrix_com.orange.people.sgsst.bean.RiskMatrix",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.sgsst.bean.RiskMatrix'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

}
