import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { AdminService } from '../../../core/services/AdminService';

interface Props {
    idSelector: number;
    onChange: any;
}
export const SelectRol = ({ idSelector, onChange }: Props) => {

    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [id, setID] = useState(0);

    useEffect(() => {

        let d = new Date();
        setYear(d.getFullYear());

    }, [])
    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [month])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {


        service.getRoleCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <div className="row">
                
                <div className="col">
                    <label>Rol del Sistema</label>
                    <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                        <option value={0}>...</option>
                        {lista &&
                            lista.map((item: any) => {
                                return (
                                    <>
                                        <option key={item.DataBeanProperties.IDRole} value={item.DataBeanProperties.IDRole} >{item.DataBeanProperties.Name}</option>
                                    </>)
                            })
                        }
                    </select>
                </div>
            </div>
        </>
    )
}