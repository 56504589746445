import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { SupplierService } from "../../../core/services/SupplierService";
import { Alert } from "../../theme/Component/Alert";
import { BasicButton } from "../../theme/Component/BasicButton";
import { FileInput } from "../../theme/Component/FileInput";
import { PrintObjectJson } from "../../theme/Component/PrintObjectJson";
import { useEffect, useState } from 'react';
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    supplier: any;
    state: number;
    updateSupplier: Function;
}
export const ApprovalStates = ({ supplier, state, updateSupplier }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new SupplierService();

    const [observacion, setObservacion] = useState('');
    const [message, setMessage] = useState('');
    const [attach, setAttach] = useState<any>([]);
    const [mFile, setMFile] = useState<any>({});

    useEffect(() => {
        setMFile({});
        setObservacion('');
        setMessage('');
    }, [supplier])

    const saveApproval = () => {

        console.log(mFile, observacion);
        setLoading(true);
        service.setApprovalState(supplier, state, observacion, JSON.stringify(mFile), single.getAccountID()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    updateSupplier(resp.DataBeanProperties.ObjectValue);
                    setMessage("Estado actualizado");
                }
                if (resp.DataBeanProperties.ErrorMessage) {
                    setMessage(resp.DataBeanProperties.ErrorMessage);
                }
                setLoading(false);
            }
        )


    }


    return (
        <div className="row">
            <div className="col-md-12">

                {message != '' &&
                    <Alert clase="warning">
                        <strong>Info: </strong>{message}
                    </Alert>

                }

            </div>
            <div className="col-md-12">
                <label>Observaciones *</label>
                <textarea className="form-control" value={observacion} onChange={(e) => { setObservacion(e.target.value) }} ></textarea>
            </div>
            <div className="col-md-12">
                <FileInput directory="approval" onCreate={setMFile} />
            </div>
            <div className="col-md-12">
                <BasicButton disable={observacion == ''} icon="ri-save-3-fill" eventClick={saveApproval}>Guardar</BasicButton>
            </div>
        </div>
    )
}