import { useEffect, useState } from 'react';
interface Props {
    taps: any;
    index: number;
    updateIndex: any;
    children: any;
}
export const CardTaps = ({ taps, index, updateIndex, children }: Props) => {


    // const taps = [
    //     { id: 0, name: 'Plan de Necesidades' },
    //     { id: 1, name: 'Elementos Plan de Necesidades' }
    // ]
    // const [indexTap, setIndexTap] = useState(0);


    return (
        <>

            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <div className="flex-grow-1 oveflow-hidden">
                        <p className="text-muted text-truncates mb-0"> </p>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                        <ul  className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                            {taps &&
                                taps.map((item: any, i: number) => {
                                    return (
                                        <li className="nav-item cursor color-tabs" role="presentation" onClick={() => { updateIndex(i) }} key={item.name}>

                                            <span className={i === index ? 'nav-link active' : 'mtap nav-link'} tabIndex={-1}>
                                                {item.name}
                                            </span>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="tab-content text-muted">
                        {children}
                    </div>
                </div>
            </div>

        </>
    )
}