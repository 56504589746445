import { create } from "zustand";

interface DashboardStore {
    activeNav: number,
    loading: boolean,
    setLoading: (v: boolean) => void
}

export const useDashboardStore = create<DashboardStore>((set, get) => (
    {
        activeNav: 0,
        loading: false,
        setLoading: (loading) => {
            set({ loading })
        }
    }
))