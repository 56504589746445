// FORMATO INFORMACIÓN DE SELECCIÓN PARA LA CONTRATACIÓN DIRECTA PERSONA JURÍDICA

import { useState } from "react"
import { formatDate, today } from "../../../utils/helpers"
import { Divider } from "./Divider"


const headValues = [
    'Numero de radicado de Estudio Previo: ',
    'Nombre del proveedor seleccionado: ',
    'Fecha de envió de la propuesta: ',
    'Valor de la propuesta: ',
]


export const DirectContractLegalEntity = (data: any) => {

    const [causal, setCausal] = useState([
        {
            label: 'Arriendo, Adquisición y compraventa de bienes inmuebles.',
            check: false
        },
        {
            label: 'Adquisición de Vivienda para el Fondo de Solidaridad',
            check: false
        },
        {
            label: 'Contratos de prestación de servicios Profesionales y de apoyo a la gestión, inferiores a 300 SMMLV.',
            check: false
        },
        {
            label: 'Contratos interadministrativos. ',
            check: false
        },
        {
            label: 'Enajenación, transferencia, administración, venta y/o gestión de activos.',
            check: false
        },
        {
            label: 'Contratos de mandato, comodato, empréstito, permuta, convenios de asociación, colaboración y crédito público.',
            check: false
        },
        {
            label: 'Contratos de servicios de auditoría, publicación, capacitación, estímulos y bienestar inferiores a 150 SMMLV.',
            check: false
        },
        {
            label: 'Contratos relacionados con la imagen institucional de la Entidad.',
            check: false
        },
        {
            label: 'Contratos de bienes y servicios de TI para mantener una plataforma tecnológica definida por la Entidad.',
            check: false
        },
        {
            label: 'Cuando se trate de bienes y/o servicios establecidos en instrumentos contractuales creados por el Gobierno Nacional.',
            check: false
        },
        {
            label: 'Cuando se trate de bienes y/o servicios generados del comercio electrónico.',
            check: false
        },
        {
            label: 'Cuando se trate de bienes y servicios generados por suscripciones.',
            check: false
        },
        {
            label: 'Cuando no exista pluralidad de oferentes, cuando no haya más de una persona que pueda proveer el bien o servicio, cuando sea proveedor exclusivo, o cuando el proveedor del bien o servicio sea titular de los derechos de propiedad industrial o de los derechos de autor.',
            check: false
        },
        {
            label: 'Cuando se presenten circunstancias que configuren grave calamidad doméstica o hechos constitutivos de caso fortuito o fuerza mayor que demanden actuaciones inmediatas por parte de la Administración. Para lo cual, la dependencia deberá justificar tal situación.',
            check: false
        },
        {
            label: 'Cuando la Convocatoria Pública se declare desierta por ausencia de oferentes.',
            check: false
        }
    ])

    return (
        <div className="row">
            <div className="w-100 my-2">
                <strong>Fecha</strong>&nbsp;{formatDate(today)}
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
                {headValues.map((_e, _i) =>
                    <strong key={_i}>{_e}</strong>
                )}
            </div>
            <div className="col-12 mt-3">
                <Divider
                    title='Causales de selección'
                />
                <div className="mt-3 mx-auto" style={{ width: '90%' }}>
                    {causal.map((_e, index) =>
                        <div key={index} className="row border">
                            <div className="col-1 border"></div>
                            <div className="col-11 border">{_e.label}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-12 mt-5">
                <strong>Observaciones:</strong>
                <br />
                <small>
                    Se debe incluir toda la información necesaria de selección del proveedor,
                    como por ejemplo: justificación de la causal, justificación del proveedor,
                    propuestas de otros proveedores que no fueron
                    favorables para Caja Honor (si aplica),
                    presupuesto estimado para la contratación, visitas al proveedor.
                </small>
            </div>
            <div className="col-12 mt-3">
                <strong>Elaboró:</strong>
            </div>
            <div className="col-12">
                <strong>Revisó y aprobó:</strong>
            </div>
        </div>
    )
}
