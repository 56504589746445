import { useEffect, useState } from "react";
import { PrintJson } from "../../../../theme/Component/PrintJson"
import { BasicTable } from "../../../../theme/Component/BasicTable";

interface Props{
    offer:any
}
export const ComparativeFactor=({offer}:Props)=>{

    const[lista,setLista] = useState<any>([]);

    useEffect(()=>{

        if(offer)
            {
                
                setLista(offer);
            }
            
        

    },[offer])
    const head = [
        {title:'Item',property:'Description',visible:true},
               
        {title:'Respuesta',property:'Response',visible:true},        
           
    ]
    
    return(
        <div className="row">
            <div className="col">
            <BasicTable head={head} body={lista} rowSet={(e:any)=> {}}></BasicTable>
            </div>
        </div>
    )
}