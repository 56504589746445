import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { Singleton } from '../../../../core/services/Singleton';
import { ButtonTrdID } from "./ButtonTrdID";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";



interface Props {
    idChild: number;
    editBean: Function;
    edit: boolean;
}
export const TrdIDChilds = ({ idChild, editBean, edit }: Props) => {

    const service = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [name, setName] = useState('');
    const [bean, setBean] = useState({});
    const [showchild, setShowchild] = useState(false);
    const [idchild, setIDchild] = useState(0);

    useEffect(() => {
        if (idchild != null && idchild != undefined) {
            getListatree(idChild);
            // setIDchild(idChild);
        }
        else
            setLista([]);
    }, [])



    const getListatree = (idArbol: any) => {
        setLoading(true);
        service.loadTrdIDTree(idArbol).subscribe((resp: any) => {

            if (resp.DataBeanProperties.ObjectValue) {
                let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
                setName(arbol.Name);
                setLista(resp.DataBeanProperties.ObjectValue.Childs);
                console.log(resp.DataBeanProperties.ObjectValue.Childs);
                setLoading(false);
            } else {
                setLoading(false);
            }

        });
    };

    return (
        <>

            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>

                                {edit &&
                                    <ButtonTrdID
                                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { getListatree(idChild); }}
                                    />
                                }
                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                                    localStorage.setItem('TrdID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                                    setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);


                                }}>
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.TrdViewCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                                    <TrdIDChilds edit={edit} idChild={item.EnvolvedObject.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}