import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
}

export const SelectorPlanSGSST = ({ idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector ?? 0);

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.getPlanSGSSTCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <label>Planes vigentes SGSST</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDPlanSGSST} value={item.DataBeanProperties.IDPlanSGSST} >{item.DataBeanProperties.Description}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}