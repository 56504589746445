import { useEffect, useState } from "react";
import { BpmService } from "../../../core/services/BpmService";
import { useDashboardStore } from "../hooks/useDashboardStore";

interface Props {
    ID: number;
}

export const CardTramitesHistory = ({ ID }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);

    useEffect(() => {
        getTramites()
    }, [ID])

    const getTramites = () => {
        setLoading(true);
        service.getProcedureActionByProcedureImp(ID).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    return (
        <>
            <div className="card-body">
                <div className="card-tittle mb-0 me-3 row">
                    <div className="col-12 d-flex justify-content-end">
                        <h4>Histórico del tramite</h4>
                    </div>
                </div>
                <div className="profile-timeline">
                    <div className="accordion accordion-flush">
                        <div className="activity-timeline" style={{ maxHeight: '600PX', overflowY: 'auto', minWidth: '350px' }}>
                            {lista.map((Historico: any, index: number) => (
                                <div key={index} className="row m-3 border-bottom pb-1">
                                    <div className="col-md-2 d-flex align-items-center">
                                        <div className="circle-number">
                                            {index + 1}
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="col-md-12"><p><strong>Proceso: {Historico.DataBeanProperties.ProcedureActionName}</strong></p></div>
                                        <div className="col-md-12">Fecha de verificación: {Historico.DataBeanProperties.UptoDate}</div>
                                        <div className="col-md-12">Tipo de Proceso: {Historico.DataBeanProperties.DocumentTypeName}</div>
                                        <div className="col-md-12">Estado: {Historico.DataBeanProperties.StateName}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
