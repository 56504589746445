import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { PrintJson } from "../../theme/Component/PrintJson";
import './Inboxcss.css';
import { CardTaps } from "../../theme/Component/CardTaps";
import { tap } from "rxjs";
import { ActionRender } from "./ActionRender";

interface Props {
    idOfferSupplier: number;
}
export const InboxActions = ({ idOfferSupplier }: Props) => {

    
    const single = Singleton.getInstance();
    const contract = new ContractService();
    const [actionList, setActionList] = useState<any>([]);
    const [row, setRow] = useState<any>({});
    const [flow, setFlow] = useState<any>(0);

    const taps = [
        { id: 0, name: 'Actividades' },
        { id: 1, name: 'Requisito' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    useEffect(() => {
        getActionListByOfferEmployee();
    }, [])
    useEffect(() => {
        setFlow(row.IDFlowStage);
    }, [row])

    const getActionListByOfferEmployee = () => {
        single.spinner(true);
        contract.getActionListByOfferEmployee(idOfferSupplier, {}).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((element: any) => {
                        try {
                            element.DataBeanProperties.jAction = JSON.parse(element.DataBeanProperties.JSONAction);
                        } catch (error) {

                        }
                    });
                    setActionList(tmp);
                }
                single.spinner(false);
            }
        )
    }

    return (
        <div className="row">
            <div className="col-md-12">
                
                <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap} >
                    <div className="row">
                        {indexTap === 0 ?
                            <div className="col">
                                <div className="list-group">
                                    {actionList.map((item: any, index: number) => {
                                        return (
                                            <button key={index} className={flow === item.DataBeanProperties.IDFlowStage ? 'list-group-item list-group-item-action active1' : 'list-group-item list-group-item-action'}
                                                onClick={() => { setRow(item.DataBeanProperties); setIndexTap(1); }}>
                                                <div className="row">

                                                    <div className="col-md-2"><i className={item.DataBeanProperties.jAction.TypeRequirement === 1 ? 'ri-bar-chart-horizontal-line align-middle me-2' : 'ri-attachment-line align-middle me-2'} /></div>
                                                    <div className="col-md-10">
                                                        {item.DataBeanProperties.jAction.StageName} <br />
                                                        {item.DataBeanProperties.jAction.FormName}
                                                    </div>

                                                </div>
                                            </button>
                                        )
                                    })}

                                </div>
                            </div>
                            :
                            <div className="col">
                                <h6>
                                  <i className=" ri-reply-line align-middle me-4 size4" onClick={()=>{setIndexTap(0)}}></i>  {row.jAction.FormDescription}
                                </h6>
                                <hr />
                                <ActionRender action={row} onReturn={(e:any)=>{console.log(e)}}/>
                            </div>
                        }
                    </div>
                </CardTaps>

            </div>
        </div>
    )
}