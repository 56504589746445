import JSEncrypt from "jsencrypt";
import { Singleton } from "../core/services/Singleton";

export function eliminarCookies() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}


export const encrypt = (yek: any, toEncrypt: string): string => {

  let encrypted: string | false = '';
  const encrypter = new JSEncrypt();  
  const yek2 = Singleton.getInstance().getCookie("yek");

  encrypter.setPublicKey(yek2);

  if (yek2 !== '') {
    encrypted = encrypter.encrypt(toEncrypt);
  } else {
    encrypted = toEncrypt;
  }

  return encrypted.toString();
}

export function camelize(str: string) {
  let msg: string = "";
  let first: string = "";
  if (str !== null) {
    if (str !== undefined) {
      let aux: string[] = str.split(" ");
      first = aux[0]
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, "");
      aux.map((word, index) => {
        if (index !== 0) {
          msg += " " + word.toLowerCase();
        }
      });
    }
  }
  return first + msg;
}

const MORNING = '🌞 Buenos Días'
const EVENING = '☕ Buenas Tardes'
const NIGHT = '🌙 Buenas Noches'

export const getGreeting = () => {
  const currentTime = new Date()
  const hour = currentTime.getHours()
  if (hour >= 0 && hour < 12) {
    return MORNING
  } else if (hour >= 12 && hour < 19) {
    return EVENING
  } else {
    return NIGHT
  }
}
export const today = new Date()

export const formatDate = (toFormat: Date) => {
  return `${toFormat.getDate().toString().padStart(2, '0')}/
  ${(toFormat.getMonth() + 1).toString().padStart(2, '0')}/
  ${toFormat.getFullYear()}`
}

export function currencyFormatter(value: number) {
  const currency = 'USD'
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency
  })
  return formatter.format(value)
} 