import { useEffect, useState } from "react";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { ForpoService } from "../service/ForpoService";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { toast } from "react-toastify";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { FileInput } from "../../../../theme/Component/FileInput";
interface Props {
    IDMediaSlideItem: number;
}


export const MediaSlideItem = ({ IDMediaSlideItem }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDMediaSlideItem';

    const head = [

        { title: 'ID', property: idBean },
        // { title: 'ID', property: idBean, visible: false },
        { title: 'tipos contenid', property: 'TypeMedia' },
        { title: 'Slider', property: 'IDMediaSlide' },
        { title: 'Fecha', property: 'Since' },
        { title: 'Imagen', property: 'JSONMedia' },

        { title: 'Titulo', property: 'Title' },
        { title: 'Descripción', property: 'Description' }



    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [IDMediaSlideItem])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getMediaSlideItemCatalogPorPropiedad('IDMediaSlide', IDMediaSlideItem, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    const updateItem = () => {

        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.updateMediaSlideItem(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const eliminaritem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const agregarItem = () => {

        let b = {

            IDEmployee: user.IDAccount,
            State: 1,
            TypeMedia: 0,
            IDMediaSlide: IDMediaSlideItem,
            JSONMedia: "",
            Title: '',
            Description: '',
            // PublicUrl: "",

            Since: single.getCurrenDate(),





        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const iconButtons = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: agregarItem,
        },
    ];
    const listaBotones = () => {
        const botones = [
            {
                titulo: "Editar",
                icono: "ri-file-list-line",
                visible: true,
                evento: () => {
                    setModal({ ...modal, view: true });
                },
            },
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setModalConfirm({ ...modalConfirm, view: true });
                },
            },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Item slider">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtons} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>

                </div>
            </BasicPage>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem} >
                <>

                    <div className="container">
                        <div className="row">

                            <div className="col-md-6">
                                <label className="form-label">Titulo</label>
                                <input type="text" className="form-control mt-2" value={bean.Title} onChange={(e) => { setBean({ ...bean, ['Title']: e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Subtitulo</label>
                                <input type="text" className="form-control mt-2" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }} />
                            </div>

                            <div className="col-md-12">
                                <FileInput

                                    directory='Multimedia'
                                    label='Cargar archivo imagen'

                                    onCreate={({ MediaContext, Media }: any) => { setBean({ ...bean, "JSONMedia": JSON.stringify({ MediaContext: MediaContext, Media: Media }) }); }}
                                ></FileInput>
                            </div>


                        </div>
                    </div>

                </>
            </Modal>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminaritem}>
                {bean.Name}
            </ModalConfirm>

        </>
    );
};
