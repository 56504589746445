import { useState } from "react";
import { AdminService } from "../../../../core/services/AdminService";
import { Singleton } from "../../../../core/services/Singleton"
import { IconButton } from "../../../../core/shared/buttons/IconButton";
import { TbFileExport } from "react-icons/tb";

export const EmployeeFixedAssets = () => {


    const single = Singleton.getInstance()
    const service = new AdminService();
    const [props, setProps] = useState({
        downloading: false,
        listDocuments: [],
        url: '#',
    })

    const getFixedAssetsReport = () => {
        setProps({ ...props, downloading: true })
        service.getEmployeeFixedAssets(single.getAccountID()).subscribe(
            (resp: any) => {
                let listFortnight = resp.DataBeanProperties.ObjectValue;
                if (resp.DataBeanProperties.ObjectValue) {
                    setProps({
                        ...props,
                        listDocuments: listFortnight,
                        downloading: false,
                        url: listFortnight[0].DataBeanProperties.URLLink
                    });
                } else {
                    setProps({ ...props, downloading: false })
                }
            }
        )
    }

    return (
        <div className="row">
            <div className="col-md-12 mt-4">
                <div className="m-5 my-3">
                    <ul className="p-0 m-0 overflow-y-hidden mh-100">
                        <li className="d-flex mb-4 pb-3 border-bottom ">
                            <div className="avatar flex-shrink-0 me-3">
                                <div style={{ color: '#006699', fontSize: '60px' }}>
                                    <TbFileExport />
                                </div>
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                <div className="me-2">
                                    <h6 className="mb-0">Reporte de activos fijos</h6>
                                    <small className="text-muted d-block mb-1">Clic en el botón para descargar</small>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                    <IconButton
                                        icon="ri-file-download-line"
                                        loading={props.downloading}
                                        event={() => getFixedAssetsReport()}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                    {props.listDocuments.length > 0 &&
                        <div key={123546} className="m-5 alert alert-success">
                            Su reporte se ha generado exitósamente, para descargar haga clic
                            <a href={props.url} target="_blank" rel="noreferrer">
                                &nbsp;aquí!
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}