import { useState, useEffect } from 'react';
import { BasicTable } from '../theme/Component/BasicTable';

export interface Props {
    lista: any;
    listAttach:Function
}

export const AttachList = (props: Props) => {

    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState({Name:'',Attach:''});
    
    const head = [
        { title: 'Nombre', property: 'Name' },
        { title: 'Adjunto', property: 'Attach' }
    ]

    useEffect(()=>{
        setBean({Name:'',Attach:''});
    },[])

    useEffect(() => {

        if (props.lista)
        {
            try {
                setLista(JSON.parse(props.lista));    
            } catch (error) {
                
            }
            
        }            
        else {
            setLista([]);
        }

    }, [props.lista])


    const addItem = () => {
        let lis: any = lista;
        let db = {
            DataBeanProperties:bean
        }
        lis.push(db);
        setLista(lis);
       setBean({Name:'',Attach:''});
       console.log(lista);
       props.listAttach(lis);
       
    }
    const removeItem = () => {
        let lis:any = [];

        let name = bean.Name;
        lista.forEach((element:any) => {
           
            
            if(element.DataBeanProperties.Name === name)
            {
                
            }else{
                lis.push(element);
            }

        });

       
       console.log(lis);
       setLista(lis);
       props.listAttach(lis);
       setBean({Name:'',Attach:''});
       
    }

    const setBeanRow = (row:any)=>{
        setBean(row);                
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 p-2">
                    <h4>Agregar Vinculo</h4>
                </div>
                <div className="col-md-4">
                    <label>Nombre</label>
                    <input type="text" name="nombre" className='form-control' value={bean.Name} onChange={(e) => { setBean({...bean,['Name']:e.target.value}) }} />
                </div>
                <div className="col-md-8">
                <label>Link</label>
                    <input type="text" name="doc" className='form-control' value={bean.Attach} onChange={(e) => { setBean({...bean,['Attach']:e.target.value}) }} />
                    {bean.Attach != '' &&
                    <a href={bean.Attach} target='_blank'>Visitar Vinculo</a>
                    }
                </div>                
                <div className="col">
                    <button className='btn btn-primary mt-2' onClick={addItem} >Agregar</button>
                    {bean.Name != '' &&
                        <button className='btn btn-danger mt-2 pl-2' onClick={removeItem} >Eliminar</button>
                    }
                    
                </div>
            </div>
            <div className="row">
                <div className="col mt-2">
                    <BasicTable head={head} body={lista} rowSet={setBeanRow}></BasicTable>  
                </div>
            </div>

            
        </>
    )
}