import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { ForpoService } from "../service/ForpoService";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../theme/Component/Modal";
import { SelectSiteID } from "../../../../admin/configuracion/SiteID/SelectSiteID";
import { Listatramitesoffice } from "../../../../bpm/config/Componentes/Listatramitesoffice";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { DataBeanProperties } from '../../../../../core/services/model/server-response.interface';


export const Portalterceros = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalLista, setModalLista] = useState<any>({ name: 'Lista de tramites por sucursal', tabIndex: -1, view: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDPortalTercero';

    const head = [
        { title: 'Fecha', property: 'Since' },
        { title: 'ID', property: idBean,visible:false },
        { title: 'Identificacion', property: 'Nit' },
        { title: 'Nombre', property: 'EntityName' },
        
        { title: 'Tipo Solicitud', property: 'TypeRequestName' },
        
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getPortalTerceroCatalogRender(null, null, {}).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((item:any) => {
                        item.DataBeanProperties.EntityName = 
                        ' '+(item.DataBeanProperties.Name1 ?? ' ') +
                        ' '+(item.DataBeanProperties.Name2 ?? ' ') +
                        ' '+(item.DataBeanProperties.Surname1 ?? ' ') +
                        ' '+(item.DataBeanProperties.Surname2 ?? ' ') ;
                        
                    });
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
                // if (resp.DataBeanProperties.ObjectValue) {
                //     let list = resp.DataBeanProperties.ObjectValue;
                //     list.forEach((ele: any) => {
                //         ele.DataBeanProperties.AccountName =
                //             (ele.DataBeanProperties.Name1 ?? '') + " " +
                //             (ele.DataBeanProperties.Name2 ?? '') + " ";



                //     });
                //     setLista(list);

                // }
                // setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 0,
            Since: single.getCurrenDate(),
            Code: 0,
            IDSiteLn: 0,
            IDEmployee: user.IDAccount,
        }
        setBean(b);
        setModal({ ...modal, 'view': true, name: 'Agregar' });
    }

    // const eliminarItem = () => {

    //     setLoading(true);
    //     service.deletePortalTercero(bean[idBean]).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp) {
    //                 getLista();
    //             }
    //             single.delete(bean[idBean], bean);
    //         }
    //     );
    //     setModalConfirm({ ...modalConfirm, 'view': false });

    // }
    const updateItem = () => {

        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.updatePortalTercero(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }
    // const updateItem1 = () => {

    //     setModal({ ...tramites, ['view']: false });

    //     setLoading(true);
    //     service.updateOffice(bean).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 getListaT();
    //             }
    //         });

    // }
    const verlista = () => {

        setModalLista({ ...modalLista, 'view': true });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Registro', icono: 'ri-braces-line', visible: true, evento: () => { setModal({ ...modal, "view": true, name: 'Registro' }) } },
           

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Portal Terceros">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                    <div className="col-md-12">
                        <Modal modal={modal} updateModal={setModal} >
                            <div className="row">
                                <div className="col">
                                    <PrintJson json={bean.JSONData}/>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </BasicPage>



        </>
    )
}