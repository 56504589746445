import { Singleton } from '../../../core/services/Singleton';
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../core/services/FinanceService';

import { SelectDefault } from './select/SelectDefault';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
export const BudgetIDType = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new FinanceService();

    const [lista, setLista] = useState([]);
    const [listaType, setListType] = useState([]);
    const [listaStructure, setListStructure] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDBudgetIDType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Tipo de Catálogo', property: 'TypeName' },
        { title: 'Tipo de Estructura ', property: 'BudgetStructureName' },
        { title: 'Estructura del Rubro Presupuestal', property: 'ViewCodeFormat' },
    ]

    useEffect(() => {
        getLista();
        getBudgetIDTypeConstants();
    }, [])



    useEffect(() => {
        setModal({ ...modal, ['view']: false });
    }, [lista])

    //listas desplegables
    const getBudgetIDTypeConstants = () => {

        setLoading(true);
        service.getBudgetIDTypeConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListType(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }
    const getBudgetStructureCatalog = (id: number) => {

        setLoading(true);
        service.getBudgetStructureCatalog(id).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListStructure(resp.DataBeanProperties.ObjectValue);
                    console.log(resp.DataBeanProperties.ObjectValue);

                }
                setLoading(false);
            }
        );

    }
    const getBudgetIDTypeByType = (id: number) => {

        setLoading(true);
        service.getBudgetIDTypeByType(id).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListStructure(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }
    //listas desplegables



    const getLista = () => {

        setLoading(true);
        service.getBudgetIDTypeCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            Type: 0,
            IDBudgetStructure: null

        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteBudgetIDType(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }


    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        console.log("Update Bean : ", bean);

        setLoading(true);
        service.updateBudgetIDType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <BasicPage titulo='Tipos de Catálogo Presupuestal'>
                <div className="row">

                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Name}
                </ModalConfirm>

                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                        </div>
                        <div className="col-md-4">
                            <SelectDefault idName='Value' render='Property' title='Tipo de Catálogo' list={listaType} valueID={bean.Type}
                                handleChange={(e: number) => { setBean({ ...bean, ['Type']: e }); getBudgetIDTypeByType(e) }} />
                        </div>
                        <div className="col-md-4">
                            <SelectDefault idName='IDBudgetStructure' render='Name' title='Tipo de Estructura' list={listaStructure} valueID={bean.IDBudgetStructure}
                                handleChange={(e: any) => {
                                    setBean({ ...bean, ['IDBudgetStructure']: e });
                                }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                </Modal>
            </BasicPage>

        </>
    );
}