import { ContractService } from "../../../../core/services/ContractService";
import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { BpmServiceall } from "../../../../core/services/BpmServiceall";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { AdminService } from "../../../../core/services/AdminService";
import { Formabody } from "./Formabody";
import { FormatTypeproperties } from "./FormatTypeproperties";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";


export const Formatos = () => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalpropertie, setModalformapropertie] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalforma, setModalforma] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const [idbean, setIDBEAN] = useState(0);

    const idBean = 'IDFormatType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Tipos', property: 'IDProject' },



    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getFormatTypeCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {

                    setLista(resp.DataBeanProperties.ObjectValue);

                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            IDProject: 0,
            State: 1,


        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }


    const eliminaritem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateFormatType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Formato', icono: 'ri-file-user-line', visible: true, evento: () => { setModalforma({ ...modalforma, "view": true }) } },
            { titulo: 'Propiedades', icono: 'ri-file-user-line', visible: true, evento: () => { setModalformapropertie({ ...modalpropertie, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }


    ]


    return (
        <>
            <BasicPage titulo="Formatos">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminaritem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">

                    <div className="col-md-6">
                        <label className="form-label">Tipos</label>

                        <select className="form-control" value={bean.IDProject} onChange={(e) => { setBean({ ...bean, ['IDProject']: e.target.value }) }}>
                            <option value={0}>...</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>


                    <div className="col-md-6">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
            <Modal modal={modalforma} updateModal={setModalforma} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        {/* <Riskitem idRiskCategory={bean[idBean]} /> */}

                        <Formabody IDFormatType={bean[idBean]} />
                    </div>
                </div>
            </Modal>
            <Modal modal={modalpropertie} updateModal={setModalformapropertie} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        {/* <Riskitem idRiskCategory={bean[idBean]} /> */}
                        <FormatTypeproperties IDFormatType={bean[idBean]} />
                    </div>
                </div>
            </Modal>

        </>
    )
}
