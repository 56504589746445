import { useEffect, useState } from 'react';
import { FileService } from '../../../core/services/FileService';
import { toast } from 'react-toastify';

interface Props {
    directory?: string;
    onCreate?: any;
    Media?: string;
    MediaContext?: string;
    label?: string;
    docAcept?: string;
    _size?: number;
}

export const FileInput = ({ onCreate, directory, Media, MediaContext, docAcept, label, _size }: Props) => {

    //tipos de fomatos para conocimiento
    // Pdf =  application/pdf
    // Imgen Jpeg = image/jpeg
    // Word = application/vnd.openxmlformats-officedocument.wordprocessingml.document
    // Excel = application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

    const fileService = new FileService();
    const [file, setFile] = useState<any>({});
    const [fileMap, setFileMap] = useState<any>({});
    const [upload, setUpload] = useState<boolean>(false);

    useEffect(() => {
        setFileMap({});
        setUpload(false);
        return () => { };
    }, []);

    const validateFileType = (fileType: any) => {
        const resultado = docAcept?.includes(fileType);
        console.log(resultado)
        return resultado;
    }

    useEffect(() => {
        let media = {};
        if (Media && MediaContext) {
            if (Media != '' && MediaContext != '') {
                const urltmp = fileService.getUrlFile(MediaContext, Media);
                if (urltmp !== "") {
                    media = { URL: urltmp, Name: Media };
                }
            }

        } else {

        }
        setFileMap(media);
    }, [Media]);

    const validateDocument = (e: any) => {
        if (e.target.files.length > 0) {
            let archivo = e.target.files[0];
            if (_size && archivo.size > _size) {
                toast.error(`El archivo no puede superar los ${_size} bytes`);
            } else {
                onChangeFile(e)
            }
        }
    }

    const onChangeFile = (e: any) => {
        const selectedFile = e.target.files[0];
        if (docAcept && !validateFileType(selectedFile.type)) {
            toast.error("Formato de archivo no válido" + selectedFile.type);
        } else {
            setFile(selectedFile);
            setUpload(true);
            fileService.postFile(selectedFile, directory).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        const uploadedFile = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                        setFileMap(uploadedFile);
                        onCreate(uploadedFile);
                    }
                    setUpload(false);
                    toast.success("Se cargó el archivo satisfactoriamente");
                },
                (error: any) => {
                    // Manejo de errores aquí
                    console.error('Error al subir el archivo:', error);
                    toast.error('Error al subir el archivo:');
                    setUpload(false);
                }
            );
        }
    };


    return (
        <>
            {!upload && (
                <>
                    <label className="mb-2">{label ?? 'Adjunto'}</label>
                    <input accept={docAcept ?? ''} type="file" className="form-control" onChange={validateDocument} />
                </>
            )}
            {upload && (
                <>
                    <label>{file.name ?? 'file'}</label>
                    <div className="form-control">
                        <strong>
                            Cargando <i className="mdi mdi-atom-variant mdi-spin btnloading" />
                        </strong>
                    </div>
                </>
            )}
            {fileMap.URL && <a href={fileMap.URL} target="_blank">{fileMap.Name}</a>}
        </>
    );
};