import { useEffect, useState } from 'react'

interface NavItem {
    label: string
    value: string
    show: boolean
}
interface Props {
    defalutValue: string
    items: NavItem[]
    value: string
    onChange: (nValue: string) => void
}

export const NavTabs = ({ items, defalutValue, value, onChange }: Props) => {

    const [active, setActive] = useState(defalutValue)
    const [list, setList] = useState<NavItem[]>([])
    useEffect(() => {
        setList(items.filter(e => e.show === true))
    }, [])

    return (
        <ul className='d-flex align-items-center' style={{ listStyleType: 'none', padding: 0 }}>
            {list.map((item: NavItem, _i) => (
                list.length === 1 ?
                    <li
                        key={`${_i} ${item.value}`}
                        style={{
                            border: '1px solid #c1c4c9',
                            textAlign: 'center',
                            background: '#e9ecf1',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            borderRadius: '25px',
                            padding: '6px 12px',
                            backgroundColor: value === item.value ? '#043072' : '',
                            color: value === item.value ? 'white' : 'black',
                        }}
                        onClick={() => { setActive(item.value); onChange(item.value) }}>
                        {item.label}
                    </li>
                    : <li
                        key={`${_i} ${item.value}`}
                        style={{
                            border: '1px solid #c1c4c9',
                            textAlign: 'center',
                            background: '#e9ecf1',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            borderTopLeftRadius: _i === 0 ? '25px' : '0px',
                            borderBottomLeftRadius: _i === 0 ? '25px' : '0px',
                            borderTopRightRadius: _i === (list.length - 1) ? '25px' : '0px',
                            borderBottomRightRadius: _i === (list.length - 1) ? '25px' : '0px',
                            padding: '6px 12px',
                            backgroundColor: value === item.value ? '#043072' : '',
                            color: value === item.value ? 'white' : 'black',
                        }}
                        onClick={() => {
                            setActive(item.value); onChange(item.value);
                        }}>
                        {item.label}
                    </li>
            ))}
        </ul>
    )
}
