import { useState } from 'react';
import { useEffect } from 'react';
import { BasicButton } from '../theme/Component/BasicButton';

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const DeclaracionVoluntaria = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});

    useEffect(() => {
        if (formSupplier.forma4 === undefined)
            formSupplier.forma4 = {};
        setFormulario(formSupplier.forma4);
    }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        temp.forma4 = formulario;
        updateInfo(temp);
    }

    return (
        <>
            <h5 className='titul-form' style={{ textAlign: 'center' }}>Declaración Voluntaria de Origen y Destino de Fondos</h5>
            <hr />
            <div className="row">
                <div className="col-md-12 text-justify">
                    <p>Declaro que los ingresos y los activos de la empresa que represento no provienen de ninguna de las actividades ilícitas contempladas
                        en la Ley y que no admitiré que terceros efectúen pagos o abonos a cargo de la institución que represento, con fondos provenientes de
                        actividades ilícitas contempladas en el Código Penal Colombiano o cualquier norma que modifique o adicione, ni se efectuarán transacciones
                        destinadas a tales actividades o a favor de personas relacionadas con las mismas. Acepto que las relaciones jurídicas que vinculen a la empresa
                        que represento con la CAJA PROMOTORA DE VIVIENDA MILITAR Y DE POLICÍA, en cuanto utilice sus productos y/o servicios, se rijan por los contratos
                        y políticas internas de la Entidad. Los recursos propios y/o adicionales que manifiesto en el trámite solicitado provienen de:</p>
                </div>
            </div>

            <label className='titul-form'>Descripción: </label>
            <textarea className='form-control mt-4' value={formulario.declaracion} onChange={(e) => setFormulario({ ...formulario, "declaracion": e.target.value })}></textarea>
            <div className="col-md-12 mt-4">
                <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
            </div>
            {/* <PrintJson json={formulario}/>
            <PrintJson json={supplier}/> */}
        </>
    )
}