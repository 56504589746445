import { env } from "../../env";
import api from "./api/api";
import { Singleton } from './Singleton';


export class GenericService {

  public url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  public baseUrl: string = env.REACT_APP_ENDPOINT || "";
  public myApi = api;
  
  
  spinON()
  {
  
      Singleton.getInstance().spinner(true);
  }
  spinOFF()
  {
    
    Singleton.getInstance().spinner(false);
  }
  idAccount()
  {
    return  Singleton.getInstance().getAccountID();
  }

 

}
