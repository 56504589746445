import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { SiteID } from './SiteID';



interface Props{
    renderValue:string;
    onChange:any;
    edit:boolean;
}
export const SelectSiteID =({edit,renderValue,onChange}:Props)=>{

    const [modal, setModal] = useState<any>({ name: 'Ciudades', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor,setValor] = useState('');


    useEffect(()=>{
        setValor(renderValue);
    },[renderValue])

    const getItem = ()=>{
        
        let bean:any = JSON.parse(localStorage.getItem('SiteID')|| '{}');
        console.log(bean);
        
        onChange(bean);
        setValor(bean.Code+" "+bean.Name);
        
        setModal({...modal,"view":false});
    }

    return(
        <>
        <label>Ciudades</label>
        <span className="form-control text-wrap " style={{minHeight:'35px'}}  onClick={()=>setModal({...modal,"view":true})}>{valor}</span>
        
        <Modal modal={modal} updateModal={setModal}  eventModal={getItem}>
                <div style={{maxHeight:'60vh',overflow:'auto'}}>
                <SiteID  id={null} edit={false}/>  
                </div>
        </Modal>

        </>
    )
}