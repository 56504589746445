import { useEffect, useState } from "react";
import { BasicPage } from "../theme/Component/BasicPage"
import { CardTaps } from '../theme/Component/CardTaps';
import { Singleton } from "../../core/services/Singleton";
import { ContractService } from "../../core/services/ContractService";
import { BasicTable } from "../theme/Component/BasicTable";
import { DropDown } from "../theme/Component/DropDown";
import { Modal } from "../theme/Component/Modal";
import { Alert } from "../theme/Component/Alert";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const MisApprovals = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const taps = [
        { id: 0, name: 'Pendientes' },
        { id: 1, name: 'Aprobados' },
        { id: 2, name: 'Rechazados' }
    ]
    const [indexTap, setIndexTap] = useState(0);
    const [state, setState] = useState(0);
    const [token, setToken] = useState<number | undefined>(undefined);
    const [obs, setObs] = useState('');
    const [message, setMessage] = useState('');
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [action, setAction] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Aprobación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


    useEffect(() => {

        return () => {
            setMessage('');
            setLista([]);
        }

    }, [])
    useEffect(() => {

        setBean({});
        getLista();

    }, [indexTap])

    useEffect(() => {

        if (action.id === 1 || action.id === 2) {
            setModal({ ...modal, "view": true });
            setState(action === 1 ? 1 : 2);
        }


    }, [action])

    const getLista = () => {
        let state = 0;
        if (indexTap === 0) {
            state = 0;
        }
        if (indexTap === 1) {
            state = 1;
        }
        if (indexTap === 2) {
            state = 2;
        }
        setState(state);
        setLoading(true);
        service.getRenderApprovalInbox(single.getAccountID(), state).subscribe(
            (resp: any) => {
                let lista: any = [];
                if (resp.DataBeanProperties.ObjectValue) {
                    lista = resp.DataBeanProperties.ObjectValue;
                }
                setLista(lista);
                setLoading(false);
            }
        )
    }

    const head = [
        { title: 'Módulo', property: 'TypeApprovalName', visible: true },
        { title: 'Comentarios', property: 'Theme', visible: true },
        { title: 'Ultima Actualización', property: 'LastUpdate', visible: true },
        { title: 'Estado', property: 'StateName', visible: true }
    ]

    const actionsDropDown = [
        { id: 1, name: 'Aprobar', permiso: null, activo: bean.StateUsr === 0 || bean.StateUsr === 2 },
        { id: 2, name: 'Rechazar', permiso: null, activo: bean.StateUsr === 0 },
    ];

    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    {bean.IDApprovalUser &&
                        <DropDown lista={actionsDropDown} eventClick={setAction}></DropDown>
                    }

                </div>
            </div>
        )
    }

    const changeApprovalUserStatus = () => {

        console.log(token && obs !== '');

        if (token && obs !== '') {
            setLoading(true);
            let st = 0;
            if (action.id === 1)
                st = 1;
            if (action.id === 2)
                st = 2;

            service.changeApprovalUserStatus(bean.IDApprovalUser, st, token ? token + "" : '0', obs).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        setMessage("Aprobacion realizada");
                        getLista();
                        single.makeAcction(obs, resp.DataBeanProperties.ObjectValue);
                    }
                    if (resp.DataBeanProperties.ErrorMessage) {
                        setMessage(resp.DataBeanProperties.ErrorMessage);
                    }

                    setLoading(false);
                }
            )

        } else {
            setMessage("El campo observación y token son obligatorios");
        }


    }

    const enviarMensaje = () => {
        setMessage("se ha enviado un código de autenticación a su numero de contacto.");
    }


    return (
        <BasicPage titulo="Mis Aprobaciones">
            <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                {/* <PrintJson json={lista}/> */}
                <BasicTable headButtons={buttons()} head={head} body={lista} rowSet={setBean} />
            </CardTaps>
            <Modal modal={modal} updateModal={setModal} eventModal={changeApprovalUserStatus}>
                <div className="row">
                    <div className="col-md-6">
                        <label>Código de seguridad</label>
                        <div className="input-group">
                            <input type="number" className="form-control" value={token} onChange={(e) => { setToken(parseInt(e.target.value)) }} />
                            <button className="btn btn-outline-success shadow-none" type="button" id="button-addon2" onClick={enviarMensaje}>Enviar</button>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label>Observaciones *</label>
                        <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                    </div>
                </div>
                {message != '' &&
                    <Alert clase="warning" >
                        {message}
                    </Alert>
                }
            </Modal>
        </BasicPage>
    )
}