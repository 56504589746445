import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { UnspscID } from './UnspscID';
import { ContractService } from '../../../../core/services/ContractService';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';

interface Props {
    renderValue: string;
    onChange: any;
}
export const SelectUnspscID = ({ renderValue, onChange }: Props) => {

    const service = new ContractService();
    const { setLoading } = useDashboardStore();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');
    const [listaB, setListaB] = useState<any>([]);
    const [txt, settxt] = useState<string>('');
    const [idchild, setIDchild] = useState(0);


    useEffect(() => {
        setValor(renderValue);
    }, [renderValue])

    const getItem = () => {

        setListaB([]);
        settxt('');
        let bean: any = JSON.parse(localStorage.getItem('UnscpID') || '{}');
        onChange(bean);
        setValor(bean.UnspscViewCode + " " + bean.Name);

        setModal({ ...modal, "view": false });
    }
    const search = () => {
        console.log(txt);
        setLoading(true);
        service.getUnspscByName(txt).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaB(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    return (
        <>
            <label>Código unspsc</label>
            <span className="form-control text-wrap mt-2" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>{valor}</span>

            <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                <div className="row">
                    <div className="col-md-12">

                        <div className="input-group mb-4">
                            <input type="text" className="form-control" placeholder='Palabra clave' value={txt} onChange={(e) => { settxt(e.target.value) }} />
                            <button className="btn btn-outline-primary shadow-none" type="button" id="button-addon2" onClick={search}>Buscar</button>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                            {listaB.length === 0 &&
                                <UnspscID edit={false} id={null} />
                            }
                            {listaB.length > 0 &&
                                <>
                                    <strong>Resultados encontrador {listaB.length}</strong>
                                    <ul className='list-group'>
                                        {listaB.map((item: any) => {
                                            return (
                                                <li className="list-group-item itemh cursor" onClick={() => {
                                                    localStorage.setItem('UnscpID', JSON.stringify(item.DataBeanProperties));
                                                    setIDchild(item.DataBeanProperties.IDLn);
                                                }}>
                                                    <strong className={idchild === item.DataBeanProperties.IDLn ? 'treeselect' : 'cursor'}>
                                                        {item.DataBeanProperties.UnspscViewCode} - {item.DataBeanProperties.Name}
                                                    </strong>
                                                </li>
                                            )
                                        })

                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}