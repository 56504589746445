import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { BpmServiceall } from '../../../../core/services/BpmServiceall';



    interface Props{
        idSelector:number;
        onChange:any;
        value?:any   
       
    }
        
    export const Selectorconstcode =({idSelector,onChange,value}:Props)=>{
        
        
        
        
    const service = new BpmService();    
    const[lista,setLista] = useState([]);
    const[id,setID] = useState(0);    
        
    useEffect(()=>{
      
        getLista();
    },[idSelector])
        
    useEffect(()=>{
        onChange(id);
    },[id])
        
    const setValue=(e:any)=>{
        setID(parseInt(e));
         onChange(id);
    }
        
    const getLista = () => {
        service.getSuppConstantsCatalogPorPropiedad(null,null,null).subscribe(
            (resp: any) => {
               
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                
            }
        );
    }
            
    return(
        <>
            <label>Tipos de Empresa</label>
                <select className="form-control" value={id} onChange={(e)=>{setValue(e.target.value)}}>
                <option value={0}>...</option>
                    {lista &&
                        lista.map((item:any)=>{
                            return(
                                <>
                                <option key={item.DataBeanProperties.Code} value={item.DataBeanProperties.Code} >{item.DataBeanProperties.Name}</option>
                            </>)
                        })
                    }
                </select>
        </>
    )
}