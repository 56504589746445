import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

export const useStoreCategory = () => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalBranch, setModalBranch] = useState<any>({ name: "Sucursales", tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: "modal-lg", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [bean, setBean] = useState<any>({
        Name: "",
        Description: "",
        State: 1,
        IDEmployee: single.getAccountID(),
        Since: single.getCurrenDate(),
        Media: '',
        MediaContext: '',
    });


    useEffect(() => {
        getLista();
    }, []);


    const idBean = "IDStoreCategory";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        service.getStoreCategoryCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Nombre", property: "Name" },
        { title: "Descripcion", property: "Description" },
        { title: "media", property: "Media" },
        { title: "mediacontext", property: "MediaContext" },
    ];

    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            State: 1,
            IDEmployee: single.getAccountID(),
            Since: single.getCurrenDate(),
            Media: '',
            MediaContext: '',
        };
        setBean(b);
        setModal({ ...modal, ["view"]: true, name: "Agregar" });
    };

    const editarItem = () => {
        setModal({ ...modal, ["view"]: true, name: 'Editar' });
    };

    const eliminarItem = () => {
        bean.State = 2;
        console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateStoreCategory(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
                if (bean.idBean > 0) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        });
        setModal({ ...modal, ["view"]: false });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        single,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
        modalBranch,
        setModalBranch,
    };
};
