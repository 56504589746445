import { useEffect, useState } from "react";
import { ContractService } from "../../core/services/ContractService";
import { AccountEditor } from "../editor/AccountEditor";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    idAdquisitionItem: number;
}

export const AdquisitionItemAnalyst = ({ idAdquisitionItem }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [item, setItem] = useState<any>({});
    const [analyst, setAnalyst] = useState<any>({});

    useEffect(() => {
        getAdquisitionItem();

    }, [idAdquisitionItem])
    useEffect(() => {

        updateItem();
    }, [analyst])
    useEffect(() => {

        console.log("update");

    }, [item])

    const getAdquisitionItem = () => {
        setLoading(true);
        service.getAdquisitionItem(idAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setItem(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                setLoading(false);
            }
        )
    }

    const updateItem = () => {
        setLoading(true);
        let b = item;
        b.IDAccountAnalyst = analyst.IDAccount;
        console.log(b);

        service.updateAdquisitionItem(b).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setItem(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                setLoading(false);
            }
        )
    }

    return (
        <>
            {/* <PrintJson json={item}/> */}
            <div className="row">
                <div className="col-md-12">
                    <AccountEditor idAccount={item.IDAccountAnalyst} label="Funcionario" onChange={(e: any) => { setAnalyst(e) }}></AccountEditor>
                </div>
            </div>
        </>
    )
}