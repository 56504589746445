

import { useState, useEffect } from 'react';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { BasicTable } from '../theme/Component/BasicTable';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { SelectFunctionalID } from '../admin/configuracion/functionalID/SelectFunctionalID';
import { AccountEditor } from '../editor/AccountEditor';
import { FunctionalIDDropDown } from './FunctionalIDDropDown';
import { AdminService } from '../../core/services/AdminService';

import { ListMenu } from '../theme/Component/ListMenu';
import { InputMoney } from '../theme/Input/InputMoney';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
interface Props {
    bean: any;
}

export const AdquisitionPlanFunctionalID = ({ bean }: Props) => {


    const serviceContract = new ContractService();
    const adminService = new AdminService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();

    const [adquisition, setAdquisition] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [user, setUser] = useState<any>({});
    const [permisos, setPermisos] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const idBean = 'IDAdquisitionPlanFunctionalID';



    const head = [
        { title: 'ID', property: idBean },
        { title: 'Área Funcional', property: 'Name' },
        { title: 'Responsable en el área funcional', property: 'AccountName' },
        { title: 'Observaciones', property: 'Observations', visible: false },
        // { title: 'Cerrar Plan De Adquisiciones Del Área Al Valor Estimado', property: 'CloseToEstimatedValue' },
        { title: 'Valor Estimado', property: 'EstimatedValue' },
        { title: 'Valor Necesidades', property: 'TotalStructured' },
        // { title: 'Estado de Estructuración', property: 'StructuringStateName' },
        { title: 'Etapa', property: 'PhaseStateName', visible: false }
    ]


    //Se implementaron los servicos AdquisitionPlanFunctionalID

    useEffect(() => {
        setUser(single.getAccount());
        // getApiPermissionsByRole();
        setAdquisition(bean);
        getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);
    }, [bean.IDAdquisitionPlan])


    const getApiPermissionsByRole = () => {


        adminService.getApiPermissionsByRole(1, 3, null).subscribe(
            (resp: any) => {

                // console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setPermisos(resp.DataBeanProperties.ObjectValue);
                }


            }
        );
    }
    const getAdquisitionPlanFunctionalIDCatalog = (idAdquisitionPlan: number) => {

        setLoading(true);
        serviceContract.getAdquisitionPlanFunctionalIDCatalog(idAdquisitionPlan, null, null).subscribe(
            (resp: any) => {

                // console.log(resp.DataBeanProperties.ObjectValue);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let list = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;

                    list.forEach((ele: any) => {

                        ele.DataBeanProperties.AccountName =
                            (ele.DataBeanProperties.Name1 ?? '') + ' ' +
                            (ele.DataBeanProperties.Name2 ?? '') + ' ' +
                            (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
                            (ele.DataBeanProperties.Surname2 ?? '');

                    });
                    setLista(list);
                }

                setLoading(false);
            }
        );

    }

    const selectedRow = (bean: any) => {

        // bean.IDEmployee = single.getAccountID();
        // bean.IDAdquisition = adquisition.IDAdquisitionPlan;
        setAdquisition(bean);
    }
    const agregarItem = () => {
        setModal({ ...modal, "view": true });
        let b = {
            IDAdquisitionPlan: bean.IDAdquisitionPlan,
            IDLnFunctionalID: 0,
            IDAccount: 0,
            Observations: '',
            CloseToEstimatedValue: false,
            TotalValue: 0
        }
        setAdquisition(b)
    }
    const editarItem = () => {
        setModal({ ...modal, "view": true });

    }
    const eliminarItem = () => {
        setModalConfirm({ ...modalConfirm, "view": false });
        serviceContract.removeFunctionalIDFromAdquisitionPlan(adquisition.IDAdquisitionPlan, adquisition.IDLnFunctionalID).subscribe(
            (resp: any) => {
                getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);
                single.delete(bean[idBean], bean);
            }
        )
    }
    const updateItem = () => {

        setLoading(true);
        console.log(adquisition, user);

        serviceContract.addFunctionalIDToAdquisitionPlan(
            adquisition.IDAdquisitionPlan,
            adquisition.IDLnFunctionalID,
            adquisition.IDAccount,
            single.getAccountID(),
            adquisition.Observations,
            adquisition.CloseToEstimatedValue == 'true' ? true : false,
            adquisition.TotalValue ? parseInt(adquisition.TotalValue) : 0).subscribe(
                (resp: any) => {
                    setLoading(false);
                    console.log(resp);
                    getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);
                    if (bean[idBean]) {

                        single.update(bean);

                    }
                    else {
                        single.create(bean);
                    }
                }
            )

        setModal({ ...modal, "view": false });
    }


    const renderButtons = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    {adquisition[idBean] &&
                        <FunctionalIDDropDown bean={adquisition} permisos={permisos} updateEvent={() => { }}></FunctionalIDDropDown>
                    }

                </div>
            </div>
        )
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete() && (adquisition.TotalValue == null), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    ]

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h4>{adquisition.Description}</h4>
                </div>
                <div className="col-md-12">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} headButtons={renderButtons()} head={head} body={lista} rowSet={selectedRow}></BasicTable>
                </div>
            </div>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <SelectFunctionalID renderValue={adquisition.Name} onChange={(e: any) => { setAdquisition({ ...adquisition, "IDLnFunctionalID": e.IDLn }) }} />
                    </div>
                    <div className="col-md-6">
                        <AccountEditor key={adquisition.IDAccount} idAccount={adquisition.IDAccount} label='Funcionario Responsable de Estructurar la Necesidad'
                            onChange={(e: any) => { setAdquisition({ ...adquisition, "IDAccount": e.IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor Estimado</label>
                        <InputMoney id={0} name='Valor Estimado' value={adquisition.TotalValue} onChange={(e: any) => { setAdquisition({ ...adquisition, ['TotalValue']: parseInt(e) }) }} />
                        {/* <input type="number" className="form-control mt-2"  value={adquisition.TotalValue} onChange={(e) => { setAdquisition({ ...adquisition, ['TotalValue']: parseInt(e.target.value) }) }} /> */}
                    </div>
                    <div className="col-md-12">
                        <label>Observaciones</label>
                        <textarea className='form-control mt-2' value={adquisition.Observations} onChange={(e) => { setAdquisition({ ...adquisition, "Observations": e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label>Limitar Plan al Valor Estimado</label>
                        <select className='form-control mt-2' value={adquisition.CloseToEstimatedValue} onChange={(e) => { setAdquisition({ ...adquisition, "CloseToEstimatedValue": e.target.value }) }}>
                            <option value="false">NO</option>
                            <option value="true">SI</option>
                        </select>
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {adquisition.IDLnFunctionalIDName}
            </ModalConfirm>
        </>
    )
}