


import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Typeformservice } from "./Typeformservice";
import { FormVisualizer } from './FormVisualizer';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { SelectorTypeForm } from './SelectorTypeForm';

const service = new BpmService();
const single = Singleton.getInstance()

export const Typeformaservice = () => {

    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modalpropiedadesform, setModalpropiedadesform] = useState<any>({ name: 'Propiedades', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});
    const [formulario, setFormualrio] = useState<any>({});

    const [groupSelected, setGroupSelected] = useState('')
    const [modalViasulizer, setModalVisualizer] = useState<any>({
        name: '',
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: false,
        format: 'modal-lg'
    });

    const [idbean, setIDBEAN] = useState(0);

    const idBean = 'IDFormType';


    const head = [
        { title: 'ID', property: idBean },
        { title: 'Código', property: 'CodeForm' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Código de Grupo', property: 'GroupCode' },

    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [])
    useEffect(() => {
        if (groupSelected !== '') {
            getListaFiltro();
        } else
            getLista();
    }, [groupSelected])


    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getListaFiltro = () => {
        setLoading(true);
        service.getFormTypeCatalogPorPropiedadesLike(groupSelected).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const getLista = () => {
        setLoading(true);
        service.getFormTypeCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 1,
            Since: single.getCurrenDate(),
            CodeForm: 0,
            GroupCode: ''
        }

        setBean(b);
        setModal({ ...modal, 'view': true });

    }
    const editarItem = () => {

        setModal({ ...modal, 'view': true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteSuppConstants(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });

    }
    const verpropiedades = () => {

        setModalpropiedadesform({ ...modalpropiedadesform, 'view': true });

    }

    const updateItem = () => {

        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.updateFormType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const iconButtonsItem = [
        {
            nombre: 'Agregar',
            visible: single.canCreate(),
            icono: 'ri-file-add-line',
            evento: agregarItem
        }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            {
                titulo: 'Propiedades', icono: 'ri-file-user-line', visible: true, evento: () => {
                    setFormualrio(bean);
                    setModalpropiedadesform({ ...modalpropiedadesform, "view": true });
                }
            },
            {
                titulo: 'Visualizar', icono: 'ri-eye-line', visible: true, evento: () => {
                    setFormualrio(bean);
                    setModalVisualizer({ ...modalViasulizer, name: bean.Name, view: true })
                }
            },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Formularios">
                <div className="row">
                    <div className="col-6">
                        <SelectorTypeForm value={groupSelected} onChange={(e: string) => setGroupSelected(e)} />
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modalConfirm &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Name}
                </ModalConfirm>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Código <small>(Identificación)</small></label>
                            <input type="number" className="form-control" value={bean.CodeForm} onChange={(e) => { setBean({ ...bean, 'CodeForm': e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Grupo de Formulario</label>
                            <input type="text" className="form-control" value={bean.GroupCode}
                                onChange={(e) => { setBean({ ...bean, 'GroupCode': e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                        </div>

                    </div>
                </Modal>}
            {modalpropiedadesform.view &&
                <Modal modal={modalpropiedadesform} updateModal={setModalpropiedadesform} eventModal={verpropiedades}>
                    <Typeformservice idSelector={formulario.IDFormType} CodePar={formulario.CodeForm} />
                </Modal>
            }
            {modalViasulizer.view &&
                <FormVisualizer formCode={bean.CodeForm} idData={0} modal={modalViasulizer} setModal={setModalVisualizer} />
            }
        </>
    )
}