export class BasicStore {

    private STORAGE = "";
    private storeItems: any = [];

    constructor(storageName:string) {
        this.STORAGE = storageName;
        this.storeItems = sessionStorage.getItem(this.STORAGE) ? JSON.parse(sessionStorage.getItem(this.STORAGE)) : [];
    }

    public getItem(name: string) {
        return this.storeItems[name];
    }
    public putItem(name: string, data: any) {
        this.storeItems[name] = data;
        sessionStorage.setItem(this.STORAGE, JSON.parse(this.storeItems));
    }
    public removeItem(name: string) {       
        const index = this.storeItems.findIndex(item => item.name === name);        
        if (index !== -1) {            
            this.storeItems.splice(index, 1);
        }        
        sessionStorage.setItem(this.STORAGE, JSON.parse(this.storeItems));
    }


}