import { AxiosRequestConfig } from "axios";
import { env } from '../../../env'

const baseUrl = env.REACT_APP_ENDPOINT;

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/x-www-form-urlencoded"
  },
};


