import { BasicPage } from "../theme/Component/BasicPage"
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { InputDate } from "../theme/Input/InputDate";
import { SelectModality } from "./select/SelectModality";
import { processState } from "../Constants";
import { ProcessActions } from './ProcessActions';
import { SelectAdquisitionPlan } from './select/SelectAdquisitionPlan';
import { DocumentGroup } from "./DocumentGroup";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const StructuringDocument = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalG, setModalG] = useState<any>({ name: 'Grupos de Requisitos de Estructuración', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });



    const states = processState;

    const idBean = 'IDStructuringDocument';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre del Tipo de Documento Estructurador', property: 'Name' },
        { title: 'Descripción', property: 'Description' },

    ]

    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getStructuringDocumentCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    let lis: any = resp.DataBeanProperties.ObjectValue;

                    setLista(lis);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            IDAccount: null,
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteProcess(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        console.log("Update ", bean);

        setLoading(true);
        service.updateProcess(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const updateBottons = (bean: any) => {
        setBean(bean);
        updateItem();
    }


    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Elimina', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
            { titulo: 'Grupos', icono: 'ri-bubble-chart-line', visible: true, evento: () => { setModalG({ ...modalG, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Tipos de Documento Estructurador de Proceso" >
                <div className="row">

                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modalG} updateModal={setModalG}>
                <DocumentGroup idStructuringDocument={bean[idBean]} />
            </Modal>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">

                    <div className="col-md-6">
                        <label className="form-label">Nombre del Tipo de Documento Estructurador</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}