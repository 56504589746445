import { useState } from "react";
import { BpmService } from "../../../../../core/services/BpmService";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { InputDate } from "../../../../theme/Input/InputDate";
import { toast } from "react-toastify";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface Props {
    idJasonS: number,
}

export const InvokeJsonServiceInPeriod = ({ idJasonS }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [bean, setBean] = useState<any>({});



    const invoke = (id: number, from: any, upto: any, rows: number) => {
        setLoading(true);
        service.invokeJsonServiceInPeriod(id, from, upto, rows).subscribe(
            (resp: any) => {
                if (resp) {
                    setLoading(false);
                    toast.success('🚀 Se ha invocado el servicio satisfatoriamente!')
                }
                else {
                    toast.error('Ha ocurrido un error inesperado')
                }
            });
    }

    const invocar = () => {
        invoke(idJasonS, bean.From, bean.Upto, bean.MaxRows)
    }

    return (
        <>

            <div className="row">
                <div className="col-md-6">
                    <InputDate label="Fecha desde" name="" setDate={(e: any) => { setBean({ ...bean, ['From']: e }) }} value={bean.From}></InputDate>
                </div>
                <div className="col-md-6">
                    <InputDate label="Fecha hasta" name="" setDate={(e: any) => { setBean({ ...bean, ['Upto']: e }) }} value={bean.Upto}></InputDate>
                </div>
                <div className="col-md-12">
                    <label className="form-label">Numero de Elementos</label>
                    <input type="number" className="form-control" value={bean.MaxRows} onChange={(e) => { setBean({ ...bean, ['MaxRows']: e.target.value }) }} />
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4" style={{ display: "flex", justifyContent: "center" }}>
                        <BasicButton eventClick={invocar} icon="">Invocar Servicio</BasicButton>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </>
    )
}