import { useState, useEffect } from 'react';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { AdminService } from '../../../core/services/AdminService';


interface Props {
    label?: string;
    code: number;
    value: any;
    updateLista: any;
    byID?: boolean;
}

export const CheckradioParam = ({ byID, code, label, value, updateLista }: Props) => {

    const service = new AdminService();
    const [origen, setOrigen] = useState([]);
    const { setLoading } = useDashboardStore();

    useEffect(() => {
        getListaParam();
    }, [code])
    useEffect(() => {
        console.log(value);
    }, [value])

    const getListaParam = () => {
        setLoading(true);
        service.getSuppConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue[`Code_${code}`];
                    let tmp = [];
                    lista.forEach((element: any) => {
                        tmp.push(
                            { id: element.DataBeanProperties.IDSuppConstantsValue, Name: element.DataBeanProperties.Name }
                        )

                    });

                    setOrigen(tmp);
                }
                setLoading(false);
            }
        )
    }

    const handleInputChange = (id) => {
        updateLista(id)
    }

    return (
        <>
            <div className="col-md-12">
                <p>{label ?? "Opciones disponibles"}</p>
                <div className="row p-2">
                    <div className="col-md-12">
                        <div className="list-group list-group-fill-success">
                            {
                                origen.map((ele: any, index: any) => {
                                    return (
                                        <>
                                            <div className="list-group-item">
                                                <div className="row flex-row-reverse align-items-center">
                                                    <div className="col-md-10">
                                                        <label className="form-check-label">
                                                            {ele.Name}
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <input key={index} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={ele.id} onChange={() => handleInputChange(ele)}
                                                            checked={byID ? value == ele.id : value === ele.Name} />
                                                    </div>
                                                </div>
                                            </div>

                                        </>

                                    )
                                })
                            }
                            {origen.length === 0 &&
                                <span className="list-group-item list-group-item-action">
                                    <i className=" ri-file-reduce-fill align-middle me-2"></i>Niguna Opción disponible
                                </span>
                            }
                        </div >
                    </div>
                </div>
            </div>
        </>
    )
}