import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';



interface Props {
    idSelector: number;
    onChange: any;
    titulo?: string;
    idProcedure: number | undefined;
    idBussinessProcess?: number;
}

export const SelectorProcedure = ({ titulo, idSelector, onChange, idProcedure, idBussinessProcess }: Props) => {


    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState<number>(null);

    useEffect(() => {
        setID(idSelector)
        if (idBussinessProcess) {
            console.log('lista completa')
            getLista();
        } else {
            console.log('lista excluyendo')
            getListaExluding();
        }

    }, [idProcedure, idBussinessProcess])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getListaExluding = () => {
        service.getProcedureListExcluding(idProcedure).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    const getLista = () => {
        service.getProcedureCatalog(idBussinessProcess).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
            }
        )
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.idProcedure} value={item.DataBeanProperties.IDProcedure} >{item.DataBeanProperties.IDProcedure}-{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}