import { useEffect, useState } from 'react';
interface Selector{
    idName:string;
    render:string
    title:string;
    list:any;
    valueID:number;
    handleChange:any;
}
export const SelectDefault=({idName,render,title,list,valueID,handleChange}:Selector)=>{

    const[id,setID] = useState(0);

    useEffect(()=>{

        if(valueID>0)
        {
            setID(valueID);
        }
       

    },[valueID])

    return(
        <>
        <label>{title}</label>
        <select className="form-control mt-2" onChange={(e)=>{
            setID(parseInt(e.target.value));
            handleChange(e.target.value);
            }} value={id}>
            
            {list && 
                list.map((item:any)=>{
                    return(
                    <>
                    <option value={item.DataBeanProperties[idName]} >{item.DataBeanProperties[render]}</option>
                    </>)
                })
            }
        </select>
        </>
    )
}