import { NotFound } from "./theme/NotFound";
import { useParams } from "react-router-dom";
import { Process } from "./contract/Process";
import { Modality } from "./contract/Modality";
import { RequirementDocs } from "./contract/RequirementDocs";
import { AdquisitionPlanCatalog } from "./contract/AdquisitionPlanCatalog";
import { MisPlanes } from "./contract/MisPlanes";
import { MisPlanesContratar } from "./contract/MisPlanesContratar";
import { MisPlanesPreparador } from "./contract/MisPlanesPreparador";
import { PlanTable } from "./contract/PlanTable";
import { DashboardPlan } from "./contract/DashboardPlan";
import { InboxSupplier } from "./supplier/InboxSupplier";
import { FixedCost } from "./contract/budget/FixedCost";
import { Formatos } from "./bpm/config/Componentes/Formatos";
import { FactorMatrix } from "./bpm/config/Componentes/FactorMatrix";
import { InboxEmployeeSupplier } from "./contract/InboxSupplier/InboxEmployeeSupplier";
import { RegisterContract } from "./contract/etapa-contrato/contrac/RegisterContract";
import { MisPlanesEstructured } from './contract/MisPlanesEstructured';

export const RouterContract = (props: any) => {
  const { module, view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "procesos":
        return <Process />;
      case "modality":
        return <Modality />;
      case "documentos-requeridos":
        return <RequirementDocs />;
      case "planes-adquisicion":
        return <AdquisitionPlanCatalog />;
      case "mis-planes":
        return <MisPlanes />;
      case "mis-planes-estructuracion":
        return <MisPlanesEstructured />;
      case "mis-planes-por-contratar":
        return <MisPlanesContratar rol={1} />;
      case "gestion-compras":
        return <MisPlanesContratar rol={4} />;
      case "mis-planes-preparador":
        return <MisPlanesPreparador />;
      case "mesa-trabajo":
        return <PlanTable />;
      case "formatos":
        return <Formatos />;
      case "Matrizdefactores":
        return <FactorMatrix />;
      case "dashboard-plan":
        return <DashboardPlan />;
      case "aprobacion-proveedores":
        return <InboxSupplier area={2} />;
      case "costos-fijos":
        return <FixedCost />;
      case "bandeja-gestion-compras":
        return <InboxEmployeeSupplier />;
      case "registar-contrato":
        return <RegisterContract />;

      default:
        return <NotFound />;
    }
  };

  return <div className="px-5" >{renderSwitch(view)}</div>;
};
