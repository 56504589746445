import { useEffect } from "react";
import { SelectorClaseTramites } from "../../../../bpm/config/Componentes/SelectorClaseTramites";
import { SelectorTramites } from "../../../../bpm/config/Componentes/SelectorTramites";
import { Procedure } from "../../../../bpm/config/Procedure";
import { ListEmployee } from "../../../../contract/FlowHiring/ListEmployee";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { InputDate } from "../../../../theme/Input/InputDate";
import { InputMoney } from "../../../../theme/Input/InputMoney";
import { useCreditCampaign } from "./useCreditCampaign";
import { SelectPolicy } from "../Policy/SelectPolicy.component";


export const CreditCanpaign = () => {
    const {
        bean,
        setBean,
        lista,
        modal,
        modalConfirm,
        modalComite,
        setModal,
        setModalConfirm,
        setModalComite,
        single,
        eliminarItem,
        iconButtonsItem,
        head,
        setRowBean,
        updateItem,
        listausers,
        procedureType,
        setProcedureType,
        procedureType2,
        setProcedureType2
    } = useCreditCampaign();

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: `Editar ${bean.Name}` }) } },
            { titulo: "Comite Aprobación", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModalComite({ ...modalComite, view: true, name: "Editar" }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const roles = [
        { id: 1, name: 'Director' },
        { id: 2, name: 'Jefe Financiero' },
        { id: 3, name: 'Jefe Presupuesto' },
        { id: 4, name: 'Ordenador del gasto' }
    ]

    const updateEmployee = (e: any) => {
        console.log(e);
        setBean({ ...bean, "ApprovalUser": JSON.stringify(e) })
    }

    return (
        <>
            <BasicPage titulo="Campaña de Credito">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modalComite} updateModal={setModalComite} eventModal={updateItem}>
                <div className="row">
                    <div className="col">
                        <ListEmployee key={bean.IDCreditCampaign}
                            listP={bean.ApprovalUser}
                            listRoles={listausers}
                            updateLista={updateEmployee}></ListEmployee>
                    </div>
                    {/* <BasicButton eventClick={mostrar} icon=""></BasicButton> */}
                </div>
            </Modal>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control mt-2" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Valor estimado</label>
                        <InputMoney id={0} name="Valor estimado" onChange={(e: any) => { setBean({ ...bean, 'BudgetValue': parseInt(e) }) }} value={bean.BudgetValue} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-6">
                        <InputDate label='Fecha inicial' name='fecha inicial' value={bean.InitDate} setDate={(e: any) => { setBean({ ...bean, "InitDate": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <InputDate label='Fecha final' name='fecha final' value={bean.EndDate} setDate={(e: any) => { setBean({ ...bean, "EndDate": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Rango de cuotas</label>
                        <input type="string" className="form-control" value={bean.QuotaRange} onChange={(e) => { setBean({ ...bean, ['QuotaRange']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Escala  de valores</label>
                        <input type="number" className="form-control" value={bean.ScaleValue} onChange={(e) => { setBean({ ...bean, ['ScaleValue']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Valor minimo a prestar</label>
                        <InputMoney id={1} name="Valor minimo a prestar" onChange={(e: any) => { setBean({ ...bean, 'MinVal': parseInt(e) }) }} value={bean.MinVal} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Valor maximo a prestar</label>
                        <InputMoney id={2} name="Valor maximo a prestar" onChange={(e: any) => { setBean({ ...bean, 'MaxVal': parseInt(e) }) }} value={bean.MaxVal} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Valor de la tasa de interes <small>(Mensual)</small></label>
                        <input type="number" className="form-control" value={bean.RateValue} onChange={(e) => { setBean({ ...bean, ['RateValue']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Uso de campaña:</label>
                        <select className="form-control mt-2" value={bean.TypeCampaign} onChange={(e: any) => { setBean({ ...bean, "TypeCampaign": parseInt(e.target.value) }) }}>
                            <option value={undefined}>...</option>
                            <option value={0}>Todo</option>
                            <option value={1}>Credito</option>
                            <option value={2}>Convenio</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <SelectPolicy idSelector={bean.IDPolicy} onChange={(e) => { setBean({ ...bean, "IDPolicy": e }) }} titulo="Politica a aplicar:"></SelectPolicy>
                    </div>
                    {bean.TypeCampaign >= 0 &&
                        <>
                            <div className="row">
                                {(bean.TypeCampaign == 1 || bean.TypeCampaign == 0) &&
                                    <>
                                        <hr className="mt-4" />
                                        <div className="d-flex justify-content-center">
                                            <h4>Configuracion de Credito</h4>
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorTramites titulo="Clase de tramites para credito" idSelector={procedureType} onChange={(e: any) => { setProcedureType(e) }} />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorClaseTramites titulo="Procedimiento de credito" idProcess={procedureType} idSelector={bean.IDProcedureCredit} onChange={(e: any) => { setBean({ ...bean, "IDProcedureCredit": e }) }} />
                                        </div>
                                    </>
                                }
                                {(bean.TypeCampaign == 2 || bean.TypeCampaign == 0) &&
                                    <>
                                        <hr className="mt-4" />
                                        <div className="d-flex justify-content-center">
                                            <h4>Configuracion de Tienda</h4>
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorTramites titulo="Clase de tramites para tienda" idSelector={procedureType2} onChange={(e: any) => { setProcedureType2(e) }} />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorClaseTramites titulo="Procedimiento de tienda" idProcess={procedureType2} idSelector={bean.IDProcedureShop} onChange={(e: any) => { setBean({ ...bean, "IDProcedureShop": e }) }} />
                                        </div>
                                    </>
                                }
                            </div>
                        </>

                    }
                </div>
            </Modal >

        </>
    );
};
