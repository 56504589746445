
import { useEffect, useState } from 'react'
import { LiaMoneyBillWaveSolid } from 'react-icons/lia'
import { IoArrowUndoCircleOutline } from 'react-icons/io5'
import { LuPalmtree } from 'react-icons/lu'

import { MenuButton } from '../MenuButton/MenuButton'
import { YearSelector } from '../../../../../core/shared/inputs/YearSelector'
import { QuickAction } from '../MenuDashed/MenuDashed'
import { TalentService } from '../../../../../core/services/TalentService'
import { Singleton } from '../../../../../core/services/Singleton'
import { NoResults } from '../../../../../core/shared/NoResults'
import './onvacation.scss'


export const OnVacation = () => {

    const single = Singleton.getInstance();
    const [activeTab, setActiveTab] = useState(0)
    const [year, setYear] = useState(new Date().getFullYear())
    const service = new TalentService();

    const certificateTabs: QuickAction[] = [
        {
            label: 'Pagadas',
            icon: <LiaMoneyBillWaveSolid />,
            event: () => { setVacations([]); setActiveTab(0) }

        },
        {
            label: 'Anticipadas',
            icon: <IoArrowUndoCircleOutline />,
            event: () => { setVacations([]); setActiveTab(1) }

        },
    ]

    const [vacations, setVacations] = useState([])

    useEffect(() => {
        if (activeTab === 0) {
            getPaidVacations()
        } else {
            getAnticipatedVacations()
        }
    }, [activeTab, year])


    const getPaidVacations = () => {
        single.spinner(true);
        service.getFortnightVacationsByEmployee(year, single.getAccountID())
            .subscribe((resp: any) => {
                single.spinner(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    console.log(resp);
                    const arr = resp.DataBeanProperties.ObjectValue.map((e) => e.DataBeanProperties)
                    setVacations(arr)
                }
            });
    }

    const getAnticipatedVacations = () => {
        single.spinner(true)
        service.getActiveEnjoyedVacationAnticipated(single.getAccountID(), true)
            .subscribe((resp: any) => {
                single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    const arr = resp.DataBeanProperties.ObjectValue.map((e) => e.DataBeanProperties)
                    console.log(arr);
                    setVacations(arr)
                }
            })
    }

    return (
        <div className="d-flex w-100 flex-column align-items-center">
            <div className="col-10 my-3 mx-auto mb-3">
                <div className="d-flex gap-5 justify-content-between">
                    {certificateTabs.map((e, i) =>
                        <MenuButton key={i} item={e} labelSize={16} active={activeTab === i} />
                    )}
                </div>
            </div>
            {activeTab === 0 &&
                <div className="d-flex"
                    style={{ width: '80%' }}
                >
                    <YearSelector size="sm" value={year} onChange={setYear} />
                </div>}
            <div className="col-12 mt-5 d-flex justify-content-center">
                {vacations.map((e: any, _i) =>
                    <li key={_i} className="d-flex mb-4 pb-3 border-bottom w-100 mx-5">
                        <div className="avatar flex-shrink-0 me-3">
                            <div style={{ color: '#006699', fontSize: '60px' }}>
                                <LuPalmtree />
                            </div>
                        </div>
                        <div className="d-flex w-100 mt-3 flex-wrap align-items-center justify-content-between gap-2">
                            <div className="me-2 d-flex">
                                <strong style={{ fontSize: 22, color: 'orange' }}>
                                    {e.Units}
                                </strong>
                                <div className='d-flex align-items-center mx-2 mt-1' style={{ fontSize: 16 }}>
                                    Días
                                </div>
                            </div>
                            <div className='me-2 d-flex flex-column'>
                                <strong>Inicia:</strong>
                                <small className='text-dark'>{e.NoveltyInitDate}</small>
                            </div>
                            <div className='me-2 d-flex flex-column'>
                                <strong>Termina:</strong>
                                <small className='text-dark'>{e.NoveltyUptoDate}</small>
                            </div>
                        </div>
                    </li>
                )}
            </div>
            {vacations.length === 0 &&
                <NoResults className='pt-3 mt-5' />
            }
        </div>
    )
}
