import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicTable } from "../../theme/Component/BasicTable";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    idRealesCredit: number;
}
export const DetailGuarantee = ({ idRealesCredit }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new GarantiasService();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});


    useEffect(() => {
        getCreditMonthRender();
    }, [idRealesCredit])

    const getCreditMonthRender = () => {
        let map = {
            IDRealesCredit: idRealesCredit
        }
        setLoading(true);
        service.getCreditMonthRender(null, null, map).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    lista.forEach((element: any) => {
                        element.DataBeanProperties.EntityName =
                            (element.DataBeanProperties.Name1 ?? '') + " " +
                            (element.DataBeanProperties.Name2 ?? '') + " " +
                            (element.DataBeanProperties.Surname1 ?? '') + " " +
                            (element.DataBeanProperties.Surname2 ?? '');

                        element.DataBeanProperties.Month = element.DataBeanProperties.Month+1; 

                    });
                    setLista(lista);
                }
                setLoading(false);
            }
        )
    }

    const head = [
        { title: 'Identificación', property: 'nit', visible: true },
        { title: 'Afiliado', property: 'EntityName', visible: true },
        { title: 'Año', property: 'Year', visible: true },
        { title: 'Mes', property: 'Month', visible: true },
        { title: 'Fecha Calculo', property: 'CalculateDate', visible: true },
        { title: 'Porcentaje', property: 'PercentValue', visible: true },
        { title: 'Valor Calculado', property: 'CalculatedValue', visible: true }
    ]

    return (
        <div className="row">
            <div className="col">
                <BasicTable head={head} body={lista} rowSet={setBean}></BasicTable>
            </div>
        </div>
    )
}