import { useState, useEffect } from 'react';
import { AdminService } from '../../../../core/services/AdminService';


interface Props {
    idSelector: number;
    onChange: any;

}

export const SelectorMenuTypeConstants = ({ idSelector, onChange }: Props) => {

    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.getMenuTypeConstants(null).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label className='mb-2'>Tipo de Menú</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}
