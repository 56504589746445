import { map } from 'rxjs';
import { useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    lista: any;
    eventClick: any;
    permisos?: any;
}

// ejemplo de uso
// const actionsDropDown = [
//     {id:1,name:'Actualizar Estado',permiso:null,activo:true},
//     {id:2,name:'Ver Documentos',permiso:null,activo:false},  
//     ];

export const DropDown = ({ lista, eventClick, permisos }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const keyp = 'mipropkey-' + single.getRandomInt();
    const keyp2 = 'propkey-' + single.getRandomInt();



    const validatePermision = (item: any) => {
        let valido = true;
        if (permisos)
            if (permisos.length > 0) {
                if (item.permiso === null) {
                    valido = true;
                } else {
                    valido = permisos.includes(item.permiso);
                }

            }
        return valido;

    }

    return (
        <div className="dropdown mt-2 " key={keyp}>
            <a href="#" className="btn btn-warning drop  ri-bar-chart-horizontal-fill" data-bs-toggle="dropdown" aria-expanded="false"></a>
            <div className="dropdown-menu" key={keyp2}>
                {lista.map((item: any) => {
                    return (
                        <div key={item.id}>
                            {validatePermision(item) && (item.activo != undefined ? item.activo : true) &&
                                <span key={item.id} className="dropdown-item cursor  " accessKey={item.name} onClick={() => { eventClick(item) }}>{item.name}</span>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}