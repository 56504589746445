import { defer, Observable } from "rxjs";
import initializeAxios from "./axios-setup";
import { axiosRequestConfiguration } from "./config";
import { map } from "rxjs/operators";


const axiosInstance = initializeAxios(axiosRequestConfiguration);

const get = <T>(url: string, queryParams?: object): Observable<T> => {
  return defer(() => axiosInstance.get<T>(url, { params: queryParams })).pipe(
    map((result) => result.data)
  );
};

const post = <T>(
  url: string,
  body: any,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.post<T>(url, body, { params: queryParams })
  ).pipe(map((result) => result.data));
};

const postH = <T>(
  url: string,
  body: any,
  queryParams?: object
): Observable<{ data: T; headers: any }> => {
  return defer(() =>
    axiosInstance.post<T>(url, body, { params: queryParams })  
  ).pipe(
    map((response:any) => ({
      data: response.data,
      headers: response.headers,
    }))
  );
};


const postHH = <T>(url: string, body: any, queryParams?: object) => {
  return defer(() => axiosInstance.post<T>(url, body, { params: queryParams }));
};
const put = <T>(
  url: string,
  body: any,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.put<T>(url, body, { params: queryParams })
  ).pipe(map((result) => result.data));
};

const patch = <T>(
  url: string,
  body: any,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.patch<T>(url, body, { params: queryParams })
  ).pipe(map((result) => result.data));
};

const deleteR = <T>(url: string, id: number): Observable<T | void> => {
  return defer(() => axiosInstance.delete(`${url}/${id}`)).pipe(
    map((result) => result.data)
  );
};

export default { get, post, put, patch, delete: deleteR, postH };
