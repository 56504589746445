import { NotFound } from './theme/NotFound';
import { useParams } from 'react-router-dom';
import { RealesCredit } from './adminServices/guarantee/RealesCredit';
import { RealesCVSample } from './adminServices/guarantee/RealesCVSample';
import { SiteIDRate } from './adminServices/guarantee/SiteIDRate';
import { CreditMonth } from './adminServices/guarantee/CreditMonth';
import { InportRealesCredit } from './adminServices/guarantee/InportRealesCredit';
import { CreditMonthReport } from './adminServices/guarantee/CreditMonthReport';


export const RouterAdminService = (props: any) => {

    const { module, view }: any = useParams();

    const renderSwitch = (url: string) => {

        switch (url) {
            case 'inmuebles':
                return (<RealesCredit key={url} />);
            case 'inmuebles-hipotecario':
                return (<RealesCredit key={url} type={1} pageTitle='Inmuebles Hipotecario' />);
            case 'inmuebles-leasing':
                return (<RealesCredit key={url} type={2} pageTitle='Inmuebles Leasing'/>);
            case 'inmuebles-report':
                return (<CreditMonthReport/>);
            case 'evidencias-inmuebles':
                return (<RealesCVSample />);
            case 'impuestos-ciudad':
                return (<SiteIDRate />);
            case 'impuesto-mensual':
                return (<CreditMonth />);
            case 'valorizacion-hipotecario':
                return (<CreditMonth typeGuarante={1} key={url} />);
            case 'valorizacion-leasing':
                return (<CreditMonth  typeGuarante={2} key={url}/>);
            case 'inmueble-cargue-masivo':
                return (<InportRealesCredit />);
            default:
                return <NotFound />;
        }
    }

    return (
        <div className='px-5'>
            {renderSwitch(view)}
        </div>
    );
}