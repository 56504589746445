import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmServiceall } from "../../../core/services/BpmServiceall";
import { Selectortipodeformulario } from "./Componentes/Selectortipodeformulario";
import { Tablaaccionesformularios } from "./Componentes/Tablaaccionesformularios";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicSwitch } from '../../theme/Component/BasicSwitch';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';


export const Formularios = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmServiceall();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modaljson, setModaljson] = useState<any>({ name: 'Prototipo JSON', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalacciones, setModalacciones] = useState<any>({ name: 'Varibles de respuesta', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [idModule, setIDModule] = useState(0);
    const [user, setUser] = useState<any>({});

    const idBean = 'IDForm';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripcion', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
        { title: 'Url del Formulario', property: 'FormURLComponent' },
        { title: 'Codigo', property: 'Code' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idModule])

    const setRowBean = (bean: any) => {
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getFormCatalog(idModule).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {

                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 0,
            IDEmployee: user.IDAccount,
            Rules: JSON.stringify([]),
            Since: single.getCurrenDate(),
            IDFormClass: idModule,
            Code: 0,
            HtmlBodyText: '',
            HtmlExtraText: '',
            HtmlStored: ''


        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const editarjson = () => {
        setModaljson({ ...modaljson, ['view']: true });
    }

    const veracciones = () => {
        setModalacciones({ ...modalacciones, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteForm(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateForm(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Json', icono: 'ri-braces-line', visible: true, evento: () => { setModaljson({ ...modaljson, "view": true }) } },
            { titulo: 'Variables de Respuesta', icono: 'ri-code-s-slash-line', visible: true, evento: () => { setModalacciones({ ...modalacciones, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const test = () => {
        // setLoading(true);
        // service.getResponseValueForJsonService(idJasonS).subscribe(
        //     (resp: any) => {
        //         if (resp.DataBeanProperties.ObjectValue) {
        //             console.log('testeo exitoso')
        //         } else {
        //             console.log('testeo no exitoso')
        //         }
        //         setLoading(false);
        //     }
        // );
    }

    const copiarAlPortapapeles = () => {

    };

    return (
        <>
            <BasicPage titulo="FORMULARIOS">
                <div className="row">
                    <div className="col-md-12">
                        <div className="w-50">
                            <Selectortipodeformulario idSelector={idModule} onChange={(e: any) => { setIDModule(e) }} />
                        </div>
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row ">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Codigo</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, 'Code': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Url del Formulario</label>
                        <textarea className="form-control" value={bean.FormURLComponent} onChange={(e) => { setBean({ ...bean, 'FormURLComponent': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-4">
                        <label>HTML Almacenado</label>
                        <BasicSwitch estado={bean.LimitedWithValues} eventChange={(e: any) => {
                            setBean({ ...bean, "HtmlStored": e })
                        }}></BasicSwitch>
                    </div>
                    {bean.HtmlStored &&
                        <>
                            <div className="col-md-12">
                                <label className="form-label">Cuerpo del Formulario</label>
                                <textarea className="form-control" value={bean.HtmlBodyText} onChange={(e) => { setBean({ ...bean, 'HtmlBodyText': e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Cuerpo extra del Formulario</label>
                                <textarea className="form-control" value={bean.HtmlExtraText} onChange={(e) => { setBean({ ...bean, 'HtmlExtraText': e.target.value }) }}></textarea>
                            </div>
                        </>
                    }
                </div>
            </Modal>


            <Modal modal={modalacciones} updateModal={setModalacciones} eventModal={veracciones}>
                <Tablaaccionesformularios IDform={bean.IDForm} />
            </Modal>

            <Modal modal={modaljson} updateModal={setModaljson} eventModal={veracciones}>
                <>
                    <div className="row">
                        {/* <div className="col">
                            <button onClick={copiarAlPortapapeles} className="btn btn-primary">Copiar JSON</button>
                        </div>
                        <div className="col-md-6" />
                        <div className="col">
                            <button onClick={test} className="btn btn-success">Iniciar Testeo</button>
                        </div> */}
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea disabled className="form-control" defaultValue={bean.ResponseJsonValue ? bean.ResponseJsonValue : 'null'} ></textarea>
                        </div>
                    </div>
                </>
            </Modal>

        </>
    )
}