import { useEffect, useState } from "react";
import { BpmService } from "../../../core/services/BpmService";
import { Singleton } from "../../../core/services/Singleton";
import { useDashboardStore } from "../hooks/useDashboardStore";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { SelectTHConstantsValue } from "../../bpm/config/Componentes/SelectTHConstantsValue";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { Selecttipodanger } from "../../bpm/config/Componentes/Selecttipodanger";


export const MatrixRisk = () => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const single = Singleton.getInstance();
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const idBean = 'IDRiskMatrix';

    const head = [
        { title: 'ID', property: idBean, visible: true },
        { title: 'Nombre de la tarea', property: "Name", visible: true },
        { title: 'Descripción de la tarea', property: "Description", visible: true },
        { title: '¿es rutinaria?', property: "IsRoutine", visible: true },
        { title: 'Descripción del Peligro', property: "DangerDescription", visible: false },
        { title: 'Fuente', property: "ControlSource", visible: false },
        { title: 'Medio', property: "ControlMeans", visible: false },
        { title: 'Interpretación Nivel de Proabilidad', property: "EvalInteNivProbabilidad", visible: true },
        { title: 'Interpretación Nivel del Riesgo', property: "EvaIntNivelRiesgo", visible: true },
        { title: 'Aceptanilidad del Riesgo', property: "AceptabilidadRiesgo", visible: true },
        { title: 'Peor Consecuencia', property: "PeorCounsecuencia", visible: true },
        { title: 'Peligro', property: "DangerDescription", visible: false },
        { title: 'Eliminación', property: "Eliminacion", visible: false },
        { title: 'Sustitución', property: "Sustitucion", visible: false },
        { title: 'Control de Ingeniería', property: "ControlIngenieria", visible: false },
        { title: 'Control de Administrativo', property: "ControlAdministrativo", visible: false },
    ]

    const getLista = () => {
        setLoading(true);
        service.getRiskMatrixCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    setLista(tmp);
                }
                setLoading(false);
            }
        );
    }


    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    const agregarItem = () => {
        let b = {
            Process: 0, //509
            IDJobPosition: 0, //550
            Name: "",
            Description: "",
            IsRoutine: false,
            IDActivityLocation: 0, //510
            IDDanger: 0,
            DangerDescription: "",
            IDDamageLevel: 0, //511
            ControlSource: "",
            ControlMeans: "",
            ControlSingle: "",
            State: 1,
            CalSource: 0, //512
            CalMedio: 0, //513
            CalSingle: 0, //514
            EvalNivelDeficiencia: undefined,
            EvalNivelExposi: undefined,
            EvalNivelProbabilidad: 0,
            EvalInteNivProbabilidad: "Falso", //Selector?
            EvaNivelConsecuencia: undefined,
            EvaNivelRiesgo: 0,
            EvaIntNivelRiesgo: "Sin Calcular", //selector?
            AceptabilidadRiesgo: "SIN ACEPTABILIDAD",
            NoExpuestos: 0,
            PeorCounsecuencia: "Sin Consecuencia",
            Eliminacion: "NA",
            Sustitucion: "NA",
            ControlIngenieria: "",
            ControlAdministrativo: "",
            Epp: 0, //515
        }

        setBean(b);
        setModal({ ...modal, 'view': true, "name": "Agregar Matriz" });
    }

    const eliminarItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        // console.log(bean)
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateRiskMatrix(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.IDGroupTemplate) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar Matriz" }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    useEffect(() => {
        if (bean.EvalNivelDeficiencia && bean.EvalNivelExposi) {
            calcEvalNivelProbabilidad();
        }
    }, [bean.EvalNivelDeficiencia, bean.EvalNivelExposi])

    const calcEvalNivelProbabilidad = () => {
        let lvlProbability = bean.EvalNivelDeficiencia * bean.EvalNivelExposi;
        console.log(lvlProbability)
        if (lvlProbability >= 24) {
            setBean({ ...bean, "EvalInteNivProbabilidad": "MA Riesgo muy Alto", "EvalNivelProbabilidad": lvlProbability });
        } else if (lvlProbability >= 10 && lvlProbability < 24) {
            setBean({ ...bean, "EvalInteNivProbabilidad": "A Riesgo Alto", "EvalNivelProbabilidad": lvlProbability });
        } else if (lvlProbability >= 6 && lvlProbability < 10) {
            setBean({ ...bean, "EvalInteNivProbabilidad": "M Riesgo Medio", "EvalNivelProbabilidad": lvlProbability });
        } else if (lvlProbability >= 2 && lvlProbability < 6) {
            setBean({ ...bean, "EvalInteNivProbabilidad": "B Riesgo Bajo", "EvalNivelProbabilidad": lvlProbability });
        } else {
            setBean({ ...bean, "EvalInteNivProbabilidad": "Falso", "EvalNivelProbabilidad": 0 });
        }
    }

    useEffect(() => {
        if (bean.EvaNivelConsecuencia) {
            bean.EvaNivelRiesgo = bean.EvalNivelProbabilidad * bean.EvaNivelConsecuencia;
            calcPeorConsecuencia();
        }
    }, [bean.EvaNivelConsecuencia, bean.EvalNivelProbabilidad])

    useEffect(() => {
        if (bean.EvaNivelRiesgo >= 600) {
            setBean({ ...bean, "EvaIntNivelRiesgo": "I", "AceptabilidadRiesgo": "NO ACEPTABLE - SITUACIÓN CRÍTICA - SUSPENDER ACTIVIDADES" });
        } else if (bean.EvaNivelRiesgo >= 150 && bean.EvaNivelRiesgo < 600) {
            setBean({ ...bean, "EvaIntNivelRiesgo": "II", "AceptabilidadRiesgo": "CORREGIR ADOPTAR CONTROLES" });
        } else if (bean.EvaNivelRiesgo >= 40 && bean.EvaNivelRiesgo < 150) {
            setBean({ ...bean, "EvaIntNivelRiesgo": "III", "AceptabilidadRiesgo": "ACEPTABLE MEJORAR CONTROLES SI ES POSIBLE" });
        } else if (bean.EvaNivelRiesgo < 40) {
            setBean({ ...bean, "EvaIntNivelRiesgo": "IV", "AceptabilidadRiesgo": "ACEPTABLE MANTENER MEDIDAS DE CONTROL" });
        } else {
            setBean({ ...bean, "EvaIntNivelRiesgo": "Sin Calcular", "AceptabilidadRiesgo": "SIN ACEPTABILIDAD" });
        }
    }, [bean.EvaNivelRiesgo])


    const calcPeorConsecuencia = () => {
        if (bean.EvaNivelConsecuencia == 100) {
            setBean({ ...bean, "PeorCounsecuencia": "INVALIDEZ-MORTAL" });
        } else if (bean.EvaNivelConsecuencia == 60) {
            setBean({ ...bean, "PeorCounsecuencia": "MUY GRAVE" });
        } else if (bean.EvaNivelConsecuencia == 25) {
            setBean({ ...bean, "PeorCounsecuencia": "LESIONES ENFERMEDADES INCAPACIDAD" });
        } else if (bean.EvaNivelConsecuencia == 10) {
            setBean({ ...bean, "PeorCounsecuencia": "LESIONES ENFERMEDADES NO INCAPACIDAD" });
        } else {
            setBean({ ...bean, "PeorCounsecuencia": "SIN CONSECUENCIA" });
        }
    }

    return (
        <>
            <BasicPage titulo="Matriz de Riesgos">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Procesos en la Organización" code={509} value={bean.Process} onChange={(proceso: any) => { setBean({ ...bean, "Process": proceso }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Cargo" code={550} value={bean.IDJobPosition} onChange={(cargo: any) => { setBean({ ...bean, "IDJobPosition": cargo }) }}></SelectTHConstantsValue>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label>Nombre de la tarea</label>
                        <input className="form-control" type="string" value={bean.Name} onChange={(nombre) => { setBean({ ...bean, "Name": nombre.target.value }) }} ></input>
                    </div>
                    <div className="col-md-6">
                        <label>¿es rutinaria?</label>
                        <BasicSwitch label="" estado={bean.IsRoutine} eventChange={(rutina) => { setBean({ ...bean, "IsRoutine": rutina }) }}></BasicSwitch>
                    </div>
                    <div className="col-md-12">
                        <label>Descripción de la tarea</label>
                        <textarea className="form-control" value={bean.Description} onChange={(desc) => { setBean({ ...bean, "Description": desc.target.value }) }}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Lugar de la Actividad" code={510} value={bean.IDActivityLocation} onChange={(autor: any) => { setBean({ ...bean, "IDActivityLocation": autor }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <Selecttipodanger titulo="Categorias de Riesgo" idSelector={bean.IDDanger} onChange={(daño: any) => { setBean({ ...bean, "IDDanger": daño }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectTHConstantsValue label="Nivel de Daño del Peligro" code={511} value={bean.IDDamageLevel} onChange={(regulacion: any) => { setBean({ ...bean, "IDDamageLevel": regulacion }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-12">
                        <label>Descripción del peligro </label>
                        <textarea className="form-control" value={bean.DangerDescription} onChange={(dangerdesc) => { setBean({ ...bean, "DangerDescription": dangerdesc.target.value }) }}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label>Fuente</label>
                        <textarea className="form-control" value={bean.ControlSource} onChange={(fuente) => { setBean({ ...bean, "ControlSource": fuente.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-4">
                        <label>Medio</label>
                        <textarea className="form-control" value={bean.ControlMeans} onChange={(medio) => { setBean({ ...bean, "ControlMeans": medio.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-4">
                        <label>Individuo</label>
                        <textarea className="form-control" value={bean.ControlSingle} onChange={(individuo) => { setBean({ ...bean, "ControlSingle": individuo.target.value }) }}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <SelectTHConstantsValue label="Cálculo de la Fuente" code={512} value={bean.CalSource} onChange={(calcfeunte: any) => { setBean({ ...bean, "CalSource": calcfeunte }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-4">
                        <SelectTHConstantsValue label="Cálculo del Medio" code={513} value={bean.CalMedio} onChange={(calcmedio: any) => { setBean({ ...bean, "CalMedio": calcmedio }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-4">
                        <SelectTHConstantsValue label="Cálculo del Individuo" code={514} value={bean.CalSingle} onChange={(calcindividuo: any) => { setBean({ ...bean, "CalSingle": calcindividuo }) }}></SelectTHConstantsValue>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-3">
                        <label>Nivel deficiencia</label>
                        <select className='form-select' value={bean.EvalNivelDeficiencia} onChange={(lvldeficiencia) => { setBean({ ...bean, "EvalNivelDeficiencia": lvldeficiencia.target.value }) }}>
                            <option value={undefined}>...</option>
                            <option value={0}>(N/V-0) Bajo</option>
                            <option value={2}>(M-2) Medio</option>
                            <option value={6}>(A-6) Alto</option>
                            <option value={10}>(MA-10) Muy Alto</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Nivel exposición</label>
                        <select className='form-select' value={bean.EvalNivelExposi} onChange={(lvlexposicion) => { setBean({ ...bean, "EvalNivelExposi": lvlexposicion.target.value }) }}>
                            <option value={undefined}>...</option>
                            <option value={1}>1 - Exp Esporádica</option>
                            <option value={2}>2 - Exp Ocacional</option>
                            <option value={3}>3 - Exp Frecuente</option>
                            <option value={4}>4 - Exp Continua</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Nivel probabilidad</label>
                        <input className="form-control" value={bean.EvalNivelProbabilidad} disabled></input>
                    </div>
                    <div className="col-md-3">
                        <label>Interpretación Nivel Probabilidad</label>
                        <input className="form-control" type="text" value={bean.EvalInteNivProbabilidad} disabled></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <label>Nivel de consecuencia</label>
                        <select className='form-select' value={bean.EvaNivelConsecuencia} onChange={(lvlexposicion) => { setBean({ ...bean, "EvaNivelConsecuencia": lvlexposicion.target.value }) }}>
                            <option value={undefined}>...</option>
                            <option value={10}>(L-10) - Leve</option>
                            <option value={25}>(G-25) - Grave</option>
                            <option value={60}>(MG-60) - Muy Grave</option>
                            <option value={100}>(M-100) - Mortal/Catastrofico</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Nivel de Riesgo</label>
                        <input className="form-control" type="text" value={bean.EvaNivelRiesgo} disabled></input>
                    </div>
                    <div className="col-md-3">
                        <label>Interpretación Nivel de Riesgo</label>
                        <input className="form-control" type="text" value={bean.EvaIntNivelRiesgo} disabled></input>
                    </div>
                    <div className="col-md-5">
                        <label>Aceptabilidad del riesgo</label>
                        <input className="form-control" type="text" value={bean.AceptabilidadRiesgo} disabled></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <label>No de Expuestos</label>
                        <input className="form-control" type="number" value={bean.NoExpuestos} onChange={(expuestos) => { setBean({ ...bean, "NoExpuestos": expuestos.target.value }) }}></input>
                    </div>
                    <div className="col-md-4">
                        <label>Peor Consecuencia</label>
                        <input className="form-control" type="text" value={bean.PeorCounsecuencia} disabled></input>
                    </div>
                    <div className="col-md-3">
                        <label>Eliminación</label>
                        <input className="form-control" type="text" value={bean.Eliminacion} onChange={(text) => { setBean({ ...bean, "Eliminacion": text.target.value }) }}></input>
                    </div>
                    <div className="col-md-3">
                        <label>Sustitución</label>
                        <input className="form-control" type="text" value={bean.Sustitucion} onChange={(text) => { setBean({ ...bean, "Sustitucion": text.target.value }) }}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label >Controles de Ingeniería</label>
                        <textarea className="form-control" value={bean.ControlIngenieria} onChange={(ctrlinge) => { setBean({ ...bean, "ControlIngenieria": ctrlinge.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label >Controles Administrativos</label>
                        <textarea className="form-control" value={bean.ControlAdministrativo} onChange={(crtladmin) => { setBean({ ...bean, "ControlAdministrativo": crtladmin.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <SelectTHConstantsValue label="Elementos de Protección Personal" code={515} value={bean.Epp} onChange={(idEpp: any) => { setBean({ ...bean, "Epp": idEpp }) }}></SelectTHConstantsValue>
                    </div>
                </div>
            </Modal>
        </>
    )
}