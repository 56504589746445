import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { BpmService } from '../../../core/services/BpmService';

interface Props {
    label?: string;
    valor: string;
    _onChange: any;
}

export const SelectJsonDataTypes = ({ label, valor, _onChange }: Props) => {
    const service = new BpmService();
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {
        getLista();
    }, [valor])

    const setValue = (e: any) => {
        console.log(e)
        _onChange(e);  // Pasar e en lugar de select
    }

    const getLista = () => {
        service.getJsonDataTypes(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        )
    }

    return (
        <>
            <label>{label}</label>
            <select className='form-control' onChange={(e: any) => setValue(e)}>
                <option >...</option>
                {lista.map((item: any) => (
                    <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                ))}
            </select>
        </>
    )
}