import { GenericService } from "../../../../../core/services/GenericService";


export class CashReimbursementsev extends GenericService {

    public updateCashReimbursement(bean) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "com.quickdataerp.bean.accountant.CashReimbursement_updateCashReimbursement_com.quickdataerp.bean.accountant.CashReimbursement",
            ArgumentList: [

                {
                    DataBeanProperties: bean,
                    DataBeanName: 'com.quickdataerp.bean.accountant.CashReimbursement'
                }


            ],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);
    }


}