import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { BasicPage } from "../theme/Component/BasicPage"
import { useEffect, useState } from 'react';
import { AdquisitionItemByFuncionalID } from "./AdquisitionItemByFuncionalID";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const MisPlanesPreparador = () => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const contract = new ContractService();
    const [bean, setBean] = useState<any>({});
    const [planes, setPlanes] = useState<any>([]);
    const [IDplan, setIDPlan] = useState<number>(0);
    const [IDPrepared, setIDPrepared] = useState<number>(0);
    const [IDArea, setIDArea] = useState<number>(0);
    const [lista, setLista] = useState<any>([]);



    useEffect(() => {
        getAdquisitionPlanOnStructuringStateForPreparer();
    }, [])

    useEffect(() => {
        getAdquisitionPlanFunctionalIDForPreparer();
    }, [IDplan])

    useEffect(() => {
        if(IDArea>0)
            {
                lista.forEach((item:any) => {
                    if(item.DataBeanProperties.IDAdquisitionPlanFunctionalID === IDArea)
                        setBean(item.DataBeanProperties);
                });
                getFunctionalIDPreparerByAccount();
            }
    }, [IDArea])

    const getAdquisitionPlanOnStructuringStateForPreparer = () => {
        setLoading(true);
        contract.getAdquisitionPlanOnStructuringStateForPreparer(single.getAccountID()).subscribe(
            (resp: any) => {
                console.log(resp);       
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setPlanes(resp.DataBeanProperties.ObjectValue);
                    setLoading(false);
                }
            }
        )
    }
    const getAdquisitionPlanFunctionalIDForPreparer = () => {
        setLoading(true);
        contract.getAdquisitionPlanFunctionalIDForPreparer(IDplan, single.getAccountID()).subscribe(
            (resp: any) => {
                console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((ele: any) => {

                        ele.DataBeanProperties.AccountName =
                            (ele.DataBeanProperties.Name1 ?? '') + ' ' +
                            (ele.DataBeanProperties.Name2 ?? '') + ' ' +
                            (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
                            (ele.DataBeanProperties.Surname2 ?? '');

                    });
                    setLista(list);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            }
        )
    }
    const getFunctionalIDPreparerByAccount = () => {
        setLoading(true);
        contract.getFunctionalIDPreparerByAccount(IDArea, single.getAccountID()).subscribe(
            (resp: any) => {
                console.log(resp);
                
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    setIDPrepared(list.DataBeanProperties.IDFunctionalIDPreparer);
                }
            }
        )
    }
  
    return (
        <>
            <BasicPage titulo="Planes por Preparar">

                <div className="row">
                    <div className="col">
                    <div className="row">
                    <div className="col-md-6">
                        <label>Plan</label>
                        <select className="form-select mb-3" value={IDplan} onChange={(e) => { setIDPlan(parseInt(e.target.value)) }}>
                            <option value="0">...</option>
                            {planes.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>
                                        {item.DataBeanProperties.Description}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label>Area / Dependencia</label>
                        <select className="form-select" value={IDArea} onChange={(e)=>{setIDArea(parseInt(e.target.value))}}>
                            <option value={undefined}>...</option>
                            {lista.map((item:any,index:number)=>{
                                return(
                                    <option key={index} value={item.DataBeanProperties.IDAdquisitionPlanFunctionalID}>{item.DataBeanProperties.Name}</option>
                                )
                            })}
                        </select>
                    </div>
                    
                </div>
                    </div>
                </div>
                { IDArea >0 ?
                    <div className="row">
                        <div className="col">
                            <AdquisitionItemByFuncionalID preparador={true} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} beanOficina={bean} idPrepared={IDPrepared}></AdquisitionItemByFuncionalID>
                        </div>
                    </div>
              :
              <div className="d-flex mt-4 justify-content-center">
                <div className="alert alert-warning" role="alert">
                  <p>
                    Debe seleccionar una <strong>Area / Dependencia</strong> para poder continuar.
                  </p>
                </div>
              </div>
            }
            </BasicPage>
        </>
    )
}