import { useEffect, useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Singleton } from "../../../core/services/Singleton";
import { BpmService } from "../../../core/services/BpmService";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { SelectorTramites } from "./Componentes/SelectorTramites";
import { Caracterizaciontramites } from "../../theme/Component/Caracterizaciontramites";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const BusinessProcess = () => {
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [idModule, setIDModule] = useState(0);
    const [modalcaracttramites, setModalcaracttramites] = useState<any>({
        name: "Caracterizaciones Para Prueba",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: false,
        format: "modal-md",
    });
    const [modala, setModala] = useState<any>({
        name: "Agregar Item",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-lg",
    });
    const [meliminar, setMeliminar] = useState<any>({
        name: "Confirmar",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-sm",
    });
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();

    // useEffect(()=>{
    //     setUser(single.getAccountID());
    // },[])

    const idBean = "IDBusinessProcess";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    useEffect(() => {
        getLista();
    }, [idModule])


    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            State: 0,
            Since: single.getCurrenDate(),
            IDBusinessClass: idModule,
            Code: 0,
            BusinessValue: "",
        };
        setBean(b);
        setModala({ ...modala, ["view"]: true });
    };

    const getLista = () => {
        setLoading(true);
        service
            .getBusinessProcessCatalog(null, null, null, idModule, null, null)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const caracterizaciontramites = () => {

        setModalcaracttramites({ ...modalcaracttramites, 'view': true });

    }

    const eliminarItem = () => {
        setLoading(true);
        service.deleteBusinessProcess(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setMeliminar({ ...meliminar, 'view': false });
    }

    const updateItem = () => {
        setModala({ ...modala, 'view': false });

        setLoading(true);
        service.updateBusinessProcess(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });
    };

    const head = [
        { title: "ID ", property: idBean },
        { title: "Nombre", property: "Name" },
        { title: "Descripción", property: "Description" },
        { title: "Precio", property: "BusinessValue" },
    ];

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton
                            value="Agregar"
                            icon="ri-file-add-line"
                            eventClick={agregarItem}
                        ></BasicButton>
                        {bean[idBean] && (
                            <>
                                <BasicButton
                                    value="Editar"
                                    icon="ri-edit-2-line"
                                    eventClick={''}
                                ></BasicButton>
                                <BasicButton
                                    value="Eliminar"
                                    icon="ri-eraser-line"
                                    eventClick={() => {
                                        setMeliminar({ ...meliminar, ["view"]: true });
                                    }}
                                ></BasicButton>
                                <BasicButton
                                    value="Caracterizaciones"
                                    icon="ri-edit-2-line"
                                    eventClick={caracterizaciontramites}
                                ></BasicButton>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Editar",
                icono: "ri-file-list-line",
                visible: true,
                evento: () => {
                    setModala({ ...modala, view: true, name: 'Editar' });
                },
            },
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setMeliminar({ ...meliminar, view: true });
                },
            },
            {
                titulo: "Caracterizaciones",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setModalcaracttramites({ ...modalcaracttramites, view: true });
                },
            },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const iconButtonsItem = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: agregarItem,
        },
    ];

    return (
        <>
            <BasicPage titulo="Tramites">
                <div className="row">
                    <div className="col-md-12">
                        <SelectorTramites titulo="Clase de tramites" idSelector={idModule} onChange={(e: any) => { setIDModule(e) }} />
                    </div>
                    <div className="col-md-12 p-2">
                        {idModule > 0 &&
                            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                        }
                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={meliminar} updateModal={setMeliminar} eventModal={eliminarItem}>
                {bean.name}
            </ModalConfirm>

            <Modal modal={modala} updateModal={setModala} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Codigo</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, 'Code': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    {/* <div className="div"><SelectorTramites idSelector={idsele2} titulo="Clase de tramite" onChange={(a:any)=>{setIDSele2(parseInt(a.target.value))}}/></div>                                         */}
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Precio</label> <input type="number" className="form-control" value={bean.BusinessValue} onChange={(e) => { setBean({ ...bean, 'BusinessValue': e.target.value }) }} />
                        {/* <textarea className="form-control" value={bean.BusinessValue} onChange={(e)=>{setBean({...bean,['BusinessValue']:e.target.value})}}></textarea>                     */}
                    </div>
                </div>
            </Modal>
            <Modal modal={modalcaracttramites} updateModal={setModalcaracttramites} eventModal={updateItem}>
                <Caracterizaciontramites IDBusinessProcess={bean[idBean]} ></Caracterizaciontramites>
            </Modal>
        </>
    )
}