import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
}

export const SelectorOffice = ({ titulo, idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector);

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {
        service.getOfficeCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <label className='form-label'>{titulo}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any, _i) =>
                    (
                        <option key={_i} value={item.DataBeanProperties.IDOffice} >{item.DataBeanProperties.Name}</option>
                    ))
                }
            </select>
        </>
    )
}