import { useEffect, useState } from "react";
import { BasicButton } from "../../../theme/Component/BasicButton"
import { MyEditor } from "../../../theme/Component/MyEditor";
import { ContractService } from "../../../../core/services/ContractService";
import { AdquisitionArticle } from "../../adquisition-setting/AdquisitionArticle";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { MultiSelectParam } from "../../../theme/Component/MultiSelectParam";
import { SelectSiteID } from "../../../admin/configuracion/SiteID/SelectSiteID";
import { RiskMatrix } from "./RiskMatrix";
import { Modal } from "../../../theme/Component/Modal";
import { PDFJuridico } from "./PDFJuridico";
import { RiskMatrixData } from "../../interfaces";
import { Singleton } from "../../../../core/services/Singleton";
import { BpmService } from "../../../../core/services/BpmService";
import { ListEmployee } from "../../FlowHiring/ListEmployee";
import { OfferEmployee } from "../../FlowHiring/OfferEmployee/OfferEmployee";

const _snigle = Singleton.getInstance()
const _service = new BpmService()

interface Props {
    adquisitioItem: any;
    rol: number;
    _item: { Dependence, Coverage };
    _Study: object;
    riskMatrixData: RiskMatrixData
}
export const EstudioPrevioJuridico = ({ adquisitioItem, rol, _Study, _item, riskMatrixData }: Props) => {




    const [modal, setModal] = useState<any>({ name: 'Resumen estudio previo', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [cover, setCover] = useState({
        Name: '',
        Address: '',
        SiteIDLn: 0,
        SiteIDLnName: '',
        Dependence: _item.Dependence,
    });
    const [item, setItem] = useState<any>(_item);
    const [estudio, setEstudio] = useState<any>(_Study);

    const [step, setStep] = useState<number>(1);

    const steps = [
        { id: 1, title: "Justificacíon de la contratación", rol: [1, 4] },
        { id: 2, title: "Descripción del Bien/servicio Requerido", rol: [1, 4] },
        { id: 3, title: "Cobertura", rol: [1, 4] },
        { id: 4, title: "Obligaciones Principales", rol: [1, 4] },
        { id: 5, title: "Requisitos del SG-SST", rol: [1, 4] },
        { id: 6, title: "Plazo del contrato", rol: [1, 4] },
        { id: 7, title: "Matriz de Riesgos", rol: [1, 4] },
        { id: 8, title: "Supervisores", rol: [1, 4] },
    ]

    useEffect(() => {
        if (_item.Coverage) {
            setCover(JSON.parse(_item.Coverage));
        }
    }, [_item])


    useEffect(() => {
        getMatrixFactorsTemplete()
    }, [])


    const getMatrixFactorsTemplete = () => {
        _snigle.spinner(true)
        _service.getFactorMatrizTemplate()
            .subscribe((resp: any) => {
                _snigle.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    const x = JSON.parse(resp.DataBeanProperties.ObjectValue[0]);
                    if (item.factorsCheck?.length > 0) {
                        setItem({ ...item, factorsCheck: item.factorsCheck })
                    } else {
                        setItem({ ...item, factorsCheck: x })
                    }
                }
            })
    }

    const update = (nValue) => {
        item.Coverage = JSON.stringify(cover);
        setLoading(true);
        let est = estudio;
        est.Body = JSON.stringify(item);
        service.updatePreviousStudy(est).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let item = resp.DataBeanProperties.ObjectValue;
                    setEstudio(item);
                    setItem(JSON.parse(item.DataBeanProperties.Body));

                }

                setLoading(false);
            }
        )
    }

    const updateListaEmpleados = (e: any) => {
        setItem({ ...item, "EmployeeList": e ? JSON.stringify(e) : '[]' });
    }

    const SeeModal = () => {
        setModal({ ...modal, "view": true })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <ul className="list-group">

                                {steps.map((item: any, index: number) => {
                                    return (
                                        <>
                                            {item.rol.includes(rol) ?
                                                <li key={index} className="list-group-item cursor"
                                                    style={{
                                                        backgroundColor: item.id === step ? '#043072d9' : '',
                                                        color: item.id === step ? 'white' : ''
                                                    }}
                                                    onClick={() => { setStep(item.id) }}>{item.title}</li>
                                                : ''
                                            }
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <BasicButton icon="ri-save-line" clase="success" eventClick={update}>Guardar</BasicButton>
                        </div>
                        <div className="col-md-6">
                            <BasicButton icon="ri-save-line" clase="success" eventClick={SeeModal}>Visualizar</BasicButton>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {step == 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    <h4>Justificación</h4> <small>(Si requiere ampliar y/o actualizar la justificación )</small> <hr />
                                    <MyEditor val={item.Justification ?? adquisitioItem.Justification} onChange={(e: any) => { setItem({ ...item, "Justification": e }) }}></MyEditor>
                                </p>
                            </div>
                            <div className="col-md-12">
                                <p>
                                    <h4>Objecto Contractual</h4>
                                    <MyEditor val={item.ObjectContract ?? adquisitioItem.Description} onChange={(e: any) => { setItem({ ...item, "ObjectContract": e }) }}></MyEditor>
                                </p>
                            </div>

                        </div>}
                    {step === 2 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <AdquisitionArticle viewHiringProcces={true} idAdquisitionItem={adquisitioItem.IDAdquisitionItem} rol={4} />
                                </div>
                            </div>
                        </div>}
                    {step === 3 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Cobertura</h4> <small>(Indique la oficina de Caja Honor en donde se debe prestar el servicio y/o entregar el bien)</small> <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label><strong>NOMBRE DEL LUGAR</strong></label>
                                            <input type="text" value={cover.Name} className="form-control" onChange={(e: any) => { setCover({ ...cover, "Name": e.target.value }) }} />
                                        </div>
                                        <div className="col-md-6">
                                            <label><strong>DIRECCIÓN</strong></label>
                                            <input type="text" value={cover.Address} className="form-control" onChange={(e: any) => { setCover({ ...cover, "Address": e.target.value }) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <SelectSiteID edit={false} onChange={(e: any) => { setCover({ ...cover, "SiteIDLnName": e.Name }) }} renderValue={cover.SiteIDLnName} />
                                        </div>
                                        <div className="col-md-12">
                                            <label><strong>DEPENDENCIA ENCARGADA DE LA RECECPCIÓN  DEL BIEN/SERVICIO EN CAJA HONOR</strong> </label>
                                            <input className="form-control" type="text" value={item.Dependence} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {step === 4 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Obligaciones Principales</h4> <hr />
                                    <div>
                                    </div>
                                    <MultiSelectParam code={13} _value={item.ObligationList} updateLista={(e: any) => {
                                        setItem({ ...item, "ObligationList": e })
                                    }} />
                                </div>
                            </div>
                        </div>}
                    {step === 5 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Sistema SST</h4> <hr />
                                    <MultiSelectParam code={14} _value={item.SST} updateLista={(e: any) => {
                                        setItem({ ...item, "SST": e })
                                    }} />
                                </div>
                            </div>
                        </div>}
                    {step === 6 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Plazo del contrato</h4> <small>(Indique el plazo del contrato)</small> <hr />
                                    <MyEditor val={item.Term} onChange={(e: any) => { setItem({ ...item, "Term": e }) }}></MyEditor>
                                </div>
                            </div>
                        </div>}
                    {step === 7 &&
                        <RiskMatrix value={riskMatrixData} onChange={(e) => { setItem({ ...item, 'RiskMatrix': e.risks }) }} />
                    }
                    {step === 8 &&
                        <OfferEmployee IDAdquisitionItem={adquisitioItem.IDAdquisitionItem}></OfferEmployee>
                    }
                </div>
            </div>
            {modal.view &&
                <Modal modal={modal} updateModal={setModal}>
                    <>
                        <PDFJuridico _AdquisitionItem={adquisitioItem} _BeanJ={item}></PDFJuridico>
                    </>
                </Modal>
            }
        </>
    )
}