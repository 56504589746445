import { useEffect, useState } from "react";


import { toast } from "react-toastify";
import { BankService } from "./Bank-Service";


interface Props {
    id: number;
    onchange: Function;
    office?: number;
}
export const Bankeditor = ({ id, onchange, office }: Props) => {


    const service = new BankService();
    const [lista, setLista] = useState<any>([]);
    const [idLista, setIDLista] = useState<number>(0);


    useEffect(() => {
        getCashDeskAccountCatalog();
    }, [])

    useEffect(() => {
        if (id) {
            setIDLista(id);
        }
    }, [id])
    // useEffect(() => {
    //     if (id != idLista) {
    //         onchange(idLista);
    //     }
    // }, [idLista])

    const setValue = (e: any) => {
        onchange(parseInt(e));
        setIDLista(e);
    }

    const getCashDeskAccountCatalog = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office ?? null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }


    return (
        <>
            <label>Compañia:</label>
            <select className="form-select" name="AccountantBook" value={idLista} onChange={(e) => setValue(e.target.value)}>
                <option value={null}>....</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDCashDeskAccount}>{item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}