import { useEffect, useState } from "react";
import { ContractService } from "../../../../core/services/ContractService"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { SearchItem } from "./SearchItem";

import { Singleton } from '../../../../core/services/Singleton';

import { InputDate } from "../../../theme/Input/InputDate";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { FindSupplier } from "../../FindSupplier";

import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";

import { InputMoney } from "../../../theme/Input/InputMoney";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { AdquisitionArticle } from "../../adquisition-setting/AdquisitionArticle";
import { AdquisitionItemBudgetID } from "../../AdquisitionItemBudgetID";
import { OfferEmployee } from "../../FlowHiring/OfferEmployee/OfferEmployee";


export const RegisterContract = () => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [bean, setBean] = useState<any>({});
    const [contract, setContract] = useState<any>({});
    const [row, setRow] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConf, setModalConf] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalRubro, setModalRubro] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
    const [modalArt, setModalArt] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalPer, setModalPer] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(() => {

        getAdquisitionContractRender();
        initBean();

        return () => {
            setBean({});
        }
    }, [])


    const initBean = () => {
        setBean({
            ...bean,
            "IDEmployee": single.getAccountID(),
            "isLote": false,
            "SeleccionDate": single.getCurrenDate(),
            "IDAdquisitionItem": 0,
            "IDAdquisitionContract": 0,
            "IDSupplier": 0,
            "ContractValue": 0,
            "Code": ""
        });
    }

    const crearContrato = () => {
        single.spinner(true);
        contracService.registerAdquisitionContract(bean.IDAdquisitionItem, bean.IDSupplier, bean.IDEmployee, bean.SeleccionDate, bean.isLote, bean.Code).subscribe(
            (resp: any) => {



                if (resp.DataBeanProperties.ObjectValue) {
                    // setContract(resp.DataBeanProperties.ObjectValue);
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    if (tmp.IDAdquisitionContract) {
                        bean.IDAdquisitionContract = tmp.IDAdquisitionContract;
                    }
                    actualizarContrato();
                    setModal({ ...modal, "view": false })
                }
                single.spinner(false);
            }
        )
    }
    const actualizarContrato = () => {
        single.spinner(true);
        contracService.updateAdquisitionContract(bean).subscribe(
            (resp: any) => {



                if (resp.DataBeanProperties.ObjectValue) {

                    getAdquisitionContractRender();
                    setModal({ ...modal, "view": false })
                }
                single.spinner(false);
            }
        )
    }
    const elimiarItem = () => {
        single.spinner(true);
        contracService.deleteAdquisitionContract(bean.IDAdquisitionContract).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {

                    getAdquisitionContractRender();

                }
                single.spinner(false);
            }
        )
    }
    const getAdquisitionContractRender = () => {
        single.spinner(true);
        contracService.getAdquisitionContractRender(null, null, {}).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setModalConf({ ...modalConf, "view": false });
                single.spinner(false);
            }
        )
    }

    const updateFomr = () => {

        if (bean.IDAdquisitionContract > 0) {
            actualizarContrato();
        }
        else {
            crearContrato();
        }

    }


    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { setModal({ ...modal, "view": true }) } }
    ]

    const [lista, setLista] = useState<any>([]);

    const head = [
        { title: 'ID', property: 'IDAdquisitionContract', visible: false },
        { title: 'Valor Contrato', property: 'ContractValue', visible: true },
        { title: 'Valor CDP', property: 'CDPValue', visible: true },
        { title: 'Fecha Contrato', property: 'SeleccionDate', visible: true },
        { title: 'Dodo radicado', property: 'Code', visible: false },
        { title: 'Código contrato', property: 'InternalCode', visible: true },
        { title: 'Proveedor', property: 'Name1', visible: true },
        { title: 'Descripcion', property: 'Description', visible: true },
        { title: 'No CDP', property: 'NoCDP', visible: false },
        { title: 'Fecha CDP', property: 'DateCDP', visible: false },
        { title: 'No registro presupuestal', property: 'NoRegistroPresupuestal', visible: false },
        { title: 'Fecha registro presupuestal', property: 'DateRegPresupuestal', visible: false },

    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-eraser-fill', visible: single.canDelete(), evento: () => { setModalConf({ ...modalConf, "view": true }) } },
            { titulo: 'Personal', icono: 'ri-list-unordered', visible: true, evento: () => { setModalPer({ ...modalPer, "view": true, "name": "Personal a cargo" }) } },
            { titulo: 'Rubros', icono: 'ri-list-unordered', visible: true, evento: () => { setModalRubro({ ...modalRubro, "view": true, "name": "Rubro Presupuestal" }) } },
            { titulo: 'Articulos', icono: 'ri-list-unordered', visible: true, evento: () => { setModalArt({ ...modalArt, "view": true, "name": "Articulos del contrato" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Registro Contratos">
            <div className="row">
                <div className="col">
                    <Modal modal={modal} updateModal={setModal} eventModal={updateFomr} >
                        <div className="row">
                            <div className="col-md-6">
                                <InputDate label="Fecha Contrato" name="date" value={bean.SeleccionDate} setDate={(e) => { setBean({ ...bean, "SeleccionDate": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Valor Contrato</label>
                                <InputMoney name="" value={bean.ContractValue} id={bean.IDAdquisitionContract} onChange={(e: number) => { setBean({ ...bean, "ContractValue": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Codigo Interno Contrato</label>
                                <input type="text" className="form-control" value={bean.InternalCode} onChange={(e) => { setBean({ ...bean, "InternalCode": e.target.value }) }} />
                            </div>

                            <div className="col-md-6">
                                <label>Radicado Dodo Contrato</label>
                                <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, "Code": e.target.value }) }} />
                            </div>
                            <div className="col-md-9">
                                <FindSupplier idSupplier={bean.IDSupplier} handleEvent={({ IDSupplier }: any) => { setBean({ ...bean, "IDSupplier": IDSupplier }) }} />
                            </div>
                            <div className="col-md-3 mt-3 mb-2">
                                <BasicSwitch label="Contrato por lote" estado={bean.isLote} eventChange={(e: any) => { setBean({ ...bean, "isLote": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>

                            <div className="col-md-6">
                                <label>Valor CDP</label>
                                <InputMoney name="" value={bean.CDPValue} id={bean.IDAdquisitionContract} onChange={(e: number) => { setBean({ ...bean, "CDPValue": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Número CDP</label>
                                <input type="text" className="form-control" value={bean.NoCDP} onChange={(e) => { setBean({ ...bean, "NoCDP": e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate label="Fecha CDP" name="fechacdp" value={bean.DateCDP} setDate={(e) => { setBean({ ...bean, "DateCDP": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <label>Número registro presupuestal</label>
                                <input type="text" className="form-control" value={bean.NoRegistroPresupuestal} onChange={(e) => { setBean({ ...bean, "NoRegistroPresupuestal": e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate label="Fecha registro presupuestal" name="fecharp" value={bean.DateRegPresupuestal} setDate={(e) => { setBean({ ...bean, "DateRegPresupuestal": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                            <div className="col-md-12">
                                <SearchItem idAdquisitionItem={bean.IDAdquisitionItem} onChage={({ IDAdquisitionItem }: any) => { setBean({ ...bean, "IDAdquisitionItem": IDAdquisitionItem }) }} />
                            </div>

                            <div className="col-md-6">
                                <BasicButton icon="" clase="danger" eventClick={initBean}>Limpiar</BasicButton>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="row">
                {/* <PrintJson  json={bean}/> */}

                <div className="col-md-12">
                    <BasicTable head={head} body={lista} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
                </div>
                <div className="col-md-12">
                    <Modal modal={modalConf} updateModal={setModalConf} eventModal={elimiarItem}>
                        <div className="row">
                            <div className="col">
                                <strong>Eliminar registro</strong>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="col-md-12">
                    <Modal modal={modalArt} updateModal={setModalArt} >
                        <AdquisitionArticle viewHiringProcces={true} idAdquisitionItem={bean.IDAdquisitionItem} ></AdquisitionArticle>
                    </Modal>
                </div>
                <div className="col-md-12">
                    <Modal modal={modalRubro} updateModal={setModalRubro} >
                        <AdquisitionItemBudgetID iDBudgetIDType={null} idAdquisitionItem={bean.IDAdquisitionItem} />
                    </Modal>
                </div>
                <div className="col-md-12">
                    <Modal modal={modalPer} updateModal={setModalPer}>
                        <OfferEmployee IDAdquisitionItem={bean.IDAdquisitionItem}/>
                    </Modal>
                </div>
            </div>
        </BasicPage>
    )
}