// FORMATO DE ESTUDIO PREVIO DE NECESIDAD, CONVIVENCIA Y OPORTUNIDAD PARA LA CONTRATACIÓN DE UNA PERSONA NATURAL
import { Divider } from './Divider'

export const OportunityContractNaturalPerson = () => {
    return (
        <div className='row'>
            <div className="d-flex">
                <small className='text-muted'>
                    El siguiente es el formato de solicitud de contratación para persona natural.
                </small>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
                <strong>Radicado No.</strong>
                <strong>Radicado disponibilidad de personal.</strong>
                <strong>Dependencia(s)</strong>
                <strong>Tipo de solicitud</strong>
            </div>
            <Divider
                className='mt-5'
                title='JUSTIFICACIÓN DE LA CONTRATACIÓN'
            />
            <p className='mt-3'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nunc congue feugiat ornare. Phasellus felis lectus,
                ornare ut orci at, pretium iaculis massa.
                Maecenas quis ante sed velit aliquam posuere.
                Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                vitae placerat diam consectetur a.
            </p>
            <div className="col-12 d-flex flex-column">
                <Divider
                    title='DESCRIPCIÓN DE LA CONTRATACIÓN'
                />
                <div className='d-flex flex-column'>
                    <strong>Nombre de la persona a contratar:</strong>
                    <strong>Formación.</strong>
                    <strong>Experiencia</strong>
                    <strong>Objeto del contrato</strong>
                </div>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
                <Divider
                    title='OBLIGACIONES PRINCIPALES'
                    description='Son las distintas acciones primordiales que el contratista debe realizar deben ser claras, 
                    expresas, exigibles y acordes con las funciones que deberá cumplir la persona.'
                />
                <p className='mt-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc congue feugiat ornare. Phasellus felis lectus,
                    ornare ut orci at, pretium iaculis massa.
                    Maecenas quis ante sed velit aliquam posuere.
                    Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                    Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                    vitae placerat diam consectetur a.
                </p>
                <p className='mt-1'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc congue feugiat ornare. Phasellus felis lectus,
                    ornare ut orci at, pretium iaculis massa.
                    Maecenas quis ante sed velit aliquam posuere.
                </p>
                <p className='mt-1'>
                    Nullam aliquet nunc quis pellentesque consequat. Pellentesque faucibus tortor at ornare pulvinar.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Quisque blandit tortor nec quam tincidunt, eu mattis mauris luctus.
                    Mauris ullamcorper vitae sapien viverra vestibulum. Praesent facilisis purus dui,
                    vitae placerat diam consectetur a.
                </p>
            </div>
            <div className="col-12 d-flex flex-column">
                <div className='d-flex flex-column'>
                    <strong>Plazo del contrato:</strong>
                    <strong>Futuro supervisor:</strong>
                </div>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
                <Divider
                    className='mt-3'
                    title='MATRIZ DE RIESGOS'
                />
            </div>
            <div className="col-12 mt-5 d-flex flex-column">
                <strong>Atentatamente:</strong>
            </div>
            <div className="col-12 mt-5 d-flex flex-column justify-content-center align-items-center">
                <strong>SUBGERENTE/JEFE OFICINA ASESORA</strong>
            </div>
            <div className="col-12 mt-5 d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <strong>Proyectó:</strong>
                    <strong>Nombre</strong>
                </div>
                <div className="d-flex flex-column">
                    <strong>Revisó y aprobó</strong>
                    <strong>Nombre Jefe Área supervisora</strong>
                </div>
            </div>

        </div>
    )
}
