import { AdminService } from "../../../core/services/AdminService";
import { BasicPage } from "../../theme/Component/BasicPage"
import { useState, useEffect } from 'react';
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicButton } from "../../theme/Component/BasicButton";
import { Modal } from "../../theme/Component/Modal";
import { MultiSelect } from "../../theme/Component/MultiSelect";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const ApiRole = () => {


    const service = new AdminService();

    const { setLoading } = useDashboardStore();


    const [listaApiModule, setListaApiModule] = useState([]);
    const [listaRol, setListaRol] = useState([]);
    const [listaRolSys, setListaRolSys] = useState([]);
    const [listaHiringRoles, setListaHiringRoles] = useState([]);

    const [bean, setBean] = useState<any>({});
    const [idApiModule, setIDApiModule] = useState(0);
    const [idRol, setIDRol] = useState(0);

    const [arrHiring, setArrHiring] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getApiModuleCatalog();
        getRoleCatalog();
        getHiringRoles();
    }, [])

    useEffect(() => {
        getApiRoleCatalog();
    }, [idApiModule])

    const getApiModuleCatalog = () => {
        setLoading(true);
        service.getApiModuleCatalog(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaApiModule(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }
    const getApiRoleCatalog = () => {
        setLoading(true);
        service.getApiRoleCatalog(idApiModule).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaRol(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }
    const getRoleCatalog = () => {
        setLoading(true);
        service.getRoleCatalog(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaRolSys(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }
    const getHiringRoles = () => {
        setLoading(true);
        service.getHiringRoles(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let temp: any = [];
                    resp.DataBeanProperties.ObjectValue.forEach((element: any) => {
                        let obj = { id: element.DataBeanProperties.Value, Name: element.DataBeanProperties.Property }
                        temp.push(obj);
                    });
                    setListaHiringRoles(temp);
                }
                setLoading(false);
            }
        )
    }
    const addRoleToApiPermissions = () => {
        setLoading(true);
        service.addRoleToApiPermissions(idApiModule, idRol, arrHiring).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getApiRoleCatalog();
                }
                setLoading(false);
            }
        )
    }
    const removeRoleFromApiPermissions = () => {
        setLoading(true);
        service.removeRoleFromApiPermissions(bean.IDApiModule, bean.IDRole).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getApiRoleCatalog();
                }
                setLoading(false);
            }
        )
    }


    const setRow = (e: any) => {
        setBean(e);
    }
    const addItem = () => {
        setModal({ ...modal, "view": true });
    }

    const idBean = 'IDApiRole';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Role', property: 'RoleName' },
        { title: 'Role Api', property: 'RolePermissions' },

    ]

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton icon="ri-file-add-line" value="Agregar" eventClick={addItem}></BasicButton>
                        {bean[idBean] &&
                            <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={removeRoleFromApiPermissions} ></BasicButton>
                        }
                    </div>
                </div>
            </>
        )
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }




    return (

        <BasicPage titulo="Rol del Api">
            <div className="row">
                <div className="col">
                    <label htmlFor="apimodule">ApiModule</label>
                    <select className="form-control" id="apimodule" value={idApiModule} onChange={(e) => { setIDApiModule(parseInt(e.target.value)) }}>
                        <option value="0">...</option>
                        {listaApiModule.map((item: any) => {
                            return (
                                <option value={item.DataBeanProperties.IDApiModule}>{item.DataBeanProperties.Name} / {item.DataBeanProperties.ClassForName}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <BasicTable listButtons={listaBotones()} head={head} body={listaRol} rowSet={(e: any) => { setRow(e) }} />
                </div>
            </div>
            <Modal modal={modal} updateModal={setModal} eventModal={addRoleToApiPermissions}>
                <div className="row">
                    <div className="col-md-12">
                        <label>Rol del Sistema</label>
                        <select className="form-control" value={idRol} onChange={(e) => { setIDRol(parseInt(e.target.value)) }}>
                            <option value="0">...</option>
                            {listaRolSys.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.IDRole}>{item.DataBeanProperties.Name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <MultiSelect lista={listaHiringRoles} label="Roles de Contratación" getLista={(e: any) => { setArrHiring(e) }}></MultiSelect>
                    </div>
                </div>
            </Modal>
        </BasicPage>

    )
}